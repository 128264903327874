import React from 'react'

export const FilterEvent  = ({
    className = "",
    width = "18px",
    height = "18px",
    viewBox = "0 0 18 18",
    fill = "",
  }) => {

    return (        
            <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
           <path fillRule="evenodd" clipRule="evenodd" d="M2.34477 6.66288C2.34477 5.1865 3.54874 4 5.02061 4C6.49247 4 7.69644 5.1865 7.69644 6.66288C7.69644 8.13926 6.49247 9.32576 5.02061 9.32576C3.54874 9.32576 2.34477 8.13926 2.34477 6.66288ZM5.02061 5.72603C4.4903 5.72603 4.0708 6.15141 4.0708 6.66288C4.0708 7.17435 4.4903 7.59973 5.02061 7.59973C5.55091 7.59973 5.97041 7.17435 5.97041 6.66288C5.97041 6.15141 5.55091 5.72603 5.02061 5.72603ZM8.98929 6.66288C8.98929 6.18625 9.37568 5.79987 9.85231 5.79987H14.6857C15.1624 5.79987 15.5487 6.18625 15.5487 6.66288C15.5487 7.13951 15.1624 7.52589 14.6857 7.52589H9.85231C9.37568 7.52589 8.98929 7.13951 8.98929 6.66288ZM9.80096 11.6214C9.80096 10.1448 11.0051 8.95848 12.4774 8.95848C13.9497 8.95848 15.1526 10.1454 15.1526 11.6214C15.1526 13.0974 13.9497 14.2842 12.4774 14.2842C11.0051 14.2842 9.80096 13.0979 9.80096 11.6214ZM12.4774 10.6845C11.9463 10.6845 11.527 11.1101 11.527 11.6214C11.527 12.1326 11.9463 12.5582 12.4774 12.5582C13.0073 12.5582 13.4266 12.1332 13.4266 11.6214C13.4266 11.1095 13.0073 10.6845 12.4774 10.6845ZM2 12.0982C2 11.6216 2.38638 11.2352 2.86301 11.2352H7.69644C8.17307 11.2352 8.55945 11.6216 8.55945 12.0982C8.55945 12.5749 8.17307 12.9613 7.69644 12.9613H2.86301C2.38638 12.9613 2 12.5749 2 12.0982Z" fill=""/>
            </svg>  

    )
}
