/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./MobileOtpLogin.css";
import { Icons } from "../../components/Icons/Icon";
import { Button } from "../../components/form-type/button";
import { useNavigate } from "react-router-dom";
import OtpInputField from "../../components/otpInput/OtpInput";

const OtpInput = (props) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const number = "+91 12456 7890";
  const num = timer > 0 ? timer : "Resend code";

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleResendCodeClick = () => {
    // You can reset the timer and trigger the resend action
    setTimer(30);
  };
  const handleLoginClick = () => {
    // Now, you can use the inputs variable for further processing
  };
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleOtpChange = (newInputValues) => {
    setOtp(newInputValues);
  };

  return (
    <>
      <div>
        <span onClick={() => handleBackClick()}>
          <Icons name="back" className="svg-size-24 svg-red" />
        </span>
      </div>
      <section className="container-fluid bg-body-tertiary d-block">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div
              className="card bg-white mb-5 mt-5 border-0"
              style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}
            >
              <div className="card-body p-5 text-center">
                <h1 className="f-24">Verification</h1>
                <h4 className="f-w400 mt-2">
                  We have sent you the verification code
                </h4>
                <h4 className="f-w500 text-brand">{number}</h4>
                <div className="otp-field mt-5">
                  <OtpInputField
                    value={otp}
                    onChange={handleOtpChange}
                    type="number"
                  />
                </div>
                <div className="mt-3">
                  {timer > 0 ? (
                    <h4 className="text-gray">
                      Resend code in <span className="text-brand">{num}s</span>
                    </h4>
                  ) : (
                    <h4
                      className="text-brand "
                      onClick={handleResendCodeClick}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Resend code
                    </h4>
                  )}
                </div>
              </div>

              <Button
                className="button btn btn-primary btn-block"
                onClick={handleLoginClick}
                label="Login"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpInput;
