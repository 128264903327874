import React from "react";
import { Card, Placeholder, Row, Col } from "react-bootstrap";

export const SearchSkeleton = ({ number = 1 }) => {
  const skelElement = [];
  for (let index = 0; index < number; index++) {
    skelElement[index] = (
      <Card className="h-60 mb-2" key={index} style={{ marginTop: "20px" }}>
        <Row>
          <Col className="w-60" md={2}>
            <Placeholder
              as={Card.Img}
              variant="top"
              className="rounded-circle w-60"
              animation="glow"
            />
          </Col>
          <Col className="pt-5">
            <Card.Body className="p-0">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={12} style={{ height: "1vh" }} />
              </Placeholder>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  }
  return skelElement;
};
