import React from 'react'

export const ArrowUp = ({
    className = "",
    width = "16px",
    height = "17px",
    viewBox = "0 0 16 17",
    fill = "",
}) => {
  return (
    
         <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9598 12.6891C14.6394 13.0094 14.1201 13.0094 13.7998 12.6891L8.00001 6.88933L2.20027 12.6891C1.87994 13.0094 1.36059 13.0094 1.04026 12.6891C0.719929 12.3687 0.719929 11.8494 1.04026 11.5291L7.42001 5.14931C7.57383 4.99549 7.78247 4.90907 8.00001 4.90907C8.21756 4.90907 8.42619 4.99549 8.58002 5.14931L14.9598 11.5291C15.2801 11.8494 15.2801 12.3687 14.9598 12.6891Z" fill="white"/>
</svg>

  )
}