/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Icons } from "../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";
import { EventCard } from "../../components/cards/EventCard";
import { Footer } from "../../components/Footer/Footer";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useSelector } from "react-redux";
import { Skeleton } from "../../components/cards/Skeleton";

export const MyBooking = () => {
  const navigate = useNavigate();
  const tempLogin = useSelector((state) => state.auth);
  const [bookingData, setBookingData] = useState([]);
  const [myBookingShow, setMyBookingShow] = useState(true);
  const [loading, setLoading] = useState(true);
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  useEffect(() => {
    setMyBookingShow(true);
    const fetchBookingData = async () => {
      try {
        // Make the API call
        const booking = await exportApiAuth.myBooking({
          user_id: userId.user_id,
          login_type: userId.login_type,
        });
        if (!booking) {
          setLoading(false);
          throw new Error("Failed to fetch data");
        }
        setLoading(false);
        setBookingData(booking);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error.message);
      }
    };

    // Call the function to fetch data when the component mounts
    fetchBookingData();
  }, []); // The empty dependency array ensures the effect runs only once, similar to componentDidMount

  const handleBackClick = () => {
    navigate('/more');
  };
  return (
    <>
      <div className="scroll-without-category">
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center">
              <span
                onClick={() => handleBackClick()}
                className="cursor-pointer"
              >
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto pr-24">
                <h1 className="f-24 f-w600 mb-0">{"My Booking"}</h1>
              </div>
            </div>
          </nav>
        </div>
        {bookingData.status === "success" && myBookingShow && Array.isArray(bookingData.data) && bookingData.data.length > 0 ? (
          <EventCard events={bookingData.data} myBookingShow={myBookingShow} />
        ) : loading ? (
          <Skeleton number={5} />
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "calc(100vh - 150px)" }}
          >
            <h3 className="text-gray">You have no booking.</h3>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
