import React from 'react'

export const Listing = ({
    className = "",
    width = "18px",
    height = "18px",
    viewBox = "0 0 18 18",
    fill = "",
}) => {

    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.87721 1.70885H10.8659C11.0157 1.70885 11.159 1.76992 11.2627 1.87796L15.0615 5.83496C15.1598 5.93738 15.2147 6.07387 15.2147 6.21585V13.0885C15.227 14.9563 13.7563 16.4974 11.8903 16.5732C11.8828 16.5735 11.8754 16.5736 11.8679 16.5736H5.88355C5.8651 16.574 5.84892 16.574 5.83719 16.5739L5.80719 16.5736H5.79496C3.9086 16.5316 2.41302 14.9701 2.45069 13.0846V5.04264C2.4945 3.1841 4.01839 1.70229 5.87721 1.70885ZM3.55069 5.06242V13.1023C3.52283 14.3824 4.53639 15.4432 5.81569 15.4737L5.83013 15.4737L5.8462 15.4739L5.85846 15.4739L5.87619 15.4736H11.8562C13.1254 15.4165 14.1238 14.3661 14.1147 13.0943L14.1147 13.0904V6.43713L10.6315 2.80885H5.87408C4.61544 2.80401 3.58359 3.80571 3.55069 5.06242Z" fill="" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6689 1.7C10.9727 1.7 11.2189 1.94624 11.2189 2.25V4.43175C11.2189 5.19327 11.8341 5.81044 12.5957 5.813H14.6612C14.9649 5.813 15.2112 6.05924 15.2112 6.363C15.2112 6.66675 14.9649 6.913 14.6612 6.913H12.5949L12.5934 6.91299C11.2253 6.90914 10.1189 5.79997 10.1189 4.43175V2.25C10.1189 1.94624 10.3652 1.7 10.6689 1.7Z" fill="" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.92867 11.7064C5.92867 11.4026 6.17491 11.1564 6.47867 11.1564H10.5287C10.8324 11.1564 11.0787 11.4026 11.0787 11.7064C11.0787 12.0101 10.8324 12.2564 10.5287 12.2564H6.47867C6.17491 12.2564 5.92867 12.0101 5.92867 11.7064Z" fill="" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.92799 8.892C5.92799 8.58824 6.17424 8.342 6.47799 8.342H8.99499C9.29875 8.342 9.54499 8.58824 9.54499 8.892C9.54499 9.19575 9.29875 9.442 8.99499 9.442H6.47799C6.17424 9.442 5.92799 9.19575 5.92799 8.892Z" fill="" />
        </svg>
    )
}




