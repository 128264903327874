import React, { useEffect, useRef, useState } from "react";
import news from "../../../assets/svg/news.jpg";
import {
  formatDate,
  formatLikeCount,
  getEmbeddedUrl,
  shareBlockFunction,
} from "../../../utils/exportGlobleFunction";
import { Icons } from "../Icons/Icon";
import { useLocation, useNavigate } from "react-router-dom";

import { Carousel, Modal, Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPreviousSubCategoryExplore } from "../../../redux/slices/subCat";

import { setExploreData } from "../../../redux/slices/dataSlice";
import { shareOnMobile } from "react-mobile-share";
import "./NewsCard.css";
import {
  getPlatform,
  newsLikeCount,
  newsViewCount,
} from "../../../utils/constGlobleData";
import { SingleSkeleton } from "./singleSkeleton";
import { Comment } from "../Comments/Comment";
import { GoToLogin } from "../GoToLogin/GoToLogin";
import { Parser } from "html-to-react";
import { CustomCarousal } from "../CustomCarousal.jsx/CustomCarousal";
let playingVideos = [];
const YoutubeIframe = ({ videoSrc }) => {
  const iframeRef = useRef();
  useEffect(() => {
    const iframe = iframeRef.current;
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playingVideos.push(iframe);
        } else {
          iframe?.contentWindow?.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
          // Remove the iframe from the list of playing videos
          playingVideos = playingVideos.filter((v) => v !== iframe);
        }
      });
    };

    const options = {
      threshold: 1, // Adjust as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(iframe);

    return () => {
      observer.disconnect();
    };
  }, [videoSrc]);

  return (
    <iframe
      ref={iframeRef}
      width="560"
      height="315"
      src={`${getEmbeddedUrl(videoSrc)}?enablejsapi=1&rel=0&autoplay=0&muted=1`}
      frameBorder="0"
      allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube video player"
    ></iframe>
  );
};

const stopAllVideos = () => {
  playingVideos.forEach((iframe) => {
    iframe?.contentWindow?.postMessage(
      '{"event":"command","func":"stopVideo","args":""}',
      "*"
    );
  });
};
const AutoPauseVideo = ({ videoSrc, posterImage, baseURL }) => {
  const videoRef = useRef();

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
          // Check if user interacted with the document
          if (document.visibilityState === "visible" && document.hasFocus()) {
            videoElement?.play().catch((error) => {
              // Handle play() promise rejection
              console.error("Failed to play video:", error);
            });
          }
        } else {
          videoElement?.pause();
        }
      });
    };

    const options = {
      threshold: 0.5, // Adjust as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(videoElement);

    return () => {
      observer.disconnect();
    };
  }, [videoSrc, posterImage]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        autoPlay
        poster={
          posterImage && !posterImage.includes("http" || "https")
            ? baseURL + posterImage
            : posterImage || news
        }
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
export const NewsCard = (props) => {
  const location = useLocation();
  const htmlParser = new Parser();
  const baseURL = process.env.REACT_APP_INDORETALK_URL.replace("/api/", "/");
  const handleFormClick = (e) => {
    if (e.target.classList.contains("clickable-icon")) {
      return;
    }
    e.stopPropagation();
  };
  const dispatch = useDispatch();
  const newsData = props.news;
  const singleNews = props.singleNews;
  useEffect(() => {
    if (singleNews?.length === undefined) {
      // Perform some logic when singleNews length is undefined
      playingVideos = [];
    }
  }, [singleNews]);

  const navigate = useNavigate();

  // const [currentSlide, setCurrentSlide] = useState(0);

  // const nextSlide = () => {
  //   setCurrentSlide(currentSlide === singleNews.bannerimage.length - 1 ? 0 : currentSlide + 1);
  // };

  // const prevSlide = () => {
  //   setCurrentSlide(currentSlide === 0 ? singleNews.bannerimage.length - 1 : currentSlide - 1);
  // };

  const exploreNewsById = async (item) => {
    try {
      dispatch(setPreviousSubCategoryExplore(item.category_id));
      dispatch(setExploreData({}));
      navigate(`explore/${item.id}`);
    } catch (error) {
      console.error("error", error);
    }
  };
  const token = "Fullscreen";
  const handleRemoveTokenButton = () => {
    const currentUrl = window.location.href;

    // Remove the token query parameter from the URL
    const newUrl = currentUrl.split("?")[0]; // Removes everything after '?'

    // Update the URL without reloading the page
    window.history.replaceState({ path: newUrl }, "", newUrl);
  };
  const handleAddTokenButton = () => {
    const currentUrl = window.location.href;

    // Append the token as a query parameter to the current URL
    const newUrl = `${currentUrl}?token=${token}`;

    // Update the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const handleClick = (e, item) => {
    // Dispatch the setPreviousSubCategoryExplore(0) action
    dispatch(setPreviousSubCategoryExplore(0));
    // Call the props.handleLikeClick function
    if (!props.liketnDisabled) {
      props.setTempLike(item);
      props.setLiketnDisabled(true);
      // Toggle like state for the clicked news item
      props.setLikeStates((prevStates) => ({
        ...prevStates,
        [item.id]: !prevStates[item.id], // Toggle the like state
      }));
      props.handleLikeClick(e, item);
    }
  };

  const [shareBlock, setShareBlock] = useState(false);

  const handleShareLink = async (item) => {
    const dummyUrl = "https://admin.indoretalk.com/dummy/blank.jpg";
    shareBlockFunction(setShareBlock);
  
    // Check if item is defined and not null
    if (item && !shareBlock) {
      const itemId = item.id;
      const currentUrl = window.location.href;
      const urlWithEvent = `${currentUrl}/explore/${itemId}`;
      const bannerImageUrl = item.videourl && item.bannerimage[0] === '/dummy/blank.jpg' ? dummyUrl : item.bannerimage[0];

      // Ensure item.description is defined before accessing replace method
      const description = item.description ? item.description.replace(/<[^>]+>/g, "") : "";

      const shareParams = {
        imageUrl: bannerImageUrl,
        title: item.heading,
        description: description, // Use the sanitized description
        url: urlWithEvent,
      };
    

      if (getPlatform() === "Android") {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          const { title, url, description, imageUrl } = shareParams;
          // eslint-disable-next-line no-undef
          Android.callShareIntent(title, url, description, imageUrl);
        }
      }else if(getPlatform() === "Mac OS" || getPlatform() === "iOS"){
        const { title, url, description, imageUrl } = shareParams;
        window.webkit.messageHandlers.share.postMessage(shareParams);
      }  
      else {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          shareOnMobile(shareParams);
        }
      }
    }
  };


  const handleShare = async (item) => {
    const dummyUrl = "https://admin.indoretalk.com/dummy/blank.jpg";
    shareBlockFunction(setShareBlock);
    if (!shareBlock) {
      const bannerImageUrl = item.videourl && item.bannerimage[0] === '/dummy/blank.jpg' ? dummyUrl : item.bannerimage[0];
      const shareParams = {
        imageUrl: bannerImageUrl,
        title: item.heading,
        description: item.description.replace(/<[^>]+>/g, ""), // Remove HTML tags for plain text
        url: window.location.href, // Use the banner image URL
      };

      if (getPlatform() === "Android") {
        if (navigator.share) {
          try {
            await navigator.share(shareParams);
          } catch (error) {
            console.error("Error sharing content:", error);
          }
        } else {
          const { title, url, description, imageUrl } = shareParams;
          // eslint-disable-next-line no-undef
          Android.callShareIntent(title, url, description, imageUrl);
        }
      } else if(getPlatform() === "Mac OS" || getPlatform() === "iOS"){
        const { title, url, description, imageUrl } = shareParams;
        window.webkit.messageHandlers.share.postMessage(shareParams);
      } else {
        if (navigator.share) {
          try {
            await navigator.share(shareParams);
          } catch (error) {
            console.error("Error sharing content:", error);
          }
        } else {
          shareOnMobile(shareParams);
        }
      }
    }
  };

  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState("");

  const [showLoginModal, setLoginModalShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    setShow(true);
    setShowId(id);
  };

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleVideoClick = (e, videosrc) => {
    e.stopPropagation();
    stopAllVideos();
    handleAddTokenButton();
    setSelectedVideo(videosrc);
    setShowVideoModal(true);
  };
  return (
    <>
      <>
        {Array.isArray(newsData) && newsData?.length > 0 && !singleNews ? (
          <>
            {newsData
              .filter((news) => news?.is_active !== 0)
              .map((item, index) => (
                <div
                  className="card mb-3"
                  key={index}
                  onClick={() => exploreNewsById(item)}
                >
                  {item.videos ? (
                    <div style={{ minHeight: "30vh" }}>
                      <AutoPauseVideo
                        videoSrc={item.videos}
                        posterImage={item.bannerimage[0]}
                        baseURL={baseURL}
                      />
                    </div>
                  ) : item.videourl ? (
                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ position: "relative" }}
                    >
                      <div
                        onClick={(e) => handleVideoClick(e, item.videourl)}
                        style={{
                          position: "absolute",
                          width: "50px",
                          height: "50px",
                          zIndex: 1,
                          bottom: "0px",
                          right: "0px",
                          minHeight: "30vh",
                        }}
                      ></div>
                      <YoutubeIframe videoSrc={item.videourl} />
                    </div>
                  ) : item.bannerimage.length > 0 ? (
                    <Carousel interval={null} controls={false}>
                      {item.bannerimage.map((image, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            // src={image}
                            src={
                              image && !image.includes("http" || "https")
                                ? baseURL + image
                                : image || news
                            }
                            alt={`Slide ${index}`}
                            style={{ maxnHeight: "70vh", minHeight: "30vh" }}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <img
                      src={
                        news && !news.includes("http" || "https")
                          ? baseURL + news
                          : news || news
                      }
                      className="card-img-top"
                      alt="news"
                      style={{ maxnHeight: "70vh", minHeight: "30vh" }}
                    />
                  )}
                  <div className="card-body">
                    <h2 className="card-title text-light-black f-14 m-b-2">
                      {item.heading}
                    </h2>
                    <form
                      className="d-flex mt-0 justify-content-between"
                      onClick={(e) => handleFormClick(e)}
                    >
                      <Stack direction="horizontal" gap={1}>
                        <div className="d-flex ">
                          {props?.liketnDisabled &&
                            props.likeStates[item.id] ? (
                            <div className="like-svg-and-count d-flex align-items-center ">
                              <Icons
                                onClick={(e) => handleClick(e, item)}
                                name="like"
                                className={` img-fluid cursor-pointer
                                                                ${props.tempLike
                                    .id ===
                                    item.id
                                    ? props
                                      .tempLike
                                      .like ===
                                      0
                                      ? "svg-red"
                                      : "svg-white"
                                    : props
                                      .tempLike
                                      .like ===
                                      1
                                      ? "svg-white"
                                      : "svg-red"
                                  }
                                                           `}
                              />
                              <h4 className="f-14 f-w500 mb-0 p-left2 text-dark m-t-2 lh-18">
                                {props.tempLike.id === item.id &&
                                  item.like_count + newsLikeCount < 1000
                                  ? props.tempLike.like !== 0
                                    ? item.like_count + newsLikeCount - 1
                                    : item.like_count + newsLikeCount + 1
                                  : formatLikeCount(
                                    item.like_count + newsLikeCount
                                  )}
                              </h4>
                            </div>
                          ) : (
                            <div className="like-svg-and-count d-flex align-items-center ">
                              <Icons
                                onClick={(e) => handleClick(e, item)}
                                name="like"
                                className={` img-fluid cursor-pointer
                                                                ${item.like ===
                                    1
                                    ? "svg-red"
                                    : "svg-white"
                                  }
                                                             `}
                              />
                              <h4 className="f-14 f-w500 mb-0 p-left2 text-dark m-t-2 lh-18">
                                {formatLikeCount(
                                  item.like_count + newsLikeCount
                                )}{" "}
                              </h4>
                            </div>
                          )}
                          <div className="like-svg-and-count d-flex align-items-center cursor-pointer ">
                            {show && showId === item.id && (
                              <Comment
                                postId={item}
                                show={show}
                                handleClose={handleClose}
                              />
                            )}

                            <div
                              onClick={() => handleShow(item.id)}
                              className="cursor-pointer"
                            >
                              <Icons name="comment" className="svg-white" />
                            </div>
                            <h4 className="f-14 f-w500 mb-0 pl-2 text-dark m-t-2 lh-18">
                              {item.postcomments_count}
                            </h4>
                          </div>
                          <span
                            onClick={async () => handleShareLink(item)}
                            className="cursor-pointer"
                          >
                            <Icons
                              name="share"
                              className="svg-white img-fluid "
                            />
                          </span>
                        </div>
                      </Stack>

                      <div className="d-flex align-items-center ">
                        <Icons name="show" className="svg-white" />
                        <h5 className="mt-2 f-w600 text-like pl-1">
                          {" "}
                          {formatLikeCount(item.view_count + newsViewCount)}
                        </h5>

                        <Icons name="calender" className="svg-white ml-2" />
                        <h5 className="mt-2 f-w600 text-like pl-1">
                          {" "}
                          {formatDate(item.start_time, "shortdate")}
                        </h5>
                      </div>
                    </form>
                  </div>
                </div>
              ))}
            {showLoginModal && (
              <GoToLogin
                show={showLoginModal}
                onHide={() => setLoginModalShow(false)}
                location={location}
              />
            )}
          </>
        ) : (
          <>
            {singleNews ? (
              <div className=" scroll-without-category">
                <div className="card">
                  {singleNews.videos ? (
                    <>
                      <div style={{ minHeight: "30vh" }}>
                        <video
                          id="myVideo"
                          width="100%"
                          height="100%"
                          autoPlay
                          muted={false}
                          poster={
                            singleNews.bannerimage[0] &&
                              !singleNews.bannerimage[0].includes(
                                "http" || "https"
                              )
                              ? baseURL + singleNews.bannerimage[0]
                              : singleNews.bannerimage[0] || news
                          }
                        >
                          <source src={singleNews.videos} type="video/mp4" />
                        </video>
                      </div>
                    </>
                  ) : singleNews.videourl ? (
                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ position: "relative" }}
                    >
                      <div
                        onClick={(e) =>
                          handleVideoClick(e, singleNews.videourl)
                        }
                        style={{
                          position: "absolute",
                          width: "50px",
                          height: "50px",
                          zIndex: 1,
                          bottom: "0px",
                          right: "0px",
                          minHeight: "30vh",
                        }}
                      ></div>
                      <YoutubeIframe videoSrc={singleNews.videourl} />
                    </div>
                  ) : singleNews.bannerimage?.length > 0 ? (
                    <>
                      {/* <Carousel
                      controls={
                        singleNews.bannerimage?.length > 1 ? true : false
                      }

                    >
                      {singleNews.bannerimage.map((image, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={
                              image && !image.includes("http" || "https")
                                ? baseURL + image
                                : image || news
                            }
                            alt={`Slide ${index}`}
                            style={{ maxnHeight: "70vh", minHeight: "30vh" }}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel> */}

                      <CustomCarousal
                        data={singleNews.bannerimage}
                        pages="false"
                      />

                      {/* 
                    <div style={{ position: "relative", display: "flex" }}>
                      <button className="justify-center" style={{ background: "transparent", width: "30px", height: "100%", position: "absolute", zIndex: "1", border: "none" }} onClick={prevSlide}>
                        <Icons
                          name="arrow-left"
                          className="svg-white  cursor-pointer next-arrow"
                        />
                      </button>
                      <button className="justify-center" style={{ background: "transparent", width: "30px", height: "100%", position: "absolute", right: "0", zIndex: "1", border: "none" }} onClick={nextSlide}>
                        <Icons
                          name="arrow-right"
                          className="svg-white svg-size-20 cursor-pointer next-arrow"
                        />
                      </button>
                      <div style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                        transition: "transform 0.5s ease-in-out", display: "flex"
                      }}>{console.log("singleNews.bannerimage", singleNews.bannerimage)}
                        {singleNews.bannerimage.map((image, index) => (

                          <img
                            className="d-block w-100"
                            src={
                              image && !image.includes("http" || "https")
                                ? baseURL + image
                                : image || news
                            }
                            alt={`Slide ${index}`}
                            style={{
                              maxnHeight: "70vh", minHeight: "30vh",
                            }}
                          />

                        ))}
                      </div>
                    </div>




 */}
                    </>
                  ) : (
                    <img
                      src={
                        singleNews.bannerimage &&
                          singleNews.bannerimage.includes("dummy")
                          ? baseURL + singleNews.bannerimage
                          : singleNews.bannerimage || news
                      }
                      className="card-img-top"
                      alt="news"
                      style={{ maxnHeight: "70vh" }}
                    />
                  )}
                  <div className="card-body">
                    <h2 className="card-title text-light-black f-14">
                      {singleNews.heading}
                    </h2>

                    <form className="d-flex justify-content-between">
                      <Stack direction="horizontal" gap={1}>
                        <div className="d-flex ">
                          {props?.liketnDisabled ? (
                            <div className="like-svg-and-count d-flex align-items-center ">
                              <Icons
                                onClick={(e) => handleClick(e, singleNews)}
                                name="like"
                                className={`img-fluid cursor-pointer
                                                                ${singleNews.like ===
                                    0
                                    ? "svg-red"
                                    : "svg-white"
                                  }
                                                                `}
                              />
                              <h4 className="f-14 f-w500 mb-0 p-left2 text-dark m-t-2 lh-18">
                                {singleNews.like_count + newsLikeCount < 1000
                                  ? singleNews.like === 0
                                    ? singleNews.like_count + newsLikeCount + 1
                                    : singleNews.like_count + newsLikeCount - 1
                                  : formatLikeCount(
                                    singleNews.like_count + newsLikeCount
                                  )}
                              </h4>
                            </div>
                          ) : (
                            <div className="like-svg-and-count d-flex align-items-center ">
                              <Icons
                                onClick={(e) => handleClick(e, singleNews)}
                                name="like"
                                className={` img-fluid cursor-pointer
                                                            ${singleNews.like ===
                                    1
                                    ? "svg-red"
                                    : "svg-white"
                                  }
                                                                `}
                              />
                              <h4 className="f-14 f-w500 mb-0 p-left2 text-dark m-t-2 lh-18">
                                {formatLikeCount(
                                  singleNews.like_count + newsLikeCount
                                )}{" "}
                              </h4>
                            </div>
                          )}

                          <div className="like-svg-and-count d-flex align-items-center  ">
                            {show && showId === singleNews.id && (
                              <Comment
                                postId={singleNews}
                                show={show}
                                handleClose={handleClose}
                              />
                            )}

                            <div
                              onClick={() => handleShow(singleNews.id)}
                              className="cursor-pointer"
                            >
                              <Icons name="comment" className="svg-white " />
                            </div>
                            <h4 className="f-14 f-w500 mb-0 pl-2 text-dark m-t-2 lh-18">
                              {singleNews.postcomments_count}
                            </h4>
                          </div>
                          <span onClick={async () => handleShare(singleNews)}>
                            <Icons
                              name="share"
                              className="svg-white img-fluid cursor-pointer"
                            />
                          </span>
                        </div>
                      </Stack>

                      <div className="d-flex align-items-center ">
                        <Icons name="show" className="svg-white" />
                        <h5 className="mt-2 f-w600 text-like pl-1">
                          {" "}
                          {formatLikeCount(
                            singleNews.view_count + newsViewCount
                          )}
                        </h5>

                        <Icons name="calender" className="svg-white ml-2" />
                        <h5 className="mt-2 f-w600 text-like pl-1">
                          {" "}
                          {formatDate(singleNews.start_time, "shortdate")}
                        </h5>
                      </div>
                    </form>

                    {singleNews?.description !== "<p>&nbsp;</p>" ? (
                      <>
                        <p className="text-black detail-dec py-2 mb-0">
                          {htmlParser.parse(singleNews?.description)}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <SingleSkeleton />
            )}
            {showLoginModal && (
              <GoToLogin
                show={showLoginModal}
                onHide={() => setLoginModalShow(false)}
                location={location}
              />
            )}
          </>
        )}
      </>

      <Modal
        show={showVideoModal}
        onHide={() => {
          handleRemoveTokenButton();
          setShowVideoModal(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title "
        style={{
          "--bs-modal-margin": 0,
          "--bs-modal-width": "100vw",
          overflowY: "hidden",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <div
          className="embed-responsive embed-responsive-16by9 video-modal "
          style={{ position: "relative" }}
        >
          <div
            onClick={() => {
              handleRemoveTokenButton();
              setShowVideoModal(false);
            }}
            style={{
              position: "absolute",
              width: "50px",
              height: "50px",
              zIndex: 1,
              bottom: "0px",
              right: "0px",
            }}
          ></div>
          <iframe
            id="yourIframeId"
            className="embed-responsive-item"
            title={`Video `}
            src={`${getEmbeddedUrl(selectedVideo)}?autoplay=1&rel=0`}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;web-share "
            autoFocus
          ></iframe>
        </div>
      </Modal>
    </>
  );
};
