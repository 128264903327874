import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Icons } from '../Icons/Icon';
import { Button } from '../form-type/button';
import { useSelector } from "react-redux";
import './PaymentStatus.css';
import { useLocation, useNavigate } from "react-router-dom";
import Ticket from '../Ticket/Ticket';

export const PaymentStatus = (props) => {
    const eventId = localStorage.getItem("event_id");
    const location = useLocation();
    const { paymentResponse, modal } = location.state || {};
    const [ticketShow, setticketShow] = useState(true);
    const { singleData } = useSelector((state) => state.singleData);
    const navigate = useNavigate();
    const backpressbutton = async () => {
        navigate(`/event-home/event/${eventId}`);
    };

    useEffect(() => {
        const eventId = localStorage.getItem("event_id");
        if (paymentResponse?.status !== 'success') {
            const timer = setTimeout(() => {
                navigate(`/event-home/event/${eventId}`);
            }, 5000); // 5 seconds

            return () => clearTimeout(timer);
        }
    }, [paymentResponse, singleData, navigate]); // Add singleData to dependencies

    return (
        <>
            <div className="top-header-fix">
                <nav className="navbar top-nav navbar-light bg-light text-center" style={{ position: "fixed" }}>
                    <div className="container-fluid mx-auto text-center">
                        <div className="m-auto">
                            <h1 className="f-24 f-w600 mb-0">Payment</h1>
                        </div>
                    </div>
                </nav>
            </div>

            {ticketShow ?
                <div className="App p-4 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                    <Modal size="sm" show={props?.show ? props?.show : modal} centered>
                        <Modal.Header className="bs-highlight-bg">
                        </Modal.Header>

                        <div className="d-flex justify-content-center">
                            {(paymentResponse?.status === 'success') || (props?.show) ? <Icons name="payment-success" /> : <Icons name="payment-unsuccess" />}
                        </div>
                        <Modal.Body className='modal-body-fix'>
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center'>
                            <p className='f-w600 text-center f-18 payment-modal-text'>
                                {(paymentResponse?.status === 'success') || (props?.show) ? 'Payment Successful' : 'Payment Unsuccessful'}
                            </p>
                            {(paymentResponse?.status === 'success') || (props?.show) ? (
                                <>
                                    <p className='text-center text-disabled f-12 f-w500'>
                                        You can view your ticket in the "More" section under the "My Booking" tab.
                                    </p>
                                    <Button
                                        className="btn btn-secondary btn-bg-red text-dark-white"
                                        style={{ height: "56px", width: "256px" }}
                                        onClick={() => setticketShow(false)}
                                        label='View Ticket'
                                    />
                                </>
                            ) : (
                                <div>
                                    <p className='text-center text-disabled f-12 f-w500'>
                                        Wait for a few seconds, we will redirect to the previous screen.
                                    </p>
                                    <Button
                                        className="btn btn-secondary btn-bg-red text-dark-white"
                                        style={{ height: "56px", width: "100%" }}
                                        onClick={backpressbutton}
                                        label='Go to back page'
                                    />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal>
                </div>
                :
                <p className='text-center text-white mt-5'>
                    <Ticket eventId={Array.isArray(paymentResponse?.data) ? paymentResponse?.data[0]?.event_id : paymentResponse?.data?.event_id} />
                </p>
            }
        </>
    );
};
