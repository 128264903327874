import React from 'react'

export const Minus = ({
    className = "",
    width = "24px",
    height = "24px",
    viewBox = "0 0 24 24",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect opacity="0.1" width="24" height="24" rx="12" fill="" />
            <path d="M16.8001 12.0001L7.20007 12.0001" stroke="#5C5C64" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}