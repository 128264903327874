import React, { useEffect, useState } from "react";
import { NewsCard } from "../../components/cards/NewsCard";
import { Footer } from "../../components/Footer/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { exportApi } from "../../../services/indoreTalk.api";
import { useSelector, useDispatch } from "react-redux";
import { Icons } from "../../components/Icons/Icon";
import { likePayloads, unnecatchLogout } from "../../../utils/exportGlobleFunction";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { SingleSkeleton } from "../../components/cards/singleSkeleton";
import { Toast } from "react-bootstrap";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from 'store'

export const NewsDetails = () => {
  const id = useParams();
  const [successToast, setSuccessToast] = useState(null);
  const dispatch = useDispatch();
  const tempLogin = useSelector((state) => state.auth);
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [singleNews, setSingleNews] = useState({});
  const location = useLocation();
  const [tempLike, setTempLike] = useState({});
  const [liketnDisabled, setLiketnDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [likeStates, setLikeStates] = useState({});
  const [errorToast, setErrorToast] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await exportApi.exploreNewsGetById({
          id: id.id,
          user_id: userId?.user_id || 0,
        });
        if (res) {
          setSingleNews(Array.isArray(res.data) ? res.data[0] : res.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id.id, userId?.user_id]); // Include userId.user_id in the dependency array

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const [showLoginModal, setLoginModalShow] = useState(false);
  const [sessionExpire, setSessionExpire] = useState(false);

  const handleLikeClick = async (e, data) => {
    e.stopPropagation(); // Stop the click event from propagating
    // Handle like button click logic here
    if (!!userId) {
      try {
        setLikeStates((prevStates) => ({
          ...prevStates,
          [data.id]: !prevStates[data.id], // Toggle the like state
        }));
        setLiketnDisabled(true);
        setTempLike(data.id);
        const like = await exportApiAuth.likePost(likePayloads(data, userId));
        if (like.status === "success") {
          setSingleNews(like.data);
          setLiketnDisabled(false);
          setTempLike({});
          setLikeStates({});
        } else {
          // if (like.code === 401) {
          setLiketnDisabled(false);
          // setErrorToast(true);
          setSessionExpire(true);
          setTempLike({});
          setLikeStates({});
          unnecatchLogout(store, dispatch, setSignOut)
          // }
        }
      } catch (error) {
        console.log("error", error);
        setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
      }
    } else {
      setLoginModalShow(true);
      setSessionExpire(false);
      setLiketnDisabled(false);
      setTempLike({});
      setLikeStates({});
    }
  };

  const handleReport = async () => {
    try {
      if(userId){
      const report = {
        login_type: userId?.login_type,
        user_id:userId?.user_id,
        email: tempLogin?.tempLogin?.user?.data?.email,
        postid:id.id,
        type:'news'
      }
      setSuccessToast({
        message: "Report havs been sent successfully to admin",
      });
      await exportApiAuth.reportPost(report)         
    }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center ">
          <div className="container-fluid mx-auto text-center pr-3">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto ">
              <h1 className="f-24 f-w600 mb-0">{"Explore"}</h1>
            </div>
            <div onClick={() => handleReport()}><Icons name="report"  className=" svg-white" /></div>
          </div>
        </nav>
      </div>
      {isLoading ? (
        <SingleSkeleton />
      ) : (
        <NewsCard
          singleNews={singleNews}
          handleLikeClick={handleLikeClick}
          setLiketnDisabled={setLiketnDisabled}
          liketnDisabled={liketnDisabled}
          setTempLike={setTempLike}
          tempLike={tempLike}
          likeStates={likeStates}
          setLikeStates={setLikeStates}
        />
      )}
      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
          loginResion={sessionExpire}
        />
      )}
     
      <Footer />
      {errorToast && (
        <Toast
          onClose={() => setErrorToast(false)}
          show={true}
          delay={3000}
          className="toast-dialog"
          autohide={true}
          style={{ bottom: "94px" }}
        >
          <Toast.Body style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}>
            {"Somthing went wrong"}
          </Toast.Body>
        </Toast>
      )}
      {successToast && (
        <Toast
          onClose={() => setSuccessToast(null)}
          show={true}
          delay={3000}
          className="report-toaster"
          autohide
        >
          <Toast.Body
            style={{ backgroundColor: "#C5FEB5", color: "#196004" }}
          >
            {successToast.message}
          </Toast.Body>
        </Toast>
      )}

    </div>
  );
};
