import IndoreTalkService from './indoreTalk.service';

class IndoreTalkApi extends IndoreTalkService {
  async signInAndSignUp(params) {
    return await this.post('login', params);
  }
  async signInAndSignUpWithWhatsapp(params) {
    return await this.post('mobile-login', params);
  }
  async generateOtp(params) {
    return await this.post('generate-otp', params);
  }
  async generateOtpWithWhatsapp(params) {
    return await this.post('generate-whatsapp-otp', params);
  }
  async verifyOtp(params) {
    return await this.post('verify-otp', params);
  }
  async viewCount(params) {
    return await this.post('postview', params);
  }
  async maincategories(params) {
    return await this.get('maincategories');
  }
  async mainSubCategories(params) {
    return await this.get(`maincategories/${params}`);
  }
  async exploreNewsGet(params) {
    return await this.post('explorenews', params);
  }
  async exploreLiveNewsGet(params) {
    return await this.post('explorelive', params);
  }
  async exploreNewsGetById(params) {
    return await this.post('explore/single', params);
  }
  async exploreEventGet(params) {
    return await this.post('event', params);
  }
  async exploreEventGetById(params) {
    return await this.post('event/single', params);
  }
  async businessGet(params) {
    return await this.post('listing', params);
  }
  async businessById(params) {
    return await this.post('listing/single', params);
  }
  async search(params) {
    return await this.post('search', params);
  }
  async listPolls(params) {
    return await this.post('listpolls', params);
  }
  async scriptionPlain() {
    return await this.get('sub-scription-list');
  }
  async scriptionOrder(params) {
    return await this.post('sub-scription-order', params);
  }
  async commentLoad(params) {
    return await this.post('comment', params);
  }
  async businessCounter(params) {
    return await this.post('listing/business-counter', params);
  }
}

export const exportApi = new IndoreTalkApi();