// NavBar.js
import React, { useEffect, useRef } from 'react';
import { titleCase } from '../../../utils/exportGlobleFunction';
import { useDispatch, useSelector } from 'react-redux';
import { setAgainRedirectionPath } from '../../../redux/slices/authSlice';
const NavBar = ({ subCatNav, activeSubCat, subCatNavChange }) => {
    const { pathBoolean } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const containerRef = useRef(null);

    // Call the scroll function when the component mounts or when the active category changes
    useEffect(() => {
        scrollActiveCategoryIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSubCat])
    const scrollActiveCategoryIntoView = async() => {
        setTimeout(() => {
                const links = document.querySelector(`#nav-scrollNav${activeSubCat}`);
                if(links){
                    links.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
        }, 50)

    };;

    useEffect(() => {
        if (!pathBoolean) {
            dispatch(setAgainRedirectionPath({
                activePathCat: activeSubCat,
            }))
        }// eslint-disable-next-line
    }, [activeSubCat])
    return (
        <div className="d-flex justify-content-between" style={{ marginTop: '0' }} ref={containerRef}>
            {Array.isArray(subCatNav) && subCatNav.map((data) => {
                return (
                    <div key={data.id} id={`nav-scrollNav${data.id}`} className={`main-cat-width text-center mt-2  ${activeSubCat === data.id ? 'active' : 'text-white'}`} onClick={() =>
                        activeSubCat !== data.id ?
                            subCatNavChange(data.id, data?.sub_category[0]?.id) : {}}>
                        <div dangerouslySetInnerHTML={{ __html: data.icon }} className={`${activeSubCat === data.id ? 'svg-red ' : 'svg-white'}`} style={{ fill: activeSubCat === data.id ? 'red !important' : 'yourInactiveColor' }} />
                        <h5 className="f-11" style={{ marginTop: '0px' }}>{titleCase(data.title)}</h5>
                    </div>
                )
            })}
        </div>
    );
};

export default NavBar;
