import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer/Footer";
import { EventCard } from "../../components/cards/EventCard";
import { Icons } from "../../components/Icons/Icon";
import { exportApi } from "../../../services/indoreTalk.api";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/navbar/navbar";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { likePayloads, unnecatchLogout } from "../../../utils/exportGlobleFunction";
import { clearTicketCount } from "../../../redux/slices/singleDataSlice";
import { Skeleton } from "../../components/cards/Skeleton";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { useSwipeable } from "react-swipeable";
import { apiCallingLimit, getPlatform } from "../../../utils/constGlobleData";
import { Toast } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from 'store'

export const EventHome = () => {
  const { tempLogin, redirectionPath } = useSelector((state) => state.auth);
  const subCategories = useSelector((state) => state.subCat);
  const userId = tempLogin?.user?.data?.social_provider;
  const subCatNav = subCategories ? subCategories.subEventCategory : [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [catId, setCatId] = useState(
    subCategories.previousSubCategoryEvent > 0
      ? subCategories.previousSubCategoryEvent
      : redirectionPath.pathBoolean
        ? redirectionPath.activePathCat
        : subCatNav[0]?.id ||
        parseInt(localStorage.getItem("eventsFirstCategory"))
  );
  // eslint-disable-next-line no-unused-vars
  const [like, setLike] = useState();
  const [activeSubCat, setActiveSubCat] = useState(
    subCategories.previousSubCategoryEvent > 0
      ? subCategories.previousSubCategoryEvent
      : catId
  );
  const [event, setEvent] = useState([]);
  const [infinite, setInfinite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [limitEvent, setLimitEvent] = useState(0);
  const [liketnDisabled, setLiketnDisabled] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [tempLike, setTempLike] = useState({});
  const [likeStates, setLikeStates] = useState({});
  const [sessionExpire, setSessionExpire] = useState(false);
  const [eventBlock, setEventBlock] = useState(false);
  const [iosBlock, setIosBlock] = useState("");

  const fetchEvent = async (id, user_id, page) => {
    try {
      setIsLoading(true);
      setActiveSubCat(id);
      setLimitEvent(page || 0);
      const res = await exportApi.exploreEventGet({
        page: page || 0,
        limit: apiCallingLimit,
        category_id: id,
        event_type: "post",
        user_id: user_id || 0,
      });
      if (res?.data?.data === "Not found!" && res?.data?.status === "fail") {
        setEvent([]);
      }
      setInfinite(res.data.length < apiCallingLimit);
      if (res.status === "success") {
        // Filtering the existing business data
        const filteredBusiness = event.filter(
          (item) => item.category_id === id
        );
        setEvent([...filteredBusiness, ...res.data]);
        setLiketnDisabled(false);
        setLikeStates({});
        setTempLike({});
      }
      setLiketnDisabled(false);
      setLikeStates({});
      setTempLike({});
      setIsLoading(false);
    } catch (err) {
      setLiketnDisabled(false);
      setLikeStates({});
      setTempLike({});
      console.error("Error fetching news:", err);
    }
  };

  useEffect(() => {
    setIosBlock(getPlatform());
    // getPlatform() === "Mac OS" || getPlatform() === "iOS"
  }, []);

  const subCatNavChange = (subId) => {
    setEventBlock(true);
    setIsLoading(true);
    setEvent([]);
    fetchEvent(subId, userId?.user_id);
    setActiveSubCat(subId);
    // Scroll to the top of the scrollable div
    document.getElementById("scrollableDiv").scrollTop = 0;
  };
  useEffect(() => {
    dispatch(clearTicketCount());
    fetchEvent(
      subCategories.previousSubCategoryEvent > 0
        ? subCategories.previousSubCategoryEvent
        : like
          ? activeSubCat
          : catId,
      userId?.user_id || 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userId?.user_id]);

  const [showLoginModal, setLoginModalShow] = useState(false);

  const handleLikeClick = async (e, data) => {
    e.stopPropagation(); // Stop the click event from propagating
    // Handle like button click logic here
    if (!!userId) {
      try {
        // Finding the index to replace based on the id
        const indexToUpdate = event.findIndex((item) => item.id === data.id);
        const like = await exportApiAuth.likePost(likePayloads(data, userId));
        if (like.status === "success") {
          let likedItemData = Array.isArray(like.data)
            ? like.data[0]
            : like.data;
          if (indexToUpdate !== -1 && likedItemData) {
            // Replace the item at the found index with the new liked item data
            event[indexToUpdate] = likedItemData;
            // Update the state with the modified news array
            // setEvent([...event]);
          }
          setLiketnDisabled(false);
          setLikeStates({});
        } else {
          // if (like.code === 401) {
          setLoginModalShow(true)
          setSessionExpire(true)
          unnecatchLogout(store, dispatch, setSignOut)
          // }
          // setErrorToast(true);
          setLiketnDisabled(false);
          setLikeStates({});
        }
      } catch (error) {
        console.log("error", error);
        setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
      }
    } else {
      setLoginModalShow(true);
      setSessionExpire(false);
      setLiketnDisabled(false);
      setLikeStates({});
    }
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const i =
        Array.isArray(subCategories.subEventCategory) &&
        subCategories.subEventCategory.findIndex(
          (item) => item.id === activeSubCat
        );
      if (subCategories.subEventCategory.length > i + 1) {
        const swipeSubId = subCategories.subEventCategory[i + 1]?.id;
        subCatNavChange(swipeSubId);
      } else {
        console.log("Cannot swipe left, no more items in subEventCategory");
      }
    },
    onSwipedRight: () => {
      const i =
        Array.isArray(subCategories.subEventCategory) &&
        subCategories.subEventCategory.findIndex(
          (item) => item.id === activeSubCat
        );
      if (0 < i) {
        const swipeSubId = subCategories.subEventCategory[i - 1]?.id;
        subCatNavChange(swipeSubId);
      } else {
        console.log("Cannot swipe right, no more items in subEventCategory");
      }
    },
  });
  return (
    <div>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center ">
          <div className="container-fluid mx-auto text-center pr-3">
            <div className="m-auto pl-28 ">
              <h1 className="f-24 f-w600 mb-0">Event</h1>
            </div>
            <div>
              <span
                onClick={() => navigate("/home/search")}
                className="cursor-pointer"
              >
                <Icons name="search" className="svg-size-24 svg-white" />
              </span>
            </div>
          </div>
        </nav>

        <div className="top-header main-categ-container">
          <NavBar
            subCatNav={subCatNav}
            activeSubCat={activeSubCat}
            subCatNavChange={subCatNavChange}
          />
        </div>
      </div>
      <div id="scrollableDiv" className="auto-load-table" {...handlers}>
        <InfiniteScroll
          dataLength={event?.length || apiCallingLimit}
          next={() => {
            console.log("Next function called");
            fetchEvent(
              subCategories.previousSubCategoryEvent > 0
                ? subCategories.previousSubCategoryEvent
                : like
                  ? activeSubCat
                  : activeSubCat
                    ? activeSubCat
                    : catId,
              userId?.user_id || 0,
              limitEvent + 1
            );
          }}
          hasMore={!infinite}
          loader={
            !infinite && (
              <div className="d-flex justify-center mt-2">
                <Spinner animation="border" className=" " />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {event?.length !== 0 &&
            event?.filter((event) => event.is_active !== 0).length > 0 ? (
            <EventCard
              events={event}
              handleLikeClick={handleLikeClick}
              setLiketnDisabled={setLiketnDisabled}
              liketnDisabled={liketnDisabled}
              setTempLike={setTempLike}
              tempLike={tempLike}
              likeStates={likeStates}
              setLikeStates={setLikeStates}
              iosPaidEventFilter={iosBlock}
            />
          ) : isLoading ? (
            <Skeleton number={5} />
          ) : (
            <p
              className="text-black text-center f-16"
              style={{ marginTop: "36vh" }}
            >
              No event found!
            </p>
          )}
        </InfiniteScroll>
      </div>
      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
          loginResion={sessionExpire}
        />
      )}
      {errorToast && (
        <Toast
          onClose={() => setErrorToast(false)}
          show={true}
          delay={3000}
          className=""
          autohide={true}
          style={{ marginTop: "-60px", zIndex: "999", width: "100%" }}
        >
          <Toast.Body style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}>
            {"Somthing went wrong"}
          </Toast.Body>
        </Toast>
      )}
      <Footer
        fetchdata={fetchEvent}
        setClickBlock={setEventBlock}
        clickBlock={eventBlock}
      />
    </div>
  );
};
