import React from "react";

export const CartBanner = ({
  className = "",
  width = "223.11px",
  height = "284px",
  viewBox = "0 0 223.11 284",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.789 11.3926C185.789 5.10059 190.889 0 197.181 0C203.473 0 208.574 5.10059 208.574 11.3926C208.574 17.6846 203.473 22.7852 197.181 22.7852C190.889 22.7852 185.789 17.6846 185.789 11.3926ZM9.64966 152.278C9.64966 97.4482 54.0979 53 108.928 53C163.757 53 208.206 97.4482 208.206 152.278C208.206 207.108 163.757 251.556 108.928 251.556C54.0979 251.556 9.64966 207.108 9.64966 152.278ZM17.7484 266.911C13.0294 266.911 9.20398 270.737 9.20398 275.456C9.20398 280.174 13.0294 284 17.7484 284C22.4673 284 26.2928 280.174 26.2928 275.456C26.2928 270.737 22.4673 266.911 17.7484 266.911Z"
        fill="#EEEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3658 33.4832C34.7934 27.4811 23.7892 28.7539 17.7871 36.3263C11.7849 43.8987 13.0578 54.903 20.6301 60.9051C28.2025 66.9073 39.2068 65.6343 45.209 58.062C51.211 50.4897 49.9382 39.4854 42.3658 33.4832ZM179.638 264.671C187.028 270.897 198.065 269.954 204.291 262.565C210.517 255.175 209.574 244.138 202.184 237.912C194.795 231.686 183.758 232.629 177.532 240.018C171.306 247.408 172.249 258.445 179.638 264.671Z"
        fill="#2B2A29"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.2026 194.943C78.2026 190.743 81.6026 187.343 85.8026 187.343C89.9526 187.343 93.3526 190.743 93.3526 194.943C93.3526 199.093 89.9526 202.493 85.8026 202.493C81.6026 202.493 78.2026 199.093 78.2026 194.943ZM134.453 194.943C134.453 190.743 137.853 187.343 142.053 187.343C146.203 187.343 149.603 190.743 149.603 194.943C149.603 199.093 146.203 202.493 142.053 202.493C137.853 202.493 134.453 199.093 134.453 194.943Z"
        fill="#E31E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.44075 75.2892C3.53452 72.6477 7.37322 72.2037 10.0147 74.2974C12.6562 76.3912 13.1003 80.2299 11.0065 82.8714C8.91275 85.5129 5.07404 85.957 2.43253 83.8632C-0.208985 81.7694 -0.65302 77.9307 1.44075 75.2892ZM221.795 224.109C219.623 226.686 215.773 227.015 213.196 224.843C210.618 222.672 210.289 218.821 212.461 216.244C214.633 213.666 218.483 213.337 221.06 215.509C223.638 217.681 223.967 221.531 221.795 224.109ZM156.654 127.095C154.654 124.795 152.654 123.745 149.604 123.745H82.0787C80.4787 123.745 79.1787 122.545 79.0287 120.94L78.0787 109.74C77.9287 108.04 76.6787 106.645 74.9787 106.34L63.0537 104.54C61.0037 104.24 59.0537 105.59 58.7037 107.64C58.3537 109.64 59.7537 111.59 61.7537 111.94L69.3037 113.24L73.9036 167.745C74.4537 174.34 79.9537 179.44 86.6037 179.44H141.154C147.504 179.44 152.904 174.795 153.804 168.49L158.554 135.69C159.004 132.695 158.654 129.395 156.654 127.095ZM133.154 149.74H119.304C117.204 149.74 115.554 148.04 115.554 145.99C115.554 143.89 117.204 142.24 119.304 142.24H133.154C135.254 142.24 136.904 143.89 136.904 145.99C136.904 148.04 135.254 149.74 133.154 149.74Z"
        fill="#E31E24"
      />
    </svg>
  );
};
