import React, { useState } from "react";
import { Icons } from "../Icons/Icon";
import news from "../../../assets/svg/news.jpg";
export const CustomCarousal = (props) => {
    const baseURL = process.env.REACT_APP_INDORETALK_URL.replace("/api/", "/");
    const [currentSlide, setCurrentSlide] = useState(0);
    const nextSlide = () => {
        setCurrentSlide(currentSlide === props.data.length - 1 ? 0 : currentSlide + 1);
    };

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? props.data.length - 1 : currentSlide - 1);
    };


    return (
        <>
            <div style={{ position: "relative", display: "flex" }}>
                {props.data.length > 1 && <button className="justify-center" style={{ background: "transparent", width: "30px", height: "100%", position: "absolute", zIndex: "1", border: "none" }} onClick={prevSlide}>
                    <Icons
                        name="arrow-left"
                        className="svg-white  cursor-pointer next-arrow"
                    />
                </button>}
                {props.data.length > 1 && <button className="justify-center" style={{ background: "transparent", width: "30px", height: "100%", position: "absolute", right: "0", zIndex: "1", border: "none" }} onClick={nextSlide}>
                    <Icons
                        name="arrow-right"
                        className="svg-white svg-size-20 cursor-pointer next-arrow"
                    />
                </button>}
                <div style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                    transition: "transform 0.5s ease-in-out", display: "flex",
                    width:"100%"
                }}>
                    {props.data.map((image, index) => (
                        <img
                            className=""
                          
                            src={
                                image && !image.includes("http" || "https")
                                    ? baseURL + image
                                    : image || news
                            }
                            alt={`Slide ${index}`}
                            style={{  width:"100%",height:"100%"
                           
                            }}
                        />

                    ))}
                </div>
            </div>
        </>
    )

}