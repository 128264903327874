import React from 'react'

export const ArrowRight = ({
    className = "",
    width = "16px",
    height = "16px",
    viewBox = "0 0 16 16",
    fill = "",
}) => {
  return (
    
         <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M4.24025 14.9598C3.91992 14.6394 3.91992 14.1201 4.24025 13.7998L10.04 8.00001L4.24025 2.20027C3.91992 1.87994 3.91992 1.36059 4.24025 1.04026C4.56058 0.719929 5.07993 0.719929 5.40026 1.04026L11.78 7.42001C11.9338 7.57383 12.0203 7.78247 12.0203 8.00001C12.0203 8.21756 11.9338 8.42619 11.78 8.58002L5.40026 14.9598C5.07993 15.2801 4.56058 15.2801 4.24025 14.9598Z" fill=""/>
</svg>
            
    
  )
}
