import React from "react";

export const TicketBanner = ({
  className = "",
  width = "223.11px",
  height = "284px",
  viewBox = "0 0 223.11 284",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M185.678 11.3925C185.678 5.10062 190.778 0 197.07 0C203.362 0 208.463 5.10062 208.463 11.3925C208.463 17.6845 203.362 22.7851 197.07 22.7851C190.778 22.7851 185.678 17.6845 185.678 11.3925Z"
        fill="#EEEDED"
      />
      <path
        d="M9.53864 152.278C9.53864 97.4483 53.9869 53 108.817 53C163.646 53 208.094 97.4483 208.094 152.278C208.094 207.108 163.646 251.556 108.817 251.556C53.9869 251.556 9.53864 207.108 9.53864 152.278Z"
        fill="#EEEDED"
      />
      <path
        d="M17.6374 266.911C12.9185 266.911 9.09302 270.737 9.09302 275.456C9.09302 280.174 12.9185 284 17.6374 284C22.3564 284 26.1818 280.174 26.1818 275.456C26.1818 270.737 22.3564 266.911 17.6374 266.911Z"
        fill="#EEEDED"
      />
      <path
        d="M42.2549 33.4832C34.6825 27.4811 23.6782 28.754 17.6761 36.3263C11.6739 43.8987 12.9468 54.9029 20.5192 60.9051C28.0915 66.9072 39.0958 65.6343 45.098 58.062C51.1001 50.4896 49.8272 39.4854 42.2549 33.4832Z"
        fill="#2B2A29"
      />
      <path
        d="M179.527 264.671C186.917 270.897 197.954 269.954 204.18 262.565C210.406 255.175 209.463 244.138 202.073 237.912C194.684 231.686 183.647 232.629 177.421 240.018C171.195 247.408 172.138 258.445 179.527 264.671Z"
        fill="#2B2A29"
      />
      <path
        opacity="0.4"
        d="M154.789 159.382C150.684 159.382 147.344 156.072 147.344 152.005C147.344 147.933 150.684 144.623 154.789 144.623C155.784 144.623 156.739 144.232 157.439 143.538C158.144 142.839 158.539 141.893 158.539 140.907L158.534 127.521C158.534 116.205 149.239 107 137.819 107H79.2586C67.8386 107 58.5436 116.205 58.5436 127.521L58.5386 141.338C58.5386 142.324 58.9336 143.271 59.6386 143.969C60.3386 144.663 61.2936 145.054 62.2886 145.054C66.5336 145.054 69.7336 148.042 69.7336 152.005C69.7336 156.072 66.3936 159.382 62.2886 159.382C60.2186 159.382 58.5386 161.047 58.5386 163.098V176.474C58.5386 187.79 67.8286 197 79.2536 197H137.824C149.249 197 158.539 187.79 158.539 176.474V163.098C158.539 161.047 156.859 159.382 154.789 159.382"
        fill="#E31E24"
      />
      <path
        d="M1.32979 75.2891C3.42355 72.6476 7.26226 72.2035 9.90378 74.2973C12.5453 76.3911 12.9893 80.2298 10.8956 82.8713C8.80179 85.5128 4.96308 85.9569 2.32157 83.8631C-0.319947 81.7693 -0.763982 77.9306 1.32979 75.2891Z"
        fill="#E31E24"
      />
      <path
        d="M221.684 224.108C219.512 226.686 215.662 227.015 213.084 224.843C210.507 222.671 210.178 218.821 212.35 216.243C214.521 213.666 218.372 213.337 220.949 215.509C223.527 217.68 223.856 221.531 221.684 224.108Z"
        fill="#E31E24"
      />
      <path
        d="M119.796 155.683L125.691 149.943C126.716 148.953 127.071 147.498 126.626 146.143C126.186 144.793 125.036 143.833 123.646 143.638L115.501 142.448L111.856 135.068C111.231 133.798 109.961 133.008 108.546 133.003H108.536C107.126 133.003 105.856 133.793 105.221 135.063L101.576 142.448L93.4462 143.633C92.0412 143.833 90.8912 144.793 90.4462 146.143C90.0062 147.498 90.3612 148.953 91.3812 149.943L97.2762 155.683L95.8862 163.798C95.6462 165.203 96.2112 166.588 97.3612 167.423C98.0112 167.893 98.7662 168.133 99.5312 168.133C100.116 168.133 100.706 167.983 101.246 167.698L108.536 163.868L115.811 167.693C117.071 168.363 118.566 168.258 119.711 167.418C120.866 166.588 121.431 165.203 121.191 163.798L119.796 155.683Z"
        fill="#E31E24"
      />
    </svg>
  );
};
