// // authActions.js

// export const SIGN_IN = 'SIGN_IN';
// export const SIGN_OUT = 'SIGN_OUT';

// export const signIn = (user) => ({
//   type: SIGN_IN,
//   payload: user,
// });

// export const signOut = () => ({
//   type: SIGN_OUT,
// });

// const initialState = {
//   isAuthenticated: false,
//   user: null,
// };

// const authReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SIGN_IN:
//       return {
//         ...state,
//         isAuthenticated: true,
//         user: action.payload,
//       };
//     case SIGN_OUT:
//       return {
//         ...state,
//         isAuthenticated: false,
//         user: null,
//       };
//     default:
//       return state;
//   }
// };

// export default authReducer;

import { createSlice } from '@reduxjs/toolkit'
import store from 'store'

// const initialTempLogin = {
//     'username': '',
//     'password': '',
// }
const initialTempLogin = {
  isAuthenticated: false,
  user: {},
};
const initialState = {
    tempLogin: store.get('tempLogin') ? store.get('tempLogin') : initialTempLogin
}

const tempLoginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setLogin: (state, action) => {
            state.tempLogin = action?.payload
            store.set('tempLogin', action?.payload)
            state.isAuthenticated = true
        },
        resetLogin: (state) => {
          store.clearAll()
          state.isUserLoggedIn = false
        }
    },

})
export const { setLogin, resetLogin } = tempLoginSlice.actions

export default tempLoginSlice.reducer
