import React from 'react'

export const Website = ({
    className = "",
    width = "18px",
    height = "18px",
    viewBox = "0 0 18 18",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.63604 2.63604C4.32387 0.948211 6.61305 0 9 0C11.3869 0 13.6761 0.948211 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948211 13.6761 0 11.3869 0 9C0 6.61305 0.948211 4.32387 2.63604 2.63604ZM9 1.28571C6.95404 1.28571 4.99189 2.09847 3.54518 3.54518C2.25077 4.83959 1.46386 6.54663 1.31253 8.35714H16.6875C16.5361 6.54663 15.7492 4.83959 14.4548 3.54518C13.0081 2.09847 11.046 1.28571 9 1.28571ZM16.6875 9.64286H1.31253C1.46386 11.4534 2.25077 13.1604 3.54518 14.4548C4.99189 15.9015 6.95404 16.7143 9 16.7143C11.046 16.7143 13.0081 15.9015 14.4548 14.4548C15.7492 13.1604 16.5361 11.4534 16.6875 9.64286Z" fill="" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.99997 0C9.19345 0 9.37665 0.08715 9.49873 0.237269C11.5155 2.71734 12.6913 5.77451 12.8563 8.96683C12.8574 8.98893 12.8574 9.01107 12.8563 9.03317C12.6913 12.2255 11.5155 15.2827 9.49873 17.7627C9.37665 17.9129 9.19345 18 8.99997 18C8.80648 18 8.62328 17.9129 8.5012 17.7627C6.48443 15.2827 5.3086 12.2255 5.14368 9.03317C5.14254 9.01107 5.14254 8.98893 5.14368 8.96683C5.3086 5.77451 6.48443 2.71734 8.5012 0.237269C8.62328 0.08715 8.80648 0 8.99997 0ZM6.42943 9C6.57188 11.6341 7.4668 14.1663 8.99996 16.2995C10.5331 14.1663 11.4281 11.6341 11.5705 9C11.4281 6.36591 10.5331 3.83374 8.99996 1.70053C7.4668 3.83374 6.57188 6.36591 6.42943 9Z" fill="" />
        </svg>

    )
}
