import React from 'react'
import { Back } from "./Back";
// import { Notification } from "./Notification";
import { Search } from "./Search";
import { ArrowUp } from './ArrowUp';
import { ArrowDown } from './ArrowDown';
import { Share } from './Share';
import { Like } from './Like';
import { CalenderEvent } from './CalenderEvent';
import { AddUser } from './AddUser';
import { Time } from './Time';
import { LanguageEvent } from './LanguageEvent';
import { Minus } from './Minus';
import { Plus } from './Plus';
import { Calling } from './Calling';
import { FilterEvent } from './FilterEvent';
import { LocationEvent } from './LocationEvent';
import { More } from './More';
import { ArrowRight } from './ArrowRight';
import { Listing } from './Listing';
import { Polls } from './Polls';
import { Event } from './Event';
import { Explore } from './Explore';
import { Standup } from './Standup';
import { Conference } from './Conference';
import { Concert } from './Concert';
import { Filter } from './Filter';
import { TopTalk } from './TopTalk';
import { EducationTalk } from './EducationTalk';
import { FoodTalk } from './FoodTalk';
import { SpecialTalk } from './SpecialTalk';
import { Views } from './Views';
import { Comment } from './Comment';

import { Facebook } from './Facebook';
import { Apple } from './Apple';

import { Infrastructure } from './Infrastructure';
import { Hardware } from './Hardware';
import { Food } from './Food';
import { AnnouncementBanner } from './AnnouncementBanner';
import { CartBanner } from './CartBanner';
import { TicketBanner } from './TicketBanner';
import { Logout1 } from './Logout1';
import { MyBookings } from './MyBookings';
import { ListYourBusiness } from './ListYourBusiness';
import { Connect } from './Connect';
import { Career } from './Careeer';
import { TermsConditions } from './TermsConditions';
import { PrivacyPolicy } from './PrivacyPolicy';
import { Help } from './Help';
import { Logout } from './Logout';
import { Splash1 } from './Splash1';
import { Splash2 } from './Splash2';
import { Edit } from './Edit';
import { Camera } from './Camera';
import { Google } from './Google';
import { ListYourBusiness1 } from './ListYourBusiness1';
import { Website } from './Website';
import { Email } from './Email';
import { FacebookLink } from './FacebookLink';
import { TwitterLink } from './TwitterLink';
import { InstaLink } from './InstaLink';
import { YoutubeLink } from './YoutubeLink';
import { Star } from './Star';
import { PaymentSuccess } from './PaymentSuccess';
import { PaymentUnsuccess } from './PaymentUnsuccess';
import { SplashNew } from './SplashNew';
import { PlusGallery } from './PlusGallery';
import { Cross } from './Cross';
import { Submit } from './Submit';
import { WhatsappLink } from './WhatsappLink';
import { ListingLocation } from './ListingLocation';
import { Forward } from './Forward';
import { OrderList } from './OrderList';
import { AboutUs } from './AboutUs';
import { Comment1 } from './Comment1';
import { Soldout } from './Soldout';
import { ArrowLeft } from './ArrowLeft';
import { Delete } from './Delete';
import { DeleteAccount } from './DeleteAccount';
import { Report } from './Report';
import { Booking } from './Booking';
import { Logo } from './Logo';




export const Icons = (props) => {
  switch (props.name) {
    case "back":
      return <Back {...props} style={{ color: props.fill, ...props.style }} />;
    // case "notification":
    //   return <Notification {...props} style={{ color: props.fill, ...props.style }} />;
    case "search":
      return <Search {...props} style={{ color: props.fill, ...props.style }} />;
    case "arrow-down":
      return <ArrowDown {...props} style={{ color: props.fill, ...props.style }} />;
    case "arrow-up":
      return <ArrowUp {...props} style={{ color: props.fill, ...props.style }} />;
    case "like":
      return <Like {...props} style={{ color: props.fill, ...props.style }} />;
    case "share":
      return <Share {...props} style={{ color: props.fill, ...props.style }} />;
    case "calender":
      return <CalenderEvent {...props} style={{ color: props.fill, ...props.style }} />;
    case "add-user":
      return <AddUser {...props} style={{ color: props.fill, ...props.style }} />;
    case "time":
      return <Time {...props} style={{ color: props.fill, ...props.style }} />;
    case "language":
      return <LanguageEvent {...props} style={{ color: props.fill, ...props.style }} />;
    case "minus":
      return <Minus {...props} style={{ color: props.fill, ...props.style }} />;
    case "plus":
      return <Plus {...props} style={{ color: props.fill, ...props.style }} />;
    case "calling":
      return <Calling {...props} style={{ color: props.fill, ...props.style }} />;
    case "filter-event":
      return <FilterEvent {...props} style={{ color: props.fill, ...props.style }} />;
    case "location":
      return <LocationEvent {...props} style={{ color: props.fill, ...props.style }} />;
    case "more":
      return <More {...props} style={{ color: props.fill, ...props.style }} />;
    case "arrow-right":
      return <ArrowRight {...props} style={{ color: props.fill, ...props.style }} />;
    case "arrow-left":
      return <ArrowLeft {...props} style={{ color: props.fill, ...props.style }} />;
    case "listing":
      return <Listing {...props} style={{ color: props.fill, ...props.style }} />;
    case "polls":
      return <Polls {...props} style={{ color: props.fill, ...props.style }} />;
    case "event":
      return <Event {...props} style={{ color: props.fill, ...props.style }} />;
    case "explore":
      return <Explore {...props} style={{ color: props.fill, ...props.style }} />;
    case "concert":
      return <Concert {...props} style={{ color: props.fill, ...props.style }} />;
    case "conference":
      return <Conference {...props} style={{ color: props.fill, ...props.style }} />;
    case "standup":
      return <Standup {...props} style={{ color: props.fill, ...props.style }} />;
    case "filter":
      return <Filter {...props} style={{ color: props.fill, ...props.style }} />;
    case "Top Talk":
      return <TopTalk {...props} style={{ color: props.fill, ...props.style }} />;
    case "Education Talk":
      return <EducationTalk {...props} style={{ color: props.fill, ...props.style }} />;
    case "Food Talk":
      return <FoodTalk {...props} style={{ color: props.fill, ...props.style }} />;
    case "Special Talk":
      return <SpecialTalk {...props} style={{ color: props.fill, ...props.style }} />;
    case "show":
      return <Views {...props} style={{ color: props.fill, ...props.style }} />;
    case "comment":
      return <Comment {...props} style={{ color: props.fill, ...props.style }} />;
    case "facebook":
      return <Facebook {...props} style={{ color: props.fill, ...props.style }} />;
    case "apple":
      return <Apple {...props} style={{ color: props.fill, ...props.style }} />;
    case "google":
      return <Google {...props} style={{ color: props.fill, ...props.style }} />;
    case "infrastructure":
      return <Infrastructure {...props} style={{ color: props.fill, ...props.style }} />;
    case "hardware":
      return <Hardware {...props} style={{ color: props.fill, ...props.style }} />;
    case "food":
      return <Food {...props} style={{ color: props.fill, ...props.style }} />;
    case "announcementbanner":
      return <AnnouncementBanner {...props} style={{ color: props.fill, ...props.style }} />;
    case "cartbanner":
      return <CartBanner {...props} style={{ color: props.fill, ...props.style }} />;
    case "ticketbanner":
      return <TicketBanner {...props} style={{ color: props.fill, ...props.style }} />;
    case "logout1":
      return <Logout1 {...props} style={{ color: props.fill, ...props.style }} />;
    case "my-bookings":
      return <MyBookings {...props} style={{ color: props.fill, ...props.style }} />;
    case "list-your-business":
      return <ListYourBusiness {...props} style={{ color: props.fill, ...props.style }} />;
    case "list-your-business1":
      return <ListYourBusiness1 {...props} style={{ color: props.fill, ...props.style }} />;
    case "connect":
      return <Connect {...props} style={{ color: props.fill, ...props.style }} />;
    case "career":
      return <Career {...props} style={{ color: props.fill, ...props.style }} />;
    case "privacy-policy":
      return <PrivacyPolicy {...props} style={{ color: props.fill, ...props.style }} />;
    case "terms-conditions":
      return <TermsConditions {...props} style={{ color: props.fill, ...props.style }} />;
    case "help":
      return <Help {...props} style={{ color: props.fill, ...props.style }} />;
    case "logout":
      return <Logout {...props} style={{ color: props.fill, ...props.style }} />;
    case "splash1":
      return <Splash1 {...props} style={{ color: props.fill, ...props.style }} />;
    case "splash2":
      return <Splash2 {...props} style={{ color: props.fill, ...props.style }} />;
    case "edit":
      return <Edit {...props} style={{ color: props.fill, ...props.style }} />;
    case "camera":
      return <Camera {...props} style={{ color: props.fill, ...props.style }} />;
    case "website":
      return <Website {...props} style={{ color: props.fill, ...props.style }} />;
    case "email":
      return <Email {...props} style={{ color: props.fill, ...props.style }} />;
    case "delete":
      return <Delete {...props} style={{ color: props.fill, ...props.style }} />;
    case "facebook-link":
      return <FacebookLink {...props} style={{ color: props.fill, ...props.style }} />;
    case "twitter-link":
      return <TwitterLink {...props} style={{ color: props.fill, ...props.style }} />;
    case "insta-link":
      return <InstaLink {...props} style={{ color: props.fill, ...props.style }} />;
    case "youtube-link":
      return <YoutubeLink {...props} style={{ color: props.fill, ...props.style }} />;
    case "whatsapp-link":
      return <WhatsappLink {...props} style={{ color: props.fill, ...props.style }} />;
    case "star":
      return <Star {...props} style={{ color: props.fill, ...props.style }} />;
    case "payment-success":
      return <PaymentSuccess {...props} style={{ color: props.fill, ...props.style }} />;
    case "payment-unsuccess":
      return <PaymentUnsuccess {...props} style={{ color: props.fill, ...props.style }} />;
    case "splash-new":
      return <SplashNew {...props} style={{ color: props.fill, ...props.style }} />;
    case "plus-gallery":
      return <PlusGallery {...props} style={{ color: props.fill, ...props.style }} />;
    case "cross":
      return <Cross {...props} style={{ color: props.fill, ...props.style }} />;
    case "submit":
      return <Submit {...props} style={{ color: props.fill, ...props.style }} />;
    case "listing-location":
      return <ListingLocation {...props} style={{ color: props.fill, ...props.style }} />;
    case "forward":
      return <Forward {...props} style={{ color: props.fill, ...props.style }} />;
    case "order-list":
      return <OrderList {...props} style={{ color: props.fill, ...props.style }} />;
    case "aboutUs":
      return <AboutUs {...props} style={{ color: props.fill, ...props.style }} />;
    case "comment1":
      return <Comment1 {...props} style={{ color: props.fill, ...props.style }} />;
    case "soldout":
      return <Soldout {...props} style={{ color: props.fill, ...props.style }} />;
    case "delete-account-modal":
      return <DeleteAccount {...props} style={{ color: props.fill, ...props.style }} />;
    case "report":
      return <Report {...props} style={{ color: props.fill, ...props.style }} />;
    case "booking":
      return <Booking {...props} style={{ color: props.fill, ...props.style }} />;
      case "logo":
        return <Logo {...props} style={{ color: props.fill, ...props.style }} />;



    default:
      return null;
  }
};


