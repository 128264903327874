import React from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useDispatch, useSelector } from "react-redux";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from 'store'

const DeleteAccount = () => {
  const tempLogin = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const handleShow = () =>{if(userId){ setShow(true)}};
  const userData = {
    login_type: userId?.login_type,
    user_id: userId?.user_id
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  // const deleteAccount = async (userData) => {
  //   try {
  //     console.log('userData', exportApiAuth.deleteAccount)
  //     // return
  //     const res = await exportApiAuth.deleteAccount(userData);
  //     console.log('deleteAccount', res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const  deleteAccount = async (userData) => {
    // return
    const url = `${process.env.REACT_APP_INDORETALK_URL}delete/user/${userData.user_id}`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': userId?.access_tokens // Include your token here
      },
      body: JSON.stringify(userData)
    };
    const res =  await fetch(url, options);
    if(res.status === 200 ){
      dispatch(setSignOut());
      localStorage.clear();
      store.clearAll()
      navigate("/login");
    }else{
      dispatch(setSignOut());
      localStorage.clear();
      store.clearAll()
      navigate("/login");
    }
  }
  
  return (
    <>
      <div className="top-header-fix">
        <nav
          className="navbar top-nav navbar-light bg-light text-center "

        >
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto pr-24">
              <h1 className="f-24 f-w600 mb-0">{"Delete Account"}</h1>
            </div>
          </div>
        </nav>
      </div>
      <div></div>

      <div>
        <div className="scroll-without-category">
          <strong className=" f-14 f-w600 ">Account Deletion:</strong>
          <ul className="mt-2">
            <li className="mb-2">
            We're sorry to see you go! We wanted to take a moment to thank you for being a part of Indore Talk community. If there's anything we can do to improve your experience in the future or if you have any feedback for us, feel free to reach out us <span className="text-red f-w500">feedback@indoretalk.com</span>.
            </li>
            <li className="mb-2">
            Deleting your account will also remove all related data associated with your account on Indore Talk App.
            </li>
          </ul>
        </div>
      </div>

      <div className="form-submit-btn dlt-acc-margin d-flex justify-content-center">
        <Button
          className="btn btn-secondary w-50 mb-3 p-2 px-3 btn-bg-red"
        onClick={() => handleShow()}
        >
          Delete Account
        </Button>
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className="text-center">
          <Icons name="delete-account-modal" className=" svg-red svg-size- ml-0 mr-2 " />
          <p className="logout-confirmation f-18 f-w600">Are you sure you want to delete your account</p>
          <div className="d-flex justify-content-evenly">
            <button
              className="bg-brand logout-modal-btn text-dark btn-fluid btn-width btn-color text-center justify-center f-16 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              className="bg-brand logout-modal-btn text-dark btn-fluid btn-width text-center justify-center f-16 "
              onClick={() => deleteAccount(userData)}
            >
              Yes, Delete
            </button>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default DeleteAccount;
