/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Icons } from "../Icons/Icon";
import {
  Col,
  Container,
  Modal,
  ModalFooter,
  Row,
  Toast,
} from "react-bootstrap";
import "./Comment.css";
import { exportApi } from "../../../services/indoreTalk.api";
import { useSelector } from "react-redux";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import {
  formatDate,
  getInitialCharacter,
  titleCase,
  titleCase2,
  unnecatchLogout,
} from "../../../utils/exportGlobleFunction";
import { GoToLogin } from "../GoToLogin/GoToLogin";
import { useLocation } from "react-router-dom";
import CommentInputField from "../form-type/CommentInputField";
import { CommentSkeleton } from "../cards/CommentSkeleton";
import { useDispatch } from "react-redux";
import store from "store";
import { setSignOut } from "../../../redux/slices/authSlice";

export const Comment = ({ postId, show, handleClose }) => {
  const [successToast, setSuccessToast] = useState(null);
  const location = useLocation();
  const tempLogin = useSelector((state) => state.auth);
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const dispatch = useDispatch();
  const userData = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [commentInput, setCommentInput] = useState({
    post_id: postId?.id,
    user_id: tempLogin.tempLogin?.user?.data?.social_provider?.user_id,
    maincategory_id: postId?.maincategory_id,
    comment_message: "",
    status: 1,
    login_type: userData?.login_type,
  });

  const [loading, setLoading] = useState(true);
  const [commentShow, setCommentShow] = useState();

  useEffect(() => {
    fetchData();
  }, [postId?.id, postId?.maincategory_id, userData?.user_id]);

  const fetchData = async () => {
    try {
      const res = await exportApi.commentLoad({
        post_id: postId?.id,
        user_id: userData?.user_id || "",
        maincategory_id: postId?.maincategory_id || 1,
      });
      if (res.status === "success") {
        setCommentShow(res.data);
      }
      setLoading(false);
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };
  const [showLoginModal, setLoginModalShow] = useState(false);
  const addCommentsHandler = (e) => {
    if (!!userData) {
      try {
        const name = e.target.name;
        const value = e.target.value;
        setCommentInput({ ...commentInput, [name]: value });
      } catch (error) {
        console.error(error);
      }
    } else {
      setLoginModalShow(true);
    }
  };
  const handleReport = async (item) => {
    try {
      if (userId) {
        const report = {
          login_type: userId?.login_type,
          user_id: userId?.user_id,
          email: tempLogin?.tempLogin?.user?.data?.email,
          postid: item.id,
          type: "comment",
        };
        setSuccessToast({
          message: "Report havs been sent successfully to admin",
        });
        await exportApiAuth.reportPost(report);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async () => {
    try {
      const res = await exportApiAuth.addComment(commentInput);
      if (res.status === "success") {
        fetchData();
        setCommentInput({
          comment_message: "",
          post_id: postId?.id,
          user_id: tempLogin.tempLogin?.user?.data?.social_provider?.user_id,
          maincategory_id: postId?.maincategory_id,
          status: 1,
          login_type: userData?.login_type,
        });
      } else {
        setLoginModalShow(true);
        unnecatchLogout(store, dispatch, setSignOut);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName="bottom-modal">
        {loading && (
          <div className="p-3">
            <CommentSkeleton number={5} />
          </div>
        )}

        {commentShow?.length === 0 && (
          <div className="p-5">
            <div className="d-flex justify-content-center">
              <Icons name="comment1" className=" svg-red svg-24 ml-0 mr-2 " />
            </div>
            <p className="f-18 f-w600 comment-text-grey mb-0 d-flex justify-content-center">
              No comment yet!
            </p>
            <p className="f-12 f-w400 comment-text-grey d-flex justify-content-center">
              Be the first to comment.
            </p>
          </div>
        )}

        {commentShow &&
          commentShow?.length > 0 &&
          Array.isArray(commentShow) &&
          commentShow.map((item, index) => {
            return (
              <Modal.Body className="commnet-modal" key={index}>
                <>
                  <div className="d-flex">
                    <div className="image-comm">
                      {item.user.name
                        ? getInitialCharacter(item.user.name, 1)
                        : getInitialCharacter("User", 1)}
                    </div>
                    <div className="f-16 f-w500 header pl-1">
                      {titleCase2(item.user.name) || "Anonymous"}
                    </div>

                    <div
                      className="f-12 f-w500 time pr-2 "
                      style={{ marginLeft: "auto", color: "#5C5c5c" }}
                    >
                      {formatDate(item.created_at, "fulldate", "true")}
                    </div>
                    <div
                      className="d-flex alignCenter"
                      onClick={() => handleReport(item)}
                    >
                      <Icons name="report" className=" svg-size-16 svg-grey" />
                    </div>
                  </div>
                  <div
                    className="f-14 f-w400 comment"
                    style={{ paddingLeft: "42px" }}
                  >
                    {titleCase(item.comment_message)}
                  </div>
                </>
              </Modal.Body>
            );
          })}
        {successToast && (
          <Toast
            onClose={() => setSuccessToast(null)}
            show={true}
            delay={3000}
            className="report-toaster"
            autohide
          >
            <Toast.Body
              style={{ backgroundColor: "#C5FEB5", color: "#196004" }}
            >
              {successToast.message}
            </Toast.Body>
          </Toast>
        )}
        <ModalFooter>
          <Container fluid>
            <Row>
              <Col
                xs={1}
                sm={1}
                style={{ alignContent: "center", padding: "0" }}
              >
                <div className="image-comm">
                  {tempLogin.tempLogin?.user?.data?.display_name
                    ? getInitialCharacter(
                        tempLogin.tempLogin?.user?.data?.display_name,
                        1
                      )
                    : getInitialCharacter("User", 1)}
                </div>
              </Col>

              <Col xs={10} sm={10}>
                <div className="">
                  <CommentInputField
                    name="comment_message"
                    type="text"
                    value={commentInput.comment_message}
                    placeholder="Type your comment here"
                    onChange={(e) => addCommentsHandler(e)}
                  >
                    <Icons name="submit" className="svg-white" />
                  </CommentInputField>
                </div>
              </Col>

              <Col
                xs={1}
                sm={1}
                onClick={submitHandler}
                style={{ alignContent: "center", padding: "0" }}
              >
                {" "}
                <Icons name="submit" className="svg-white" />
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>

      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
        />
      )}
    </div>
  );
};
