import React, { useEffect, useState } from "react";
import "./listingHome.css";
import { Footer } from "../../components/Footer/Footer";
import { ListingCard } from "../../components/cards/ListingCard";
import { Icons } from "../../components/Icons/Icon";
import NavBar from "../../components/navbar/navbar";
import { useDispatch, useSelector } from "react-redux";
import { exportApi } from "../../../services/indoreTalk.api";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  setPreviousSubCategoryBusiness,
  setPreviousSubCategoryBusinessdata,
  setSubListingSubCategory,
} from "../../../redux/slices/subCat";
import { useLocation, useNavigate } from "react-router-dom";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import {
  likePayloads,
  unnecatchLogout,
} from "../../../utils/exportGlobleFunction";
import { Skeleton } from "../../components/cards/Skeleton";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import DisplaySubCat from "../../components/navbar/subNavBar";
import { setSubSubCat } from "../../../redux/slices/singleDataSlice";
import { useSwipeable } from "react-swipeable";
import { apiCallingLimit } from "../../../utils/constGlobleData";
import { Toast } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from "store";

export const ListingHome = ({ header = "Listing" }) => {
  const subCategories = useSelector((state) => state.subCat);
  const { tempLogin, redirectionPath } = useSelector((state) => state.auth);
  const { subSubCat } = useSelector((state) => state.singleData);
  const userId = tempLogin?.user?.data?.social_provider;
  // eslint-disable-next-line no-unused-vars
  const [like, setLike] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subCatNav = subCategories ? subCategories.subListingCategory : [];
  const [catId, setCatId] = useState(
    subCategories?.previousSubCategoryBusinessData > 0
      ? subCategories?.previousSubCategoryBusinessData
      : redirectionPath.pathBoolean
      ? redirectionPath.activePathCat
      : subCategories.subListingCategory[0]?.id ||
        parseInt(localStorage.getItem("listingFirstCategory"))
  );
  const [dynamicSubCatId, setDynamicSubCatId] = useState(catId);
  const [activeNavMain, setActiveNavMain] = useState(
    subCategories?.previousSubCategoryBusinessData !== 0
      ? subCategories?.previousSubCategoryBusinessData
      : subCategories.subListingCategory[0]?.sub_category[0]?.id
  );
  const [activelistingSub, setActivelistingSub] = useState();
  const [activeSubCat, setActiveSubCat] = useState(
    subCategories.previousSubCategoryBusiness > 0
      ? subCategories.previousSubCategoryBusiness
      : dynamicSubCatId
  );
  const [shouldRender, setShouldRender] = useState(false);
  const [listingBlock, setListingBlock] = useState(false);
  const [business, setBusiness] = useState([]);
  const [infinite, setInfinite] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [liketnDisabled, setLiketnDisabled] = useState(false);
  const [likeStates, setLikeStates] = useState({});
  const [tempLike, setTempLike] = useState({});
  const [likeId, setLikeId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [limitBusiness, setLimitBusiness] = useState(0);
  const [sessionExpire, setSessionExpire] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const foundItem =
        Array.isArray(subCategories.subListingCategory) &&
        subCategories.subListingCategory?.find((item) => {
          return (
            item.id ===
            (subCategories?.previousSubCategoryBusiness > 0
              ? subCategories?.previousSubCategoryBusiness
              : dynamicSubCatId)
          );
        })?.sub_category[0]?.id;
      setActiveNavMain(foundItem);
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, [subCategories, dynamicSubCatId]);
  useEffect(() => {
    const fetchData = async () => {
      await fetchBusiness(
        subCategories.previousSubCategoryBusiness > 0
          ? subCategories.previousSubCategoryBusiness
          : like
          ? activeSubCat
          : dynamicSubCatId,
        userId?.user_id || 0,
        subSubCat !== 0
          ? subSubCat
          : activelistingSub ||
              subCategories.subListingCategory[0]?.sub_category[0]?.id
      );
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId?.user_id]);

  const subCatNavChange = (subId, subCatId) => {
    dispatch(setSubSubCat(0));
    setBusiness([]);
    setListingBlock(true);
    setActivelistingSub(subCatId);
    dispatch(setPreviousSubCategoryBusinessdata(0));
    dispatch(setPreviousSubCategoryBusiness(0));
    setDynamicSubCatId(subId);
    setIsLoading(true);
    fetchBusiness(subId, userId?.user_id, subCatId);
    setActiveSubCat(subId);
  };
  const fetchBusiness = async (id, user_id, sub_category_id, page) => {
    setCatId(id);
    try {
      setIsLoading(true);
      setActiveSubCat(id);
      setActivelistingSub(sub_category_id);
      setLimitBusiness(page || 0);
      const res = await exportApi.businessGet({
        page: page || 0,
        limit: apiCallingLimit,
        category_id: id,
        user_id: user_id || 0,
        sub_category_id: sub_category_id,
      });
      setShouldRender(true);
      setInfinite(res.data.length < apiCallingLimit);
      if (res.status === "success") {
        const filteredBusiness = business.filter(
          (item) =>
            item.category_id === id && item.sub_category_id === sub_category_id
        );
       // Finding the index to replace based on likeId state
        const indexToUpdate = filteredBusiness.findIndex(
          (item) => item.id === likeId
        );

        // If indexToUpdate exists and likeId is not null, replace that item with data from res.data
        if (indexToUpdate !== -1 && likeId !== null) {
          const test = res.data.find(
            (item) => item.id === likeId
          )
          if(test){
            filteredBusiness[indexToUpdate] = res.data.find(
              (item) => item.id === likeId
            );
            setBusiness([...filteredBusiness]);
          }else{
            setBusiness([...filteredBusiness]);
          }
          setLikeId(null);
        } else {
          // Merging filteredBusiness with new data and updating the state
          setBusiness([...filteredBusiness, ...res.data]);
        }
        setLiketnDisabled(false);
        setTempLike({});
        setLikeStates({});
      }
      setLikeStates({});
      setIsLoading(false);
      setLiketnDisabled(false);
      setTempLike({});
    } catch (err) {
      console.error("Error fetching business:", err);
      setLikeStates({});
      setTempLike({});
      setLiketnDisabled(false);
    }
  };
  const [showLoginModal, setLoginModalShow] = useState(false);
  const handleLikeClick = async (e, data) => {
    e.stopPropagation(); // Stop the click event from propagating
    // Handle like button click logic here
    if (!!userId) {
      if (!isLoading) {
        try {
          // Finding the index to replace based on the id
          const indexToUpdate = business.findIndex(
            (item) => item.id === data.id
          );
          setLikeId(data.id);
          const like = await exportApiAuth.likePost(likePayloads(data, userId));
          if (like.status === "success") {
            let likedItemData = Array.isArray(like.data)
              ? like.data[0]
              : like.data;
            if (indexToUpdate !== -1 && likedItemData) {
              // Replace the item at the found index with the new liked item data
              business[indexToUpdate] = likedItemData;
              // Update the state with the modified news array
              // setBusiness([...business]);
              setLiketnDisabled(false);
              setLikeStates({});
            }
            setLiketnDisabled(false);
            setLikeStates({});
          } else {
            // if (like.code === 401) {
            setLoginModalShow(true);
            unnecatchLogout(store, dispatch, setSignOut);
            setSessionExpire(true);
            // }
            // setErrorToast(true);
            setLiketnDisabled(false);
            setLikeStates({});
          }
        } catch (error) {
          console.log("error", error);
          setErrorToast(true);
          setLiketnDisabled(false);
          setLikeStates({});
        }
      } else {
        setLiketnDisabled(false);
        setLikeStates({});
      }
    } else {
      setLoginModalShow(true);
      setSessionExpire(false);
      setLiketnDisabled(false);
      setLikeStates({});
    }
  };

  const subCatNav2Change = (item) => {
    setIsLoading(true);
    setBusiness([]);
    setListingBlock(true);
    setActiveNavMain(item.id);
    setActivelistingSub(item.id);
    fetchBusiness(item.category_id, userId?.user_id, item.id);
    document.getElementById("scrollableDiv").scrollTop = 0;
  };
  const [data, setData] = useState(null);
  const processData = () => {
    const newData =
      Array.isArray(subCategories?.subListingCategory) &&
      subCategories?.subListingCategory.find(
        (item) =>
          item.id ===
          (subCategories?.previousSubCategoryBusiness > 0
            ? subCategories?.previousSubCategoryBusiness
            : dynamicSubCatId)
      );
    if (JSON.stringify(newData) !== JSON.stringify(data)) {
      setData(newData);
      dispatch(setSubListingSubCategory(newData?.sub_category));

      if (newData?.sub_category && newData.sub_category[0]?.id) {
        setActivelistingSub(newData.sub_category[0].id);
      }
    } else {
      // Update activeNav only if newData is found
      if (newData?.sub_category && newData.sub_category[0]?.id) {
      }
    }
  };

  useEffect(() => {
    processData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategories, dynamicSubCatId]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const i =
        Array.isArray(subCategories?.subListingSubCategory) &&
        subCategories?.subListingSubCategory.findIndex(
          (item) => item.id === activelistingSub
        );
      if (subCategories?.subListingSubCategory?.length - 1 > i) {
        const subCat = subCategories?.subListingSubCategory[i + 1]?.id;
        const mainCat =
          subCategories?.subListingSubCategory[i + 1]?.category_id;
        setActivelistingSub(subCat);
        subCatNavChange(mainCat, subCat);
      } else {
        console.log("Cannot swipe left, no more items in subExploreCategory");
      }
    },
    onSwipedRight: () => {
      const i =
        Array.isArray(subCategories?.subListingSubCategory) &&
        subCategories?.subListingSubCategory.findIndex(
          (item) => item.id === activelistingSub
        );
      if (0 < i) {
        const subCat = subCategories?.subListingSubCategory[i - 1]?.id;
        const mainCat =
          subCategories?.subListingSubCategory[i - 1]?.category_id;
        setActivelistingSub(subCat);
        subCatNavChange(mainCat, subCat);
      } else {
        console.log("Cannot swipe right, no more items in subExploreCategory");
      }
    },
  });
  return (
    <>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center ">
          <div className="container-fluid mx-auto text-center pr-3">
            <div className="m-auto pl-28 ">
              <h1 className="f-24 f-w600 mb-0">Listing</h1>
            </div>
            <div>
              <span
                onClick={() => navigate("/home/search")}
                className="cursor-pointer"
              >
                <Icons name="search" className="svg-size-24 svg-white" />
              </span>
            </div>
          </div>
        </nav>
        <div className="top-header ">
          <NavBar
            subCatNav={subCatNav}
            activeSubCat={activeSubCat}
            subCatNavChange={subCatNavChange}
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sub-categ-container">
                {shouldRender && (
                  <DisplaySubCat
                    subCategories={subCategories}
                    dynamicSubCatId={dynamicSubCatId}
                    subCatNav2Change={subCatNav2Change}
                    fetchBusiness={fetchBusiness}
                    activeNavMain={
                      subSubCat !== 0
                        ? subSubCat
                        : activelistingSub || activeNavMain
                    }
                    userId={userId}
                    data={data}
                    subSubCat={subSubCat}
                    dispatch={dispatch}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="scrollableDiv" className="auto-load-table" {...handlers}>
        <InfiniteScroll
          dataLength={business?.length || apiCallingLimit}
          next={() => {
            console.log("Next function called");
            fetchBusiness(
              subCategories.previousSubCategoryBusiness > 0
                ? subCategories.previousSubCategoryBusiness
                : like
                ? activeSubCat
                : activeSubCat
                ? activeSubCat
                : dynamicSubCatId,
              userId?.user_id || 0,
              subSubCat !== 0 ? subSubCat : activelistingSub || activeNavMain,
              limitBusiness + 1
            );
          }}
          hasMore={!infinite}
          loader={
            !infinite && (
              <div className="d-flex justify-center mt-2">
                <Spinner animation="border" className=" " />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {business?.length !== 0 &&
          Array.isArray(business) &&
          business?.filter((event) => event?.is_active !== 0).length > 0 ? (
            <ListingCard
              business={business}
              handleLikeClick={handleLikeClick}
              setLiketnDisabled={setLiketnDisabled}
              liketnDisabled={liketnDisabled}
              setTempLike={setTempLike}
              tempLike={tempLike}
              likeStates={likeStates}
              setLikeStates={setLikeStates}
            />
          ) : isLoading ? (
            <Skeleton number={5} />
          ) : (
            <p
              className="text-black text-center f-16 pt-2"
              style={{ marginTop: "36vh" }}
            >
              No business list found!
            </p>
          )}
        </InfiniteScroll>
      </div>
      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
          loginResion={sessionExpire}
        />
      )}
      {errorToast && (
        <Toast
          onClose={() => setErrorToast(false)}
          show={true}
          delay={3000}
          className=""
          autohide={true}
          style={{ marginTop: "-60px", zIndex: 999, width: "100%" }}
        >
          <Toast.Body style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}>
            {"Somthing went wrong"}
          </Toast.Body>
        </Toast>
      )}
      <Footer
        fetchdata={fetchBusiness}
        setClickBlock={setListingBlock}
        clickBlock={listingBlock}
        processData={processData}
        setDynamicSubCatId={setDynamicSubCatId}
      />
    </>
  );
};
