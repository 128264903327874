// import OtpInput from "../views/components/loginWithSocial/OtpInput"
import Career from "../views/layouts/Career/Career"
import { EventDetail } from "../views/layouts/Event/EventDetail"
import { HomeLayout } from "../views/layouts/HomePage/HomeLayout"
import { ListingDetails } from "../views/layouts/Listing/ListingDetails"
import { Login } from "../views/layouts/LoginLayout/login"
import { NewsDetails } from "../views/layouts/NewsDetails/NewsDetails"
import OtpInput from "../views/layouts/OTPLogin/MobileOtpLogin"
import PrivacyPolicy from "../views/layouts/PrivacyPolicy/PrivacyPolicy"
import TermAndCond from "../views/layouts/TermAndCond/TermAndCond"
import Help from "../views/layouts/Help/Help"
import AboutUs from "../views/layouts/AboutUs/AboutUs"
import DeleteAccount from "../views/layouts/Deleteaccount/deleteAccount"
import QrReader from "../views/components/QR Reader/QrReader"



const PublicRoutes = [
  { path: '/login', exact: true, name: 'Login', component: Login },
  {
    path: '/home',
    exact: true,
    name: 'Otp',
    component: HomeLayout,
  },
  {
    path: '/otp-login',
    exact: true,
    name: 'Otp',
    component: OtpInput,
  },
  {
    path: 'home/explore/:id',
    exact: true,
    name: 'Explore',
    component: NewsDetails,
  },
  {
    path: '/event-home/event/:id',
    exact: true,
    name: 'Event',
    component: EventDetail,
  },
  {
    path: '/home/more/career',
    exact: true,
    name: 'career',
    component: Career,
  },
  {
    path: '/home/more/privacy-policy',
    exact: true,
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/home/more/term-condition',
    exact: true,
    name: 'term-condition',
    component: TermAndCond,
  },
  {
    path: '/home/more/about-us',
    exact: true,
    name: 'About Us',
    component: AboutUs,
  },
  {
    path: '/listing-home/business/:id',
    exact: true,
    name: 'Listing',
    component: ListingDetails,
  },
  {
    path: '/home/more/help',
    exact: true,
    name: 'help',
    component: Help,
  },
  {
    path: '/home/more/delete-account',
    exact: true,
    name: 'Delete Account',
    component: DeleteAccount,
  },
  {
    path: '/home/more/Qr-Reader',
    exact: true,
    name: 'Qr Reader',
    component: QrReader,
  },
  
]
export default PublicRoutes