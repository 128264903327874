import React from 'react'

export const Help = ({
  className = "",
  width = "42px",
  height = "42px",
  viewBox = "0 0 42 42",
  fill = "",
}) => {

  return (
    <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="21" cy="21" r="21" fill="#E31E24" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 15.435C11 12.9859 12.9857 11 15.434 11H20.309C22.7582 11 24.744 12.9858 24.744 15.435V16.368C24.744 16.7822 24.4082 17.118 23.994 17.118C23.5798 17.118 23.244 16.7822 23.244 16.368V15.435C23.244 13.8142 21.9298 12.5 20.309 12.5H15.434C13.8143 12.5 12.5 13.8141 12.5 15.435V26.565C12.5 28.1859 13.8143 29.5 15.434 29.5H20.319C21.9341 29.5 23.244 28.1905 23.244 26.576V25.633C23.244 25.2188 23.5798 24.883 23.994 24.883C24.4082 24.883 24.744 25.2188 24.744 25.633V26.576C24.744 29.0195 22.7619 31 20.319 31H15.434C12.9857 31 11 29.0141 11 26.565V15.435ZM17.9965 21C17.9965 20.5858 18.3322 20.25 18.7465 20.25H28.9712L27.3301 18.6162C27.0366 18.324 27.0355 17.8491 27.3277 17.5556C27.62 17.262 28.0949 17.261 28.3884 17.5532L31.3164 20.4682C31.3344 20.4861 31.3514 20.5048 31.3673 20.5243C31.4736 20.6537 31.5375 20.8194 31.5375 21C31.5375 21.2159 31.4462 21.4105 31.3003 21.5473L28.3885 24.4471C28.095 24.7394 27.6201 24.7385 27.3278 24.445C27.0355 24.1515 27.0365 23.6766 27.33 23.3843L28.971 21.75H18.7465C18.3322 21.75 17.9965 21.4142 17.9965 21Z" fill="white" />
    </svg>
  )
}