import { createSlice } from '@reduxjs/toolkit'
import store from 'store'

const initialTempLogin = {
  isAuthenticated: false,
  user: '', // removed quotes around 'user'
}

const initialState = {
  tempLogin: store.get('tempLogin') ? store.get('tempLogin') : initialTempLogin,
  userData: {},
  redirectionPath: {
    'path': '',
    'activePathCat': '',
    'pathBoolean': false
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setSignIn: (state, action) => {
      state.tempLogin = { // store the entire tempLogin object
        isAuthenticated: true,
        user: action.payload,
      }
      store.set('tempLogin', state.tempLogin) // store the updated tempLogin
    },
    setUserUpdateData: (state, action) => {
      state.userData = action.payload
      store.set('tempLoginUser', state.userData) // store the updated tempLogin
    },
    setAgainRedirectionPath: (state, action) => {
      state.redirectionPath = {
        ...state.redirectionPath,
        // Update only the specified properties from the action payload
        ...action.payload,
      };
    },
    setSignOut: (state) => {
      state.tempLogin = initialTempLogin;
      store.remove('tempLogin');
      store.remove('tempLoginUser'); // Remove user data from local storage if needed
      store.clearAll()
    },
  },
})

export const { setSignIn, setSignOut, setUserUpdateData, setAgainRedirectionPath } = authSlice.actions
export default authSlice.reducer
