// store.js
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import mainCatSlice from './slices/mainCatSlice';
import subCatSlice from './slices/subCat';
import singleDataSlice from './slices/singleDataSlice';
import dataSlice from './slices/dataSlice';
import listingFormData from './slices/formSlice';
const store = configureStore({
  reducer: {
    auth: authSlice,
    mainCat: mainCatSlice,
    subCat: subCatSlice,
    singleData: singleDataSlice,
    data: dataSlice,
    formData:listingFormData
    // Add other reducers here if needed
  },
  devTools: process.env.NODE_ENV !== 'production', // This line is not strictly necessary as it's enabled by default in development
});

export default store;
