import React from 'react'

export const CalenderEvent = ({
    className = "",
    width = "20px",
    height = "20px",
    viewBox = "0 0 20 20",
    fill = "",
  }) => {

    return (        
            <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.80556 1.5H15.1944C17.0201 1.5 18.5 3.067 18.5 5V15C18.5 16.933 17.0201 18.5 15.1944 18.5H4.80556C2.97995 18.5 1.5 16.933 1.5 15V5C1.5 3.067 2.97995 1.5 4.80556 1.5ZM4.80556 3H5.98611V4.20983C5.98611 4.50816 6.30324 4.75 6.69444 4.75C7.08565 4.75 7.40278 4.50816 7.40278 4.20983V3H12.5972V4.20983C12.5972 4.50816 12.9144 4.75 13.3056 4.75C13.6968 4.75 14.0139 4.50816 14.0139 4.20983V3H15.1944C16.2376 3 17.0833 3.89543 17.0833 5V6.92658H2.91667V5C2.91667 3.89543 3.76235 3 4.80556 3ZM2.91667 8.34374V15C2.91667 16.1046 3.76235 17 4.80556 17H15.1944C16.2376 17 17.0833 16.1046 17.0833 15V8.34374H2.91667ZM6.22222 10.9419C6.22222 11.4636 5.79938 11.8866 5.27778 11.8866C4.75618 11.8866 4.33333 11.4636 4.33333 10.9419C4.33333 10.4201 4.75618 9.99708 5.27778 9.99708C5.79938 9.99708 6.22222 10.4201 6.22222 10.9419ZM5.27778 15.6657C5.79938 15.6657 6.22222 15.2427 6.22222 14.7209C6.22222 14.1991 5.79938 13.7762 5.27778 13.7762C4.75618 13.7762 4.33333 14.1991 4.33333 14.7209C4.33333 15.2427 4.75618 15.6657 5.27778 15.6657ZM10.9444 10.9419C10.9444 11.4636 10.5216 11.8866 10 11.8866C9.4784 11.8866 9.05556 11.4636 9.05556 10.9419C9.05556 10.4201 9.4784 9.99708 10 9.99708C10.5216 9.99708 10.9444 10.4201 10.9444 10.9419ZM10 15.6657C10.5216 15.6657 10.9444 15.2427 10.9444 14.7209C10.9444 14.1991 10.5216 13.7762 10 13.7762C9.4784 13.7762 9.05556 14.1991 9.05556 14.7209C9.05556 15.2427 9.4784 15.6657 10 15.6657ZM15.6667 10.9419C15.6667 11.4636 15.2438 11.8866 14.7222 11.8866C14.2006 11.8866 13.7778 11.4636 13.7778 10.9419C13.7778 10.4201 14.2006 9.99708 14.7222 9.99708C15.2438 9.99708 15.6667 10.4201 15.6667 10.9419ZM14.7222 15.6657C15.2438 15.6657 15.6667 15.2427 15.6667 14.7209C15.6667 14.1991 15.2438 13.7762 14.7222 13.7762C14.2006 13.7762 13.7778 14.1991 13.7778 14.7209C13.7778 15.2427 14.2006 15.6657 14.7222 15.6657Z" fill=""/>
</svg>


            

    )
}
