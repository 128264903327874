import React from 'react'

export const Email = ({
    className = "",
    width = "18px",
    height = "18px",
    viewBox = "0 0 18 18",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
           <path fillRule="evenodd" clipRule="evenodd" d="M0.201253 2.2043C0.0670844 2.33928 0 2.5158 0 2.69231V13.7692C0 14.4425 0.267444 15.0881 0.743498 15.5642C1.21955 16.0402 1.86522 16.3077 2.53846 16.3077H15.4615C16.1348 16.3077 16.7804 16.0402 17.2565 15.5642C17.7326 15.0881 18 14.4425 18 13.7692V2.69231C18 2.68083 17.9997 2.66939 17.9991 2.65798C17.9907 2.48687 17.9189 2.32449 17.7972 2.20277C17.662 2.06759 17.4849 2 17.3077 2H0.692308C0.515131 2 0.337954 2.06759 0.202772 2.20277C0.202265 2.20328 0.201759 2.20379 0.201253 2.2043ZM15.6363 3.38462H2.36369L9 10.0209L15.6363 3.38462ZM16.6154 4.36369L9.48954 11.4895C9.21917 11.7599 8.78083 11.7599 8.51046 11.4895L1.38462 4.36369V13.7692C1.38462 14.4062 1.90154 14.9231 2.53846 14.9231H15.4615C16.0985 14.9231 16.6154 14.4062 16.6154 13.7692V4.36369Z" fill="#E31E24"/>
        </svg>

    )
}
