import React from 'react'

export const PaymentSuccess = ({
    className = "",
    width = "143px",
    height = "181px",
    viewBox = "0 0 143 181",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M118.905 7.26075C118.905 3.25075 122.156 0 126.166 0C130.176 0 133.427 3.25075 133.427 7.26075C133.427 11.2707 130.176 14.5215 126.166 14.5215C122.156 14.5215 118.905 11.2707 118.905 7.26075ZM6 97.2722C6 62.3279 34.3279 34 69.2722 34C104.217 34 132.544 62.3279 132.544 97.2722C132.544 132.217 104.217 160.544 69.2722 160.544C34.3279 160.544 6 132.217 6 97.2722ZM11.8088 170.109C8.80134 170.109 6.36328 172.547 6.36328 175.555C6.36328 178.562 8.80134 181 11.8088 181C14.8163 181 17.2544 178.562 17.2544 175.555C17.2544 172.547 14.8163 170.109 11.8088 170.109Z" fill="#EEEDED"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4981 21.34C22.6721 17.5147 15.6588 18.3259 11.8335 23.152C8.00816 27.978 8.81941 34.9913 13.6454 38.8166C18.4715 42.6419 25.4848 41.8307 29.3101 37.0046C33.1354 32.1786 32.3241 25.1653 27.4981 21.34ZM114.986 168.681C119.695 172.649 126.729 172.048 130.697 167.339C134.665 162.629 134.064 155.595 129.355 151.627C124.645 147.659 117.611 148.26 113.643 152.969C109.675 157.679 110.276 164.713 114.986 168.681Z" fill="#2B2A29"/>
<path opacity="0.4" d="M69.7919 130.25C69.374 130.25 68.9561 130.154 68.5789 129.959L56.4252 123.667C52.9765 121.88 50.2787 119.875 48.1721 117.538C43.5614 112.428 40.9961 105.869 40.9553 99.0647L40.8126 76.6694C40.7956 74.0847 42.4639 71.7647 44.9579 70.891L67.2742 63.1104C68.5993 62.6372 70.0773 62.6305 71.4262 63.0872L93.8275 70.6031C96.335 71.4404 98.0305 73.7438 98.0441 76.3252L98.1868 98.7371C98.231 105.531 95.754 112.117 91.2146 117.283C89.1318 119.653 86.4578 121.688 83.0431 123.509L70.9981 129.942C70.6244 130.144 70.2098 130.247 69.7919 130.25" fill="#E31E24"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.41581 47.9836C2.75022 46.3001 5.19672 46.0171 6.88022 47.3515C8.56372 48.6859 8.84672 51.1324 7.51231 52.8159C6.1779 54.4994 3.7314 54.7824 2.0479 53.448C0.364396 52.1136 0.0814021 49.6671 1.41581 47.9836ZM141.853 142.83C140.469 144.472 138.015 144.682 136.372 143.298C134.729 141.914 134.52 139.46 135.904 137.817C137.288 136.174 139.742 135.965 141.385 137.349C143.027 138.733 143.237 141.187 141.853 142.83ZM65.3948 103.618C65.8977 104.101 66.5501 104.336 67.2024 104.333C67.8548 104.33 68.5038 104.085 68.9998 103.595L82.1593 90.6186C83.148 89.6489 83.1378 88.0769 82.1355 87.1105C81.1365 86.1474 79.5226 86.1574 78.5305 87.1337L67.165 98.3396L62.5101 93.8652C61.5078 92.9021 59.8939 92.912 58.9052 93.8883C57.9164 94.8613 57.9266 96.43 58.9255 97.3964L65.3948 103.618Z" fill="#E31E24"/>
        </svg>

    )
}