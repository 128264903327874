import React, { useEffect, useState } from "react";
import { Card, Carousel, Stack } from "react-bootstrap";
import { Button } from "../form-type/button";
import "./listingCard.css";
import { Icons } from "../Icons/Icon";
import { shareOnMobile } from "react-mobile-share";
import {
  cardHeadingLimit,
  cardLocationLimit,
  getPlatform,
  listingLikeCount,
  listingViewCount,
} from "../../../utils/constGlobleData";
import {
  setPreviousSubCategoryBusiness,
  setPreviousSubCategoryBusinessdata,
} from "../../../redux/slices/subCat";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formatLikeCount,
  shareBlockFunction,
  sliceCharacters,
} from "../../../utils/exportGlobleFunction";

export const ListingCard = (businessProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(false);
  const business = businessProps.business;
  const exploreBusinessById = async (item) => {
    try {
      dispatch(setPreviousSubCategoryBusiness(item.category_id));
      dispatch(setPreviousSubCategoryBusinessdata(item.sub_category_id));
      navigate(`business/${item.id}`);
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleLikeClick = (e, item) => {
    // dispatch(setPreviousSubCategoryBusiness(0));
    if (!businessProps.liketnDisabled && !businessProps.isLoading) {
      businessProps.setLiketnDisabled(true);
      businessProps.setTempLike(item);
      businessProps.setLikeStates((prevStates) => ({
        ...prevStates,
        [item.id]: !prevStates[item.id], // Toggle the like state
      }));
      // Call the props.handleLikeClick function
      businessProps.handleLikeClick(e, item);
    }
  };

  useEffect(() => {
    const data = business?.filter((event) => event?.is_active !== 0);
    setData(data);
  }, [business]);
  const [shareBlock, setShareBlock] = useState(false);
  const handleShareLink = async (item) => {
    shareBlockFunction(setShareBlock);
    if (!shareBlock) {
      // Use item.business_image directly as it's a single string URL
      const bannerImageUrl = item.business_image || "";
      const itemId = item.id;

      // Form the URL using the current location and the item ID
      const urlWithEvent = `${window.location.href}/business/${itemId}`;

      // Create share parameters
      const shareParams = {
        imageUrl: bannerImageUrl,
        description: item.description.replace(/<[^>]+>/g, ""), // Remove HTML tags
        title: item.business_name,
        url: urlWithEvent,
      };

      // Convert the URL to a string
      const urlAsString = String(urlWithEvent);
      // Check platform and share
      if (getPlatform() === "Android") {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          const { title, description, imageUrl } = shareParams;
          // Call the Android share intent
          // eslint-disable-next-line no-undef
          Android.callShareIntent(title, urlAsString, description, imageUrl);
        }
      } else if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
        const { title, url, description, imageUrl } = shareParams;
        window.webkit.messageHandlers.share.postMessage(shareParams);
      } else {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          shareOnMobile(shareParams);
        }
      }
    }
  };
  return (
    <>
      {data.length !== 0 ? (
        Array.isArray(business) &&
        business
          ?.filter((event) => event?.is_active !== 0)
          .map((item, index) => {
            {console.log("item",item)}
            if (item !== undefined && item) {
              return (
                <Card className="my-3" key={index}>
                  {item.business_image && item.business_image !== null ? (
                    <Carousel
                      controls={false}
                      interval={null}
                      onClick={() => exploreBusinessById(item)}
                    >
                      {Array.isArray(item?.business_image) ? (
                        item?.business_image?.map((image, index) => (
                          <Carousel.Item key={index}>
                            <div className="dark-image">
                              <Card.Img
                                variant="top"
                                style={{
                                  maxnHeight: "70vh",
                                  minHeight: "30vh",
                                }}
                                src={image}
                              />
                            </div>
                          </Carousel.Item>
                        ))
                      ) : (
                        <div className="dark-image">
                          <Card.Img
                            variant="top"
                            style={{
                              maxHeight: "72vh",
                              minHeight: "30vh",
                              borderRadius: "",
                            }}
                            src={item?.business_image}
                          />
                        </div>
                      )}
                      <div className="d-flex card-text-overlay-list">
                        <div className="">
                          <h2 className="f-14 f-w600 text-white mb-0-5">
                            {sliceCharacters(
                              item?.business_name,
                              cardHeadingLimit
                            )}
                          </h2>
                          <div className="d-flex ">
                            <Icons name="location" className="svg-white" />
                            <h4 className="text-dark-white mt-1 f-fw500 pl-1 f-10">
                              {sliceCharacters(
                                item?.address,
                                cardLocationLimit
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Carousel>
                  ) : (
                    <div className="dark-image">
                      <Card.Img
                        variant="top"
                        style={{ maxnHeight: "70vh", minHeight: "30vh" }}
                        src={item.bannerimage ? item.bannerimage[0] : ""}
                      />
                    </div>
                  )}

                  <Card.Footer className="">
                    <div className="d-flex justify-content-between">
                      <Stack direction="horizontal" gap={1}>
                        <div className="d-flex ">
                          {businessProps?.liketnDisabled &&
                            businessProps.likeStates[item.id] ? (
                            <>
                              <div className="cursor-pointer">
                                <Icons
                                  name="like"
                                  className={`cursor-pointer
                                ${businessProps.tempLike.id === item.id
                                      ? businessProps.tempLike.like === 0
                                        ? "svg-red"
                                        : "svg-white"
                                      : businessProps.tempLike.like === 1
                                        ? "svg-white"
                                        : "svg-red"
                                    }
                                `}
                                  onClick={(e) => handleLikeClick(e, item)}
                                />
                              </div>
                              <div className="mr-3 text-dark mb-0 f-14 ms-1 m-t-3 lh-18 f-w500">
                                {businessProps.tempLike.id === item.id &&
                                  item.like_count < 1000
                                  ? businessProps.tempLike.like !== 0
                                    ? item.like_count - 1+listingLikeCount
                                    : item.like_count + 1+listingLikeCount
                                  : formatLikeCount(item.like_count+listingLikeCount)}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="cursor-pointer">
                                <Icons
                                  name="like"
                                  className={`
                                ${item.like === 1 ? "svg-red" : "svg-white"}
                                `}
                                  onClick={(e) => handleLikeClick(e, item)}
                                />
                              </div>
                              <div className="mr-3 text-dark f-14 ms-1 m-t-3 lh-18 f-w500">
                                {formatLikeCount(item?.like_count+listingLikeCount)}
                              </div>
                            </>
                          )}
                          <span
                            onClick={async () => handleShareLink(item)}
                            className="cursor-pointer"
                          >
                            <Icons
                              name="share"
                              className=" svg-size-20 svg-white ms-2 m-t-1 mr-3"
                            />

                          </span>


                          <div className="cursor-pointer">
                              <Icons
                                name="show"
                                className="svg-white svg-size-20 ms-2 m-t-1"
                                
                              />
                            </div>
                            <div className="mr-3 text-like f-14 ms-1 m-t-2  lh-18 f-w500">
                              {formatLikeCount(item?.views_count + listingViewCount)}
                            </div>
                        </div>
                      </Stack>
                      <Button
                        className="link view-details-button"
                        onClick={() => exploreBusinessById(item)}
                        label={
                          <>
                            View Details{" "}
                            <Icons
                              name="arrow-right"
                              className="svg-white "
                              viewBox="0 1 16 16"
                            />
                          </>
                          
                        }
                      />
                    </div>
                  </Card.Footer>
                </Card>
              );
            }
          })
      ) : (
        <p className="text-black text-center">No business list found!</p>
      )}
    </>
  );
};
