import React from "react";

export const ListingLocation = ({
  className = "",
  width = "27px",
  height = "27px",
  viewBox = "0 0 27 27",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
     <rect x="0.511719" y="0.223022" width="26" height="26" rx="4" fill="#E31E24"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.66133 12.5599C9.66133 10.4093 11.3888 8.67302 13.511 8.67302C15.6334 8.67302 17.3613 10.4093 17.3613 12.5599C17.3613 13.924 16.544 15.324 15.5683 16.4224C15.0883 16.9627 14.59 17.4071 14.1774 17.7123C13.9706 17.8653 13.7931 17.9775 13.6567 18.0487C13.5833 18.0871 13.536 18.1059 13.511 18.1151C13.4861 18.1059 13.4388 18.0871 13.3654 18.0487C13.229 17.9775 13.0516 17.8653 12.8448 17.7123C12.4322 17.4072 11.934 16.9628 11.4541 16.4224C10.4785 15.324 9.66133 13.924 9.66133 12.5599ZM13.511 7.62302C10.8005 7.62302 8.61133 9.83785 8.61133 12.5599C8.61133 14.308 9.63192 15.952 10.669 17.1197C11.1953 17.7123 11.7471 18.2064 12.2203 18.5565C12.4564 18.7311 12.6808 18.8757 12.8793 18.9794C13.0522 19.0697 13.2853 19.173 13.511 19.173C13.7368 19.173 13.9699 19.0697 14.1428 18.9794C14.3413 18.8758 14.5657 18.7311 14.8018 18.5565C15.2751 18.2064 15.8269 17.7123 16.3534 17.1197C17.3906 15.952 18.4113 14.308 18.4113 12.5599C18.4113 9.83776 16.2215 7.62302 13.511 7.62302ZM13.5116 10.5397C12.4159 10.5397 11.528 11.4276 11.528 12.5233C11.528 13.6185 12.416 14.5063 13.5116 14.5063C14.6067 14.5063 15.4946 13.6184 15.4946 12.5233C15.4946 11.4278 14.6068 10.5397 13.5116 10.5397ZM12.578 12.5233C12.578 12.0075 12.9958 11.5897 13.5116 11.5897C14.0267 11.5897 14.4446 12.0074 14.4446 12.5233C14.4446 13.0385 14.0268 13.4563 13.5116 13.4563C12.9957 13.4563 12.578 13.0384 12.578 12.5233Z" fill="white"/>
</svg>

  );
};
