import React from "react";

export const LocationEvent = ({
  className = "",
  width = "21px",
  height = "21px",
  viewBox = "0 0 21 21",
  fill = "",
}) => {
  return (
    <div>
      <svg
        viewBox={viewBox}
        width={width}
        height={height}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.15412 9.61421C4.15412 6.2654 6.84399 3.5619 10.1485 3.5619C13.4532 3.5619 16.1438 6.26554 16.1438 9.61421C16.1438 11.7382 14.8711 13.9182 13.3519 15.6284C12.6045 16.4698 11.8286 17.1618 11.1861 17.637C10.864 17.8752 10.5877 18.0498 10.3753 18.1608C10.261 18.2205 10.1874 18.2498 10.1485 18.2641C10.1096 18.2499 10.036 18.2205 9.92177 18.1608C9.70938 18.0499 9.43313 17.8752 9.11106 17.637C8.46872 17.1618 7.69288 16.4699 6.94557 15.6284C5.4266 13.9182 4.15412 11.7382 4.15412 9.61421ZM10.1485 1.92694C5.92786 1.92694 2.51917 5.37566 2.51917 9.61421C2.51917 12.3361 4.10832 14.896 5.72314 16.7142C6.54272 17.6369 7.40182 18.4063 8.13876 18.9514C8.50632 19.2233 8.8558 19.4486 9.16477 19.61C9.43404 19.7506 9.797 19.9114 10.1485 19.9114C10.5 19.9114 10.863 19.7506 11.1323 19.61C11.4412 19.4486 11.7908 19.2233 12.1584 18.9514C12.8954 18.4063 13.7546 17.637 14.5743 16.7142C16.1893 14.896 17.7787 12.3361 17.7787 9.61421C17.7787 5.37552 14.369 1.92694 10.1485 1.92694ZM10.1494 6.46846C8.44332 6.46846 7.06072 7.85107 7.06072 9.55717C7.06072 11.2626 8.44354 12.645 10.1494 12.645C11.8546 12.645 13.2372 11.2623 13.2372 9.55717C13.2372 7.85128 11.8548 6.46846 10.1494 6.46846ZM8.69567 9.55717C8.69567 8.75403 9.34628 8.10341 10.1494 8.10341C10.9514 8.10341 11.6023 8.75381 11.6023 9.55717C11.6023 10.3594 10.9516 11.01 10.1494 11.01C9.34607 11.01 8.69567 10.3592 8.69567 9.55717Z"
          fill="#E31E24"
        />
      </svg>
    </div>
  );
};
