import React from 'react';

export const Food = ({
    className = "",
    width = "38px",
    height = "38px",
    viewBox = "0 0 38 38",
    fill = "",

}) => {
   

    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill}  xmlns="http://www.w3.org/2000/svg" className={className}>
<path d="M6 11.669C6.05698 11.4119 6.10071 11.1509 6.1736 10.8991C6.61223 9.37382 7.94404 8.37994 9.60581 8.33091C11.0556 8.28717 12.3715 8.73906 13.6171 9.43213C14.8217 10.1027 15.9335 10.915 16.8029 11.9871C18.1214 13.6104 18.3387 15.4206 17.5132 17.3487C17.492 17.3978 17.4734 17.4495 17.4442 17.5223C18.0724 18.0802 18.7045 18.6421 19.3538 19.2186C19.9992 18.6448 20.6353 18.0855 21.2634 17.517C21.3058 17.4786 21.3151 17.3686 21.2979 17.3037C20.899 15.8076 21.282 14.5407 22.3752 13.4567C24.4346 11.4119 26.482 9.35394 28.5347 7.30257C28.5996 7.23763 28.6606 7.17005 28.7321 7.11307C28.9402 6.95007 29.2264 6.96597 29.4053 7.14355C29.5895 7.32775 29.6054 7.59941 29.4371 7.81409C29.3815 7.88565 29.3125 7.94793 29.2489 8.01154C27.1843 10.0775 25.121 12.1434 23.0524 14.2067C22.1486 15.1079 21.9114 16.1442 22.3898 17.3342C22.4998 17.6072 22.4481 17.8258 22.2281 18.0206C20.7797 19.3034 19.334 20.5901 17.8855 21.8716C17.5582 22.1618 17.3051 22.1419 17.0149 21.8173C16.4623 21.1971 15.9084 20.5782 15.3637 19.9514C15.2458 19.8162 15.161 19.7765 14.9794 19.8573C12.9969 20.7399 10.7799 20.3211 9.25729 18.7733C7.88308 17.3753 6.79113 15.7983 6.25443 13.8847C6.1471 13.5004 6.08481 13.1029 6.00133 12.7119C6 12.3634 6 12.0162 6 11.669ZM18.5945 19.8918C17.924 19.2968 17.2839 18.7283 16.6425 18.1598C16.2251 17.79 16.2039 17.6655 16.4729 17.1765C17.2614 15.7453 17.1368 13.9709 16.0952 12.7093C15.1835 11.6054 14.0425 10.7732 12.769 10.1239C11.9235 9.69186 11.0357 9.4096 10.0829 9.33804C8.60133 9.22673 7.43914 9.9781 7.11183 11.2847C6.94883 11.9354 6.98726 12.5887 7.12773 13.238C7.44577 14.6984 8.16004 15.9626 9.10887 17.0917C9.55811 17.6257 10.0325 18.173 10.5984 18.5679C11.9487 19.5114 13.3998 19.5485 14.8628 18.7892C15.3372 18.5427 15.4644 18.556 15.8249 18.9602C16.0555 19.2199 16.2847 19.4796 16.5153 19.7394C16.8413 20.1064 17.1686 20.4722 17.5092 20.8552C17.8749 20.5305 18.2208 20.2231 18.5945 19.8918Z" fill=""/>
<path d="M21.9536 21.5243C22.55 22.053 23.1277 22.5672 23.7055 23.0814C25.9093 25.0387 28.121 26.9867 30.3155 28.9546C31.6182 30.122 31.4856 32.1257 30.069 33.0931C28.9651 33.8458 27.5194 33.6457 26.5851 32.5988C25.0917 30.9238 23.6048 29.2421 22.114 27.5631C21.2354 26.5732 20.3581 25.5833 19.4808 24.5934C19.4451 24.5536 19.4252 24.498 19.3841 24.4224C18.976 24.8823 18.6129 25.2878 18.2511 25.6959C16.2037 28.0031 14.1589 30.3115 12.1076 32.6147C11.3641 33.4496 10.4285 33.7424 9.36045 33.4257C8.29104 33.109 7.66555 32.351 7.51448 31.2431C7.39654 30.3818 7.6629 29.6198 8.30561 29.0407C10.2987 27.2451 12.3116 25.472 14.3193 23.6923C14.5697 23.471 14.8811 23.4869 15.072 23.7121C15.2708 23.9467 15.2297 24.2276 14.9474 24.4781C13.283 25.957 11.6172 27.4346 9.95148 28.9122C9.65067 29.1785 9.34853 29.4422 9.05169 29.7126C8.34272 30.3566 8.30959 31.4194 8.9735 32.0754C9.64007 32.734 10.6936 32.6916 11.335 31.9693C14.6506 28.2376 17.9635 24.5046 21.2765 20.7716C21.8516 20.1236 22.4267 19.4742 23.0032 18.8262C23.2444 18.5559 23.3901 18.5267 23.7294 18.6606C24.8783 19.1151 25.8974 18.9044 26.768 18.0377C28.8578 15.9585 30.9383 13.8714 33.0228 11.7869C33.3621 11.4476 33.6589 11.3959 33.8935 11.6345C34.132 11.877 34.0817 12.1526 33.7305 12.5038C31.6592 14.575 29.5867 16.6463 27.5167 18.7176C26.4592 19.7764 25.2096 20.1249 23.7572 19.7432C23.683 19.7234 23.5584 19.7287 23.5147 19.775C22.9939 20.3449 22.4837 20.9266 21.9536 21.5243ZM20.0308 23.6896C20.101 23.7718 20.154 23.8341 20.2084 23.8963C21.4249 25.2692 22.6427 26.6408 23.8592 28.0137C25.0161 29.3177 26.1743 30.6203 27.3299 31.9256C27.7486 32.3987 28.2628 32.6253 28.8909 32.5074C29.5284 32.3868 29.9617 31.9998 30.1406 31.3783C30.3327 30.7091 30.1353 30.1379 29.6158 29.6768C26.8793 27.2477 24.1415 24.8187 21.4037 22.3896C21.3652 22.3552 21.3215 22.3287 21.2712 22.2929C20.8551 22.7607 20.4496 23.2179 20.0308 23.6896Z" fill=""/>
<path d="M31.981 9.78723C32.144 9.9065 32.3321 9.97806 32.3931 10.1079C32.4594 10.2497 32.4302 10.4525 32.3958 10.6181C32.3759 10.7109 32.2646 10.7877 32.1877 10.8646C30.5445 12.5091 28.9013 14.1524 27.2567 15.7956C27.1865 15.8658 27.1162 15.94 27.0341 15.9957C26.8247 16.1362 26.5517 16.0964 26.3887 15.9149C26.231 15.7399 26.2164 15.4815 26.3596 15.2854C26.402 15.2271 26.4563 15.1767 26.508 15.1251C28.1777 13.4553 29.8448 11.7843 31.5185 10.1199C31.6391 9.99661 31.8008 9.91445 31.981 9.78723Z" fill=""/>
<path d="M31.1267 8.94977C31.0154 9.12337 30.9504 9.27842 30.8404 9.38841C29.1575 11.082 27.4665 12.7689 25.7783 14.4585C25.765 14.4718 25.7531 14.485 25.7398 14.497C25.4867 14.7302 25.1965 14.7448 24.9871 14.5354C24.7764 14.3234 24.7897 14.0318 25.0309 13.7853C25.4138 13.3944 25.8034 13.0114 26.1904 12.6245C27.4798 11.3351 28.7679 10.0457 30.0586 8.75762C30.1355 8.68076 30.211 8.56679 30.3037 8.54824C30.4694 8.51379 30.6721 8.48463 30.8139 8.55089C30.9425 8.61053 31.0154 8.7987 31.1267 8.94977Z" fill=""/>
</svg>


      
    );
};
