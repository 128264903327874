import React from 'react'

export const ArrowDown = ({
    className = "",
    width = "16px",
    height = "17px",
    viewBox = "0 0 16 17",
    fill = "",
}) => {
  return (
    
         <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04023 5.15944C1.36056 4.83911 1.87992 4.83911 2.20025 5.15944L7.99999 10.9592L13.7997 5.15944C14.1201 4.83911 14.6394 4.83911 14.9597 5.15944C15.2801 5.47976 15.2801 5.99912 14.9597 6.31945L8.57999 12.6992C8.42617 12.853 8.21753 12.9394 7.99999 12.9394C7.78244 12.9394 7.57381 12.853 7.41998 12.6992L1.04023 6.31945C0.719906 5.99912 0.719906 5.47976 1.04023 5.15944Z" fill="white"/>
</svg>

    
  )
}
