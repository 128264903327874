import { Card, Col, Container, Row } from "react-bootstrap";
import MyImage from "./alpha.jpg";
import { Icons } from "../../components/Icons/Icon";
import './notification.css'
import { useNavigate } from 'react-router-dom'

export const Notification = () => {
  const cardsData = [
    { img: { MyImage }, title: "Payment", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "News", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "Explore", date: "12 jan 2024", content: "Hi, this place will be taken by content thihsihishihi" },
    { img: { MyImage }, title: "Cart", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "News", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "Explore", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "Cart", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "News", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "Explore", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
    { img: { MyImage }, title: "Cart", date: "12 jan 2024", content: "Hi, this place will be taken by content" },
  ]
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (<Container>
    <div className='eventDetail scroll-without-category' >

      <div className="top-header-fix">
        <nav
          className="navbar top-nav navbar-light bg-light text-center "
          style={{ position: "fixed" }}
        >
          <div className="container-fluid mx-auto text-center pr-3">
            <span onClick={() => handleBackClick()}>
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto  ">
              <h1 className="f-24 f-w600 mb-0">{"Notification"}</h1>
            </div>
          </div>
        </nav>
      </div>
      {cardsData.map((card, index) =>
        <Card className="h-60 b-btm mb-1" key={index}>
          <Row>
            <Col className="w-60 " md={2}>
              <Card.Img variant="top" className=" rounded-circle w-60" src={MyImage} />
            </Col>
            <Col className="pt-10"> <Card.Body className="p-0">
              <Card.Title className="f-14 pl-15 text-black" >{card.title}</Card.Title>
              <Col >
                <Card.Text className="text-light-black f-8" >{card.date} &nbsp;&nbsp;|&nbsp;&nbsp; {card.content}</Card.Text></Col>
            </Card.Body>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  </Container>
  )
}