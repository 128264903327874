import React from 'react'
 
export const Cross = ({
    className = "",
    width = "20px",
    height = "auto",
    viewBox = "0 0 20 20",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
          <path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" fill="#E31E24"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.81361 6.81239C7.13243 6.49364 7.64928 6.49369 7.96803 6.81251L10.0009 8.84581L12.0319 6.81478C12.3507 6.49599 12.8676 6.49599 13.1864 6.81478C13.5051 7.13356 13.5051 7.65041 13.1864 7.9692L11.1552 10.0004L13.188 12.0336C13.5067 12.3524 13.5067 12.8692 13.1879 13.188C12.869 13.5067 12.3522 13.5067 12.0334 13.1879L10.0008 11.1548L7.97076 13.1848C7.65198 13.5036 7.13513 13.5036 6.81634 13.1848C6.49756 12.866 6.49756 12.3492 6.81634 12.0304L8.84649 10.0002L6.81349 7.96681C6.49474 7.64799 6.49479 7.13114 6.81361 6.81239Z" fill="white"/>
</svg>
 
    )
}