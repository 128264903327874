import React from "react";

export const DeleteAccount = ({
  className = "",
  width = "143px",
  height = "auto",
  viewBox = "0 0 143 290",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
<rect x="7.36328" y="34.2112" width="126.544" height="126.544" rx="63.2722" fill="#EEEDED"/>
<rect x="119.906" y="0.5" width="14.5215" height="14.5215" rx="7.26075" fill="#EEEDED"/>
<rect x="7.36328" y="170.609" width="10.8911" height="10.8911" rx="5.44556" fill="#EEEDED"/>
<rect x="4.83203" y="45.4353" width="7.77937" height="7.77937" rx="3.88969" transform="rotate(38.4017 4.83203 45.4353)" fill="#E31E24"/>
<rect x="140.348" y="146.304" width="7.77937" height="7.77937" rx="3.88969" transform="rotate(-139.884 140.348 146.304)" fill="#E31E24"/>
<rect x="19.7598" y="14.9136" width="22.3009" height="22.3009" rx="11.1504" transform="rotate(38.4017 19.7598 14.9136)" fill="#2B2A29"/>
<rect x="124.512" y="176.366" width="22.3009" height="22.3009" rx="11.1504" transform="rotate(-139.884 124.512 176.366)" fill="#2B2A29"/>
<path opacity="0.4" d="M94.8385 89.5301C94.8385 89.7454 93.1508 111.091 92.1868 120.075C91.5832 125.588 88.0292 128.932 82.6981 129.027C78.6021 129.118 74.5922 129.15 70.6471 129.15C66.4586 129.15 62.3625 129.118 58.3866 129.027C53.2342 128.903 49.6771 125.493 49.1042 120.075C48.1125 111.059 46.4556 89.7454 46.4248 89.5301C46.394 88.8809 46.6035 88.2634 47.0285 87.7631C47.4473 87.3008 48.051 87.0222 48.6854 87.0222H92.6088C93.2401 87.0222 93.8129 87.3008 94.2657 87.7631C94.6876 88.2634 94.9001 88.8809 94.8385 89.5301Z" fill="#E31E24"/>
<path d="M99.1348 78.4101C99.1348 77.1086 98.1092 76.089 96.8773 76.089H87.6442C85.7656 76.089 84.1333 74.7527 83.7145 72.8686L83.1971 70.5602C82.4733 67.7705 79.9756 65.8167 77.1731 65.8167H64.0995C61.2662 65.8167 58.7931 67.7705 58.0417 70.7122L57.5582 72.8718C57.1362 74.7527 55.504 76.089 53.6284 76.089H44.3953C43.1603 76.089 42.1348 77.1086 42.1348 78.4101V79.6134C42.1348 80.8832 43.1603 81.9345 44.3953 81.9345H96.8773C98.1092 81.9345 99.1348 80.8832 99.1348 79.6134V78.4101Z" fill="#E31E24"/>
</svg>
  );
};
