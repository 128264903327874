import React from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";

const AboutUs = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This will go back to the previous page in history
  };

  return (
    <>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center ">
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto pr-24">
              <h1 className="f-24 f-w600 mb-0">{"About Us"}</h1>
            </div>
          </div>
        </nav>
      </div>

      <div>
        <div className="scroll-without-category">
          <p className=" f-14 f-w600 ">About Indore Talk App:</p>
          <p className=" f-14 f-w400 ">
            Indore Talk Media Network's 'Indore Talk App' is developed to
            revolutionize Indore’s Digital Living! Pioneering social
            interaction, infotainment, business listing and event listing and
            bookings powered by cutting-edge AI and EI technology, it's first
            and one-of-a-kind of hyperlocal hybrid social media platform
            designed to enrich digital experience and digital wellbeing of
            Indorians like never before.
          </p>
          <div>
            <p className=" f-14 f-w400 ">
              Our app is a one-stop information hub for the residents as well as
              advertisers in Indore, allowing them to explore and engage with
              filtered local businesses, events, and information.
            </p>
          </div>
          <div>
            <p className=" f-14 f-w00 ">
              As a Team of Digital Media Professionals and Content Creators our
              aim is to Empower Indore and urban explorers with curated insights
              and information! Our hybrid infotainment app delivers tailored
              city information, news, highlighting top businesses and events to
              enrich your digital life and wellbeing of Indorians.
            </p>
          </div>

          <p className=" f-14 f-w600 ">About Indore Talk Media Network:</p>
          <p className=" f-14 f-w400 ">
            Indore Talk Media Network, a thriving presence in the digital and
            social media landscape, has rapidly emerged as a prominent force
            with 1 million plus followership within its five years of operation.
            Under the visionary leadership of its founders, who bring more than
            two decades of expertise in content, digital marketing, and AV
            production from working in various media organisations and the
            parent company, MP 360 Degree, the company has solidified its
            reputation as an innovative and versatile New Age Digital Media
            House.
          </p>
          <p className=" f-14 f-w600 ">Diverse Ventures:</p>
          <p className=" f-14 f-w400 ">
            The heart of Indore Talk Media Network’s offerings lies in its
            diverse ventures, each catering to distinct facets of modern
            communication and branding.
          </p>

          <p className=" f-14 f-w600 ">Indore Talk Channel:</p>
          <p className=" f-14 f-w400 ">
            Indore Talk stands as the pioneering Hyperlocal Infotainment Digital
            Channel in the city of Indore. Operating across the web, various
            social media platforms and now on its own mobile app , it serves as
            a dynamic source of information and entertainment, exclusively
            tailored to the local community. </p>
            <div>
              <p className=" f-14 f-w400 ">
                This digital channel is dedicated to providing the residents of
                Indore with a unique blend of hyperlocal news, updates, and
                engaging content that resonates with their interests and
                preferences.
              </p>
            </div>
         
          <p className=" f-14 f-w600 ">Indore Talk Production:</p>
          <p className=" f-14 f-w400 ">
            Indore Talk Production specializes in corporate video production,
            TVC, Ad films, Promotional videos and content videos, creating
            engaging and impactful visual content that effectively communicates
            the thought behind creating the video.
          </p>
          <p className=" f-14 f-w600 ">Indore Talk Events:</p>
          <p className=" f-14 f-w400 ">
            Indore Talk Events is dedicated to curating and organizing
            city-centric events that celebrate the vibrancy, diversity,
            awareness and spirit of Indore. We believe in creating memorable
            experiences that bring the community together and showcase the
            unique essence of the city.
          </p>
          <p className=" f-14 f-w600 ">Indore Talk Academy:</p>
          <p className=" f-14 f-w400 ">
            At the Indore Talk Content Creator Academy, we are committed to
            fostering the next generation of content creators who will shape the
            digital world. Our academy is a place where creativity meets
            expertise, empowering individuals to transform their passion for
            content creation into a fulfilling and impactful career.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
