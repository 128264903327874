/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { React, useEffect, useRef, useState } from "react";
import { Icons } from "../../components/Icons/Icon";
import { shareOnMobile } from "react-mobile-share";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Container,
  Image,
  Modal,
  Row,
  Stack,
  Tab,
  Tabs,
  Toast,
} from "react-bootstrap";
import "./ListingDetails.css";
import { exportApi } from "../../../services/indoreTalk.api";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubSubCat,
  setsingleBusinessData,
} from "../../../redux/slices/singleDataSlice";
import {
  displayRating,
  exportTextAsUppercase,
  formatLikeCount,
  getEmbeddedUrl,
  handleButtonClick,
  handleOrderButtonClick,
  likePayloads,
  shareBlockFunction,
  sliceCharacters,
  titleCase,
  unnecatchLogout,
} from "../../../utils/exportGlobleFunction";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { getPlatform, listingLikeCount, listingViewCount } from "../../../utils/constGlobleData";
import ReactStars from "react-rating-stars-component";
import InputField from "../../components/form-type/InputField";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { SingleSkeleton } from "../../components/cards/singleSkeleton";
import { Parser } from "html-to-react";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from 'store'

let playingVideos = [];
const YoutubeIframe = ({ videoSrc }) => {
  const iframeRef = useRef();

  useEffect(() => {
    const iframe = iframeRef.current;
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playingVideos.push(iframe);
        } else {
          iframe?.contentWindow?.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
          playingVideos = playingVideos.filter((v) => v !== iframe);
        }
      });
    };

    const options = {
      threshold: 1,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(iframe);

    return () => {
      observer.disconnect();
    };
  }, [videoSrc]);

  return (
    <iframe
      ref={iframeRef}
      width="560"
      height="315"
      src={`${getEmbeddedUrl(videoSrc)}?enablejsapi=1&rel=0&autoplay=0&muted=1`}
      frameBorder="0"
      allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube video player"
    ></iframe>
  );
};

const stopAllVideos = () => {
  playingVideos.forEach((iframe) => {
    iframe?.contentWindow?.postMessage(
      '{"event":"command","func":"stopVideo","args":""}',
      "*"
    );
  });
};

export const ListingDetails = ({ item }) => {
  const tempLogin = useSelector((state) => state.auth);
  const location = useLocation();
  const htmlParser = new Parser();
  const { singleBusinessData } = useSelector((state) => state.singleData);
  const navigate = useNavigate();
  const id = useParams();
  const dispatch = useDispatch();
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [singleEvent, setSingleEvent] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(false);
  const [like, setLike] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [commentView, setCommentView] = useState([]);
  const [show, setShow] = useState(false);
  const [review, setReview] = useState(false);
  const [successReview, setSuccessReview] = useState();
  const [errorReview, setErrorReview] = useState();
  const [liketnDisabled, setLiketnDisabled] = useState(false);
  const [likeStates, setLikeStates] = useState({});
  const [tempLike, setTempLike] = useState({});
  const [sessionExpire, setSessionExpire] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const userData = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [formData, setFormData] = useState({
    user_id: userData?.user_id,
    login_type: userData?.login_type,
    name: userData?.name,
    business_id: singleBusinessData?.id,
    title: "",
    rating: "",
    review: "",
    is_active: singleBusinessData?.is_active,
  });
  const [validationErrors, setValidationErrors] = useState({
    rating: "",
    title: "",
    review: "",
  });
  let imagesObject = null;
  let imageUrls = null;

  useEffect(() => {
    setFormData({
      user_id: userData?.user_id || 0,
      login_type: userData?.login_type,
      name: userData?.name,
      business_id: singleBusinessData?.id,
      is_active: singleBusinessData?.is_active,
      rating: "",
    });
  }, [singleBusinessData]);

  if (singleBusinessData.gallery_images) {
    try {
      imagesObject = singleBusinessData.gallery_images;
      imageUrls = imagesObject ? Object.values(imagesObject) : null;
    } catch (error) {
      console.error("Error parsing gallery_images as JSON:", error);
    }
  }

  let menuObject = null;
  let menuUrls = null;
  if (singleBusinessData?.menus_image) {
    try {
      menuObject = singleBusinessData?.menus_image;
      menuUrls = menuObject ? Object.values(menuObject) : null;
    } catch (error) {
      console.error("Error parsing menu image as JSON:", error);
    }
  }

  let videoObject = null;
  let videoUrls = null;
  if (singleBusinessData?.videos) {
    try {
      videoObject = singleBusinessData?.videos;
      videoUrls = videoObject ? Object.values(videoObject) : null;
    } catch (error) {
      console.error("Error parsing videos as JSON:", error);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await exportApi.businessById({
          id: id.id,
          user_id: userId?.user_id || 0,
        });
        dispatch(setSubSubCat(res?.data?.sub_category_id));
        setSingleEvent(res.data);
        setLikeStates({});
        setLiketnDisabled(false);
        dispatch(setsingleBusinessData(res.data));
      } catch (error) {
        console.error("Error fetching data:", error);
        setLikeStates({});
        setLiketnDisabled(false);
      }
    };

    fetchData();
  }, [id, like, review]);

  useEffect(() => {
    const customerReviews = singleBusinessData?.custmer_rating_review?.data;
    setCommentView(customerReviews);
  }, [singleBusinessData]);

  const handleLoadMore = () => {
    setVisibleComments((prevVisibleComments) => prevVisibleComments + 3);
  };
  const [successToast, setSuccessToast] = useState(null);
  const handleReport = async (item) => {
    try {
      if (userId) {
        const report = {
          login_type: userId?.login_type,
          user_id: userId?.user_id,
          email: tempLogin?.tempLogin?.user?.data?.email,
          postid: item.id,
          type: "reviews",
        };
        setSuccessToast({
          message: "Report havs been sent successfully to admin",
        });
        await exportApiAuth.reportPost(report);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [showLoginModal, setLoginModalShow] = useState(false);

  const handleLikeClick = async (e, data) => {
    e.stopPropagation();
    if (!!userId) {
      try {
        const like = await exportApiAuth.likePost(likePayloads(data, userId));
        if (like.status === "success") {
          setSingleEvent(like.data);
          setLiketnDisabled(false);
          setTempLike({});
          setLikeStates({});
        } else {
          // if (like.code === 401) {
          setLiketnDisabled(false);
          // setErrorToast(true);
          setSessionExpire(true);
          setTempLike({});
          setLikeStates({});
          unnecatchLogout(store, dispatch, setSignOut);
          // }
        }
        // setLike(like)
      } catch (error) {
        setSessionExpire(false);
        setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
      }
    } else {
      setLiketnDisabled(false);
      setSessionExpire(false);
      setTempLike({});
      setLikeStates({});
      setErrorToast(true);
      setLoginModalShow(true);
    }
  };
  const [visibleComments, setVisibleComments] = useState(3);
  const handleImageClick = (imageSrc) => {
    setShowModal(true);
  };
  const handleVideoClick = (videosrc) => {
    stopAllVideos();
    handleAddTokenButton();
    setSelectedVideo(videosrc);
    setShowVideoModal(true);

  };
  const handleMenuImageClick = (menuSrc) => {
    setShowMenuModal(true);
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  let branches = null;
  let listItems1 = {};
  let listItems = null;
  if (singleBusinessData?.branch) {
    try {
      branches = singleBusinessData.branch;
      if (branches && typeof branches === "object") {
        listItems = Object.keys(branches)
          .filter((key) => branches[key].branchname !== "")
          .map((key) => {
            const branch = branches[key];
            return (
              <div className="d-flex float-left mr-2 mb-1 ">
                {branch.branchurl ? (
                  <a
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      const branchUrl = branch.branchurl;
                      if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
                        window.webkit.messageHandlers.reactNative.postMessage({
                          action: 'open_url',
                          url: branchUrl
                        });
                      } else {
                        window.open(branchUrl, "_blank");
                      }
                    }}
                    href={branch.branchurl} // Provide href for default behavior if JavaScript is disabled
                    target="_blank"
                    className="text-white text-decoration-none listing-branch-ui"
                    rel="noopener noreferrer"
                  >
                    {branch.branchname}
                    <span className="pl-1">
                      <Icons name="forward" className="" />
                    </span>
                  </a>
                ) : (
                  <div className="text-white text-decoration-none listing-branch-ui">
                    {branch.branchname}
                    <span className="pl-1">
                      <Icons name="forward" className="" />
                    </span>
                  </div>
                )}

              </div>
            );
          });
        listItems1 = listItems;
      } else {
        console.error("Invalid branches data:", branches);
      }
    } catch (error) {
      console.error("Error parsing branch as JSON:", error);
    }
  }

  const [shareBlock, setShareBlock] = useState(false)

  const handleShareLink = async (item) => {
    shareBlockFunction(setShareBlock);
    if (!shareBlock) {
      const bannerImageUrl = item.business_image || '';
      const shareParams = {
        imageUrl: bannerImageUrl,
        description: item.description.replace(/<[^>]+>/g, ''),
        title: item.business_name,
        url: window.location.href,
      };

      if (getPlatform() === "Android") {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          const { title, url, description, imageUrl } = shareParams;
          // eslint-disable-next-line no-undef
          Android.callShareIntent(title, url, description, imageUrl);
        }
      } else if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
        const { title, url, description, imageUrl } = shareParams;
        window.webkit.messageHandlers.share.postMessage(shareParams);
      }
      else {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          shareOnMobile(shareParams);
        }
      }
    }
  };
  const DisplayOperatingData = () => {
    return (
      <div>
        {Array.isArray(singleEvent.operating_hours) &&
          singleEvent.operating_hours.map((item, index) => (
            <Row key={index}>
              <Col xs={4} className="pl-0">
                {item.weekday}
              </Col>
              {item.open_close === "off" ? (
                <Col>
                  {item.open_time} {exportTextAsUppercase(item.open_am_pm)} -{" "}
                  {item.end_time} {exportTextAsUppercase(item.end_am_pm)}
                </Col>
              ) : (
                <Col>Closed</Col>
              )}
            </Row>
          ))}
      </div>
    );
  };
  const token = "Fullscreen"
  const handleClose = () => setShow(false);
  const handleRemoveTokenButton = () => {

    const currentUrl = window.location.href;

    // Remove the token query parameter from the URL
    const newUrl = currentUrl.split('?')[0]; // Removes everything after '?'

    // Update the URL without reloading the page
    window.history.replaceState({ path: newUrl }, '', newUrl);

  }
  const handleAddTokenButton = () => {

    const currentUrl = window.location.href;

    // Append the token as a query parameter to the current URL
    const newUrl = `${currentUrl}?token=${token}`;

    // Update the URL without reloading the page
    window.history.pushState({ path: newUrl }, '', newUrl);
  }


  const handleAddReviewButton = () => {
    setValidationErrors({
      rating: "",
      title: "",
      review: "",
    });
    if (!!userId) {
      setShow(true);
    } else {
      setLoginModalShow(true);
    }
  };

  const [modalIndex, setModalIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setModalIndex(selectedIndex);
  };

  const submitReview = async () => {
    let isValid = true;
    const errors = {};

    if (!formData.rating) {
      errors.rating = "Please select stars";
      isValid = false;
    }

    if (!formData.title) {
      errors.title = "Please add heading";
      isValid = false;
    }

    if (!formData.review) {
      errors.review = "Please add review";
      isValid = false;
    }

    if (!isValid) {
      setValidationErrors(errors);
      return;
    }
    try {
      const review = await exportApiAuth.ratingReview(formData);
      if (review.status === "success") {
        setShow(false);
        setReview(true);
        setSuccessReview("Review added Succesfully");
        setFormData({
          user_id: "",
          login_type: "",
          name: "",
          business_id: "",
          title: "",
          rating: "",
          review: "",
          is_active: "",
        });
      }
      if (review.status === "fail") {
        setShow(false);
        setReview(true);
        setErrorReview("Review not added");
      }
    } catch (error) {
      console.log(error);
      setShow(false);
      setReview(true);
      setErrorReview("Multiple reviews are not permitted.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const isWebsite = (url) => {
    if (url === null) {
      return false;
    }

    const pattern =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?([/?#].*)?$/;
    return pattern.test(url);
  };

  const ratingChanged = (newRating) => {
    setFormData((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      rating: "",
    }));
  };


  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center pr-3">
              <span
                onClick={() => handleBackClick()}
                className="cursor-pointer"
              >
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto pr-20">
                <h1 className="f-24 f-w600 mb-0">
                  {singleEvent?.business_name &&
                    sliceCharacters(singleEvent?.business_name, 25)}
                </h1>
              </div>
              <div></div>
            </div>
          </nav>
        </div>
        {singleEvent?.id ? (
          <Stack gap={2}>
            <Image
              src={singleEvent?.business_image}
              style={{
                width: "100%",
                height: "200px",
                marginTop: "50px",
                borderRadius: "8px 8px 0 0",
              }}
            />

            <div className="f-w500 f-16 text-black">
              {singleEvent?.business_name}
            </div>

            <Stack direction="horizontal" gap={1}>
              {liketnDisabled && likeStates[singleEvent.id] ? (
                <>
                  <div className="cursor-pointer">
                    <Icons
                      name="like"
                      className={`${tempLike.id === singleEvent.id
                        ? tempLike.like === 0
                          ? "svg-red"
                          : "svg-white"
                        : tempLike.like === 1
                          ? "svg-white"
                          : "svg-red"
                        }`}
                      onClick={(e) => handleLikeClick(e, singleEvent)}
                    />
                  </div>
                  <div className="mr-3 m-t-2 f-14 lh-18 text-dark">
                    {tempLike.id === singleEvent.id
                      ? tempLike.like !== 0
                        ? singleEvent.like_count - 1 + listingLikeCount
                        : singleEvent.like_count + 1 + listingLikeCount
                      : singleEvent.like_count + listingLikeCount}
                  </div>
                </>
              ) : (
                <>
                  <div className="cursor-pointer">
                    <Icons
                      name="like"
                      className={
                        singleEvent?.like === 1 ? `svg-red` : "svg-white"
                      }
                      onClick={(e) => handleLikeClick(e, singleEvent)}
                    />
                  </div>
                  <div className="mr-3 m-t-2 f-14 lh-18 text-dark">
                    {singleEvent?.like_count + listingLikeCount}
                  </div>
                </>
              )}
              <span
                onClick={async () => handleShareLink(singleEvent)}
                className="cursor-pointer"
              >
                <Icons name="share" className="svg-white ms-2 mr-3" />
              </span>

              <div className="cursor-pointer">
                <Icons
                  name="show"
                  className="svg-white svg-size-20 ms-2 m-t-1"

                />
              </div>
              <div className="mr-3 text-like f-14  m-t-2  lh-18 f-w500">
                {formatLikeCount(singleEvent?.views_count + listingViewCount)}
              </div>
              {displayRating(singleBusinessData?.avgRating) !== "0" && (
                <div className="ml-auto">
                  {displayRating(singleBusinessData?.avgRating)}
                </div>
              )}
            </Stack>

            <Stack direction="horizontal" gap={1}>
              {singleEvent?.contact_details !== null && (
                <button
                  className="bg-brand text-dark btn-listing-1 text-center f-10"
                  onClick={() =>
                    handleButtonClick(
                      "call",
                      `tel:${singleEvent?.contact_details}`, singleEvent?.id
                    )
                  }
                >
                  <Icons name="calling" className="svg-size-11 svg-white " />
                  <div className="btn-web"> Call</div>
                </button>
              )}
              {singleEvent?.website !== (null || "") &&
                isWebsite(singleEvent?.website) && (
                  <button
                    className="btn-listing-2 bg-brand text-dark  text-center f-10"
                    onClick={() =>
                      handleButtonClick("website", singleEvent?.website, singleEvent?.id)
                    }
                  >
                    <Icons name="website" className="svg-size-11 svg-white  " />
                    <div className="btn-web">Website </div>
                  </button>
                )}
              <div className="ms-auto">
                {singleEvent?.facebook !== null &&
                  singleEvent?.facebook !== "" &&
                  isWebsite(singleEvent?.facebook) && (
                    <Icons
                      name="facebook-link"
                      className="svg-size-20 m-1 svg-white cursor-pointer"
                      onClick={() =>
                        handleButtonClick("facebook", singleEvent?.facebook, singleEvent?.id)
                      }
                    />
                  )}
                {singleEvent?.instagram !== null &&
                  singleEvent?.instagram !== "" &&
                  isWebsite(singleEvent?.instagram) && (
                    <Icons
                      name="insta-link"
                      className="svg-size-20 m-1 svg-white cursor-pointer"
                      onClick={() =>
                        handleButtonClick("instagram", singleEvent?.instagram, singleEvent?.id)
                      }
                    />
                  )}
                {singleEvent?.whatsapp !== null &&
                  singleEvent?.whatsapp !== "" && (
                    <Icons
                      name="whatsapp-link"
                      className="svg-size-20 m-1 svg-white cursor-pointer"
                      onClick={() =>
                        handleButtonClick("whatsapp", singleEvent?.whatsapp, singleEvent?.id)
                      }
                    ></Icons>
                  )}
                {singleEvent?.twitter !== null &&
                  singleEvent?.twitter !== "" &&
                  isWebsite(singleEvent?.twitter) && (
                    <Icons
                      name="twitter-link"
                      className="svg-size-20 m-1 svg-white cursor-pointer"
                      onClick={() =>
                        handleButtonClick("twitter", singleEvent?.twitter, singleEvent?.id)
                      }
                    />
                  )}
                {singleEvent?.youtube !== "" &&
                  isWebsite(singleEvent?.youtube) && (
                    <Icons
                      name="youtube-link"
                      className="svg-size-20 m-1 svg-white cursor-pointer"
                      onClick={() =>
                        handleButtonClick("youtube", singleEvent?.youtube, singleEvent?.id)
                      }
                    />
                  )}
              </div>
            </Stack>

            {/* ..................................................Address tab.......................................................................*/}

            <div className="btn-collapsable"> Address </div>

            <div id="Address-list" className="text-white d-flex ">
              {singleBusinessData?.business_name !== null && (
                <>
                  <a
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      const locationUrl = singleBusinessData?.location_url;
                      if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
                        window.webkit.messageHandlers.reactNative.postMessage({
                          action: 'open_url',
                          url: locationUrl
                        });
                      } else {
                        window.open(locationUrl, "_blank");
                      }
                    }}
                    href={singleBusinessData?.location_url} // Provide href for default behavior if JavaScript is disabled
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    className="d-flex"
                    rel="noopener noreferrer"
                  >
                    <div style={{ width: "26px" }}>
                      <Icons name="listing-location" className="" />
                    </div>
                    <div
                      className={`pl-8 f-12 f-w400 lh-14 d-flex alignCenter text-dark ${singleBusinessData?.address?.length < 75 ? "" : ""}`}
                    >
                      {singleBusinessData?.address}
                    </div>
                  </a>
                </>
              )}

            </div>

            {/* ......................................................ORDER AND BOOKING BUTTON.......................................................................*/}

            {singleEvent?.order_url && (
              <button
                className="bg-brand text-dark btn-fluid text-center borderless justify-center h-26 f-10"
                onClick={() => {
                  handleOrderButtonClick(singleEvent?.order_url)
                }}
              >
                <Icons name="order-list" className="svg-size-16 svg-white" />
                <span className="ml-1">Order</span>
              </button>
            )}

            {singleEvent?.booking_url && (
              <button
                className="bg-brand text-dark btn-fluid text-center borderless justify-center h-26 f-10"
                onClick={() => { window.location.href = singleEvent?.booking_url; }}
              >
                <Icons name="booking" className="svg-size-16 svg-white " />
                <span className="ml-1">Booking</span>
              </button>
            )}

            {/*.................................................Offers placeholder...........................................................*/}
            {singleEvent?.coupon_description && singleEvent?.coupon_code && (
              <>
                <div className="f-14 text-red br-btm">Offer</div>
                <div className="f-w500 f-12 text-white ">
                  {singleEvent?.coupon_description} : &nbsp;
                  <span
                    className="f-12 f-w700 text-red"
                    style={{ display: "inline" }}
                  >
                    {singleEvent?.coupon_code}
                  </span>
                </div>
              </>
            )}

            {/*................................................... About tab.......................................................................*/}

            <button
              className="btn-collapsable pt-0"
              onClick={() => setOpen(!open)}
              aria-controls="About-list"
              aria-expanded={open}
            >
              About
              {open ? (
                <Icons
                  name="arrow-up"
                  className="svg-size-16 ms-auto svg-white"
                />
              ) : (
                <Icons
                  name="arrow-down"
                  className="svg-size-16 ms-auto svg-white"
                />
              )}
            </button>
            <Collapse in={open}>
              <div id="About-list " className="about text-white">
                <div>
                  {htmlParser.parse(singleEvent?.description)}
                  {singleEvent?.operating_hours !== null && (
                    <>
                      <div className="hrs d-flex">Operating Hours </div>
                      <Container>{DisplayOperatingData()}</Container>
                    </>
                  )}
                </div>
              </div>
            </Collapse>

            {/*.....................................................Branches.....................................................................*/}

            {listItems1?.length > 0 && (
              <>
                <button
                  className="btn-collapsable"
                  onClick={() => setOpen1(!open1)}
                  aria-controls="Branches-list"
                  aria-expanded={open1}
                >
                  Branches
                  {open1 ? (
                    <Icons
                      name="arrow-up"
                      className="svg-size-16 ms-auto svg-white"
                    />
                  ) : (
                    <Icons
                      name="arrow-down"
                      className="svg-size-16 ms-auto svg-white"
                    />
                  )}
                </button>
                <Collapse in={open1}>
                  <div id="Branches-list" className="">
                    {listItems !== null && listItems}
                  </div>
                </Collapse>
              </>
            )}

            {/*................................................Gallery................................................... */}
            {((singleBusinessData?.videos &&
              singleBusinessData.videos.some((video) => video.trim() !== "")) ||
              imageUrls ||
              singleBusinessData?.menus_image) && (
                <>
                  <button
                    className="btn-collapsable"
                    onClick={() => setOpen3(!open3)}
                    aria-controls="gallery-list"
                    aria-expanded={open3}
                  >
                    Gallery
                    {open3 ? (
                      <Icons
                        name="arrow-up"
                        className="svg-size-16 ms-auto svg-white"
                      />
                    ) : (
                      <Icons
                        name="arrow-down"
                        className="svg-size-16 ms-auto svg-white"
                      />
                    )}
                  </button>
                  <Collapse in={open3}>
                    <div>
                      <Tabs
                        defaultActiveKey={imageUrls ? `Images` : `Videos`}
                        id="uncontrolled-tab-example"
                        className="mb-3 borderless "
                      >
                        {imageUrls && (
                          <Tab eventKey="Images" className=" f-14" title="Images">
                            <div className="sub-categ-container">
                              {Array.isArray(imageUrls) &&
                                imageUrls.map((imageUrl, index) => (
                                  <img
                                    key={index}
                                    className="image-gall img-fluid cursor-pointer"
                                    alt=""
                                    onClick={() => {
                                      setModalIndex(index);
                                      handleImageClick(imageUrl);
                                    }}
                                    src={imageUrl}
                                  />
                                ))}
                            </div>

                            <Modal
                              show={showModal}
                              onHide={() => setShowModal(false)}
                              dialogClassName="modal-90w"
                              aria-labelledby="example-custom-modal-styling-title"
                              centered
                            >
                              <Carousel
                                activeIndex={modalIndex}
                                onSelect={handleSelect}
                                fade
                                interval={null}
                              >
                                {Array.isArray(imageUrls) &&
                                  imageUrls.map((imageUrl, index) => (
                                    <Carousel.Item>
                                      <Image
                                        key={index}
                                        src={imageUrl}
                                        fluid
                                        alt="Selected Image"
                                        style={{
                                          display: "flex",
                                          margin: "auto",
                                          width: "100vw",
                                        }}
                                      />
                                    </Carousel.Item>
                                  ))}
                              </Carousel>
                            </Modal>
                          </Tab>
                        )}

                        {/*.........................................................VIDEO URLS...................................................... */}
                        {videoUrls && (
                          <Tab eventKey="Videos" title="Videos">
                            {Array.isArray(videoUrls) &&
                              videoUrls.map(
                                (url, index) =>
                                  url !== "" && (
                                    <div
                                      className="embed-responsive embed-responsive-16by9 width-50"
                                      key={index}
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleVideoClick(url);
                                        }}
                                        style={{
                                          position: "absolute",
                                          width: "50px",
                                          height: "50px",
                                          zIndex: 1,
                                          bottom: "0px",
                                          right: "0px",
                                        }}
                                      ></div>
                                      <YoutubeIframe videoSrc={url} />
                                    </div>
                                  )
                              )}

                            <Modal
                              show={showVideoModal}
                              onHide={() => {
                                handleAddReviewButton();
                                setShowVideoModal(false)
                              }}
                              dialogClassName="modal-90w"
                              aria-labelledby="example-custom-modal-styling-title specific-modal"
                              style={{
                                "--bs-modal-margin": 0,
                                "--bs-modal-width": "100vw",
                                overflowY: "hidden",
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                              }}
                            >
                              <div
                                className="embed-responsive embed-responsive-16by9 video-modal "
                                style={{ position: "relative" }}
                              >
                                <div
                                  onClick={() => {
                                    handleRemoveTokenButton();
                                    setShowVideoModal(false)
                                  }}
                                  style={{
                                    position: "absolute",
                                    width: "50px",
                                    height: "50px",
                                    zIndex: 1,
                                    bottom: "0px",
                                    right: "0px",
                                  }}
                                ></div>
                                <iframe
                                  id="yourIframeId"
                                  className="embed-responsive-item"
                                  title={`Video `}
                                  src={`${getEmbeddedUrl(
                                    selectedVideo
                                  )}?autoplay=1&rel=0`}
                                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;web-share "
                                  autoFocus
                                ></iframe>
                              </div>
                            </Modal>
                          </Tab>
                        )}

                        {/* ........................................................Menu URLS .......................................................*/}
                        {menuUrls && (
                          <Tab eventKey="Menu" title="Menu">
                            <div className="sub-categ-container">
                              {Array.isArray(menuUrls) &&
                                menuUrls.map((menuUrls, index) => (
                                  <img
                                    key={index}
                                    className="image-gall img-fluid"
                                    alt=""
                                    onClick={() => {
                                      setModalIndex(index);
                                      handleMenuImageClick(menuUrls);
                                    }}
                                    src={menuUrls}
                                  />
                                ))}
                            </div>

                            <Modal
                              show={showMenuModal}
                              onHide={() => setShowMenuModal(false)}
                              dialogClassName="modal-90w"
                              aria-labelledby="example-custom-modal-styling-title"
                              centered
                            >
                              <Carousel
                                activeIndex={modalIndex}
                                onSelect={handleSelect}
                                slide={false}
                                interval={null}
                              >
                                {Array.isArray(menuUrls) &&
                                  menuUrls.map((menuUrls, index) => (
                                    <Carousel.Item>
                                      <Image
                                        key={index}
                                        src={menuUrls}
                                        fluid
                                        alt="Selected Image"
                                        style={{
                                          display: "flex",
                                          margin: "auto",
                                          width: "100vw",
                                        }}
                                      />
                                    </Carousel.Item>
                                  ))}
                              </Carousel>
                            </Modal>
                          </Tab>
                        )}
                      </Tabs>
                    </div>
                  </Collapse>
                </>
              )}

            {/* ..................................................COmments/Reviews.......................................................................*/}

            <button
              className="btn-collapsable"
              onClick={() => setOpen2(!open2)}
              aria-controls="Comment-list"
              aria-expanded={open2}
            >
              Customer Reviews
              {open2 ? (
                <Icons
                  name="arrow-up"
                  className="svg-size-16 ms-auto svg-white"
                />
              ) : (
                <Icons
                  name="arrow-down"
                  className="svg-size-16 ms-auto svg-white"
                />
              )}
            </button>
            <Collapse in={open2}>
              <div>
                {Array.isArray(commentView) &&
                  commentView.slice(0, visibleComments).map((review, index) => (
                    <Card key={index} className="review">
                      <div className="d-flex c-head">
                        {titleCase(review.name) || "Anonymous"}
                        {displayRating(review.rating)}
                        <div className='d-flex alignCenter pl-2' onClick={() => handleReport(review)} ><Icons name="report" className=" svg-size-16 svg-white " /></div>
                      </div>

                      <div className="c-title">{review.title}</div>
                      <div className="c-body">{review.review}</div>
                    </Card>
                  ))}
                <div className="d-flex gap-2">
                  {Array.isArray(commentView) &&
                    commentView?.length > visibleComments && (
                      <Button
                        variant="secondary"
                        className="container-fluid"
                        onClick={handleLoadMore}
                      >
                        All Reviews
                      </Button>
                    )}

                  <Toast
                    onClose={() => {
                      setReview(false);
                      setSuccessReview("");
                      setErrorReview("");
                    }}
                    show={review}
                    delay={3000}
                    autohide
                    className="bottom-toast"
                  >
                    {successReview && (
                      <Toast.Body className="bg-green text-white text-centre">
                        {successReview}
                      </Toast.Body>
                    )}
                    {errorReview && (
                      <Toast.Body className="bg-danger text-white text-centre">
                        {errorReview}
                      </Toast.Body>
                    )}
                  </Toast>
                  <Button
                    className="container-fluid bg-brand"
                    onClick={handleAddReviewButton}
                  >
                    Add a Review
                  </Button>

                  {/* ..................................................Add a Review tab.......................................................................*/}

                  <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    className=" "
                  >
                    <Modal.Body className="code-black ">
                      <div className="d-flex justify-content-center code-black f-16 f-w500">
                        Share your experience
                      </div>

                      <div className="d-flex justify-content-center code-black">
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={24}
                          activeColor="#FFB800"
                          color="#ffffff"
                          classNames={""}
                          value={formData.rating}
                        />
                      </div>
                      {validationErrors.rating && (
                        <span
                          className="text-danger text-left d-block mb-2 mt-1"
                          style={{ fontSize: "x-small", marginTop: "-8px" }}
                        >
                          {validationErrors.rating}
                        </span>
                      )}
                      <div className="mt-3">
                        <InputField
                          name="title"
                          label={<span className="text-disabled">Heading</span>}
                          type="text"
                          value={formData.title}
                          placeholder="Type your heading"
                          onChange={handleInputChange}
                          error={validationErrors.title}
                        />

                        <InputField
                          name="review"
                          label={<span className="text-disabled">Review</span>}
                          type="text"
                          value={formData.review}
                          placeholder="Type your review"
                          onChange={handleInputChange}
                          error={validationErrors.review}
                        />
                      </div>
                      <Button
                        variant=""
                        className="  container-fluid mt-3 h-56 bg-brand text-dark"
                        onClick={() => submitReview()}
                        label="Submit"
                      >
                        Submit
                      </Button>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </Collapse>
          </Stack>
        ) : (
          <SingleSkeleton />
        )}
        {showLoginModal && (
          <GoToLogin
            show={showLoginModal}
            onHide={() => setLoginModalShow(false)}
            location={location}
            loginResion={sessionExpire}
          />
        )}
      </div>
      {errorToast && (
        <Toast
          onClose={() => setErrorToast(false)}
          show={true}
          delay={3000}
          className="toast-dialog"
          autohide={true}
          style={{}}
        >
          <Toast.Body style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}>
            {"Somthing went wrong"}
          </Toast.Body>
        </Toast>
      )}
      {successToast && (
        <Toast
          onClose={() => setSuccessToast(null)}
          show={true}
          delay={3000}
          className="report-toaster"
          style={{ position: "fixed", bottom: "10px" }}
          autohide
        >
          <Toast.Body
            style={{ backgroundColor: "#C5FEB5", color: "#196004" }}
          >
            {successToast.message}
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};
