// InputField.jsx
import { TextField } from "@mui/material";
import "./InputField.css";
import React from "react";
import { Icons } from "../Icons/Icon";
import styled from "@emotion/styled";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "& input": {
      color: "white",
      "&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 1000px black inset !important",
        "-webkit-text-fill-color": "white !important",
      },
    },
    "& input::placeholder": {
      color: "white",
    },
    "& fieldset.MuiOutlinedInput-notchedOutlin": {
      borderColor: "var(--white) !important",
    },
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--white) !important",
  },
});

const InputField = ({
  label,
  type,
  name,
  autoComplete,
  icon = "",
  placeholder,
  error,
  onBlur,
  readOnly = false,
  disabled = false,
  className,
  value,
  onChange,
  onMobileNumberChange,
  style,
}) => {
  return (
    <div
      className="position-relative"
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "1000",
        lineHeight: "34px",
        letterSpacing: "0px",
        textAlign: "center",
        ...style,
      }}
    >
      <StyledTextField
        className={`text-dark ${className}`}
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        fullWidth
        margin="normal"
        InputProps={{
          readOnly: readOnly,
          disabled: disabled,
        }}
        focused
      />
      {error && (
        <span
          className="text-danger text-left d-block mb-2"
          style={{ fontSize: "xx-small", marginTop: "-8px" }}
        >
          {error}
        </span>
      )}
      {icon && (
        <span className="position-absolute translate-middle-y form-control-icon">
          <Icons name={icon} className="svg-size-24 svg-red " />
        </span>
      )}
    </div>
  );
};

export default InputField;
