import React from 'react';

export const YoutubeLink = ({
    className = "",
    width = "20px",
    height = "20px",
    viewBox = "0 0 20 20",
    fill = "",
    onClick
}) => {


    return (

        <svg viewBox={viewBox} width={width} onClick={onClick} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.52771 3.05702C2.147 3.05702 1.02771 4.17631 1.02771 5.55702V16.2814C1.02771 17.6621 2.147 18.7814 3.52771 18.7814H16.5173C17.898 18.7814 19.0173 17.6621 19.0173 16.2814V5.55702C19.0173 4.17631 17.898 3.05702 16.5173 3.05702H3.52771ZM2.02771 5.55702C2.02771 4.7286 2.69928 4.05702 3.52771 4.05702H16.5173C17.3458 4.05702 18.0173 4.7286 18.0173 5.55702V16.2814C18.0173 17.1098 17.3458 17.7814 16.5173 17.7814H3.52771C2.69928 17.7814 2.02771 17.1098 2.02771 16.2814V5.55702ZM7.85401 7.78595C7.28919 7.50354 6.62463 7.91426 6.62463 8.54575V13.2926C6.62463 13.9241 7.2892 14.3348 7.85402 14.0524L12.9455 11.679C13.5716 11.3659 13.5716 10.4724 12.9455 10.1594L7.85401 7.78595ZM11.9324 10.9192L7.75728 12.8345V9.00392L11.9324 10.9192Z" fill="white"/>
</svg>






    );
};
