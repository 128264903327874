import { Badge } from "react-bootstrap";
import { parseISO, format } from 'date-fns';
import { Icons } from "../views/components/Icons/Icon";
import { allowedFileTypes, getPlatform, maxSizeInBytes, shareBlockTime } from "./constGlobleData";
import { exportApiAuth } from "../services/indoreTalkAuth.api";
import { exportApi } from "../services/indoreTalk.api";
import { setSignIn } from "../redux/slices/authSlice";
import { useSelector } from "react-redux";
export const formatDate = (dateString, attribute = 'fulldate', commentTime = false) => {
  const options = {
    shortdate: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    fulldate: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    },
    onlytime: {
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
    },
    onlytimeWithoutSecound: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    onlydateandmonth: {
      month: 'short',
      day: 'numeric',
    },
    onlydateandmonthyear: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
    // Add more attributes as needed
  };

  const selectedOptions = options[attribute] || options['fulldate'];
   

  const formattedDateTime = new Date(dateString).toLocaleString(undefined, selectedOptions);
  if (commentTime) {
    const now = new Date();

    const diffInMs = now - new Date(dateString);
    const diffInHours = diffInMs / (1000 * 60 * 60);
    if (diffInHours < 1) {
      if (Math.floor(diffInHours * 60) > 0) {
        return `${Math.floor(diffInHours * 60)} minutes ago`;
      } else {
        return `1 minute ago`;
      }
    } else if (diffInHours < 24) {
      if (Math.floor(diffInHours) > 1) {
        return `${Math.floor(diffInHours)} hours ago`;
      } else {
        return `1 hour ago`;
      }

    } else if ((Math.floor(diffInHours / 24) < 30)) {
      if (((Math.floor(diffInHours / 24) > 1))) {
        return `${Math.floor(diffInHours / 24)} days ago`;
      } else {
        return `1 day ago`;
      }
    } else if ((Math.floor(diffInHours / (24 * 30)) < 12)) {
      if (((Math.floor(diffInHours / (24 * 30 * 12)) > 1))) {
        return `${Math.floor(diffInHours / (24 * 30))} month ago`;
      } else {
        return `1 month ago`;
      }
    } else if ((Math.floor(diffInHours / (24 * 30)) > 12)) {
      if (Math.floor(diffInHours / (24 * 30 * 12)) > 1) {
        return `${Math.floor(diffInHours / (24 * 30 * 12))} year ago`;
      } else {
        return `1 year ago`;
      }

    } else {
      return formattedDateTime
    }
  }
  return formattedDateTime;
};



export const timeFormatting = (date) => {
  if(!date)
    return;
  // Parse the date string using date-fns
  const eventDate = parseISO(date);

  // Format the time in the desired format (hours and minutes in AM/PM format)
  const formattedTime = format(eventDate, 'hh:mm a');

  return formattedTime;
};
export const dateFormatting = (date) => {
  if(!date)
    return;
  // Parse the date string using date-fns
  const eventDate = parseISO(date);

  // Format the date in the desired format using date-fns
  const formattedDate = format(eventDate, 'd MMM yyyy');

  return formattedDate;
};
export const dateFormate = (date) => { 
  if(!date )
    return 
  // Parse the date string using date-fns
  const eventDate = parseISO(date);

  // Format the date in the desired format using date-fns
  const formattedDate = format(eventDate, 'd MMM');

  return formattedDate;
};
export const dateFormat = (date) => {
  if(!date)
    return;
  // Parse the date string using date-fns
  const eventDate = parseISO(date);

  // Format the date in the desired format using date-fns
  const formattedDate = format(eventDate, 'd MMM');

  return formattedDate;
};

// Function to format time in the desired format (e.g., "12:00 PM")
function getFormattedTime(date) {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleTimeString('en-US', options);
}

// titleCase.js
export const titleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, (char) => {
    return char.toUpperCase();
  });
};

// titleCase.js
export const titleCase2 = (name) => {
  if (!name) return '';

  return name
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


export const handleViewMapClick = (mapUrl) => {
  // Redirect the user to Google Maps

  // Regular expression for a more flexible URL validation
  const urlRegex = /^(https?:\/\/)?(www\.)?google\.com\/maps(\/[^\s]+)?$/;

  if (mapUrl && urlRegex.test(mapUrl) && mapUrl.includes('map')) {
    window.location.href = mapUrl;
  } else {
    window.location.href = 'https://www.google.com/maps';
  }
};

export const likePayloads = (data, userId) => {
  const updateData = {
    post_id: data.id,
    user_id: userId?.user_id,
    maincategory_id: data.maincategory_id,
    activity_type: 'like',
    login_type: userId?.login_type
  }
  return updateData
}

export const viewPayloads = (data, userId) => {
  const updateData = {
    post_id: data?.id,
    user_id: userId?.user_id,
    maincategory_id: data?.maincategory_id,
    activity_type: 'view',
    login_type: userId?.login_type
  }
  return updateData
}
//k and millions feature 
export const formatLikeCount = (count) => {
  if (count > 1000000) {
    return parseFloat((count / 1000000).toFixed(1)) + "M";
  } else if (count > 999) {
    return parseFloat((count / 1000).toFixed(1)) + "K";
  } else {
    return count?.toString();
  }
};

export const useCalculateSubtotal = (ticketCount) => {
  const total = ticketCount.reduce((acc, item) => acc + item.totalAmount, 0);
  return total
};
export const sum = (price, type = '') => {
  if (price === 0) { return 0 }
  if (type === 'amount') {
    const total = price.reduce((acc, item) => acc + item.totalAmount, 0);
    return total
  }
  if (type === 'ticket') {
    const total = price.reduce((acc, item) => acc + item.buySeats, 0);
    return total
  }
};

// Function to calculate the time difference between two dates
export function calculateTimeDifference(startDateTime, endDateTime) {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  const timeDifference = endDate - startDate;

  // Convert the time difference to hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Create the formatted string based on the values
  let formattedString = `${hours}h`;

  if (minutes > 0 || seconds > 0) {
    formattedString += ` ${minutes}m`;
  }

  if (seconds > 0) {
    formattedString += ` ${seconds}s`;
  }

  return formattedString;
}

// fileUtils.js

export const isValidFile = (file) => {
  const fileType = file.type;
  const fileSize = file.size;
  const size = (maxSizeInBytes / (1024 * 1024));

  if (!allowedFileTypes.includes(fileType)) {
    return {
      isValid: false,
      error: `Invalid file type ${fileType}. Please select a valid file type.`,
    };
  }

  if (fileSize > maxSizeInBytes) {
    return {
      isValid: false,
      error: `File size exceeds the limit. Please select a smaller file ${size} mb.`,
    };
  }

  return {
    isValid: true,
    error: null,
  };
};

export const handleButtonClick = async (action, data, listingId) => {
  try {

    const payload = {
      "business_list_id": listingId,
      "counter_type": action
    };

    const result = await exportApi.businessCounter(payload);

    switch (action) {
      case 'call':
        // Redirect to the dialing number pad
        window.location.href = `${data}`;
        break;
      case 'website':
        // Redirect to the specified website
        handleOrderButtonClick(data)
        break;
      case 'facebook':
        // Redirect to the specified Facebook page
        handleOrderButtonClick(data)
        break;
      case 'whatsapp':
        window.location.href = `https://wa.me/${data}`;
        break;
      case 'instagram':
        handleOrderButtonClick(data)
        break;
      case 'twitter':
        handleOrderButtonClick(data)
        break;
      case 'youtube':
        handleOrderButtonClick(data)
        break;
      default:
        break;
    }
  }
  catch (error) {
    console.error("Error occurred during redicting website:", error);
  }
};


// Function to handle order button click action
export const handleOrderButtonClick = (orderUrl) => {
  // Implement your logic here for handling the order button action
  
  // For example, sending a message to React Native
  if (getPlatform() === "Mac OS" || getPlatform() === "iOS")
    {
  window.webkit.messageHandlers.reactNative.postMessage({
    action: 'open_url',
    url: orderUrl
  });
} else
{
  window.location.href = orderUrl;
}
};

export function displayRating(avgRating) {
  const ratingValue = parseFloat(avgRating);

  if (isNaN(ratingValue) || ratingValue < 0 || ratingValue > 5) {
    return "0";
  }

  const roundedRating = Math.round(ratingValue);

  return (
    <Badge className="ms-auto br-4 text-center bg-badge d-flex">
      <div className="f-12" style={{ paddingTop: "2px", paddingRight: "2px" }}>{roundedRating}</div><Icons name="star" className="svg-size-14" />
    </Badge>
  );
}

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

export const exportTextAsUppercase = (text) => {
  if (typeof text !== 'string') {
    throw new Error('Input must be a string');
  }

  return text.toUpperCase();
}

export const sliceCharacters = (text, number) => {
  if (text && text.length > number) {
    return text.slice(0, number) + '...';
  } else {
    return text; // Return the original text if it's shorter than or equal to the specified number
  }
};

export const extractVideoId = (youtubeUrl) => {

  if (typeof youtubeUrl !== 'string') {
    console.error("Invalid YouTube URL: youtubeUrl is not a string");
    return null;
  }
  // eslint-disable-next-line
  const match = youtubeUrl.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^"&?\/\s]{11})/);
  // eslint-disable-next-line
  const match_1 = youtubeUrl.match(/\/shorts\/([^"&?\/\s]+)/)

  if (Array.isArray(match) || Array.isArray(match_1)) {
    return match ? match[1] : match_1[1];
  }
}

export const getEmbeddedUrl = (originalUrl) => {

  if (typeof originalUrl !== 'string') {
    return null;
  }
  // Extract the video ID from the original URL
  const videoId = extractVideoId(originalUrl);

  // Check if a valid video ID was extracted
  if (videoId) {
    // Construct the embedded URL
    const embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embeddedUrl;
  } else {
    // Handle the case where the URL is invalid
    console.warn("Invalid YouTube URL");
    return null;
  }
}

export const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      reject(new Error("Failed to load script"));
    };
    document.body.appendChild(script);
  });
}

export const PollPercentage = (totalVotes, count) => {
  if (totalVotes === 0 || count === 0) {
    return 0; // Handle division by zero
  }

  const percentage = (count / totalVotes) * 100;
  if (String(percentage).includes('.')) {
    return percentage.toFixed(2);
  } else {
    return percentage
  }
}

// subscibe data
export const subscibeFilterData = (data, filterKey) => {
  if (data && filterKey) {
    const data1 = data.filter((item) => item.id === filterKey)
    //  const data2 =  Object.assign({}, data1)
    return data1[0]
  }
}

// Import necessary dependencies or functions here, if needed

export const displayRazorpay = async (paymentName, payData, setLoader, navigate, setShow, setStatus, user_id, userData, userDetails, result, access_tokens, display_name) => {
  setLoader(true);
 
  try {
    // eslint-disable-next-line
    let { amount, final_amount, id: order_id, currency, name, email, mobile, subscription_id } = result;
    const options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY,
      amount: (amount * 100).toString(),
      currency,
      name:display_name ,
      email: email,
      phone: mobile,
      id: user_id,
      description: "Ticket Transaction",
      orderId: result?.order?.id,
      login_type: payData?.login_type,
      booking_id: result?.id,
      access_tokens: access_tokens
      // prefill: {
      //   name: userData.name,
      //   email: result?.email,
      //   contact: result?.mobile,
      // },
      // notes: {
      //   address: "123 Main Street, Cityville",
      //   order_number: result?.data?.id,
      // },
      // theme: {
      //   color: "#61dafb",
      // },
    };
    // const data = {
    //   id: "16",
    //   name: "Test name",
    //   key: "rzp_test_ZmbCKND78rC5r3",
    //   amount: "1000",
    //   currency: "INR",
    //   orderId: "order_NifC4G5jgC9vPd",
    //   email: "test@gmail.com",
    //   phone: "9876543210"
    // };
    
    const handleShareLink = async (options) => {
      // console.log('options', options)
      // const shareParams = {
      //   title: item?.heading,
      //   url: window?.location.href,
      // };
      // const data = {
      //   id: "16",
      //   name: "Test name",
      //   key: "rzp_test_ZmbCKND78rC5r3",
      //   amount: "1000",
      //   currency: "INR",
      //   orderId: "order_NifC4G5jgC9vPd",
      //   email: "test@gmail.com",
      //   phone: "9876543210"
      // };
      if (getPlatform() === "Android") {
        //   if (navigator.share) {
        //     await navigator.share(shareParams);
        //   } else {
        // // eslint-disable-next-line no-undef
        // Android.callShareIntent(item.heading);
        // const { title, url } = shareParams;
        // Call Android.callShareIntent with individual strings
        // alert(JSON.stringify(options))
        // eslint-disable-next-line no-undef
        await Android.payIntent(JSON.stringify(options));
        //   }
      } else {
        if (navigator.share) {
          // await navigator.share(shareParams);
        } else {
          // shareOnMobile(shareParams);
        }
      }
    };
    // const android = window.Android;
    // const handleShareLink = async (item) => {
    //   console.log('Share')
    //   // const shareParams = {
    //   //   title: item.heading,
    //   //   url: window.location.href,
    //   // };
    //   const data = {
    //     id: "16",
    //     name: "Test name",
    //     key: "rzp_test_ZmbCKND78rC5r3",
    //     amount: "1000",
    //     currency: "INR",
    //     orderId: "order_NifC4G5jgC9vPd",
    //     email: "test@gmail.com",
    //     phone: "9876543210"
    //   };
    //   console.log(JSON.stringify(data))
    //   if (getPlatform() === "Android") {
    //     // if (navigator.share) {
    //     //   await navigator.share(shareParams);
    //     // } else {
    //       // // eslint-disable-next-line no-undef
    //       // Android.callShareIntent(item.heading);
    //       // const { title, url } = shareParams;
    //       // Call Android.callShareIntent with individual strings
    //       // eslint-disable-next-line no-undef
    //       Android.payIntent(JSON.stringify(data));
    //     // }
    //   }
    //   //  else {
    //   //   if (navigator.share) {
    //   //     await navigator.share(shareParams);
    //   //   } else {
    //   //     // shareOnMobile(shareParams);
    //   //   }
    //   // }
    // };
    // if (getPlatform() === "Android") {
    //   if (navigator.share) {
    //     await navigator.share(options);
    //     console.log('navigator.share is available')
    //   } else {
    //     // // eslint-disable-next-line no-undef
    //     // Android.callShareIntent(item.heading);
    //     // Call Android.callShareIntent with individual strings
    //     // eslint-disable-next-line no-undef
    //     Android.payIntent(JSON.stringify(options))
    //     // handlePayIntentResponse(Android.payIntent(JSON.stringify(options)));
    //   }
    // } else {
    //   if (navigator.share) {
    //     console.log('navigator.share is available2')
    //     setHandlerBasedOnPaymentName(paymentName, options, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate);
    //   } else {
    //     // shareOnMobile(shareParams);
    //     // const paymentObject = new window.Razorpay(options);
    //     // paymentObject.open();
    //     console.error('Window face is available');
    //     setHandlerBasedOnPaymentName(paymentName, options, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate);
    //     setLoader(false);
    //     console.log('shareOnMobile is available2')
    //   }
    // }
    // function handlePayIntentResponse(response) {
    //   // Process the payment response here
    //   console.log("Payment response:", response);
    // }
    // if (getPlatform() === 'Android' && android) {
    //   try {
    //     const response = await new Promise((resolve, reject) => {
    //       android.androidRazorpayPayment(options, (success, response) => {
    //         if (success) {
    //           resolve(response);
    //         } else {
    //           reject(response);
    //         }
    //       });
    //     });  
    //     handleSuccessResponse(response, paymentName, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate);
    //   } catch (error) {
    //     console.error('Error calling androidRazorpayPayment:', error);
    //     handleError(error, setLoader);
    //   }
    // } else {
    //   console.log('test window')
    //   // Check the paymentName value and set the handler accordingly
    //   // if (paymentName === 'listingBusiness') {
    //   //   options.handler = async function (response) {

    //   //   };
    //   // } else {
    //   //   console.log('demo book ticket request')
    //   //   // const razorpayScriptLoaded = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    //   //   // if (!razorpayScriptLoaded) {
    //   //   //   throw new Error("Razorpay SDK failed to load. Please check your internet connection and try again.");
    //   //   // }
    //   //   options.handler = async function (response) {

    //   //   };
    //   // }
    //   console.log('options', options);
    //   // const paymentObject = new window.Razorpay(options);
    //   // Trigger the payment process for web
    //   // paymentObject.open();
    //   setHandlerBasedOnPaymentName(paymentName, options, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate);
    //   console.error('Window face is available');
    //   setLoader(false);
    // }

    handleShareLink(options)
  } catch (error) {
    handleError(error, setLoader);
  }
};
// eslint-disable-next-line
const setHandlerBasedOnPaymentName = (paymentName, options, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate) => {
  // Check the paymentName value and set the handler accordingly
  if (paymentName === 'listingBusiness') {
    options.handler = async function (response) {
  
      try {
        if (response.razorpay_payment_id) {
          const data = {
            'user_id': user_id,
            'login_type': userData.login_type,
            'sub_scription_order_id': result?.order?.order_id,
            booking_id: result?.data?.id,
            transaction_status: 'success',
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response?.razorpay_order_id,
            razorpaySignature: response?.razorpay_signature,
          };
          const res = await exportApiAuth.listingPaymentSucces(data)
          if (res.status === 'success') {
            if (payData?.code) {
              const res1 = await exportApiAuth.applyCoupon({
                'code': res.data?.code,
                'amount': result?.actual_amount,
                'user_id': payData?.user_id,
                'order_id': result?.order?.id
              })
              
              setLoader(false);
            }
            setLoader(false);
            setShow(true);
            setStatus(res);
            // const newUrl = 'buy';
            // navigate(newUrl, { state: { paymentResponse: res, modal: true } });
          } else {
            console.error('Something went wrong');
          }
        } else {
          console.error('Payment failed');
        }
      } catch (error) {
        console.error("Error during payment processing:", error.message);
        // Handle error during payment processing
      }
      try {
        await handleListingBusinessResponse(response, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate);
      } catch (error) {
        console.error("Error during listingBusiness response processing:", error.message);
      }
    };
  } else {
    options.handler = async function (response) {
      
      try {
        if (response.razorpay_payment_id) {
          const data = {
            user_id: payData?.user_id,
            login_type: payData?.login_type,
            booking_id: result?.id,
            status: 'success',
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response?.razorpay_order_id,
            razorpaySignature: response?.razorpay_signature,
          };
          const res = await exportApiAuth.paymentSuccess(data)
          if (res.status === 'success') {
            setLoader(false);
            setShow(true);
            setStatus(res);
            const newUrl = 'buy';
            navigate(newUrl, { state: { paymentResponse: res, modal: true } });
          } else {
            console.error('Something went wrong');
            setLoader(false);
          }
        } else {
          console.error('Payment failed');
        }
      } catch (error) {
        console.error("Error during payment processing:", error.message);
        // Handle error during payment processing
      }
      try {
        await handleOtherResponse(response, result, payData, setLoader, setShow, setStatus, navigate);
      } catch (error) {
        console.error("Error during other response processing:", error.message);
      }
    };
  }
  
  const paymentObject = new window.Razorpay(options);
  // Trigger the payment process for web
  const res = paymentObject.open();
  console.error('Window face is available', res);
  setLoader(false);
};

const handleListingBusinessResponse = async (response, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate) => {
  if (response.razorpay_signature) {
    const data = {
      'user_id': user_id,
      'login_type': userData.login_type,
      'sub_scription_order_id': result?.subscription_id,
      booking_id: result?.data?.id,
      transaction_status: 'success',
      razorpayPaymentId: response.razorpay_payment_id,
      razorpayOrderId: response.razorpay_order_id,
      razorpaySignature: response.razorpay_signature,
    };
    const res = await exportApiAuth.listingPaymentSuccess(data);
    handlePaymentSuccessResponse(res, result, payData, setLoader, setShow, setStatus, navigate);
  } else {
    console.error('Payment failed');
    setLoader(false);
  }
};

const handleOtherResponse = async (response, result, payData, setLoader, setShow, setStatus, navigate) => {
  if (response.razorpay_signature) {
    const data = {
      user_id: payData?.user_id,
      login_type: payData?.login_type,
      booking_id: result?.id,
      status: 'success',
      razorpayPaymentId: response.razorpay_payment_id,
      razorpayOrderId: response.razorpay_order_id,
      razorpaySignature: response.razorpay_signature,
    };
    const res = await exportApiAuth.paymentSuccess(data);
    handlePaymentSuccessResponse(res, result, payData, setLoader, setShow, setStatus, navigate);
  } else {
    console.error('Payment failed');
    setLoader(false);
  }
};
// eslint-disable-next-line
const handleSuccessResponse = async (response, paymentName, result, user_id, userData, payData, setLoader, setShow, setStatus, navigate) => {
  if (response.razorpay_signature) {
    try {
      if (paymentName === 'listingBusiness') {
        const data = {
          user_id,
          login_type: userData.login_type,
          sub_scription_order_id: result?.subscription_id,
          booking_id: result?.data?.id,
          transaction_status: 'success',
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        const res = await exportApiAuth.listingPaymentSuccess(data);
        handlePaymentSuccessResponse(res, result, payData, setLoader, setShow, setStatus, navigate);
      } else {
        const data = {
          user_id: payData?.user_id,
          login_type: payData?.login_type,
          booking_id: result?.id,
          status: 'success',
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        const res = await exportApiAuth.paymentSuccess(data);
        handlePaymentSuccessResponse(res, result, payData, setLoader, setShow, setStatus, navigate);
      }
    } catch (error) {
      console.error('Error during payment processing:', error.message);
      // Handle error during payment processing
      setLoader(false);
    }
  } else {
    console.error('Payment failed');
    setLoader(false);
  }
};

const handlePaymentSuccessResponse = async (res, result, payData, setLoader, setShow, setStatus, navigate) => {
  if (res.status === 'success') {
    if (payData?.code) {
      const res1 = await exportApiAuth.applyCoupon({
        code: res.data?.code,
        amount: result?.actual_amount,
        user_id: payData?.user_id,
        order_id: result?.order?.id,
      });
     
    }
    setShow(true);
    setStatus(res);
    navigate('buy', { state: { paymentResponse: res, modal: true } });
  } else {
    console.error('Something went wrong');
  }
  setLoader(false);
};

const handleError = (error, setLoader) => {
  console.error(error.message);
  setLoader(false);

  // Additional error handling for Razorpay API failures
  if (error.message.includes("Razorpay API call failed")) {
    // Handle specific error related to Razorpay API failure
    console.error("Razorpay API call failed:", error.message);
    alert("Razorpay API call failed. Please check your internet connection and try again.");
  } else {
    // Handle other types of errors
    alert("An unexpected error occurred. Please try again later.");
  }
};


// export const displayRazorpay = async (paymnetName, payData, setLoader, navigate, setShow, setStatus, user_id, userData, userDetials) => {
//   setLoader(true);
//   try {
//     const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
//     if (!res) {
//       throw new Error("Razorpay SDK failed to load. Are you online?");
//     }

//     // const result =  await exportApiAuth.payment(payData);
//     const result = paymnetName === 'listingBusiness' ? await exportApiAuth.listingPayment(payData) : paymnetName === 'bookTicket' ? await exportApiAuth.payment(payData) : [];
//     if (!result) {
//       throw new Error("Server error. Are you online?");
//     }

//     // Getting the order details back
//     // const { amount, id: order_id, currency, name, email, mobile } = result;
//     // {const { final_amount, id: order_id, currency, name, email, mobile, subscription_id } = result.data;
//     let final_amount, amount, order_id, currency, name, email, mobile, subscription_id;
//     if (paymnetName === 'listingBusiness') {
//       ({ final_amount, id: order_id, currency, name, email, mobile, subscription_id } = result);
//     } else {
//       ({ amount, id: order_id, currency, name, email, mobile } = result);
//     }
//     // Check for window.Razorpay before using it
//     if (window.Razorpay) {
//       alert('displayRazorpay option')
//       const options = paymnetName === 'listingBusiness' ?
//         {
//           // key: process.env.RAZORPAY_API_KEY,
//           key: process.env.REACT_APP_RAZORPAY_API_KEY,
//           amount: final_amount.toString(),
//           currency: result.order.currency,
//           name: 'Listing Name',
//           description: "Test Transaction",
//           order_id: result?.order?.id,
//           // order_id: generateDynamicOrderId(),
//           // handler: async function (response) {
//           //   console.log('response', response)
//           //   try {
//           //     if (response.razorpay_signature) {
//           //       const data = {
//           //         'user_id': user_id,
//           //         'login_type': userData.login_type,
//           //         'sub_scription_order_id': subscription_id,
//           //         booking_id: result?.data?.id,
//           //         transaction_status: 'success',
//           //         razorpayPaymentId: response.razorpay_payment_id,
//           //         razorpayOrderId: response.razorpay_order_id,
//           //         razorpaySignature: response.razorpay_signature,
//           //       };
//           //       const res = await exportApiAuth.listingPaymentSucces(data)
//           //       if (res.status === 'success') {
//           //         if (payData?.code) {
//           //           const res1 = await exportApiAuth.applyCoupon({
//           //             'code': res.data?.code,
//           //             'amount': result?.actual_amount,
//           //             'user_id': payData?.user_id,
//           //             'order_id': result?.order?.id
//           //           })
//           //           console.log('res1', res1)
//           //           setLoader(false);
//           //         }
//           //         setLoader(false);
//           //         setShow(true);
//           //         setStatus(res);
//           //         // const newUrl = 'buy';
//           //         // navigate(newUrl, { state: { paymentResponse: res, modal: true } });
//           //       } else {
//           //         console.error('Something went wrong');
//           //       }
//           //     } else {
//           //       console.error('Payment failed');
//           //     }
//           //   } catch (error) {
//           //     console.error("Error during payment processing:", error.message);
//           //     // Handle error during payment processing
//           //   }
//           // },
//           prefill: {
//             name: userData.name,
//             email: 'abc@gmail.com',
//             contact: '1236547890',
//           },
//           notes: {
//             address: "123 Main Street, Cityville",
//             order_number: result?.data?.id,
//           },
//           theme: {
//             color: "#61dafb",
//           },
//         }
//         : {
//           // key: "rzp_test_zQSukeeKTs73Oj",
//           key: process.env.REACT_APP_RAZORPAY_API_KEY,
//           amount: amount.toString(),
//           currency: currency,
//           name: name,
//           description: "Test Transaction",
//           order_id: result?.order?.id,
//           // handler: async function (response) {
//           //   try {
//           //     if (response.razorpay_signature) {
//           //       const data = {
//           //         user_id: payData?.user_id,
//           //         login_type: payData?.login_type,
//           //         booking_id: result?.id,
//           //         status: 'success',
//           //         razorpayPaymentId: response.razorpay_payment_id,
//           //         razorpayOrderId: response.razorpay_order_id,
//           //         razorpaySignature: response.razorpay_signature,
//           //       };
//           //       const res = await exportApiAuth.paymentSuccess(data)
//           //       if (res.status === 'success') {
//           //         setLoader(false);
//           //         setShow(true);
//           //         setStatus(res);
//           //         const newUrl = 'buy';
//           //         navigate(newUrl, { state: { paymentResponse: res, modal: true } });
//           //       } else {
//           //         console.error('Something went wrong');
//           //         setLoader(false);
//           //       }
//           //     } else {
//           //       console.error('Payment failed');
//           //     }
//           //   } catch (error) {
//           //     console.error("Error during payment processing:", error.message);
//           //     // Handle error during payment processing
//           //   }
//           // },
//           prefill: {
//             name: name,
//             email: email,
//             contact: mobile,
//           },
//           notes: {
//             address: "123 Main Street, Cityville",
//             order_number: result?.order?.id,
//           },
//           theme: {
//             color: "#61dafb",
//           },
//         };
//       const android = window.Android;
//       if (getPlatform() === 'Android' || android) {
//         try {
//           const orderOption = options;
//           console.log('orderOption', orderOption)
//           const response = await new Promise((resolve, reject) => {
//             android.startRazorpayPayment(orderOption, (success, response) => {
//               if (success) {
//                 resolve(response);
//               } else {
//                 reject(response);
//               }
//             });
//           });

//           if (response.razorpay_signature) {
//             if (paymnetName === 'listingBusiness') {
//               const data = {
//                 user_id: user_id,
//                 login_type: userData.login_type,
//                 sub_scription_order_id: subscription_id,
//                 booking_id: result?.data?.id,
//                 transaction_status: 'success',
//                 razorpayPaymentId: response.razorpay_payment_id,
//                 razorpayOrderId: response.razorpay_order_id,
//                 razorpaySignature: response.razorpay_signature,
//               };
//               try {
//                 const res = await exportApiAuth.listingPaymentSucces(data);
//                 if (res.status === 'success') {
//                   if (payData?.code) {
//                     const res1 = await exportApiAuth.applyCoupon({
//                       code: res.data?.code,
//                       amount: result?.actual_amount,
//                       user_id: payData?.user_id,
//                       order_id: result?.order?.id,
//                     });
//                     console.log('res1', res1);
//                     setLoader(false);
//                   }
//                   setLoader(false);
//                   setShow(true);
//                   setStatus(res);
//                   // const newUrl = 'buy';
//                   // navigate(newUrl, { state: { paymentResponse: res, modal: true } });
//                 } else {
//                   console.error('Something went wrong');
//                 }
//               } catch (error) {
//                 console.error('Error during payment processing:', error.message);
//                 // Handle error during payment processing
//               }
//             } else {
//               const data = {
//                 user_id: payData?.user_id,
//                 login_type: payData?.login_type,
//                 booking_id: result?.id,
//                 status: 'success',
//                 razorpayPaymentId: response.razorpay_payment_id,
//                 razorpayOrderId: response.razorpay_order_id,
//                 razorpaySignature: response.razorpay_signature,
//               };

//               try {
//                 const res = await exportApiAuth.paymentSuccess(data);

//                 if (res.status === 'success') {
//                   setLoader(false);
//                   setShow(true);
//                   setStatus(res);
//                   const newUrl = 'buy';
//                   navigate(newUrl, { state: { paymentResponse: res, modal: true } });
//                 } else {
//                   console.error('Something went wrong');
//                   setLoader(false);
//                 }
//               } catch (error) {
//                 console.error('Error during payment processing:', error.message);
//                 // Handle error during payment processing
//               }
//               // Handle successful payment in ReactJS
//             }
//           } else {
//             console.error('Payment failed');
//           }

//         } catch (error) {
//           console.error('Error calling startRazorpayPayment:', error);
//         }
//       } else {
//         console.log('options', options)
//         const paymentObject = new window.Razorpay(options);
//         console.error('AndroidInterface not available');
//       }
//       // const paymentObject = new window.Razorpay(options);
//       // if (paymentObject) {

//       //   if (getPlatform() === 'Android' || android) {
//       //     try {
//       //       const orderOption = options;
//       //       // const customerName = 'Test name';
//       //       // const apiKey = 'rzp_test_ZmbCKND78rC5r3';
//       //       // const amount = '100';
//       //       // const currency = 'INR';

//       //       await android.startRazorpayPayment(
//       //         orderOption,
//       //         (success, response) => {
//       //           if (success) {
//       //             console.log('Payment successful:', response);
//       //             try {
//       //               if (response.razorpay_signature) {
//       //                 const data = {
//       //                   user_id: payData?.user_id,
//       //                   login_type: payData?.login_type,
//       //                   booking_id: result?.id,
//       //                   status: 'success',
//       //                   razorpayPaymentId: response.razorpay_payment_id,
//       //                   razorpayOrderId: response.razorpay_order_id,
//       //                   razorpaySignature: response.razorpay_signature,
//       //                 };
//       //                 const res = await exportApiAuth.paymentSuccess(data)
//       //                 if (res.status === 'success') {
//       //                   setLoader(false);
//       //                   setShow(true);
//       //                   setStatus(res);
//       //                   const newUrl = 'buy';
//       //                   navigate(newUrl, { state: { paymentResponse: res, modal: true } });
//       //                 } else {
//       //                   console.error('Something went wrong');
//       //                   setLoader(false);
//       //                 }
//       //               } else {
//       //                 console.error('Payment failed');
//       //               }
//       //             } catch (error) {
//       //               console.error("Error during payment processing:", error.message);
//       //               // Handle error during payment processing
//       //             }
//       //             // Handle successful payment in ReactJS
//       //           } else {
//       //             console.error('Payment failed:', response);
//       //             // Handle failed payment in ReactJS
//       //           }
//       //         }
//       //       );
//       //     } catch (error) {
//       //       console.error('Error calling startRazorpayPayment:', error);
//       //     }
//       //   } else {
//       //     const paymentObject = new window.Razorpay(options);
//       //     console.error('AndroidInterface not available');
//       //   }
//       // };
//       // const tempLoginUser = store.get('tempLogin')?.user?.data?.social_provider?.access_tokens;
//       // const optionsString = JSON.stringify(options);
//       // const user = JSON.stringify(userDetials);
//       // const payDataString = JSON.stringify(payData)
//       // const resultString = JSON.stringify(result)
//       // const baseURL = JSON.stringify(process.env.REACT_APP_INDORETALK_URL)
//       // // const baseURL = encodeURIComponent(process.env.REACT_APP_INDORETALK_URL);
//       // const exportApiAuthString = JSON.stringify(exportApiAuth);
//       // const tokenString = JSON.stringify(tempLoginUser)
//       // Open Razorpay payment in a new browser window with options included in the URL
//       // const razorpayWindow = window.open(`secondPage.html?optionsString=${encodeURIComponent(optionsString)}`, '_blank');
//       // const razorpayWindow = window.open(`secondPage.html?optionsString=${encodeURIComponent(optionsString)}&payDataString=${encodeURIComponent(payDataString)}&resultString=${encodeURIComponent(resultString)}&tokenString=${encodeURIComponent(tokenString)}&baseURL=${encodeURIComponent(baseURL)}&user=${encodeURIComponent(user)}`, '_blank');
//       // alert('displayRazorpay razorpayWindow')
//       // if (razorpayWindow && paymnetName === 'bookTicket') {
//       //   alert('displayRazorpay bookTicket')
//       //   razorpayWindow.document.write('<html><head><title>Razorpay Payment</title></head><body>');
//       //   razorpayWindow.document.write('<script src="https://checkout.razorpay.com/v1/checkout.js"></script>');
//       //   razorpayWindow.document.write('<script>');

//       //   razorpayWindow.document.write(`
//       //   var user1 =  ${JSON.stringify(user)};
//       //   var baseURLStr =  ${JSON.stringify(baseURL)};
//       //   var user = JSON.parse(decodeURIComponent(user1));
//       //   var url = JSON.parse(decodeURIComponent(baseURLStr));
//       //   const handleAuthStateChanged = async (authUser) => {
//       //     if (authUser) {
//       //       const tempLoginProvider = authUser;
//       //       const data = {
//       //         login_type: tempLoginProvider.social_provider.login_type === 'google' ? 'google' : tempLoginProvider.social_provider.login_type === 'phone' ? 'mobile' : '',
//       //         email: tempLoginProvider?.email,
//       //         mobile_number: tempLoginProvider?.mobile_number,
//       //         access_tokens: tempLoginProvider.social_provider.access_tokens,
//       //         name: tempLoginProvider.display_name,
//       //         refresh_tokens: tempLoginProvider.social_provider.refresh_tokens,
//       //       }
//       //       console.log('data', data)
//       //       const res = await fetch(url + 'login', {
//       //         method: 'POST',
//       //         headers: {
//       //           'Content-Type': 'application/json',
//       //         },
//       //         body: JSON.stringify(data)
//       //       })
//       //       .then((response) => response.json())
//       //       .catch((error) => console.log(error));
//       //       console.log('res', res)
//       //       if (res) {
//       //       //   dispatch(setSignIn(loginsignUp)) // call function with parameters on page load
//       //       var optionsString1 =  ${JSON.stringify(optionsString)};
//       //       var payData1 =  ${JSON.stringify(payDataString)};
//       //       var result1 =  ${JSON.stringify(resultString)};
//       //       var token1 =  ${JSON.stringify(tokenString)};
//       //       var options = JSON.parse(decodeURIComponent(optionsString1));
//       //       var payData = JSON.parse(decodeURIComponent(payData1));
//       //       var result = JSON.parse(decodeURIComponent(result1));
//       //       var token = JSON.parse(decodeURIComponent(token1));
//       //       options.handler = async function (response) {
//       //         console.log('response2', response);
//       //         try {
//       //           if (response.razorpay_signature) {
//       //             const data = {
//       //               user_id: payData?.user_id,
//       //               login_type: payData?.login_type,
//       //               booking_id: result?.id,
//       //               status: 'success',
//       //               razorpayPaymentId: response.razorpay_payment_id,
//       //               razorpayOrderId: response.razorpay_order_id,
//       //               razorpaySignature: response.razorpay_signature,
//       //             };

//       //             const res = await fetch(url + 'payment-success', {
//       //               method: 'POST',
//       //               headers: {
//       //                 'Content-Type': 'application/json',
//       //                 Token: token,
//       //               },
//       //               body: JSON.stringify(data)
//       //             })
//       //             .then((response) => response.json())
//       //             .catch((error) => console.log(error));

//       //             console.log('res', res);

//       //             if (res && res.status === 'success') {
//       //               console.log('Your payment successfully captured');
//       //               // setLoader(false);
//       //               // setShow(true);
//       //               // setStatus(res);
//       //               // const newUrl = 'buy';
//       //               // navigate(newUrl, { state: { paymentResponse: res, modal: true } });
//       //             } else {
//       //               console.error('Something went wrong');
//       //               // setLoader(false);
//       //             }
//       //           } else {
//       //             console.error('Payment failed');
//       //           }
//       //         } catch (error) {
//       //           console.error("Error during payment processing:", error.message);
//       //           // Handle error during payment processing
//       //         }
//       //       };

//       //       var paymentObject = new Razorpay(options);
//       //       paymentObject.open();
//       //       }
//       //     } else {
//       //       console.error('something wrong');
//       //     }
//       //   };

//       //   window.onload = handleAuthStateChanged( user );
//       //   `);

//       //   razorpayWindow.document.write('</script>');
//       //   razorpayWindow.document.write('</body></html>');
//       // } else if (razorpayWindow && paymnetName === 'listingBusiness') {
//       //   alert('displayRazorpay listingBusiness')
//       //   razorpayWindow.document.write('<html><head><title>Razorpay Payment</title></head><body>');
//       //   razorpayWindow.document.write('<script src="https://checkout.razorpay.com/v1/checkout.js"></script>');
//       //   razorpayWindow.document.write('<script>');

//       //   razorpayWindow.document.write(`
//       //   var user1 =  ${JSON.stringify(user)};
//       //   var baseURLStr =  ${JSON.stringify(baseURL)};
//       //   console.log('user', user1, baseURLStr)
//       //   var user = JSON.parse(decodeURIComponent(user1));
//       //   var url = JSON.parse(decodeURIComponent(baseURLStr));

//       //   const handleAuthStateChanged = async (authUser) => {
//       //     if (authUser) {
//       //       const tempLoginProvider = authUser;
//       //       const data = {
//       //         login_type: tempLoginProvider.social_provider.login_type === 'google' ? 'google' : tempLoginProvider.social_provider.login_type === 'phone' ? 'mobile' : '',
//       //         email: tempLoginProvider?.email,
//       //         mobile_number: tempLoginProvider?.mobile_number,
//       //         access_tokens: tempLoginProvider.social_provider.access_tokens,
//       //         name: tempLoginProvider.display_name,
//       //         refresh_tokens: tempLoginProvider.social_provider.refresh_tokens,
//       //       }
//       //       console.log('data', data)
//       //       const res = await fetch(url + 'login', {
//       //         method: 'POST',
//       //         headers: {
//       //           'Content-Type': 'application/json',
//       //         },
//       //         body: JSON.stringify(data)
//       //       })
//       //       .then((response) => response.json())
//       //       .catch((error) => console.log(error));
//       //       console.log('res', res)
//       //       if (res) {
//       //         var optionsString1 =  ${JSON.stringify(optionsString)};
//       //         var payData1 =  ${JSON.stringify(payDataString)};
//       //         var result1 =  ${JSON.stringify(resultString)};
//       //         var apiAuth1 =  ${JSON.stringify(exportApiAuthString)};
//       //         var token1 =  ${JSON.stringify(tokenString)};

//       //         var options = JSON.parse(decodeURIComponent(optionsString1));
//       //         var payData = JSON.parse(decodeURIComponent(payData1));
//       //         var result = JSON.parse(decodeURIComponent(result1));
//       //         var token = JSON.parse(decodeURIComponent(token1));

//       //         options.handler = async function (response) {
//       //           console.log('response2', response);
//       //           try {
//       //             if (response.razorpay_signature) {
//       //               const data = {
//       //                 'user_id': payData?.user_id,
//       //                 'login_type': payData?.login_type,
//       //                 'sub_scription_order_id': result?.subscription_id,
//       //                 booking_id: result?.data?.id,
//       //                 transaction_status: 'success',
//       //                 razorpayPaymentId: response.razorpay_payment_id,
//       //                 razorpayOrderId: response.razorpay_order_id,
//       //                 razorpaySignature: response.razorpay_signature,
//       //               };

//       //               const res = await fetch( url + 'sub-scription-status', {
//       //                 method: 'POST',
//       //                 headers: {
//       //                   'Content-Type': 'application/json',
//       //                   Token: token,
//       //                 },
//       //                 body: JSON.stringify(data),
//       //               }).then((response) => response.json()).catch((error) => console.log(error));

//       //               if (res && res.status === 'success') {
//       //                 console.log('Your payment successfully captured');

//       //                 if (payData?.code) {
//       //                   const applyCouponPayloads = {
//       //                     'code': res.data?.code,
//       //                     'amount': result?.actual_amount,
//       //                     'user_id': payData?.user_id,
//       //                     'order_id': result?.order?.id,
//       //                   };

//       //                   const res1 = await fetch('https://indoretalk.webcraft.co.in/api/apply-coupon', {
//       //                     method: 'POST',
//       //                     headers: {
//       //                       'Content-Type': 'application/json',
//       //                       Token: token,
//       //                     },
//       //                     body: JSON.stringify(applyCouponPayloads),
//       //                   }).then((response) => response.json()).catch((error) => console.log(error));

//       //                   console.log('res1', res1);
//       //                   // setLoader(false);
//       //                 }
//       //               } else {
//       //                 console.error('Something went wrong');
//       //                 // setLoader(false);
//       //               }
//       //             } else {
//       //               console.error('Payment failed');
//       //             }
//       //           } catch (error) {
//       //             console.error("Error during payment processing:", error.message);
//       //             // Handle error during payment processing
//       //           }
//       //         };

//       //         var paymentObject = new Razorpay(options);
//       //         paymentObject.open();
//       //       }
//       //     }else {
//       //       console.error('something wrong');
//       //     }
//       //   };
//       //     window.onload = handleAuthStateChanged( user );
//       //   `);

//       //   razorpayWindow.document.write('</script>');
//       //   razorpayWindow.document.write('</body></html>');
//       // } else {
//       //   setLoader(false);
//       //   console.error("Unable to open Razorpay window. Please check your browser settings.");
//       //   alert("Unable to open Razorpay window. Please check your browser settings.");
//       // }
//       setLoader(false);
//     } else {
//       setLoader(false);
//       console.error("Razorpay script not loaded successfully.");
//       alert("Razorpay script not loaded successfully. Please try again later.");
//     }
//   } catch (error) {
//     setLoader(false);

//     // Additional error handling for Razorpay API failures
//     if (error.message.includes("Razorpay API call failed")) {
//       // Handle specific error related to Razorpay API failure
//       console.error("Razorpay API call failed:", error.message);
//       alert("Razorpay API call failed. Please check your internet connection and try again.");
//     } else {
//       // Handle other types of errors
//       console.error(error.message);
//       alert("An unexpected error occurred. Please try again later.");
//     }
//   }
// };
// catch (error) {
//   setLoader(false);
//   console.error(error.message);
//   alert(error.message);
// }
// };


// apiUtils.js

export const calculateFinalAmount = (apiResponse, actualAmount) => {
  const { type, amount, code } = apiResponse;
  let final_amount = actualAmount; // Declare outside the if block
  if (apiResponse && apiResponse) {

    if (type === 'percentage') {
      // Calculate the final amount after deducting the percentage
      const percentageDiscount = (actualAmount / 100) * amount;
      final_amount = actualAmount - percentageDiscount;
    } else if (type === 'fixed') {
      // Deduct the fixed amount from the actual amount
      final_amount = actualAmount - amount;
    } else {
      // Handle other types if needed
      // For now, do nothing or provide a default behavior
    }
  }

  // Return the actual amount if there's an issue with the API response
  return {
    discounted_amount: actualAmount - final_amount,
    final_amount: final_amount,
    code: code,
  };
};


export const removeMoreFromUrl = (url) => {
  // Use string manipulation to remove or trim '/more' from the URL
  const trimmedUrl = url.replace('/more', '');

  // You can also use the substring method
  // const trimmedUrl = url.substring(0, url.lastIndexOf('/more'));

  return trimmedUrl;
};

export const getInitialCharacter = (fullName, wordsLimit = 2) => {
  // Check if the input is valid
  if (typeof fullName !== 'string') {
    return "Invalid input";
  }

  // Split the full name into an array of words
  const words = fullName.split(' ');

  // Get the first letter of each word
  const initials = words.map(word => word.charAt(0).toUpperCase());

  // Join the initials and return the result
  return initials.join('').slice(0, wordsLimit);
}
export const handleAuthStateChanged = async (dispatch) => {
  const localData = localStorage.getItem('tempLogin');
  const data = JSON.parse(localData)
  const accesData = data?.user?.data
  if (accesData) {
    const loginsignUp = await exportApi.signInAndSignUp({
      login_type: accesData?.social_provider?.login_type,
      email: accesData?.email,
      mobile_number: accesData?.mobile_number,
      access_tokens: accesData?.social_provider?.access_tokens,
      name: accesData.display_name,
      refresh_tokens: accesData?.social_provider?.refresh_tokens,
    });
    if (loginsignUp) {
      dispatch(setSignIn(loginsignUp))
    }
  }
  else {
    console.error('something wrong');
  }
  // else if (authUser) {
  //   const tempLoginProvider = authUser.providerData[0] || '';
  //   const loginsignUp = await exportApi.signInAndSignUp({
  //     login_type: tempLoginProvider.providerId === 'google.com' ? 'google' : tempLoginProvider.providerId === 'phone' ? 'mobile' : '',
  //     email: tempLoginProvider?.email,
  //     mobile_number: tempLoginProvider?.phoneNumber,
  //     access_tokens: authUser.stsTokenManager.accessToken,
  //     name: authUser.displayName,
  //     refresh_tokens: authUser.stsTokenManager.refreshToken,
  //   });
  //   if (loginsignUp) {
  //     dispatch(setSignIn(loginsignUp))
  //   }
  // } 
};

export const unnecatchLogout = (store, dispatch, setSignOut) => {
  try {
    store.clearAll();
    dispatch(setSignOut());
    localStorage.clear();
  } catch (error) {
    console.warn(error)
  }
}

export const shareBlockFunction = (setShareBlock) => {
  try {
    setShareBlock(true)
    setTimeout(() => {
      setShareBlock(false)
    }, shareBlockTime)
  } catch (error) {
    console.warn(error)
  }
}

export const refreshTokenFun = async (userData) => {
  console.log('userData', userData)
  try {
    const data = {
      'refresh_tokens': userData?.access_tokens,
      'user_id': userData?.user_id,
      'login_type': userData?.login_type
    }
    console.log('data', data)
    if (data) {
      await exportApiAuth.regenerateToken(data);
    }
  } catch (error) {
    console.warn(error);
  }
};