/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './footer.css';
import { Icons } from '../Icons/Icon';
import home from "../../../assets/svg/home.svg";
import event from "../../../assets/svg/event.svg";
import listing from "../../../assets/svg/listing.svg";
import more from "../../../assets/svg/more.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { titleCase } from '../../../utils/exportGlobleFunction';
import { useDispatch, useSelector } from 'react-redux';
import { setPreviousSubCategoryBusiness, setPreviousSubCategoryEvent, setPreviousSubCategoryExplore, } from '../../../redux/slices/subCat';
import { setActivemainCategory } from '../../../redux/slices/mainCatSlice';
import { setAgainRedirectionPath } from '../../../redux/slices/authSlice';
import { setSubSubCat } from '../../../redux/slices/singleDataSlice';

const images = [
  { name: 'polls', path: '/polls' },
  { src: event, name: 'event', path: '/event-home' },
  { src: home, name: 'explore', path: '/home' },
  { src: listing, name: 'listing', path: '/listing-home' },
  { src: more, name: 'more', path: '/more' },
];

export const Footer = ({ fetchdata, setClickBlock, clickBlock,  setDynamicSubCatId }) => {
  let url = window.location.href;
  const { tempLogin } = useSelector((state) => state.auth)
  const subCategories = useSelector((state) => state.subCat)
  const subCatNavEvent = subCategories ? subCategories.subEventCategory : []
  const subCatNavNews = subCategories ? subCategories.subExploreCategory : []
  const subCatNavListing = subCategories ? subCategories.subListingCategory : []
  const userId = tempLogin?.user?.data?.social_provider
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [endPath, setEndPath] = useState()
  const nevigate = (path) => {
    if (location.pathname === path) {
      if (path.includes('/event-home') && clickBlock) {
        setClickBlock(false)
        fetchdata(subCatNavEvent[0]?.id, userId?.user_id || 0)
      } else if (path.includes('/home') && clickBlock) {
        setClickBlock(false)
        fetchdata(subCatNavNews[0]?.id, userId?.user_id || 0)
      } else if (path.includes('/listing-home') && clickBlock) {
        setClickBlock(false)
        setDynamicSubCatId(subCatNavListing[0]?.id)
        fetchdata(subCatNavListing[0]?.id, userId?.user_id || 0, subCatNavListing[0]?.sub_category[0]?.id)
      }
    }

    dispatch(setSubSubCat(0))
    dispatch(setPreviousSubCategoryExplore(0))
    dispatch(setPreviousSubCategoryEvent(0))
    dispatch(setPreviousSubCategoryBusiness(0))
    dispatch(setAgainRedirectionPath({
      'pathBoolean': false,
    }))
    setEndPath(path)
    navigate(path)
  }
  useEffect(() => {
    dispatch(setActivemainCategory(location.pathname ? location.pathname : endPath))
  }, [endPath, location.pathname])
  return (
    <footer className="text-center fixed-bottom footer-style" style={{ backgroundColor: 'white' }}>
      <div className="">
        <div className="d-flex justify-content-evenly">
          {images.map((imageName, index) => (
            <div key={index} className={`cursor-pointer ${url.includes(imageName.path) ? 'active' : ''}`} onClick={() => nevigate(imageName.path)}
            >
              <Icons
                name={imageName.name}
                className={`svg-size-24 ${imageName.name === 'explore' ? 'svg-size-90 mx-2 mb-0 custom-explore-style' : (url.includes(imageName.path) ? 'svg-red' : 'svg-white')}`}
              />

              <h5 className={`${imageName.name === 'explore' ? 'explore-text' : 'mt-1'} ${url.includes(imageName.path) ? 'active' : 'footer-icon-name'}`}>{titleCase(imageName.name)}</h5>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};
