import React from "react";

export const Time = ({
  className = "",
  width = "24px",
  height = "25px",
  viewBox = "0 0 28 25",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.25 12.2769C21.25 17.3859 17.109 21.5269 12 21.5269C6.891 21.5269 2.75 17.3859 2.75 12.2769C2.75 7.16786 6.891 3.02686 12 3.02686C17.109 3.02686 21.25 7.16786 21.25 12.2769Z"
        stroke="#E31E24"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4321 15.2193L11.6621 12.9703V8.12329"
        stroke="#E31E24"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
