import React from 'react';

export const ListYourBusiness= ({
    className = "",
    width = "42px",
    height = "42px",
    viewBox = "0 0 42 42",
    fill = "",

}) => {
   

    return (
        
            <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
               <circle cx="21" cy="21" r="21" fill="#E31E24"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.6953 13.5803H18.2952C18.4625 12.9584 19.0308 12.5 19.7052 12.5H22.2852C22.9597 12.5 23.528 12.9584 23.6953 13.5803ZM27.19 13.5803H25.2211C25.0346 12.1253 23.7908 11 22.2852 11H19.7052C18.1997 11 16.9559 12.1253 16.7694 13.5803H14.81C12.709 13.5803 11 15.2729 11 17.3803V20.8303C11 21.0967 11.1413 21.343 11.3711 21.4776C13.7827 22.8892 16.8762 23.7649 20.2452 23.8779V25.6769C20.2452 26.0911 20.581 26.4269 20.9952 26.4269C21.4095 26.4269 21.7452 26.0911 21.7452 25.6769V23.8775C25.1111 23.7631 28.2095 22.8879 30.6189 21.4776C30.8487 21.343 30.99 21.0967 30.99 20.8303V17.3903C30.99 15.2893 29.2974 13.5803 27.19 13.5803ZM12.5 17.3803C12.5 16.1077 13.531 15.0803 14.81 15.0803H27.19C28.4626 15.0803 29.49 16.1113 29.49 17.3903V20.3923C27.2109 21.6295 24.2534 22.3856 21.0202 22.3903C21.0119 22.39 21.0036 22.3899 20.9952 22.3899C20.9868 22.3899 20.9783 22.39 20.9699 22.3903C17.7332 22.3872 14.7808 21.631 12.5 20.3923V17.3803ZM12.5223 24.4269C12.4912 24.0138 12.1312 23.7042 11.7181 23.7353C11.3051 23.7664 10.9955 24.1265 11.0266 24.5395L11.2156 27.0488C11.3732 29.1306 13.1079 30.7402 15.1954 30.7402H26.7944C28.882 30.7402 30.6167 29.1303 30.7743 27.0485L30.9633 24.5395C30.9944 24.1265 30.6848 23.7664 30.2718 23.7353C29.8587 23.7042 29.4987 24.0138 29.4676 24.4269L29.2786 26.9359C29.18 28.2359 28.0968 29.2402 26.7944 29.2402H15.1954C13.8931 29.2402 12.8099 28.2356 12.7113 26.9356L12.5223 24.4269Z" fill="white"/>
</svg>

      
    );
};
