export const validationPatternForOtp = /^[0-9]+$/;

export const allowedFileTypes = ['image/jpeg', 'image/png'];

export const maxSizeInBytes = 1024 * 1024;

// Function to check if the platform includes any supported browser
export const isSupportedBrowser = (platform) => {
    const supportedBrowsers = ['Chrome', 'Firefox', 'Safari', 'Edge', 'Opera', 'Internet Explorer'];
    for (let browser of supportedBrowsers) {
        if (platform.includes(browser)) {
            return true;
        }
    }
    return false;
}


export const getPlatform = () => {
    const platform = ["Win32", "Android", "iOS", "Mac OS", "Windows"];
    const uA = navigator.userAgent || navigator.vendor || window.opera;
    for (var i = 0; i < platform.length; i++) {

        if (uA.indexOf(platform[i]) > - 1) {
            return platform[i];
        }
    }
}

export const cardHeadingLimit = 30;
export const cardLocationLimit = 40;
export const navtextLimit = 20;

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const timeOptions = [
    '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
    '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30',
    '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30'
];
export const amPmOptions = ['AM', 'PM'];

export const getDefaultHours = {
    open_close: false,
    open_time: '',
    open_am_pm: '',
    end_time: '',
    end_am_pm: ''
};

export const apiCallingLimit = 5;
export const shareBlockTime = 2000;
export const newsViewCount = 7200;
export const newsLikeCount = 114;
export const eventViewCount = 200;
export const eventLikeCount = 31;

export const listingLikeCount = 53;
export const listingViewCount = 5300;
export const endpointsToCancel = ['explorenews', 'explorelive', 'event', 'listing'];
