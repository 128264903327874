import axios from "axios";
import { endpointsToCancel } from "../utils/constGlobleData";

export default class IndoreTalkService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_INDORETALK_URL,
    });

    this.cancelToken = axios.CancelToken;
    this.source = {};

    this.axiosInstance.interceptors.request.use((request) => {
      // Check if request URL matches any endpoint to cancel
      const shouldCancel = endpointsToCancel.some((endpoint) =>
        request.url.includes(endpoint)
      );

      if (shouldCancel) {
        // Get the endpoint name
        const endpointName = endpointsToCancel.find((endpoint) =>
          request.url.includes(endpoint)
        );

        // Cancel previous request for the endpoint
        if (this.source[endpointName]) {
          this.source[endpointName].cancel(
            `Cancelled previous request for ${endpointName}.`
          );
        }

        // Create new cancel token for the endpoint
        this.source[endpointName] = this.cancelToken.source();
        request.cancelToken = this.source[endpointName].token;
      }

      request.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Add your headers here if needed
      };
      return request;
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async get(endpoint, params = "") {
    try {
      const response = await this.axiosInstance.get(endpoint + params, {
        cancelToken: this.getSourceTokenForEndpoint(endpoint),
      });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      }
      return Promise.reject(error);
    }
  }

  async post(endpoint, params = {}) {
    try {
      const response = await this.axiosInstance.post(
        endpoint,
        params instanceof FormData ? params : JSON.stringify(params),
        {
          headers: {
            "Content-Type": params instanceof FormData ? "multipart/form-data" : "application/json",
          },
        }
      );console.log("res", response)
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  

  async put(endpoint, params = {}) {
    return await this.axiosInstance.put(
      endpoint,
      params && JSON.stringify(params)
    );
  }

  async delete(endpoint) {
    return await this.axiosInstance.delete(endpoint);
  }

  // Helper method to get cancel token for an endpoint
  getSourceTokenForEndpoint(endpoint) {
    const endpointName = endpointsToCancel.find((name) =>
      endpoint.includes(name)
    );
    return this.source[endpointName]
      ? this.source[endpointName].token
      : undefined;
  }
}
