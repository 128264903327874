import React from 'react'

export const Delete = ({
    className = "",
    width = "42px",
    height = "42px",
    viewBox = "0 0 42 42",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <circle cx="21" cy="21" r="21" fill="#E31E24" />
            <path d="M30.0751 14.8737H11.9238M26.5776 14.8737C25.7374 14.8737 25.0138 14.2796 24.849 13.4565L24.5889 12.1549C24.4284 11.5545 23.8846 11.1392 23.2649 11.1392H18.734C18.1143 11.1392 17.5705 11.5545 17.41 12.1549L17.1499 13.4565C16.985 14.2796 16.2615 14.8737 15.4212 14.8737" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8643 17.4516C29.3568 17.5192 29.7011 17.9732 29.6335 18.4657L28.1935 28.9505C27.9692 30.5842 26.5733 31.8015 24.9242 31.8015H17.0914C15.4262 31.8015 14.0219 30.5609 13.8166 28.9084L12.5177 18.4542C12.4564 17.9609 12.8066 17.5114 13.2999 17.4501C13.7931 17.3888 14.2427 17.739 14.304 18.2323L15.6029 28.6864C15.6962 29.4376 16.3345 30.0015 17.0914 30.0015H24.9242C25.6738 30.0015 26.3083 29.4482 26.4103 28.7056L27.8503 18.2208C27.9179 17.7283 28.3719 17.384 28.8643 17.4516Z" fill="white" />
        </svg>
    )
}
