// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import store from 'store';

// export default class IndoreTalkAuthService {
//   constructor() {
//     this.axiosInstance = axios.create({
//       baseURL: process.env.REACT_APP_INDORETALK_URL,
//     });

//     this.axiosInstance.interceptors.request.use((request) => {
//       const tempLoginUser = store.get('tempLogin').user.data.social_provider.access_tokens
//       request.headers = {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         // Authorization: store.get(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`),
//         // 'accountId': store.get(`${process.env.REACT_APP_ACCOUNTID}`)
//         token: tempLoginUser
//       };
//       return request;
//     });

//     this.axiosInstance.interceptors.response.use(
//       (results) => {
//         return results.data;
//       },
//       (error) => {
//         if (error.response?.status === 401 && error.response.data.displayMessage === 'Unauthorized') {
//           store.clearAll();
//           window.location.href = '/login';
//         }

//         if (error.response?.status === 400 && error.response.data.message === 'Authorization token invalid!') {
//           store.clearAll();
//           window.location.href = '/login';
//         }
//         return error.response;
//       },
//     );
//   }

//   async get(endpoint, params = '') {
//     return await this.axiosInstance.get(endpoint + params);
//   }

//   async post(endpoint, params = {}) {
//     return await this.axiosInstance.post(endpoint, params instanceof FormData ? params : JSON.stringify(params), {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//   }

//   async put(endpoint, params = {}) {
//     return await this.axiosInstance.put(endpoint, params && JSON.stringify(params));
//   }

//   async delete(endpoint) {
//     return await this.axiosInstance.delete(endpoint);
//   }
// }

import axios from 'axios';
import { useDispatch } from 'react-redux';
import store from 'store';
import { handleAuthStateChanged } from '../utils/exportGlobleFunction';

export default class IndoreTalkAuthService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_INDORETALK_URL,
    });

    this.axiosInstance.interceptors.request.use(async (request) => {
      const tempLoginUser = store.get('tempLogin')?.user?.data?.social_provider?.access_tokens;
      // Check if tempLoginUser is null, and redirect to login if true
      if (!tempLoginUser) {
        window.location.href = '/login';
        return Promise.reject('Redirecting to login');
      }
      const formType = request.formType || false;
      if (formType) {
        request.headers = {
          ...request.headers,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          type: 'formData',
          token: tempLoginUser,
        };
      } else {
        request.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: tempLoginUser,
        };
      }

      return request;
    });

    // let isRefreshing = false;
    // let refreshQueue = [];

    this.axiosInstance.interceptors.response.use(
      async (response) => {
        return response.data;
      },
      // async (error) => {
      //   const dispatch = useDispatch();

      //   const originalRequest = error.config;

      //   if (error.response?.status === 401 && error.response.data?.status === 'fail') {
      //     if (!isRefreshing) {
      //       isRefreshing = true;

      //       try {
      //         // const refreshedToken = await this.refreshToken();

      //         // // Update the original request with the new token
      //         // originalRequest.headers.token = refreshedToken;

      //         // Retry the original request with the new token
      //         // return axios(originalRequest);
      //       } catch (refreshError) {
      //         // Handle refresh token error, redirect to login, etc.
      //         store.clearAll();
      //         window.location.href = '/login';
      //         return Promise.reject(refreshError);
      //       } finally {
      //         isRefreshing = false;
      //         refreshQueue.forEach((resolve) => resolve());
      //         refreshQueue = [];
      //       }
      //     } else {
      //       // If a refresh is already in progress, queue the original request
      //       return new Promise((resolve) => {
      //         refreshQueue.push(() => resolve(axios(originalRequest)));
      //       });
      //     }
      //   }

      //   return Promise.reject(error);
      // },
      (error) => {
        const dispatch = useDispatch();
        if (error.response?.status === 400 && error.response.data?.data === 'Token Expired') {
          handleAuthStateChanged(dispatch)
          // store.clearAll();
          // window.location.href = '/login';
        }
        if (error.response?.status === 401 && error.response.data.displayMessage === 'Unauthorized') {
          store.clearAll();
          window.location.href = '/login';
        }
        return error.response;
      },
    );
  }

  async refreshToken() {
    const refresh_token = store.get('tempLogin')?.user?.data?.social_provider?.refresh_tokens;
    console.log('refresh_token', refresh_token)
    if (!refresh_token) {
      throw new Error('Refresh token not available');
    }

    try {
      const response = await axios.post('YOUR_REFRESH_TOKEN_ENDPOINT', {
        refresh_token,
      });

      if (response.data.access_token) {
        // Update the token in your store or wherever it is stored
        store.set('tempLogin.user.data.social_provider.access_tokens', response.data.access_token);
        return response.data.access_token;
      } else {
        throw new Error('Invalid response while refreshing token');
      }
    } catch (refreshError) {
      throw new Error('Error refreshing token');
    }
  }
  // Your existing methods (get, post, put, delete) remain unchanged

  async get(endpoint, params = '') {
    return await this.axiosInstance.get(endpoint + params);
  }

  async post(endpoint, params = {}, formType) {
    return await this.axiosInstance.post(endpoint, params instanceof FormData ? params : JSON.stringify(params), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      formType, // Include formType directly in the request
    });
  }

  async put(endpoint, params = {}) {
    return await this.axiosInstance.put(endpoint, params && JSON.stringify(params));
  }

  async delete(endpoint) {
    return await this.axiosInstance.delete(endpoint);
  }
}
