import React from 'react'

export const More = ({
    className = "",
    width = "24px",
    height = "24px",
    viewBox = "0 0 24 24",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M7.67833 11.3811C7.06327 11.3811 6.44821 11.3837 5.83316 11.3806C4.81868 11.375 4.00545 10.5617 4.00288 9.54624C3.99928 8.30997 3.99928 7.0737 4.00288 5.83794C4.00596 4.8214 4.81816 4.00663 5.83264 4.00355C7.06892 3.99996 8.3057 3.99996 9.54197 4.00355C10.5693 4.00612 11.3794 4.81781 11.382 5.84718C11.3851 7.07678 11.3851 8.30689 11.382 9.53648C11.3794 10.5659 10.5698 11.3765 9.543 11.3811C8.92127 11.3837 8.30006 11.3811 7.67833 11.3811ZM5.23094 7.68362C5.23094 8.273 5.22991 8.86239 5.23145 9.45177C5.23248 9.90254 5.4825 10.1515 5.93532 10.1521C7.10794 10.1531 8.28055 10.1531 9.45316 10.1521C9.90546 10.1515 10.1524 9.90254 10.1529 9.44818C10.154 8.27557 10.154 7.10347 10.1529 5.93086C10.1524 5.48112 9.90033 5.23161 9.44854 5.23109C8.27592 5.23007 7.10331 5.23007 5.9307 5.23109C5.47788 5.23161 5.23248 5.47958 5.23145 5.93446C5.22991 6.51768 5.23094 7.1009 5.23094 7.68362Z" fill=""/>
        <path d="M16.2953 11.3812C15.6802 11.3812 15.0652 11.3838 14.4501 11.3807C13.4341 11.3755 12.6183 10.5638 12.6157 9.55039C12.6122 8.30796 12.6116 7.06501 12.6157 5.82258C12.6193 4.82093 13.4367 4.00667 14.4409 4.00308C15.6838 3.99897 16.9268 3.99897 18.1697 4.00308C19.1745 4.00616 19.9908 4.8199 19.9944 5.82258C19.999 7.06501 19.9985 8.30796 19.9944 9.55039C19.9913 10.5649 19.1765 11.375 18.16 11.3802C17.5382 11.3837 16.9165 11.3812 16.2953 11.3812ZM16.2855 10.1526C16.8749 10.1526 17.4648 10.1536 18.0542 10.1521C18.5209 10.1511 18.7658 9.90669 18.7658 9.44052C18.7668 8.27459 18.7668 7.10865 18.7658 5.94271C18.7653 5.4786 18.5178 5.23165 18.0537 5.23114C16.8873 5.23011 15.7213 5.23011 14.5549 5.23114C14.0877 5.23165 13.8438 5.475 13.8438 5.9422C13.8428 7.10814 13.8428 8.27407 13.8438 9.44001C13.8443 9.90464 14.0913 10.1511 14.5554 10.1521C15.1324 10.1531 15.709 10.1526 16.2855 10.1526Z" fill=""/>
        <path d="M11.3825 16.3145C11.3825 16.9296 11.3851 17.5441 11.382 18.1592C11.3769 19.1747 10.5647 19.9874 9.54866 19.9905C8.31239 19.9941 7.0756 19.9941 5.83933 19.9905C4.82279 19.9874 4.00597 19.1752 4.00289 18.1628C3.9993 16.9203 3.99878 15.6774 4.00289 14.435C4.00648 13.4323 4.82228 12.617 5.82598 12.6139C7.06893 12.6098 8.31188 12.6098 9.55482 12.6139C10.5601 12.617 11.3748 13.4297 11.3815 14.4319C11.3856 15.0593 11.3825 15.6871 11.3825 16.3145ZM7.68398 13.8415C7.10076 13.8415 6.51754 13.8404 5.93482 13.842C5.47995 13.843 5.23198 14.0884 5.23146 14.5417C5.23044 15.7144 5.23044 16.8865 5.23146 18.0591C5.23198 18.5114 5.48149 18.7624 5.93174 18.7629C7.10435 18.764 8.27697 18.764 9.44958 18.7629C9.90445 18.7624 10.1529 18.5155 10.1534 18.0627C10.1545 16.89 10.1545 15.718 10.1534 14.5453C10.1529 14.093 9.90342 13.843 9.45266 13.842C8.86327 13.8404 8.27337 13.8415 7.68398 13.8415Z" fill=""/>
        <path d="M16.9196 15.6881C17.76 15.6881 18.5732 15.6865 19.386 15.6891C19.6652 15.6901 19.8901 15.8632 19.9728 16.1281C20.0498 16.3755 19.9615 16.6594 19.732 16.8011C19.6242 16.8679 19.483 16.91 19.3562 16.9115C18.6194 16.9218 17.8827 16.9167 17.146 16.9167C17.0772 16.9167 17.0084 16.9167 16.9196 16.9167C16.9196 17.0029 16.9196 17.0712 16.9196 17.14C16.9196 17.8767 16.9206 18.6129 16.9191 19.3497C16.918 19.7245 16.6593 19.9919 16.304 19.9919C15.9508 19.9914 15.692 19.7214 15.691 19.3476C15.6895 18.5472 15.6905 17.7468 15.6905 16.9172C15.6155 16.9172 15.5483 16.9172 15.481 16.9172C14.7443 16.9172 14.0076 16.9182 13.2708 16.9167C12.8832 16.9161 12.6126 16.6584 12.6147 16.296C12.6168 15.9412 12.8842 15.6891 13.2636 15.6886C14.0635 15.6871 14.8639 15.6881 15.6905 15.6881C15.6905 15.6095 15.6905 15.5423 15.6905 15.4745C15.6905 14.7378 15.6895 14.0016 15.691 13.2648C15.6915 12.8849 15.9426 12.6164 16.2958 12.6133C16.6598 12.6097 16.9185 12.8793 16.9191 13.2664C16.9201 13.9964 16.9196 14.727 16.9196 15.4571C16.9196 15.5258 16.9196 15.5952 16.9196 15.6881Z" fill=""/>
        </svg>
        

    )
}

