import React, { useEffect, useState } from "react";
import { exportApi } from "../../../services/indoreTalk.api";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  sliceCharacters,
} from "../../../utils/exportGlobleFunction";
import "./searchBox.css";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Icons } from "../Icons/Icon";
import InputField from "../form-type/InputField";
import MyImage from "../Notification/alpha.jpg";
import { cardHeadingLimit } from "../../../utils/constGlobleData";
import { setPreviousSubCategoryBusiness, setPreviousSubCategoryBusinessdata, setPreviousSubCategoryEvent } from "../../../redux/slices/subCat";
import { SearchSkeleton } from "../cards/searchSkeleton";
import debounce from "lodash.debounce";

export const Search = () => {
  const dispatch = useDispatch();
  const mainCategory = useSelector((state) => state.mainCat)
  const tabData = mainCategory?.mainCategory?.data
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState();
  const [skeletpn, setSkeletpn] = useState(true)
  const navigate = useNavigate();
  const [filter, setFilter] = useState(() => Array.isArray(tabData) ? tabData.find((item) => (item.name).toLowerCase() === 'listing')?.id : null);
  useEffect(() => {
    handleSearch(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);



  const [mappedCount, setMappedCount] = useState(0);

  useEffect(() => {
    // Update the mappedCount to map more items whenever filteredData changes
    if (filteredData && filteredData.length > mappedCount) {
      // Only increase mappedCount if there are more items to map
      const nextCount = mappedCount + 1 <= filteredData.length ? mappedCount + 1 : filteredData.length;
      setMappedCount(nextCount);
    }
  }, [filteredData, mappedCount]);

  const handleSearch = async (searchString) => {
    if (searchString) {
      setShow(true);
    }
    setSearchTerm(searchString);
  
    try {
      setSkeletpn(false);
      
      if (searchString.length === 0) {
        setFilteredData([]);
      } else {
        const res = await exportApi.search({
          'search': show ? searchTerm : searchString,
          'maincategory_id': filter,
        });
        
        if (res.data === 'No available data') {
          setFilteredData([]); // Handle the case for no available data
        } else {
          const response = res.data.filter((event) => event?.is_active !== 0);
          setFilteredData(response);
          setMappedCount(1);
        }
        
        setShow(false);
      }
  
      if (!searchString) {
        setSkeletpn(true);
      }
    } catch (error) {
      console.error(error);
      setShow(false);
    }
  };
  
  const debouncedHandleSearch = debounce(handleSearch, 0); // Create a debounced version of handleSearch

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const clickFunction = async (data) => {
    try {
      const test = Array.isArray(tabData) && tabData.find((item) => item?.id === data?.maincategory_id);
      const eventPathname = window.location.pathname.replace('/home/search', '/event-home');
      const listingPathname = window.location.pathname.replace('/home/search', '/listing-home');
      if (test?.name.toLowerCase() === 'events') {
        dispatch(setPreviousSubCategoryEvent(data?.category_id));
        navigate(`${eventPathname}/event/${data?.id}`);
      }
      if (test?.name.toLowerCase() === 'listing') {
        dispatch(setPreviousSubCategoryBusiness(data?.category_id));
        dispatch(setPreviousSubCategoryBusinessdata(data?.sub_category_id));
        navigate(`${listingPathname}/business/${data?.id}`);
      }
    } catch (error) {
      console.error('error');
    }
  }
  return (
    <>
      <div className="eventDetail scroll-without-category">
        <div className="top-header-fix">
          <nav
            className="navbar top-nav navbar-light bg-light text-center "

          >
            <div className="container-fluid mx-auto text-center pr-3">
              <span onClick={() => handleBackClick()} className="cursor-pointer">
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto  pr-20">
                <h1 className="f-24 f-w600 mb-0">{"Search"}</h1>
              </div>
            </div>
          </nav>
        </div>

        {/* Search Field */}

        <Row>
          <InputField
            className="mt--10 mb-4 mt-2"
            placeholder="Enter text to search"
            onChange={(e) => debouncedHandleSearch(e.target.value)}
            icon={"search"}
          />
        </Row>

        {/* Filter Search Tabs */}
        <Tabs
          variant="pills"
          // defaultActiveKey="all"
          defaultActiveKey={String(filter)}
          id="search-filter"
          className="mb-3"
          justify
          onSelect={(eventKey) => {
            setFilter(Number(eventKey));
          }}
        >
          {Array.isArray(tabData) &&
            tabData.filter((item) => item.name !== 'Explore').map((item, index) => (
              <Tab
                key={index}
                eventKey={item.id}
                className="mr-3 cursor-pointer"
                title={
                  <>
                    {" "}
                    {(item.name).toLowerCase() === 'events' &&
                      <Icons name="event" className="svg-size-24  mr-2 " />
                    }
                    {(item.name).toLowerCase() === 'listing' &&
                      <Icons name="listing" className="svg-size-24  mr-2 " />
                    }

                    {item.name}

                  </>
                }
              >
                {/* Tab content goes here */}
              </Tab>
            ))}
        </Tabs>

        {/* Searched Data */}
        <>
     
          {Array.isArray(filteredData) && filteredData.length > 0 && filteredData && !show ?
            filteredData?.slice(0, mappedCount).map((card, index) => {
              let img;
              if (card?.bannerimage) {
                try {
                  img = JSON.parse(card.bannerimage) || card.bannerimage;
                } catch (error) {
                  // Handle the case where card.bannerimage is not valid JSON
                  img = null;
                }
              } else if (card?.business_image) {
                try {
                  img = card.business_image || JSON.parse(card.business_image);
                } catch (error) {
                  // Handle the case where card.business_image is not valid JSON
                  img = null;
                }
              } else {
                img = null;
              }
              return (
                <Card className="h-60 b-btm mb-1" key={index} onClick={() => clickFunction(card)}>
                  <Row>
                    <Col className="w-60" md={2}>
                      <Card.Img
                        variant="top"
                        className="rounded-circle w-60 h-100"
                        src={img === null ? MyImage : typeof img === 'object' ? img['0'] : img}
                      />
                    </Col>
                    <Col className="pt-10">
                      <Card.Body className="p-0">
                        <Card.Title className="f-14 pl-15 text-black">
                          {sliceCharacters(card.heading ? card.heading : card?.business_name ? card?.business_name : '', cardHeadingLimit)}
                        </Card.Title>
                        <Col>
                          <Card.Text className="text-light-black f-8">
                            {formatDate(card.start_time ? card.start_time : card.created_at ? card?.created_at : '', 'shortdate')} &nbsp;&nbsp;|&nbsp;&nbsp; {sliceCharacters(card?.addressevent ? card?.addressevent : card?.address ? card?.address : " ", 35)}
                          </Card.Text>
                        </Col>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              );
            })
            :
            filteredData.length === 0 && !show ? (
              searchTerm?.length === 0 || skeletpn ? (
                <p className="text-center">Please enter text</p>
              ) : (
                <p className="text-center">No data found</p>
              )
            ) : (
              <SearchSkeleton number={3} />
            )
          }
        </>
      </div>
    </>
  );
};
