import React from 'react';

export const Infrastructure = ({
    className = "",
    width = "38px",
    height = "38px",
    viewBox = "0 0 38 38",
    fill = "",

}) => {
   

    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill}  xmlns="http://www.w3.org/2000/svg" className={className}>
<path d="M27.4279 32.5517C27.0641 32.4039 26.7306 32.2296 26.5335 31.8582C26.0636 30.9789 26.6775 29.914 27.6743 29.9102C27.86 29.9102 27.9585 29.8496 28.0684 29.7055C28.7544 28.7998 30.1301 28.7808 30.8274 29.6752C30.9677 29.8533 31.0965 29.9329 31.3163 29.914C31.4224 29.9026 31.5361 29.9405 31.6612 29.9595C31.6612 24.0435 31.6612 18.1389 31.6612 12.1963C31.5589 12.1963 31.4565 12.1963 31.3542 12.1963C29.9368 12.1963 28.5194 12.1963 27.102 12.1963C27.0224 12.1963 26.9466 12.2001 26.867 12.1963C26.5487 12.1812 26.3668 12.0182 26.3705 11.7529C26.3743 11.4914 26.5525 11.3171 26.8746 11.3133C28.5952 11.3057 30.3158 11.3057 32.0364 11.3133C32.4116 11.3133 32.5594 11.4952 32.5594 11.9007C32.5594 14.0685 32.5594 16.2401 32.5594 18.4079C32.5594 22.5654 32.5594 26.7229 32.5594 30.8766C32.5594 31.8771 32.3661 32.1765 31.4717 32.5555C30.1225 32.5517 28.7733 32.5517 27.4279 32.5517ZM29.4593 31.6687C30.0391 31.6687 30.6152 31.6725 31.195 31.6649C31.3997 31.6649 31.5892 31.5815 31.6309 31.3731C31.6574 31.2404 31.6233 31.0623 31.5513 30.9486C31.4452 30.7894 31.2595 30.7553 31.0662 30.8236C30.5962 30.9865 30.4788 30.9297 30.2779 30.4635C30.1187 30.0997 29.8269 29.895 29.4555 29.9064C29.0727 29.9178 28.8112 30.111 28.6558 30.4597C28.4512 30.9372 28.3526 30.9789 27.8448 30.816C27.6022 30.7402 27.3521 30.8577 27.2801 31.0888C27.2005 31.3352 27.337 31.5815 27.5985 31.6497C27.6818 31.6725 27.769 31.6725 27.8562 31.6725C28.3868 31.6687 28.9249 31.6687 29.4593 31.6687Z" fill=""/>
<path d="M6.00342 27.0567C6.05648 26.5792 6.09816 26.0979 6.16259 25.6204C6.28766 24.7032 6.44683 23.7937 6.88267 22.9637C6.98878 22.759 7.11764 22.5582 7.27681 22.3914C7.91351 21.7244 8.73591 21.7888 9.28544 22.5316C9.62653 22.9902 9.82361 23.5132 9.96762 24.0552C10.2898 25.2907 10.4414 26.5451 10.411 27.8223C10.3883 28.7205 9.75539 29.5164 8.8913 29.7741C8.69044 29.8347 8.64496 29.9257 8.64875 30.1189C8.66012 30.7329 8.64496 31.3469 8.65633 31.957C8.6639 32.2299 8.57674 32.4232 8.33419 32.5521C8.24702 32.5521 8.15985 32.5521 8.07648 32.5521C7.83771 32.408 7.75813 32.1958 7.76571 31.9191C7.77708 31.3128 7.76571 30.7102 7.77329 30.1038C7.77708 29.9257 7.72402 29.8499 7.54968 29.7741C7.23133 29.6301 6.92436 29.4481 6.65527 29.2283C6.2725 28.9176 6.12848 28.4514 6.00342 27.989C6.00342 27.6783 6.00342 27.3675 6.00342 27.0567ZM9.53179 27.3334C9.4901 26.1737 9.36503 25.0254 8.99741 23.9187C8.8913 23.5966 8.72454 23.2858 8.53505 23.0016C8.35693 22.7439 8.11058 22.7552 7.90214 22.9902C7.79603 23.1115 7.70507 23.2555 7.64064 23.4033C7.04184 24.7942 6.90162 26.2684 6.90162 27.7617C6.90162 28.3377 7.31471 28.8001 7.85287 28.9555C8.37967 29.1071 8.94057 28.9176 9.28165 28.4704C9.53937 28.1293 9.56211 27.7389 9.53179 27.3334Z" fill=""/>
<path d="M6.00379 11.6014C6.15539 11.3664 6.3752 11.3058 6.64428 11.3058C8.28529 11.3096 9.93009 11.3096 11.5711 11.3096C11.6734 11.3096 11.7796 11.3134 11.8781 11.3437C12.0865 11.4005 12.1926 11.5521 12.1851 11.7682C12.1813 11.9842 12.0676 12.1282 11.8553 12.1737C11.7454 12.1964 11.6317 12.1964 11.5218 12.1964C10.0779 12.1964 8.63396 12.1964 7.19002 12.1964C7.09527 12.1964 7.00432 12.1964 6.88304 12.1964C6.88304 12.3253 6.88304 12.4276 6.88304 12.5299C6.88304 15.1032 6.88304 17.6803 6.88304 20.2537C6.88304 20.3219 6.87925 20.3939 6.88304 20.4621C6.8982 20.716 6.83377 20.9358 6.56848 21.0192C6.29561 21.1064 6.13265 20.9396 6 20.7274C6.00379 17.6841 6.00379 14.6409 6.00379 11.6014Z" fill=""/>
<path d="M12.4886 32.5518C12.2536 32.4002 12.1854 32.1766 12.1967 31.9075C12.2043 31.7332 12.1967 31.5626 12.1967 31.3883C12.2005 30.9259 12.3408 30.7819 12.7993 30.7819C14.1561 30.7819 15.5129 30.7819 16.8697 30.7819C19.515 30.7819 22.1603 30.7819 24.8056 30.7819C24.9004 30.7819 24.9989 30.7743 25.0899 30.7895C25.3135 30.8274 25.4461 30.9638 25.4613 31.1912C25.4764 31.4186 25.3665 31.574 25.1467 31.6346C25.0406 31.6612 24.9231 31.665 24.8132 31.665C21.0196 31.665 17.2221 31.665 13.4285 31.665C13.3261 31.665 13.2238 31.665 13.0987 31.665C13.076 31.9947 13.1404 32.3471 12.7955 32.548C12.6932 32.5518 12.5909 32.5518 12.4886 32.5518Z" fill=""/>
<path d="M24.5892 6.8944C21.0381 6.8944 17.5211 6.8944 13.97 6.8944C13.97 7.02326 13.97 7.14075 13.97 7.25824C13.97 14.5613 13.97 21.8644 13.97 29.1675C13.97 29.2963 13.9662 29.429 13.9434 29.554C13.9017 29.7852 13.7312 29.8951 13.5152 29.8913C13.2992 29.8875 13.14 29.7663 13.1021 29.5351C13.0869 29.4328 13.0831 29.3304 13.0831 29.2243C13.0831 21.7052 13.0831 14.1861 13.0831 6.66701C13.0831 6.12127 13.2082 6 13.7577 6C17.4415 6 21.1214 6 24.8052 6C25.3547 6 25.476 6.12127 25.476 6.66701C25.476 14.1861 25.476 21.7052 25.476 29.2243C25.476 29.6753 25.3358 29.8875 25.044 29.8951C24.7408 29.9027 24.5892 29.6753 24.5892 29.2167C24.5892 21.9061 24.5892 14.5954 24.5892 7.28098C24.5892 7.1597 24.5892 7.03842 24.5892 6.8944Z" fill=""/>
<path d="M16.6309 25.4687C16.2747 25.4687 15.9411 25.4687 15.6076 25.4687C15.4788 25.4687 15.3461 25.4687 15.2211 25.4498C14.9937 25.4119 14.8724 25.2641 14.8724 25.0367C14.8686 24.8093 14.9861 24.6615 15.2097 24.6122C15.3082 24.5895 15.4144 24.5857 15.5167 24.5857C18.0294 24.5857 20.5458 24.5857 23.0585 24.5857C23.1533 24.5857 23.248 24.5895 23.3428 24.6084C23.5739 24.6539 23.7104 24.8207 23.699 25.0443C23.6839 25.283 23.555 25.4422 23.3124 25.4536C22.941 25.4725 22.5696 25.465 22.1982 25.4725C22.1224 25.4725 22.0466 25.4801 21.9329 25.4877C21.9329 25.59 21.9329 25.681 21.9329 25.7719C21.9329 26.9733 21.9329 28.1747 21.9329 29.3761C21.9329 29.6907 21.7662 29.8877 21.5123 29.8991C21.247 29.9105 21.0651 29.7058 21.0499 29.3837C21.0461 29.323 21.0499 29.2624 21.0499 29.2018C21.0499 28.061 21.0499 26.9203 21.0499 25.7795C21.0499 25.6886 21.0499 25.5938 21.0499 25.4915C19.8599 25.4915 18.6964 25.4915 17.5139 25.4915C17.5139 25.7151 17.5139 25.9197 17.5139 26.1282C17.5139 27.1742 17.5139 28.2202 17.5102 29.2624C17.5102 29.3799 17.5026 29.505 17.4647 29.6187C17.4002 29.8157 17.2373 29.9067 17.0402 29.8915C16.8318 29.8764 16.684 29.7589 16.6423 29.5466C16.6233 29.4557 16.6271 29.3571 16.6271 29.2624C16.6271 28.1065 16.6271 26.9468 16.6271 25.7909C16.6309 25.6923 16.6309 25.5976 16.6309 25.4687Z" fill=""/>
<path d="M29.9103 26.3741C29.9103 26.772 29.9141 27.17 29.9103 27.5679C29.9065 27.9545 29.7435 28.125 29.3607 28.1288C28.8415 28.1364 28.3223 28.1364 27.8031 28.1288C27.4128 28.125 27.2574 27.9658 27.2536 27.5717C27.2498 26.7607 27.2498 25.9458 27.2536 25.1348C27.2574 24.7445 27.4127 24.5891 27.8107 24.5853C28.3223 24.5777 28.8302 24.5777 29.3418 24.5853C29.7435 24.5891 29.9065 24.7558 29.9103 25.1575C29.9141 25.5631 29.9103 25.9686 29.9103 26.3741ZM28.1594 25.4873C28.1594 26.0747 28.1594 26.647 28.1594 27.223C28.455 27.223 28.724 27.223 29.0083 27.223C29.0083 26.6394 29.0083 26.0709 29.0083 25.4873C28.724 25.4873 28.4436 25.4873 28.1594 25.4873Z" fill=""/>
<path d="M22.8196 21.0649C22.8196 21.4704 22.8234 21.8759 22.8196 22.2852C22.8158 22.6528 22.6642 22.8082 22.3004 22.812C21.766 22.8158 21.2278 22.8158 20.6935 22.812C20.3334 22.8082 20.1705 22.6452 20.1667 22.2852C20.1629 21.4552 20.1629 20.6252 20.1667 19.7952C20.1705 19.4428 20.341 19.2798 20.701 19.276C21.2278 19.2722 21.7546 19.2722 22.2814 19.276C22.6566 19.2798 22.812 19.439 22.8158 19.818C22.8234 20.2349 22.8196 20.648 22.8196 21.0649ZM21.0724 20.1704C21.0724 20.773 21.0724 21.3415 21.0724 21.9138C21.368 21.9138 21.6409 21.9138 21.9176 21.9138C21.9176 21.3264 21.9176 20.7541 21.9176 20.1704C21.6296 20.1704 21.3567 20.1704 21.0724 20.1704Z" fill=""/>
<path d="M15.7436 21.0496C15.7436 20.6365 15.7398 20.2196 15.7436 19.8065C15.7474 19.4427 15.9066 19.2835 16.2666 19.2797C16.801 19.2759 17.3392 19.2759 17.8735 19.2797C18.2184 19.2835 18.389 19.4503 18.3927 19.7876C18.4003 20.6251 18.4003 21.4627 18.3927 22.304C18.389 22.6451 18.2222 22.8081 17.8773 22.8119C17.343 22.8156 16.8048 22.8156 16.2704 22.8119C15.8952 22.8081 15.7474 22.6527 15.7436 22.2661C15.7398 21.8606 15.7436 21.4551 15.7436 21.0496ZM16.6418 21.9099C16.945 21.9099 17.2179 21.9099 17.4983 21.9099C17.4983 21.3224 17.4983 20.7502 17.4983 20.1779C17.2027 20.1779 16.9223 20.1779 16.6418 20.1779C16.6418 20.7653 16.6418 21.33 16.6418 21.9099Z" fill=""/>
<path d="M11.3096 15.7361C11.3096 16.1492 11.3134 16.5661 11.3096 16.9792C11.302 17.3392 11.1428 17.5022 10.7828 17.506C10.2484 17.5136 9.71026 17.5136 9.17589 17.506C8.8348 17.5022 8.66047 17.3317 8.66047 16.9906C8.65289 16.153 8.65289 15.3154 8.66047 14.4741C8.66426 14.1406 8.82723 13.9738 9.15315 13.97C9.70648 13.9625 10.2598 13.9625 10.8131 13.97C11.1504 13.9738 11.302 14.1292 11.3096 14.4665C11.3172 14.891 11.3096 15.3154 11.3096 15.7361ZM9.55109 16.6078C9.85428 16.6078 10.1234 16.6078 10.4076 16.6078C10.4076 16.0204 10.4076 15.4519 10.4076 14.872C10.1158 14.872 9.83533 14.872 9.55109 14.872C9.55109 15.4595 9.55109 16.0241 9.55109 16.6078Z" fill=""/>
<path d="M15.7436 15.7361C15.7436 15.323 15.7398 14.9061 15.7436 14.493C15.7474 14.1292 15.899 13.9738 16.2666 13.97C16.801 13.9625 17.3392 13.9625 17.8735 13.97C18.2336 13.9738 18.3927 14.1406 18.3927 14.5006C18.3965 15.323 18.3965 16.1416 18.3927 16.964C18.3927 17.3354 18.2222 17.5022 17.8508 17.506C17.324 17.5098 16.7972 17.5136 16.2704 17.506C15.9104 17.5022 15.7474 17.3392 15.7436 16.983C15.7398 16.5661 15.7436 16.153 15.7436 15.7361ZM17.4983 16.6154C17.4983 16.0241 17.4983 15.4519 17.4983 14.872C17.2027 14.872 16.9261 14.872 16.6494 14.872C16.6494 15.4633 16.6494 16.0355 16.6494 16.6154C16.9374 16.6154 17.2027 16.6154 17.4983 16.6154Z" fill=""/>
<path d="M22.8194 15.7626C22.8194 16.1682 22.8232 16.5737 22.8194 16.983C22.8157 17.3392 22.6527 17.5022 22.2926 17.506C21.7659 17.5136 21.2391 17.5098 20.7123 17.506C20.3409 17.5022 20.1741 17.3354 20.1703 16.964C20.1665 16.1416 20.1665 15.323 20.1703 14.5006C20.1741 14.1368 20.3295 13.9738 20.6895 13.97C21.2239 13.9625 21.7621 13.9625 22.2964 13.97C22.6641 13.9738 22.8157 14.1292 22.8194 14.493C22.8232 14.9175 22.8194 15.342 22.8194 15.7626ZM21.0609 16.6078C21.3641 16.6078 21.637 16.6078 21.9175 16.6078C21.9175 16.0204 21.9175 15.4481 21.9175 14.872C21.6218 14.872 21.3452 14.872 21.0609 14.872C21.0609 15.4595 21.0609 16.0241 21.0609 16.6078Z" fill=""/>
<path d="M27.2535 15.729C27.2535 15.3159 27.2459 14.899 27.2535 14.4859C27.2611 14.1335 27.4127 13.9781 27.7613 13.9743C28.3071 13.9667 28.849 13.9667 29.3948 13.9743C29.7359 13.9781 29.9026 14.1449 29.9064 14.4897C29.914 15.3197 29.914 16.1497 29.9064 16.9797C29.9026 17.3359 29.7321 17.5065 29.3796 17.5103C28.8452 17.5178 28.3071 17.5178 27.7727 17.5103C27.4278 17.5065 27.2611 17.3435 27.2573 17.0024C27.2459 16.5742 27.2535 16.1497 27.2535 15.729ZM28.1517 14.8687C28.1517 15.4637 28.1517 16.0322 28.1517 16.6083C28.4435 16.6083 28.7202 16.6083 29.0044 16.6083C29.0044 16.0171 29.0044 15.4486 29.0044 14.8687C28.7164 14.8687 28.4435 14.8687 28.1517 14.8687Z" fill=""/>
<path d="M27.2539 21.0383C27.2539 20.6328 27.2501 20.2273 27.2539 19.818C27.2577 19.4352 27.4168 19.2798 27.7883 19.276C28.315 19.2722 28.8418 19.2722 29.3686 19.276C29.7249 19.2798 29.903 19.4428 29.903 19.7952C29.9106 20.6252 29.9106 21.4552 29.903 22.2852C29.8992 22.6452 29.7325 22.8044 29.3762 22.8082C28.8494 22.812 28.3226 22.812 27.7958 22.8082C27.4017 22.8044 27.2577 22.6566 27.2539 22.2511C27.2501 21.8494 27.2539 21.4438 27.2539 21.0383ZM29.0086 21.9138C29.0086 21.3301 29.0086 20.7579 29.0086 20.178C28.713 20.178 28.4363 20.178 28.1597 20.178C28.1597 20.7692 28.1597 21.3377 28.1597 21.9138C28.4515 21.9138 28.7244 21.9138 29.0086 21.9138Z" fill=""/>
<path d="M18.4005 10.4185C18.4005 10.824 18.4043 11.2295 18.4005 11.6388C18.3967 12.0292 18.2413 12.1884 17.8472 12.1922C17.328 12.1997 16.8088 12.1997 16.2895 12.1922C15.8954 12.1884 15.7476 12.033 15.7476 11.635C15.7438 10.824 15.7438 10.0092 15.7476 9.19816C15.7476 8.82296 15.9106 8.6562 16.282 8.65241C16.8088 8.64483 17.3355 8.64483 17.8623 8.65241C18.23 8.6562 18.3929 8.83053 18.3967 9.20194C18.4005 9.60745 18.4005 10.013 18.4005 10.4185ZM16.642 11.2902C16.9414 11.2902 17.2143 11.2902 17.4985 11.2902C17.4985 10.7027 17.4985 10.1343 17.4985 9.5544C17.2029 9.5544 16.9262 9.5544 16.642 9.5544C16.642 10.138 16.642 10.7027 16.642 11.2902Z" fill=""/>
<path d="M22.8194 10.4336C22.8194 10.8392 22.8232 11.2447 22.8194 11.654C22.8157 12.0254 22.6716 12.1846 22.3116 12.1922C21.7659 12.1997 21.2239 12.1997 20.6782 12.1922C20.3333 12.1846 20.1741 12.0178 20.1703 11.6729C20.1665 10.843 20.1665 10.013 20.1703 9.18299C20.1741 8.82675 20.3409 8.6562 20.6971 8.65241C21.2315 8.64483 21.7696 8.64483 22.304 8.65241C22.6565 8.6562 22.8157 8.82674 22.8194 9.19057C22.8232 9.60366 22.8194 10.0168 22.8194 10.4336ZM21.9175 11.2902C21.9175 10.7027 21.9175 10.1343 21.9175 9.5544C21.6256 9.5544 21.3452 9.5544 21.0685 9.5544C21.0685 10.1456 21.0685 10.7179 21.0685 11.2902C21.3603 11.2902 21.6332 11.2902 21.9175 11.2902Z" fill=""/>
<path d="M10.4265 20.1707C10.1195 20.1707 9.8466 20.1707 9.53584 20.1707C9.53584 20.3261 9.54342 20.4701 9.53205 20.6142C9.5131 20.8605 9.35013 21.0235 9.12274 21.0348C8.89914 21.0462 8.69449 20.9022 8.67554 20.6596C8.64901 20.3261 8.64901 19.985 8.67554 19.6515C8.69449 19.4166 8.86503 19.2801 9.1 19.2763C9.68743 19.2688 10.2749 19.265 10.8623 19.2763C11.1503 19.2839 11.3019 19.4583 11.3057 19.7728C11.3133 20.618 11.3133 21.4669 11.3057 22.312C11.3019 22.619 11.139 22.7933 10.8812 22.8009C10.6159 22.8085 10.4302 22.6266 10.4265 22.3158C10.4151 21.7208 10.4227 21.122 10.4227 20.527C10.4265 20.4209 10.4265 20.3072 10.4265 20.1707Z" fill=""/>
<path d="M19.7116 27.6748C19.7154 27.9211 19.5221 28.122 19.2757 28.1182C19.0408 28.1144 18.8513 27.9287 18.8475 27.6937C18.8437 27.4398 19.0294 27.2541 19.2833 27.2579C19.5296 27.2579 19.7078 27.4322 19.7116 27.6748Z" fill=""/>
</svg>



      
    );
};