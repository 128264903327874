/* eslint-disable react-hooks/exhaustive-deps */
// src/components/Polls.js

import React, { useEffect, useState } from "react";
import { Icons } from "../../components/Icons/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import "./Polls.css";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { exportApi } from "../../../services/indoreTalk.api";
import { PollPercentage } from "../../../utils/exportGlobleFunction";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useSelector } from "react-redux";
import { Button } from "../../components/form-type/button";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { Skeleton } from "../../components/cards/Skeleton";

export const Polls = ({ options }) => {
  const location = useLocation();
  const tempLogin = useSelector((state) => state.auth);
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skeletons, setSkeletons] = useState(true);
  const [pollId, setpollId] = useState();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState({
    pollId: "",
    optionValue: "",
  });
  const fetchData = async () => {
    try {
      const res = await exportApi.listPolls({
        user_id: userId?.user_id || "",
      });
      if (res.status === "success") {
        setPolls(res.data);
        setLoading(false);
      }
      setSkeletons(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setSkeletons(false);
      setLoading(false);
      // Handle errors here
    }
  };

  // In your component

  useEffect(() => {
    fetchData();
  }, []); // Or add dependencies if needed

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleRadioClick = (item, option) => {
    setSelectedValue({
      pollId: item?.id,
      optionValue: option,
    });
  };
  const [showLoginModal, setLoginModalShow] = useState(false);

  const pollsSubmit = async (e, item) => {
    e.preventDefault();
    setLoading(true);
    if (!!userId) {
      try {
        const res = await exportApiAuth.pollSend({
          user_id: userId?.user_id,
          poll_id: item.id,
          option: selectedValue?.optionValue,
        });
        if (res.status === "success") {
          fetchData();
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    } else {
      setLoginModalShow(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="eventDetail scroll-without-category">
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center pr-3">
              <span
                onClick={() => handleBackClick()}
                className="cursor-pointer"
              >
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto pr-20">
                <h1 className="f-24 f-w600 mb-0">{"Poll’s"}</h1>
              </div>
            </div>
          </nav>
        </div>

        <div className="mt-5 pb-4">
          {skeletons && <Skeleton number={5} />}
          {polls &&
            polls.map((item, index) => {
              return (
                <>
                  <div className="poll-container mb-5" key={index}>
                    <div className="text-center">
                      <p className="f-w600 f-20">{item.question}</p>
                    </div>
                    {/* *******************************(1)polls ui************* */}
                    {item.is_answered === 0 ? (
                      <>
                        <Form
                          onSubmit={(e) => {
                            pollsSubmit(e, item);
                          }}
                        >
                          {Array.isArray(item.optionData) &&
                            item.optionData.map((option, optionIndex) => (
                              <div
                                key={`inline-${optionIndex}`}
                                className="mb-3"
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <div
                                      className={`polls-options ${
                                        selectedValue.optionValue ===
                                          option.option &&
                                        selectedValue.pollId === item.id
                                          ? "active1"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setpollId(item.id);
                                        handleRadioClick(item, option.option);
                                      }}
                                    >
                                      <Form.Check
                                        type="radio"
                                        id="option1"
                                        className="form-check-poll-radio"
                                        label=""
                                        checked={
                                          selectedValue.optionValue ===
                                            option.option &&
                                          selectedValue.pollId === item.id
                                        }
                                      />
                                      <span className="polls-options-span f-w400 font-14 text-white">
                                        {option.option}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <div className="d-flex justify-content-center">
                            <Button
                              className={`btn text-center text-white poll-submit-btn btn-bg-red  ${
                                item.id === pollId && loading
                                  ? "btn-loading"
                                  : ""
                              }`}
                              disabled={!(item.id === pollId)}
                              label="Submit"
                              type={!(item.id === pollId) ? "submit" : ""}
                            />
                          </div>
                        </Form>
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-12 ">
                          {Array.isArray(item.optionData) &&
                            item.optionData.map((option, optionIndex) => (
                              <div
                                className="polls-results-adjust"
                                key={optionIndex}
                              >
                                <Form.Check>
                                  <ProgressBar
                                    className={`polls-results  ${
                                      option.is_selected === 1 ? `active1` : ""
                                    } polls-progress-bar relative`}
                                    now={PollPercentage(
                                      item.total_votes,
                                      option.count
                                    )}
                                  />
                                </Form.Check>
                                <div className="row polls-span-and-percentage">
                                  <div className="col-9">
                                    <span className="polls-results-span f-w400 font-14 text-white">
                                      {option.option}
                                    </span>
                                  </div>
                                  <div className="col-3 ">
                                    <span className="polls-result-percentage f-w400 font-14 text-right d-block text-white ">
                                      {PollPercentage(
                                        item.total_votes,
                                        option.count
                                      ) + "%"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                </>
              );
            })}
        </div>
      </div>

      <Footer />
      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
        />
      )}
    </>
  );
};

export default Polls;
