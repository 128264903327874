import React, { useState } from "react";
import { Button } from "../../components/form-type/button";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";
import InputField from "../../components/form-type/InputField";
import "./ListYourBusiness.css";
import { Toast } from "react-bootstrap";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { unnecatchLogout } from "../../../utils/exportGlobleFunction";
import store from 'store'
import { setSignOut } from "../../../redux/slices/authSlice";

const ListYourBusiness = ({ header }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch();
  const [successToast, setSuccessToast] = useState(null);
  const tempLogin = useSelector((state) => state.auth);
  const { subListingCategory } = useSelector((state) => state.subCat);
  const userData = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [showLoginModal, setLoginModalShow] = useState(false);
  const [formData, setFormData] = useState({
    user_id: userData?.user_id,
    login_type: userData?.login_type,
    company_name: "",
    contact_number: "",
    complete_name: "",
    address: "",
    business_category: "",
    describe: "",
    errors: {},
  });
  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();

    const errors = {};
    if (!formData?.company_name) {
      errors.company_name = "Company Name can't be empty";
    } else if (formData?.company_name.length < 3) {
      errors.company_name = "Company Name must be at least 3 characters long";
    }
    if (!formData?.complete_name) {
      errors.complete_name = "Name can't be empty";
    } else if (formData?.complete_name.length < 3) {
      errors.complete_name = "Name must be at least 3 characters long";
    }
    if (!formData?.contact_number.trim()) {
      errors.contact_number = "Mobile number can't be empty";
    } else if (!/^\+91[6-9]\d{9}$/.test(formData?.contact_number)) {
      errors.contact_number = "Mobile number is invalid";
    }
    if (!formData?.address.trim()) {
      errors.address = "Address can't be empty";
    } else if (!/^[\S\s]{5,200}$/.test(formData?.address)) {
      errors.address = "Address must be atleast 5 char";
    }
    if (!formData?.business_category) {
      errors.business_category = "Please select category";
    }
    if (formData?.describe.length < 5) {
      errors.describe = "Description must be atleast 5 char";
    }

    if (Object.keys(errors).length > 0) {
      // If there are errors, set them and return
      setFormData((prevData) => ({
        ...prevData,
        errors: errors,
      }));
      setTimeout(() => {
        setFormData((prevData) => ({
          ...prevData,
          errors: {
            ...prevData.errors,
            company_name: null,
            complete_name: null,
            contact_number: null,
            address: null,
            business_category: "",
            describe: null,
          },
        }));
      }, 3000);
      return;
    }

    try {
      if (!userData) {
        // Handle the case where userData is not available
        console.error("User data is not available");
        return;
      }

      const res = await exportApiAuth.ListYourBusiness({
        user_id: userData?.user_id,
        login_type: userData?.login_type,
        company_name: formData?.company_name,
        contact_number: formData?.contact_number?.startsWith("+91")
          ? formData?.contact_number.slice(3)
          : formData?.contact_number,
        complete_name: formData?.complete_name,
        address: formData?.address,
        business_category: formData?.business_category,
        describe: formData?.describe,
      });

      if (res.status === "success") {
        setSuccessToast({
          message: "Connect successfully",
        });
        setFormData({
          company_name: "",
          contact_number: "",
          complete_name: "",
          address: "",
          business_category: "",
          describe: "",
        });
        setSuccessToast({
          message: "Thank You for The Submission",
        });
      } else {
        setLoginModalShow(true)
        unnecatchLogout(store, dispatch, setSignOut)
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error, e.g., show error toast
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "contact_number") {
      if (/^\+91\d*$/.test(value) && value.length <= 13) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "describe") {
      if (value.length <= 60) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <div className="form-div">
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center ">
              <span
                onClick={() => handleBackClick()}
                className="cursor-pointer"
              >
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto pr-24">
                <h1 className="f-24 f-w600 mb-0">{"List Your Business"}</h1>
              </div>
            </div>
          </nav>
        </div>
        <div>
          <div className="listyourbusiness-main-icon d-flex justify-content-center align-items-center">
            <Icons name="list-your-business1" className="" />
          </div>
        </div>

        <div>
          <div className="">
            <div className="inputfield-height-with-validation-error">
              <InputField
                label={
                  <span className="text-disabled">
                    Company Name <span className="text-red">*</span>
                  </span>
                }
                type="text"
                placeholder="Company Name"
                name="company_name"
                value={formData?.company_name}
                onChange={handleInputChange}
              />
              {formData?.errors?.company_name && (
                <p className="text-red mb-0">
                  {formData?.errors?.company_name}
                </p>
              )}
            </div>
            <div className="inputfield-height-with-validation-error">
              <InputField
                name="contact_number"
                label={
                  <span className="text-disabled">
                    Contact No. <span className="text-red">*</span>
                  </span>
                }
                type="text"
                placeholder="Contact No."
                value={
                  formData?.contact_number.startsWith("+91")
                    ? formData?.contact_number
                    : "+91"
                }
                onChange={(e) => {
                  if (e.target.value.startsWith("+91")) {
                    handleInputChange(e);
                  }
                }}
              />
              {formData?.errors?.contact_number && (
                <p className="text-red mb-0">
                  {formData?.errors?.contact_number}
                </p>
              )}
            </div>
            <div className="inputfield-height-with-validation-error">
              <InputField
                label={
                  <span className="text-disabled">
                    Your Full Name <span className="text-red">*</span>
                  </span>
                }
                type="text"
                placeholder="Your Full Name"
                name="complete_name"
                value={formData?.complete_name}
                onChange={handleInputChange}
              />
              {formData?.errors?.complete_name && (
                <p className="text-red mb-0">
                  {formData?.errors?.complete_name}
                </p>
              )}
            </div>
            <div className="inputfield-height-with-validation-error">
              <InputField
                label={
                  <span className="text-disabled mb-2">
                    Address <span className="text-red">*</span>
                  </span>
                }
                type="text"
                placeholder="Address"
                value={formData?.address}
                onChange={handleInputChange}
                name="address"
              />
              {formData?.errors?.address && (
                <p className="text-red mb-0">{formData?.errors?.address}</p>
              )}
            </div>
            <div className="choosearea-with-validation-popup-height mt-2 mb-0">
              <select
                name="business_category"
                className={`form-select form-select-lg mb-2 f-w4500 font-16  ${formData?.business_category !== "Business Category" &&
                  formData?.business_category !== ""
                  ? "white"
                  : ""
                  }`}
                aria-label=".form-select-lg example"
                value={formData?.business_category}
                onChange={handleInputChange}
              >
                <option style={{ backgroundColor: "black" }}>
                  {"Business Category"}
                </option>
                {Array.isArray(subListingCategory) &&
                  subListingCategory.map((sub, subIndex) => (
                    <option
                      style={{ backgroundColor: "black" }}
                      key={subIndex}
                      value={sub.title}
                    >
                      {sub.title}
                    </option>
                  ))}
                <option style={{ backgroundColor: "black" }} value={"other"}>
                  {"Other"}
                </option>
              </select>
              {formData?.errors?.business_category && (
                <p className="text-red mb-0">
                  {formData?.errors?.business_category}
                </p>
              )}
            </div>
            <div className="inputfield-height-with-validation-error">
              <div className="form-floating">
                <InputField
                  label={
                    <span className="text-disabled">
                      Describe <span className="text-red">*</span>
                    </span>
                  }
                  type="text"
                  placeholder="Describe Your Query"
                  value={formData?.describe}
                  onChange={handleInputChange}
                  name="describe"
                />
                {formData?.errors?.describe && (
                  <p className="text-red mb-0">{formData?.errors?.describe}</p>
                )}
               
              </div>
            </div>
            <p className="character-only-p text-right f-w4500 mb-0 f-12">
                  60 characters only
                </p>
            {successToast && (
              <Toast
                onClose={() => setSuccessToast(null)}
                show={true}
                delay={3000}
                className="mb-0"
                autohide
              >
                <Toast.Body
                  style={{ backgroundColor: "#C5FEB5", color: "#196004" }}
                >
                  {successToast.message}
                </Toast.Body>
              </Toast>
            )}

            {showLoginModal &&
              <GoToLogin show={showLoginModal} onHide={() => setLoginModalShow(false)} location={location} />}

            <div className="form-submit-btn mt-2">
              <Button
                onClick={(event) => handleSubmitClick(event)}
                className="btn btn-secondary w-100 mt-2 mb-3 p-2 z-index-999 btn-bg-red"
                label="Submit"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListYourBusiness;
