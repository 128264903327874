import React from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";

const TermAndCond = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="top-header-fix">
        <nav
          className="navbar top-nav navbar-light bg-light text-center "
      
        >
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto pr-24">
              <h1 className="f-24 f-w600 mb-0">{"Terms & Condition"}</h1>
            </div>
          </div>
        </nav>
      </div>
      <div></div>

      <div>
        <div className="scroll-without-category">
          <strong className=" f-14 f-w600 ">Terms</strong>
          <p className=" f-14 f-w400 ">
            By accessing the Indore Talk App, you are agreeing to be bound by
            these terms of service, all applicable laws, and regulations, and
            agree that you are responsible for compliance with any applicable
            local laws. If you do not agree with any of these terms, you are
            prohibited from using or accessing this site. The materials
            contained in this App are protected by applicable copyright and
            trademark law.
          </p>
          <strong className=" f-14 f-w600 ">Use License</strong>
          <p className=" f-14 f-w400 ">
            Permission is granted to temporarily download one copy of the
            materials (information or software) on Indore Talk's App for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not modify or copy the materials; use the materials for any
            commercial purpose, or for any public display (commercial or
            non-commercial); attempt to recompile or reverse engineer any
            software contained on Indore Talk’s App; remove any copyright or
            other proprietary notations from the materials; or transfer the
            materials to another person or “mirror” the materials on any other
            server. This license shall automatically terminate if you violate
            any of these restrictions and may be terminated by Indore Talk at
            any time. Upon terminating your viewing of these materials or upon
            the termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
          </p>
          <strong className=" f-14 f-w600 ">Disclaimer</strong>
          <p className=" f-14 f-w400 ">
            The materials on Indore Talk App are provided on an 'as is' basis.
            Indore Talk makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. Further, Indore
            Talk does not warrant or make any representations concerning the
            accuracy, likely results, or reliability of the use of the materials
            on its App or otherwise relating to such materials or on any sites
            linked to this site.
          </p>
          <strong className=" f-14 f-w600 ">Limitations</strong>
          <p className=" f-14 f-w400 ">
            In no event shall Indore Talk or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on Indore Talk App, even if Indore
            Talk or a Indore Talk authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>
          <strong className=" f-14 f-w600 ">Accuracy of Materials</strong>
          <p className=" f-14 f-w400 ">
            The materials appearing on Indore Talk's App could include
            technical, typographical, or photographic errors. Indore Talk does
            not warrant that any of the materials on its App are accurate,
            complete or current. Indore Talk may make changes to the materials
            contained on its App at any time without notice. However, Indore
            Talk does not make any commitment to update the materials.
          </p>
          <strong className=" f-14 f-w600 ">Links</strong>
          <p className=" f-14 f-w400 ">
            Indore Talk has not reviewed all of the sites linked to its App and
            is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by Indore Talk of
            the site. Use of any such linked App is at the user’s own risk.
          </p>
          <strong className=" f-14 f-w600 ">Modifications</strong>
          <p className=" f-14 f-w400 ">
            Indore Talk may revise these terms of service for its App at any
            time without notice. By using this App, you are agreeing to be bound
            by the then current version of these terms of service.
          </p>
          <strong className=" f-14 f-w600 ">Governing Law</strong>
          <p className=" f-14 f-w400 ">
            These terms and conditions are governed by and construed in
            accordance with the laws of Madhya Pradesh and you irrevocably
            submit to the exclusive jurisdiction of the courts in that State or
            location.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermAndCond;
