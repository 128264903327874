import React from 'react'

export const Views = ({
    className = "",
    width = "20px",
    height = "20px",
    viewBox = "0 0 20 20",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.73906 12.1276C2.5036 7.70061 6.37223 4.75 10 4.75C13.6278 4.75 17.4964 7.70061 18.2609 12.1276C18.3314 12.5358 18.7195 12.8096 19.1276 12.7391C19.5358 12.6686 19.8095 12.2805 19.7391 11.8724C18.8528 6.74047 14.3722 3.25 10 3.25C5.62777 3.25 1.14721 6.74047 0.26094 11.8724C0.19045 12.2805 0.464195 12.6686 0.872366 12.7391C1.28054 12.8096 1.66857 12.5358 1.73906 12.1276ZM13 11.5C13 13.1569 11.6569 14.5 10 14.5C8.34315 14.5 7 13.1569 7 11.5C7 9.84315 8.34315 8.5 10 8.5C11.6569 8.5 13 9.84315 13 11.5ZM14.5 11.5C14.5 13.9853 12.4853 16 10 16C7.51472 16 5.5 13.9853 5.5 11.5C5.5 9.01472 7.51472 7 10 7C12.4853 7 14.5 9.01472 14.5 11.5Z" fill=""/>
</svg>


    )
}

