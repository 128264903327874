import React from 'react';

export const Standup = ({
    className = "",
    width = "38px",
    height = "38px",
    viewBox = "0 0 38 38",
    fill = "",

}) => {


    return (

        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M19.9594 3C20.3103 3.07721 20.6707 3.13103 21.0146 3.23866C23.7124 4.08799 25.3034 5.90598 25.7526 8.70433C25.7994 8.99914 25.8088 9.30331 25.8088 9.6028C25.8135 12.6632 25.8111 15.7236 25.8111 18.7864C25.8111 22.0714 23.6094 24.72 20.3735 25.3283C16.9855 25.9671 13.4923 23.5758 12.8699 20.1738C12.788 19.7293 12.7459 19.2683 12.7436 18.8168C12.7319 15.7353 12.7365 12.6538 12.7389 9.57238C12.7412 6.3365 15.0155 3.63407 18.1975 3.07721C18.3286 3.05381 18.4596 3.02574 18.5883 3C19.0445 3 19.5008 3 19.9594 3ZM23.93 15.1246C22.987 15.1246 22.0698 15.1246 21.1597 15.1246C21.1597 14.4929 21.1597 13.8963 21.1597 13.2645C22.0932 13.2645 23.0104 13.2645 23.9183 13.2645C23.9183 12.3146 23.9183 11.3974 23.9183 10.4568C22.9847 10.4568 22.0745 10.4568 21.1644 10.4568C21.1644 9.82742 21.1644 9.22376 21.1644 8.58968C22.0605 8.58968 22.9356 8.58968 23.813 8.58968C23.5135 6.4605 21.2603 4.72674 19.0235 4.87649C16.899 5.01921 14.7581 6.87698 14.7534 8.61308C15.6308 8.61308 16.5059 8.61308 17.381 8.61308C17.381 9.24716 17.381 9.85315 17.381 10.4802C16.4474 10.4802 15.5396 10.4802 14.6247 10.4802C14.6247 11.4208 14.6247 12.3356 14.6247 13.2879C15.5606 13.2879 16.4778 13.2879 17.388 13.2879C17.388 13.9197 17.388 14.5163 17.388 15.148C16.4544 15.148 15.5372 15.148 14.6294 15.148C14.6294 16.098 14.6294 17.0152 14.6294 17.9557C15.563 17.9557 16.4708 17.9557 17.3833 17.9557C17.3833 18.5851 17.3833 19.1888 17.3833 19.8229C16.4872 19.8229 15.6121 19.8229 14.7347 19.8229C15.0225 21.938 17.3084 23.6975 19.5242 23.5384C21.6557 23.3863 23.7872 21.5332 23.7942 19.7995C22.9168 19.7995 22.0418 19.7995 21.1667 19.7995C21.1667 19.1654 21.1667 18.5594 21.1667 17.9323C22.1003 17.9323 23.0081 17.9323 23.93 17.9323C23.93 16.9988 23.93 16.0909 23.93 15.1246Z" fill=""/>
<path d="M26.7167 34.9782C21.7423 34.9782 16.7961 34.9782 11.8264 34.9782C11.8264 34.3652 11.8264 33.7615 11.8264 33.1321C13.9837 33.1321 16.1363 33.1321 18.3169 33.1321C18.3169 31.8195 18.3169 30.5327 18.3169 29.2318C15.1115 28.841 12.5588 27.3576 10.7361 24.6833C9.57324 22.9799 9.01404 21.0824 9 19C9.63173 19 10.2284 19 10.8578 19C10.9537 21.7726 12.0557 24.0469 14.2808 25.7245C15.8508 26.9084 17.6454 27.4582 19.6038 27.374C21.8429 27.2781 23.7662 26.4147 25.3245 24.7956C26.8734 23.1858 27.6245 21.2368 27.683 19C28.303 19 28.8996 19 29.4986 19C29.763 22.9308 26.5669 28.5486 20.2285 29.2271C20.2285 30.5046 20.2285 31.7868 20.2285 33.1017C22.3787 33.1017 24.5407 33.1017 26.7167 33.1017C26.7167 33.7522 26.7167 34.3652 26.7167 34.9782Z" fill=""/>
</svg>





    );
};
