import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Icons } from "../Icons/Icon";
import { Carousel, Stack } from "react-bootstrap";
import { shareOnMobile } from "react-mobile-share";
import { eventLikeCount, eventViewCount, getPlatform } from "../../../utils/constGlobleData";
import { formatLikeCount, shareBlockFunction } from "../../../utils/exportGlobleFunction";


export const EventDetailsCard = (propsevent) => {
  // const dispatch = useDispatch();
  const event = propsevent.event;
  const myBookingShow = propsevent.myBookingShow;
 
  const handleLikeClick = (e, item) => {
    // Dispatch the setPreviousSubCategoryExplore(0) action
    // dispatch(setPreviousSubCategoryEvent(0));
    // Call the props.handleLikeClick function
    if (!propsevent.liketnDisabled) {
      propsevent?.setTempLike(item.id);
      propsevent?.setLiketnDisabled(true);
      propsevent.setLikeStates((prevStates) => ({
        ...prevStates,
        [item.id]: !prevStates[item.id], // Toggle the like state
      }));
      propsevent?.handleLikeClick(e, item);
    }
  };
  const [shareBlock, setShareBlock] = useState(false);

  const handleShareLink = async (e, item) => {
    e.stopPropagation();
    const dummyUrl = "https://admin.indoretalk.com/dummy/blank.jpg";
    shareBlockFunction(setShareBlock)
    if (!shareBlock) {
      const bannerImageUrl =item.videourl && item.bannerimage[0] === '/dummy/blank.jpg' ? dummyUrl : item.bannerimage[0];;
      const shareParams = {
        imageUrl: bannerImageUrl,
        title: item.heading,
        description: item.aboutevent.replace(/<[^>]+>/g, ''),
        url: window.location.href,
      };
      if (getPlatform() === "Android") {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          // // eslint-disable-next-line no-undef
          const { title, url, description, imageUrl } = shareParams;
          // eslint-disable-next-line no-undef
          Android.callShareIntent(title, url, description, imageUrl);
        }
      }
      else if(getPlatform() === "Mac OS" || getPlatform() === "iOS"){
     
        window.webkit.messageHandlers.share.postMessage(shareParams);
      } 
      else {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          shareOnMobile(shareParams); 
        }
      }
    }
  };
 
  
  return (
    <>
      {event.business_image && (
        <Card>
          {Array.isArray(event?.business_image) ? (
            <Carousel interval={2500} controls={false}>
              {event.business_image.map((image, index) => (
                <Carousel.Item key={index}>
                  <div className="dark-image">
                    <Card.Img
                      variant="top"
                      style={{ height: "25vh" }}
                      src={image.business_image}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div className="dark-image">
              <Card.Img
                variant="top"
                style={{ height: "25vh" }}
                src={event.business_image ? event.business_image : ""}
              />
            </div>
          )}
          <Card.Body>
            <h2 className="text-dark">{event.business_name}</h2>
            {!myBookingShow && (
              <div className="d-flex align-items-center">
                <Stack direction="horizontal" gap={1}>
                  {propsevent?.liketnDisabled &&
                    propsevent.likeStates[event.id] ? (
                    <>
                      <Icons
                        name="like"
                        onClick={(e) => handleLikeClick(e, event)}
                        className={`img-fluid  ${propsevent.tempLike.id === event.id
                            ? propsevent.tempLike.like === 0
                              ? "svg-red"
                              : "svg-white"
                            : propsevent.tempLike.like === 1
                              ? "svg-white"
                              : "svg-red"
                          }`}
                      />
                      <h5 className="f-14 f-w500 mb-0 text-black">
                        {propsevent.tempLike.id === event.id
                          ? propsevent.tempLike.like !== 0
                            ? event.like_count - 1 + eventLikeCount
                            : event.like_count + 1 + eventLikeCount
                          : event.like_count + eventLikeCount}
                      </h5>
                    </>
                  ) : (
                    <>
                      <Icons
                        name="like"
                        onClick={(e) => handleLikeClick(e, event)}
                        className={`img-fluid ${event.like === 1 ? "svg-red" : "svg-white"
                          }`}
                      />
                      <h4 className="f-14 f-w500 mb-0 pl-2 text-dark m-t-2 lh-18">
                        {formatLikeCount(event.like_count + eventLikeCount)}
                      </h4>
                    </>
                  )}
                </Stack>
              </div>
            )}
          </Card.Body>
        </Card>
      )}

      {event.bannerimage && (
        <Card>
          {Array.isArray(event?.bannerimage) ? (
            <Carousel
              interval={2500}
              controls={event.bannerimage.length > 1 ? true : false}
            >
              {event.bannerimage.map((image, index) => (
                <Carousel.Item key={index}>
                  <div className="dark-image">
                    <Card.Img
                      variant="top"
                      style={{ height: "25vh", width: "100%" }}
                      src={image}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div className="dark-image">
              <Card.Img
                variant="top"
                style={{ height: "25vh" }}
                src={event.bannerimage ? event.bannerimage : ""}
              />
            </div>
          )}
          <Card.Body>
            <h2 className="text-dark">{event.heading}</h2>
            <div className="d-flex align-items-center">
              <Stack direction="horizontal" gap={1}>
                {!myBookingShow && (
                  <>
                    <Icons
                      name="like"
                      onClick={(e) => handleLikeClick(e, event)}
                      className={`img-fluid  ${event.like === 1 || propsevent.tempLike === event.id
                          ? "svg-red"
                          : "svg-white"
                        }`}
                    />
                    <h5
                      className="mt-1 f-14 f-w500 mb-0 text-black mr-3"
                   
                    >
                      {propsevent?.liketnDisabled && event.like_count < 1000
                        ? event.like === 1
                          ? event.like_count - 1 + eventLikeCount
                          : event.like_count + 1 + eventLikeCount
                        : formatLikeCount(event.like_count + eventLikeCount)}
                    </h5>
                  </>
                )}
                <span onClick={async (e) => handleShareLink(e, event)}  style={{ minWidth: "20px" }}>
                  <Icons name="share" className="img-fluid  svg-white ms-2 mr-3" />
                </span>

                <>
                    <Icons
                      name="show"
                      className= "svg-white ms-2 mt-1"
                    />
                    <h5
                      className="mt-1 f-14 f-w500 mb-0 text-like"
                     
                    >
                      { formatLikeCount(event.view_count + eventViewCount)}
                    </h5>
                  </>
              </Stack>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
