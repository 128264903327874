import React from 'react'

export const Plus = ({
    className = "",
    width = "24px",
    height = "24px",
    viewBox = "0 0 24 24",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect opacity="0.1" x="0.878906" width="24" height="24" rx="12" fill="" />
            <path d="M12.879 7.20007L12.879 16.8001" stroke="#E31E24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.6789 12.0001L8.07895 12.0001" stroke="#E31E24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}