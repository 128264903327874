/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { setSubSubCat } from '../../../redux/slices/singleDataSlice';

const DisplaySubCat = ({ activeSubCat, subCatNav2Change, data, activeNavMain, subSubCat, dispatch }) => {
    const containerRef = useRef(null);
    // Call the scroll function when the component mounts or when the active category changes
    useEffect(() => {
        scrollActiveCategoryIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeNavMain])
    useEffect(() => {
        scrollActiveCategoryIntoView()
    }, [activeSubCat])
    const scrollActiveCategoryIntoView = () => {
        setTimeout(() => {
            const links = document.querySelector(`#nav-scroll${activeNavMain}`);
            if (links) {
                links?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
        }, 50)
    }
    return (
        data?.sub_category && (
            <div style={{ margin: 'auto' }} ref={containerRef}>
                {Array.isArray(data.sub_category) && data.sub_category.map((item, index) => (
                    <button
                        type="button"
                        id={`nav-scroll${item.id}`}
                        className={`btn sub-categ-btn-plain ${((subSubCat !== 0 ? subSubCat : activeNavMain) === item.id) ? 'active' : ''} `}
                        onClick={(e) => {
                            dispatch(setSubSubCat(0))
                            subCatNav2Change(item);
                        }}
                        key={index}
                    >
                        {item?.title}
                    </button>
                ))}
            </div>
        )
    )
};

export default DisplaySubCat;
