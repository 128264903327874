import React from "react";

export const Submit = ({
  className = "",
  width = "33px",
  height = "32px",
  viewBox = "0 0 32 33",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
   <rect x="0.962891" y="0.00088501" width="32" height="32" rx="16" fill="#E31E24"/>
<g clip-path="url(#clip0_3966_11274)">
<path d="M19.5631 24.1181C18.9691 24.1181 18.4201 23.8211 18.1051 23.2991L14.9011 18.1061L9.65414 14.8211C9.06914 14.4611 8.76314 13.8041 8.86214 13.1201C8.95214 12.4361 9.42914 11.8871 10.0861 11.6981L22.8751 7.95413C23.4781 7.77413 24.1351 7.94513 24.5761 8.39513C25.0261 8.84513 25.1881 9.50213 25.0081 10.1141L21.2281 22.8941C21.0301 23.5601 20.4811 24.0281 19.7971 24.1181C19.7161 24.1181 19.6441 24.1181 19.5631 24.1181ZM16.2511 17.7191L19.2571 22.5881C19.3741 22.7771 19.5541 22.7771 19.6261 22.7681C19.6981 22.7591 19.8781 22.7141 19.9411 22.4981L23.7211 9.71813C23.7751 9.52013 23.6761 9.38513 23.6221 9.34013C23.5771 9.29513 23.4421 9.18713 23.2531 9.24113L10.4641 12.9851C10.2481 13.0481 10.2031 13.2281 10.1941 13.3001C10.1851 13.3721 10.1851 13.5611 10.3741 13.6781L15.3061 16.7561L19.6621 12.3551C19.9231 12.0941 20.3551 12.0851 20.6161 12.3461C20.8771 12.6071 20.8861 13.0391 20.6251 13.3001L16.2511 17.7191Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_3966_11274">
<rect width="18" height="18" fill="white" transform="translate(7.96289 7.00089)"/>
</clipPath>
</defs>
</svg>

  );
};
