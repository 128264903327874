/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "../../components/form-type/button";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";
import InputField from "../../components/form-type/InputField";
import "./Subscription.css";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { exportApi } from "../../../services/indoreTalk.api";
import {
  setListingScriptionPlain,
  setVerifyCoupon,
} from "../../../redux/slices/formSlice";
import {
  calculateFinalAmount,
  displayRazorpay,
  formatDate,
  subscibeFilterData,
} from "../../../utils/exportGlobleFunction";
import { PaymentStatus } from "../Payment/PaymentStatus";

const Subscription = ({ header }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { scriptionPlainList } = useSelector((state) => state.formData);
  const [errorfail, setErrorFail] = useState("");
  const [isSelected, setIsSelected] = useState({});
  const [actualAmount, setActualAmount] = useState();
  const tempLogin = useSelector((state) => state.auth);
  const userData = tempLogin?.tempLogin?.user?.data?.social_provider;
  const userDetials = tempLogin?.tempLogin?.user?.data;
  const [couponInput, setCouponInput] = useState({
    code: "",
    user_id: userData?.user_id,
  });
  const [payData, setPayData] = useState({
    user_id: userData?.user_id,
    login_type: userData?.login_type,
    subscription_id: "",
    code: "",
    discounted_amount: "",
    final_amount: "",
    business_listing_id: "",
    transaction_status: "in_progress",
    payment_type: "razorpay",
    currency: "INR",
  });
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState({});
  const [activePage, setActivePage] = useState();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [subDataShow, setSubDataShow] = useState({});
  const currentDate = new Date();
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleSubmitClick = async (event) => {
    displayRazorpay(
      "listingBusiness",
      payData,
      setLoader,
      navigate,
      setShow,
      setStatus,
      userData?.user_id,
      userData,
      userDetials
    );
  };

  const listBusinessForm = (event) => {
    navigate("/more/list-your-business/form");
  };

  useEffect(() => {
    statusCheck();
  }, []);

  const statusCheck = async () => {
    try {
      const res = await exportApiAuth.subScriptionCheck({
        login_type: userData?.login_type,
        user_id: userData?.user_id,
      });
      if (res.status === "success") {
        setSubDataShow(res?.data[0]);
        if (
          !res?.data[0]?.transaction_id &&
          !res?.data[0]?.business_listing_id
        ) {
          setActivePage(1);
        } else if (!res?.data[0]?.transaction_id) {
          setActivePage(2);
        } else if (!res?.data[0]?.business_listing_id) {
          setActivePage(3);
        } else {
          setActivePage(4);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await exportApi.scriptionPlain();
        if (res.status === "success") {
          dispatch(setListingScriptionPlain(res?.data));
          setIsSelected(res?.data[0]);
          handleSubClick(res?.data[0]);
        }
      } catch (error) {
        console.error(error);
        setErrorFail(error?.response?.data?.data);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const couponCodeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCouponInput({ ...couponInput, [name]: value });
    setPayData({ ...payData, [name]: value });
  };

  const handleSubClick = (item) => {
    setErrorFail("");
    try {
      setIsSelected(item);
      setActualAmount(item?.amount);
      setPayData({
        ...payData,
        subscription_id: item.id,
      });
      setPayData({
        user_id: userData?.user_id,
        login_type: "google",
        discounted_amount: 0,
        final_amount: item.amount,
        code: "",
        subscription_id: item.id,
        business_listing_id: "",
        transaction_status: "in_progress",
        payment_type: "razorpay",
        currency: "INR",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const coupenOnBlur = async () => {
    setErrorFail("");
    if (couponInput.code) {
      try {
        const res = await exportApiAuth.verifyCoupon(couponInput);
        console.log("res", res);
        if (res.status === "success") {
          dispatch(setVerifyCoupon(res.data));
          console.log("res.data", res.data);
          if (res.data) {
            const amountFinal = calculateFinalAmount(res.data, actualAmount);
            setPayData({
              user_id: userData?.user_id,
              login_type: "google",
              discounted_amount: amountFinal?.discounted_amount,
              final_amount: amountFinal?.final_amount,
              code: amountFinal?.code,
              subscription_id: isSelected.id,
              business_listing_id: "",
              transaction_status: "in_progress",
              payment_type: "razorpay",
              currency: "INR",
            });
          }
        } else {
          setErrorFail(res.massage);
        }
      } catch (error) {
        setErrorFail(error.response.data.data);
        console.error(error);
      }
    }
  };
  return (
    <>
      {show && <PaymentStatus show={show} />}
      <div className="top-header-fix">
        <nav
          className="navbar top-nav navbar-light bg-light text-center "
          style={{ position: "fixed" }}
        >
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()}>
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto ">
              <h1 className="f-24 f-w600 mb-0">{"Subscription"}</h1>
            </div>
            <div>
              <span onClick={() => navigate("/home/search")}>
                <Icons name="search" className="svg-size-24 svg-white" />
              </span>
              <span
                onClick={() => navigate("/home/notification")}
                className="pl-2"
              >
                <Icons name="notification" className="svg-size-24 svg-white" />
              </span>
            </div>
          </div>
        </nav>
      </div>
      <div>
        <div className="listyourbusiness-main-icon d-flex justify-content-center mt-5 align-items-center">
          <Icons name="list-your-business1" className="" />
        </div>
      </div>

      <div>
        <div className="mt-5">
          {/* ***********************************page 1************************************* */}
          {activePage === 1 && (
            <>
              <div className="subscription-container">
                <div>
                  {scriptionPlainList &&
                    Array.isArray(scriptionPlainList) &&
                    scriptionPlainList.map((item, index) => (
                      <>
                        <div
                          className="row mt-3 d-flex align-items-center p-3 "
                          onClick={() => handleSubClick(item)}
                          key={index}
                        >
                          <div className="col-10">
                            <span className="subscription-price f-w500 f-18">
                              {item.title}
                            </span>
                            <br />
                            <span className="text-red f-w500 f-16">
                              ₹ {item.amount}
                              <span className="text-white f-w500 f-16">
                                /{item.duration}
                              </span>
                            </span>
                          </div>
                          <div className="col-2">
                            <Form.Check
                              type="radio"
                              checked={isSelected.title === item.title}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>

                <InputField
                  name="code"
                  label={
                    <span className="text-dark form-placeholder-color ">
                      Apply Coupon Code
                    </span>
                  }
                  onChange={couponCodeHandler}
                  onBlur={coupenOnBlur}
                  error={errorfail}
                  type="text"
                  placeholder="Please enter your coupon code"
                />
                <Button
                  className={`button  btn btn-primary btn-block ${
                    loader ? "btn-loading" : ""
                  }`}
                  onClick={handleSubmitClick}
                  disabled={!isSelected.title}
                  label="Pay Now!"
                />
              </div>
              <div className="pl-1 pr-1 ">
                <Button
                  className="button  btn btn-primary mt-3  btn-block"
                  onClick={listBusinessForm}
                  label="List Your Business"
                />
              </div>
            </>
          )}
          {/* **********************************page 2********************************** */}
          {activePage === 2 && (
            <>
              <div className="subscription1-container">
                <div>
                  <div className="row mt-3 d-flex align-items-center">
                    <div className="col-10">
                      <span className="subscription-price f-w500 f-18">
                        {scriptionPlainList && subDataShow.id
                          ? subscibeFilterData(
                              scriptionPlainList,
                              subDataShow.subscription_id
                            )?.title
                          : "Subscribe Plain "}
                      </span>
                      <br />
                      <span className="text-red f-w500 f-14">
                        {formatDate(
                          subDataShow?.end_time,
                          "onlydateandmonthyear"
                        ) < formatDate(currentDate, "onlydateandmonthyear")
                          ? "Already Subscribed"
                          : "Plan expired"}
                      </span>
                    </div>
                  </div>
                  <hr className="mt-2" />
                  <span className="f-w400 f-14 text-white">
                    Your plan will expire on{" "}
                    {formatDate(subDataShow.end_time, "onlydateandmonthyear")}
                  </span>
                </div>
              </div>
              <Button
                className="button  btn btn-primary mt-3 btn-block"
                onClick={listBusinessForm}
                label="List Your Business"
              />
            </>
          )}
          {/* *************************************page 3************************** */}
          {activePage === 3 && (
            <>
              <div className="subscription1-container">
                <div>
                  <div className="row mt-3 d-flex align-items-center">
                    <div className="col-10">
                      <span className="subscription-price f-w500 f-18">
                        {scriptionPlainList && subDataShow.id
                          ? subscibeFilterData(
                              scriptionPlainList,
                              subDataShow.subscription_id
                            )?.title
                          : "Subscribe Plain "}
                      </span>
                      <br />
                      <span className="text-red f-w500 f-14">
                        {formatDate(
                          subDataShow?.end_time,
                          "onlydateandmonthyear"
                        ) < formatDate(currentDate, "onlydateandmonthyear")
                          ? "Already Subscribed"
                          : "Plan expired"}
                      </span>
                    </div>
                  </div>
                  <hr className="mt-2" />
                  <span className="f-w400 f-14 text-white">
                    Your plan will expire on{" "}
                    {formatDate(subDataShow.end_time, "onlydateandmonthyear")}
                  </span>
                </div>
              </div>
              <div className="your-business-listed-container mt-3">
                <div className="mt-3">
                  <span className="subscription-price f-w500 f-18">
                    Your Business Listed
                  </span>
                  <br />
                  <span className="text-red f-w500 f-14">
                    {subDataShow?.business_name
                      ? subDataShow?.business_name
                      : "Apna Sweets-Indian Sweets Shop"}
                  </span>
                </div>
              </div>
            </>
          )}
          {/* ******************************* page 4 ************************************** */}
          {activePage === 4 && (
            <>
              <div className="subscription-container">
                <div>
                  {scriptionPlainList &&
                    Array.isArray(scriptionPlainList) &&
                    scriptionPlainList.map((item, index) => (
                      <>
                        <div
                          className="row mt-3 d-flex align-items-center p-3 "
                          onClick={() => handleSubClick(item)}
                          key={index}
                        >
                          <div className="col-10">
                            <span className="subscription-price f-w500 f-18">
                              {item.title}
                            </span>
                            <br />
                            <span className="text-red f-w500 f-16">
                              ₹ {item.amount}
                              <span className="text-white f-w500 f-16">
                                /{item.duration}
                              </span>
                            </span>
                          </div>
                          <div className="col-2">
                            <Form.Check
                              type="radio"
                              checked={isSelected.title === item.title}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                </div>

                <InputField
                  name="code"
                  label={
                    <span className="text-dark form-placeholder-color ">
                      Apply Coupon Code
                    </span>
                  }
                  onChange={couponCodeHandler}
                  onBlur={coupenOnBlur}
                  error={errorfail}
                  type="text"
                  placeholder="Please enter your coupon code"
                />
                <Button
                  className={`button  btn btn-primary btn-block ${
                    loader ? "btn-loading" : ""
                  }`}
                  onClick={handleSubmitClick}
                  disabled={!isSelected.title}
                  label="Pay Now!"
                />
              </div>
              <p className="f-w600 f-10 text-note-grey mb-0 mt-2">
                Note: Make a payment to active your Business Listing
              </p>

              <div className="your-business-listed-container mt-3">
                <div className="mt-3">
                  <span className="subscription-price f-w500 f-18">
                    Your Business Listed
                  </span>
                  <br />
                  <span className="text-red f-w500 f-14">
                    {subDataShow?.business_name
                      ? subDataShow?.business_name
                      : "Apna Sweets-Indian Sweets Shop"}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscription;
