import React from "react";

export const Forward = ({
  className = "",
  width = "11px",
  height = "9px",
  viewBox = "0 0 11 9",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.119141 8.35547H1.50104V4.22324H7.7196V6.68605L10.8814 3.53453L7.7196 0.355469V2.84583H1.23572C0.9188 2.84583 0.653476 2.95143 0.439742 3.16263C0.226008 3.37383 0.119141 3.63554 0.119141 3.94776V8.35547Z"
        fill="#E31E24"
      />
    </svg>
  );
};
