import React, { useEffect, useState } from "react";
import "./eventDetails.css";
import { EventDetailsCard } from "../../components/cards/EventDetailsCard";
import { Button } from "../../components/form-type/button";
import { Collapse, Toast } from "react-bootstrap";
import { Icons } from "../../components/Icons/Icon";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { exportApi } from "../../../services/indoreTalk.api";
import { Footer } from "../../components/Footer/Footer";
import { parseISO, isAfter } from 'date-fns';

import {
  setApiTicketData,
  setTicketCount,
  setsingleData,
} from "../../../redux/slices/singleDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import {
  dateFormatting,
  timeFormatting,
  likePayloads,
  unnecatchLogout,
} from "../../../utils/exportGlobleFunction";
import { SingleSkeleton } from "../../components/cards/singleSkeleton";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { Parser } from "html-to-react";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from "store";
import { getPlatform } from "../../../utils/constGlobleData";

export const EventDetail = ({ item }) => {
  const tempLogin = useSelector((state) => state.auth);
  const { ticketCount, singleData } = useSelector((state) => state.singleData);
  const htmlParser = new Parser();
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const id = useParams();
  const location = useLocation();
  const { myBookingShow } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [singleEvent, setSingleEvent] = useState({});
  const [silverCounts, setSilverCounts] = useState(
    Array.from(
      { length: singleData.ticket ? singleData.ticket.length : 0 },
      () => 0
    )
  );
  const [disabledButton, setDisabledButton] = useState(false);
  const [liketnDisabled, setLiketnDisabled] = useState(false);
  const [likeStates, setLikeStates] = useState({});
  const [tempLike, setTempLike] = useState({});
  const [sessionExpire, setSessionExpire] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  localStorage.setItem("event_id", singleData?.id);
  const currentDateTime = new Date();

  const isDateValid = (startTime) => {
    if (!startTime) return false;
    // Parse startTime into a Date object using parseISO
    const eventStartTime = parseISO(startTime);
    // Get current date and time
    const currentDateTime = new Date();
    // Compare dates using isAfter function from date-fns
    const isEventAfterCurrentDateTime = isAfter(eventStartTime, currentDateTime);
    return isEventAfterCurrentDateTime;
  };

  const isTimeValid = (startTime) => {

    const eventStartTime = new Date(startTime);
    return currentDateTime < eventStartTime;
  };

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("localData");
      try {
        const res = await exportApi.exploreEventGetById({
          id: id.id,
          user_id: userId?.user_id || 0,
        });
        setSingleEvent(res.data);
        dispatch(setsingleData(res.data));
        setTempLike({});
        setLikeStates({});
      } catch (error) {
        console.error("Error fetching data:", error);
        setLikeStates({});
        setLiketnDisabled(false);
        setTempLike({});
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisabledButton(ticketCount.some((ticket) => ticket?.totalAmount > 0));
  }, [ticketCount]);

  const [showLoginModal, setLoginModalShow] = useState(false);

  const handleLikeClick = async (e, data) => {
    e.stopPropagation();
    if (!!userId) {
      try {
        const like = await exportApiAuth.likePost(likePayloads(data, userId));
        if (like.status === "success") {
          setSingleEvent(like.data);
          setLiketnDisabled(false);
          setTempLike({});
          setLikeStates({});
        } else {
          // if (like.code === 401) {
          setLiketnDisabled(false);
          // setErrorToast(true);
          setSessionExpire(true);
          setTempLike({});
          setLikeStates({});
          unnecatchLogout(store, dispatch, setSignOut);
          // }
        }
        // setLike(like)
      } catch (error) {
        setSessionExpire(false);
        setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
      }
    } else {
      setLiketnDisabled(false);
      setSessionExpire(false);
      setTempLike({});
      setLikeStates({});
      setErrorToast(true);
      setLoginModalShow(true);
    }
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderContent = () => {
    const content = singleEvent.aboutevent;
    const words = content?.split(" ");

    if (words?.length > 20 && !showMore) {
      const truncatedContent = words.slice(0, 20).join(" ");
      return (
        <>
          {htmlParser.parse(truncatedContent)}...{" "}
          <span onClick={toggleShowMore} style={{ color: "#E31E24" }}>
            Read More
          </span>
        </>
      );
    } else {
      return htmlParser.parse(content);
    }
  };
  const updateSilverCountPlus = (index, value, item, ticketname) => {
    const numericValue = parseInt(value, 10);
    const valueup =
      ticketCount && ticketCount.length > 0
        ? (ticketCount.find((t) => t.ticketName === ticketname) || {})
          .ticketNumber
        : 0;

    if (valueup >= item?.max_limit_ticket || valueup >= item.ticketnumber) {
      return; // Do nothing if the limit is reached
    }
    const data = {
      ticketName: ticketname,
      value: valueup === "0" ? numericValue : (valueup || 0) + 1,
      ticketDetails: item,
    };

    setSilverCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = Math.max(
        0,
        isNaN(numericValue) ? 0 : valueup === "0" ? numericValue : valueup + 1
      ); // Ensure the count is not negative
      return newCounts;
    });
    dispatch(setTicketCount(data));
    dispatch(setApiTicketData(data));
  };
  const updateSilverCountMinus = (index, value, item, ticketname) => {
    if (
      (!(silverCounts[index] !== undefined)
        ? silverCounts[index] || 0
        : (ticketCount.find((t) => t.ticketName === item.ticketname) || {})
          .ticketNumber || 0) === 0
    ) {
      return;
    } else {
      const numericValue = parseInt(value, 10);
      const valueup = (
        ticketCount.find((t) => t.ticketName === ticketname) || {}
      )?.ticketNumber;

      const data = {
        ticketName: ticketname,
        value: valueup === "0" ? numericValue : valueup - 1,
        ticketDetails: item,
      };

      setSilverCounts((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = Math.max(
          0,
          isNaN(numericValue) ? 0 : valueup === "0" ? numericValue : valueup - 1
        ); // Ensure the count is not negative
        return newCounts;
      });
      dispatch(setTicketCount(data));
      dispatch(setApiTicketData(data));
    }
  };

  const handleButtonClick = () => {
    if (!!userId) {
      navigateToTicket();
    } else {
      setLoginModalShow(true);
    }
  };

  const navigateToTicket = () => {
    navigate("event-ticket");
  };
  const handleBackClick = () => {
    navigate('/event-home');
  };

  const renderPlusIcon = (index, item, ticketname) => {
    const selectedTicket = ticketCount.find((t) => t.ticketName === ticketname);
    const ticketNumber = selectedTicket ? selectedTicket.ticketNumber : 0;
    const isDisabled =
      ticketNumber >= item?.max_limit_ticket ||
      ticketNumber >= item.ticketnumber;

    return (
      <span
        onClick={() => {
          if (!isDisabled && singleEvent && !eventHasStarted) {
            updateSilverCountPlus(
              index,
              silverCounts[index] ? silverCounts[index] + 1 : 1,
              item,
              item.ticketname
            );
          }
        }}
      >
        <Icons
          name="plus"
          className={`svg-size-24 ${isDisabled ? "svg-grey" : "svg-red"}`}
          style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
        />
      </span>
    );
  };

  const eventHasStarted = singleEvent && !isDateValid(singleEvent?.start_time);
  return (
    <>
      <div className="eventDetail scroll-without-category">
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center pr-3">
              <span onClick={() => handleBackClick()}>
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto pr-20 ">
                <h1 className="f-24 f-w600 mb-0">{"Event Details"}</h1>
              </div>
            </div>
          </nav>
        </div>
        {singleEvent?.id ? (
          <div className="single-event-detail">
            <h2 className="text-dark">
              <EventDetailsCard
                event={singleEvent ? singleEvent : {}}
                handleLikeClick={handleLikeClick}
                myBookingShow={myBookingShow}
                setTempLike={setTempLike}
                tempLike={tempLike}
                setLiketnDisabled={setLiketnDisabled}
                liketnDisabled={liketnDisabled}
                likeStates={likeStates}
                setLikeStates={setLikeStates}
              />
            </h2>

            {/* ..........................................................calender and location added here ...................................................*/}
            <div className="mt-2">
              <div className="d-flex  ">
                <Icons name="calender" className="svg-size-18 svg-red mr-1" />
                <h5 className="cal-center cal-margin text-disabled">
                  {dateFormatting(singleEvent.start_time) ===
                    dateFormatting(singleEvent.expiration_time)
                    ? dateFormatting(singleEvent.start_time)
                    : `${dateFormatting(
                      singleEvent.start_time
                    )} - ${dateFormatting(singleEvent.expiration_time)}`}
                </h5>
              </div>

              <div className="d-flex">
                <Icons name="time" className="svg-size-18" />
                <h5 className="time-margin text-disabled">{`${timeFormatting(
                  singleEvent.start_time
                )} to ${timeFormatting(singleEvent.expiration_time)}`}</h5>
              </div>
              {singleEvent.language && (
                <div className="d-flex ">
                  <Icons name="language" className="svg-size-18 svg-grey" />
                  <h5 className="language-margin text-disabled">
                    {singleEvent.language}
                  </h5>
                </div>
              )}
              {singleEvent.addressevent && (
                <div className="d-flex ">
                  <Icons name="location" className="svg-size-18 svg-red " />

                  <h5 className=" text-disabled mt-1 ml-1">
                    {singleEvent.addressevent}
                    {singleEvent?.map_url && (
                      <a
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          const mapUrl = singleEvent.map_url;
                          if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
                            window.webkit.messageHandlers.reactNative.postMessage({
                              action: 'open_url',
                              url: mapUrl
                            });
                          } else {
                            window.open(mapUrl, "_blank");
                          }
                        }}
                        href={singleEvent.map_url} // Provide href for default behavior if JavaScript is disabled
                        style={{
                          color: "#E31E24",
                          fontSize: "10px",
                          fontWeight: "500",
                          outline: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        View on map
                      </a>
                    )}

                  </h5>
                </div>
              )}
            </div>

            {/*........................................................... view on map link ....................................................*/}

            <Button
              onClick={() => setOpen(!open)}
              aria-controls="more-info"
              aria-expanded={open}
              className=" d-flex w-100 justify-content-between btn-tpt align-items-center p-0   border-0"
              style={{ fontWeight: 600 }}
            ></Button>
            <Collapse in={open}>
              <div id="more-info" className="ml-1">
                <div className="d-flex">
                  <Icons name="time" className="svg-size-18 svg-grey" />
                  <h5 className="ml-2 text-disabled">{`${timeFormatting(
                    singleEvent.start_time
                  )} to ${timeFormatting(singleEvent.expiration_time)}`}</h5>
                </div>

                <div className="d-flex">
                  <Icons name="language" className="svg-size-18 svg-grey" />
                  <h5 className="ml-2 text-disabled">{singleEvent.language}</h5>
                </div>
              </div>
            </Collapse>
            {/* .........................................................Line border............................................................. */}
            <hr className="my-2 mb-3" />

            {/*....................................................... Contact details ..........................................................*/}
            {singleEvent.contactdetails && (
              <>
                <div className="mt-2">
                  <div>
                    <h4 className="f-14 text-dark ">Contact Details</h4>
                  </div>
                  <div className="d-flex ">
                    <Icons name="calling" className="svg-size-18 svg-grey " />
                    <div className="ml-1  " style={{ alignSelf: "center" }}>
                      {singleEvent.contactdetails}
                    </div>
                  </div>
                </div>
                <hr className="my-2 mb-3" />
              </>
            )}

            {singleEvent.event_ticket_type && (
              <>
                <div className="mt-2">
                  <div>
                    <h4
                      className="f-14 text-dark "
                      style={{
                        backgroundColor: "#202020",
                        padding: "5px",
                        borderRadius: "4px",
                      }}
                    >
                      Ticket Category
                    </h4>
                  </div>
                  <div className="d-flex ">
                    {/* <Icons name="calling" className="svg-size-18 svg-grey " /> */}
                    <div className="ml-1  " style={{ alignSelf: "center" }}>
                      {singleEvent.event_ticket_type === "paid" && (
                        <p>Paid Event</p>
                      )}
                      {singleEvent.event_ticket_type === "free" && (
                        <p>Free Event</p>
                      )}
                      {singleEvent.event_ticket_type === "registration" && (
                        <p>
                          Registration at:{" "}
                          <Button
                            onClick={() => {
                              const orderUrl = singleEvent.registration_link;
                              if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
                                window.webkit.messageHandlers.reactNative.postMessage({
                                  action: 'open_url',
                                  url: orderUrl
                                });
                              } else {
                                window.open(orderUrl, "_blank");
                              }
                            }}
                            className="btn btn-secondary .registrationButton f-14 ml-2 z-index-999 btn-bg-red"
                            label="Click Here"
                          />
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="my-2 mb-3" />
              </>
            )}
            {/*..................................................... Select Ticket type.......................................................*/}
            {!myBookingShow && (
              <>
                {singleEvent.ticket &&
                  singleEvent.ticket.map((item, index) => {
                    return (
                      <>
                        {item?.per_amount > 0 && (
                          <div className=" mt-3" key={index}>
                            {index === 0 && item.per_amount > 0 && (
                              <div>
                                <h5 className="text-disabled ">
                                  Select Ticket Type
                                </h5>
                              </div>
                            )}
                            <div className="mt-2 d-flex justify-content-between Silver">
                              <div>
                                <div>
                                  <h4 className="text-dark mb-0">
                                    {item.ticketname}
                                  </h4>
                                </div>
                                <div>
                                  <h6 className="text-disabled f-14">
                                    ₹ {item.per_amount} Per person |{" "}
                                    {item.ticketnumber} Seats available
                                  </h6>
                                </div>
                              </div>
                              <div className="d-flex mt-2">
                                {item.ticketnumber === 0 ? (
                                  <span className="soldout-banner">
                                    <div className="soldout-svg d-flex justify-content-center">
                                      <Icons name="soldout" className="" />
                                      <span className="f-12 f-w500">
                                        Soldout
                                      </span>
                                    </div>
                                  </span>
                                ) : (
                                  <>
                                    <span
                                      onClick={() => {
                                        const selectedTicket = ticketCount.find(
                                          (t) =>
                                            t.ticketName === item.ticketname
                                        );

                                        if (
                                          (selectedTicket &&
                                            selectedTicket.ticketNumber >
                                            item?.ticketnumber) ||
                                          item?.ticketnumber === 0 ||
                                          selectedTicket?.ticketNumber === 0
                                        ) {
                                        } else {
                                          updateSilverCountMinus(
                                            index,
                                            silverCounts[index] - 1,
                                            item,
                                            item.ticketname
                                          );
                                        }
                                      }}
                                    >
                                      <Icons
                                        name="minus"
                                        className="svg-size-24 svg-grey"
                                      />
                                    </span>
                                    <input
                                      className="bg-tpt text-dark"
                                      type="text"
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        border: 0,
                                        textAlign: "center",
                                      }}
                                      value={
                                        !(silverCounts[index] !== undefined)
                                          ? silverCounts[index] || 0
                                          : (
                                            ticketCount.find(
                                              (t) =>
                                                t.ticketName ===
                                                item.ticketname
                                            ) || {}
                                          ).ticketNumber || 0
                                      }
                                      readOnly
                                    />
                                    {renderPlusIcon(
                                      index,
                                      item,
                                      item.ticketname
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                {Array.isArray(singleEvent?.ticket) &&
                  parseInt(singleEvent?.ticket[0]?.per_amount) > 0 && (
                    <hr className="my-2 mb-3" />
                  )}
              </>
            )}
            {/*.............................................................. Proile section................................................... */}

            <div className="ml-1">
              <div className="">
                <h3 className="text-dark"> About Event </h3>
                <div>
                  <p className="f-14 text-dark">{renderContent()}</p>
                </div>
              </div>
            </div>

            {/*............................................................... Bottom button ........................................................*/}
            {Array.isArray(singleEvent?.ticket) &&
              singleEvent?.ticket.some((item) => item.per_amount > 0) &&
              singleEvent && !eventHasStarted && (
                <div className="payButton">
                  <Button
                    onClick={handleButtonClick}
                    className="btn btn-secondary w-100 mt-3 mb-3 p-2 z-index-999 btn-bg-red"
                    label={myBookingShow ? "View Ticket" : `Buy Ticket`}
                    disabled={!disabledButton}
                  />
                </div>
              )}
            {singleEvent && eventHasStarted && (
              <Button
                onClick={handleButtonClick}
                className="btn btn-secondary w-100 mt-3 mb-3 p-2 z-index-999 btn-bg-red"
                disabled={true}
              >
                Event has started
              </Button>
            )}
          </div>
        ) : (
          <SingleSkeleton />
        )}
        {showLoginModal && (
          <GoToLogin
            show={showLoginModal}
            onHide={() => setLoginModalShow(false)}
            location={location}
            loginResion={sessionExpire}
          />
        )}
        <Footer />
      </div>
      {errorToast && (
        <Toast
          onClose={() => setErrorToast(false)}
          show={true}
          delay={3000}
          className="toast-dialog"
          autohide={true}
          style={{ bottom: "94px" }}
        >
          <Toast.Body style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}>
            {"Somthing went wrong"}
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};
