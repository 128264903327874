import React from 'react'

export const Facebook = ({
    className = "",
    width = "28px",
    height = "28px",
    viewBox = "0 0 28 28",
    fill = "",
}) => {

    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect x="2" y="2" width="23.6323" height="22.9999" rx="11.4999" fill="#1977F3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9052 24.8641C15.3272 24.9536 14.7351 25 14.132 25H13.4996C12.9771 25 12.4627 24.9651 11.9587 24.8976V17.163H8.75208V13.6218H11.9587V10.9227C11.9587 7.85318 13.843 6.1571 16.7287 6.1571C17.6757 6.16994 18.6204 6.24995 19.5554 6.39648V9.41144H17.9621C16.3935 9.41144 15.9052 10.3557 15.9052 11.3255V13.6223H19.4076L18.8477 17.163H15.9052V24.8641Z" fill="white" />
        </svg>


    )
}
