import React, { useEffect } from "react";
 
const Help = () => {
  const redirectToWhatsApp = () => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_PHONE_NUMBER;
   console.log(phoneNumber,"phoneNumber")
    // Open WhatsApp link using the "wa.me" format
    window.location.href = `https://wa.me/${phoneNumber}`;
  };
 
  useEffect(() => {
    // Trigger redirection when the component mounts
    redirectToWhatsApp();
  }, []); // Empty dependency array ensures this effect runs only once after initial render
 
  return (
<div>
<p className="text-dark-white">Redirecting to WhatsApp...</p>
</div>
  );
};
 
export default Help;