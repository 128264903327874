/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { useDispatch } from "react-redux";
import { PrivateRoutes, PublicRoutes } from "./routes";
import { setmainCategory } from "./redux/slices/mainCatSlice";
import { exportApi } from "./services/indoreTalk.api";
import { SplashScreen } from "./views/layouts/Onboarding/SplashScreen";
import { auth } from "./firebase/firebase";
import { setSignIn } from "./redux/slices/authSlice";
import {
  setSubEventCategory,
  setSubExploreCategory,
  setSubListingCategory,
} from "./redux/slices/subCat";
import { getPlatform, isSupportedBrowser } from './utils/constGlobleData';

function App() {
  const dispatch = useDispatch();
  function redirectToApp() {
    const platform = window.navigator.userAgent
    if (isSupportedBrowser(platform)) {
      if(getPlatform() === "Android"){
        window.location.href = 'https://play.google.com/store/apps/details?id=com.indoretalk.com'
      }
      if(getPlatform() === "Mac OS" || getPlatform() === "iOS"){
        window.location.href = 'https://apps.apple.com/in/app/indore-talk/id6499068650'
      }
    }
  }
  useEffect(() => {
     redirectToApp()
  }, [])
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await exportApi.maincategories();
        if (res && res.data.length > 0) {
          dispatch(setmainCategory(res));
          getMainCat(res.data, "Listing");
          getMainCat(res.data, "Events");
          getMainCat(res.data, "Explore");
        }
      } catch (err) {
        console.error("Error fetching news:", err);
      }
    }
    fetchData();
  }, []);

  const getMainCat = async (mainCatFilter, category) => {
    try {
      const filterMainCat =
        mainCatFilter && Array.isArray(mainCatFilter)
          ? mainCatFilter.find((item) => item.name === category)
          : null;
      if (filterMainCat) {
        const res = await exportApi.mainSubCategories(filterMainCat.id);
        if (res.status === "success") {
          switch (category) {
            case "Listing":
              localStorage.setItem("listingFirstCategory", res.data[0].id);
              dispatch(setSubListingCategory(res.data));
              break;
            case "Events":
              localStorage.setItem("eventsFirstCategory", res.data[0].id);
              dispatch(setSubEventCategory(res.data));
              break;
            case "Explore":
              localStorage.setItem("exploreFirstCategory", res.data[0].id);
              dispatch(setSubExploreCategory(res.data));
              break;
            default:
              console.error("Invalid category");
          }
        } else {
          console.error(`Error ${category.toLowerCase()} fetching`);
        }
      }
    } catch (err) {
      console.error(`Error fetching ${category.toLowerCase()}:`, err);
    }
  };

  useEffect(() => {
    const localData = localStorage.getItem("tempLogin");
    const data = JSON.parse(localData);
    const accesData = data?.user?.data;
    const handleAuthStateChanged = async (authUser) => {
      if (accesData) {
        let loginsignUp;
        // const loginsignUp = await exportApi.signInAndSignUp({
        //   login_type: accesData?.social_provider?.login_type,
        //   email: accesData?.email,
        //   mobile_number: accesData?.mobile_number,
        //   access_tokens: accesData?.social_provider?.access_tokens,
        //   name: accesData.display_name,
        //   refresh_tokens: accesData?.social_provider?.access_tokens,
        // });
        console.log("accesData", accesData.social_provider.login_type);
        if (accesData.social_provider.login_type === "mobile") {
          loginsignUp = await exportApi.signInAndSignUpWithWhatsapp({
            login_type: accesData?.social_provider?.login_type,
            email: accesData?.email,
            mobile_number: accesData?.mobile_number?.startsWith(
              "+91"
            ) ? accesData?.mobile_number : `+91${accesData?.mobile_number}`,
            access_tokens: accesData?.social_provider?.access_tokens,
            name: accesData.display_name,
            refresh_tokens: accesData?.social_provider?.access_tokens,
          });
        }
        if (accesData.social_provider.login_type === "google") {
          loginsignUp = await exportApi.signInAndSignUp({
            login_type: accesData?.social_provider?.login_type,
            email: accesData?.email,
            mobile_number: accesData?.mobile_number,
            access_tokens: accesData?.social_provider?.access_tokens,
            name: accesData.display_name,
            refresh_tokens: accesData?.social_provider?.access_tokens,
          });
        }
        if (loginsignUp) {
          dispatch(setSignIn(loginsignUp));
        }
      } else if (authUser) {
        const tempLoginProvider = authUser.providerData[0] || "";
        const loginsignUp = await exportApi.signInAndSignUp({
          login_type:
            tempLoginProvider.providerId === "google.com"
              ? "google"
              : tempLoginProvider.providerId === "phone"
              ? "mobile"
              : "",
          email: tempLoginProvider?.email,
          mobile_number: tempLoginProvider?.phoneNumber,
          access_tokens: authUser.stsTokenManager.accessToken,
          name: authUser.displayName,
          refresh_tokens: authUser.stsTokenManager.accessToken,
        });
        if (loginsignUp) {
          dispatch(setSignIn(loginsignUp));
        }
      } else {
        console.error("something wrong");
      }
    };

    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          {PrivateRoutes.private.map((route, i) => {
            return (
              route.component && (
                <Route
                  key={i}
                  path={route.path}
                  element={<route.component />}
                />
              )
            );
          })}
          {PublicRoutes.map((route, i) => {
            return (
              route.component && (
                <Route
                  key={i}
                  path={route.path}
                  element={<route.component />}
                />
              )
            );
          })}
          {/* <Route path="*" element={<Navigate to="/home" />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
