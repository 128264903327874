import React from 'react';

export const Search = ({
    className = "",
    width = '24px',
    height = '24px',
    viewBox = '0 1 24 24',
    fill = ''
}) => {

    return (

        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5209 3.75C7.22917 3.75 3.75 7.22917 3.75 11.5209C3.75 15.8127 7.22917 19.2919 11.5209 19.2919C15.8127 19.2919 19.2919 15.8127 19.2919 11.5209C19.2919 7.22917 15.8127 3.75 11.5209 3.75ZM2.25 11.5209C2.25 6.40074 6.40074 2.25 11.5209 2.25C16.6411 2.25 20.7919 6.40074 20.7919 11.5209C20.7919 16.6411 16.6411 20.7919 11.5209 20.7919C6.40074 20.7919 2.25 16.6411 2.25 11.5209Z" fill="" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9164 17.3604C17.2089 17.0671 17.6838 17.0665 17.977 17.359L21.3177 20.691C21.611 20.9835 21.6116 21.4584 21.3191 21.7517C21.0266 22.0449 20.5517 22.0456 20.2584 21.7531L16.9177 18.421C16.6245 18.1285 16.6239 17.6536 16.9164 17.3604Z" fill="" />
        </svg>




    );
};





