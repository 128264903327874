import React from 'react';

export const Report = ({
    className = "",
    width = '24px',
    height = '25px',
    viewBox = '0 0 24 25',
    fill = ''
}) => {

    return (

        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M11.9985 18.3233C12.238 18.3233 12.4381 18.2424 12.599 18.0807C12.7608 17.9189 12.8416 17.7187 12.8416 17.4802C12.8416 17.2417 12.7608 17.041 12.599 16.8784C12.4372 16.7157 12.2371 16.6348 11.9985 16.6357C11.76 16.6366 11.5598 16.7175 11.3981 16.8784C11.2363 17.0392 11.1554 17.2398 11.1554 17.4802C11.1554 17.7206 11.2363 17.9207 11.3981 18.0807C11.5598 18.2406 11.76 18.3215 11.9985 18.3233ZM11.3131 14.264H12.684V5.93301H11.3131V14.264ZM7.43752 23.2271L1.03125 16.8331V7.69875L7.42518 1.29248H16.5596L22.9658 7.68641V16.8208L16.5719 23.2271H7.43752ZM8.02289 21.8561H15.9742L21.5949 16.2354V8.28413L15.9742 2.66339H8.02289L2.40216 8.28413V16.2354L8.02289 21.8561Z" fill="" />
        </svg>




    );
};





