/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Ticket.css";
import { Button } from "../../components/form-type/button";
import { Collapse } from "react-bootstrap";
import { Icons } from "../../components/Icons/Icon";
import { useSelector } from "react-redux";
import {
  dateFormatting,
  formatDate,
  sum,
  timeFormatting,
  useCalculateSubtotal,
} from "../../../utils/exportGlobleFunction";
import { useNavigate, useParams } from "react-router-dom";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import Loader from "../../../utils/loader";

export const Ticket = ({ eventId }) => {
  const { singleData, ticketCount, apiTicketData } = useSelector(
    (state) => state.singleData
  );
  const { tempLogin } = useSelector((state) => state.auth);
  const userId = tempLogin?.user?.data?.social_provider;
  const uid = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [showDetials, setShowDetials] = useState({});
  const handleBackClick = () => {
    // Check if the current URL contains /my-booking/${event_id}
    if (window.location.pathname.includes('/my-booking/ticket')) {
      navigate('/my-booking'); // Navigate back
    } else {
      navigate("/event-home"); // Navigate to event-home
    }
  };

  useEffect(() => {
    ticketDataCall();
  }, [uid]);

  const ticketDataCall = async () => {
    setloading(true);
    try {
      const res = await exportApiAuth.singleBooking({
        user_id: userId?.user_id,
        login_type: userId?.login_type,
        event_id: eventId ? eventId : parseFloat(uid.id),
      });
      if (res) {
        setloading(false);
        setShowDetials(res.data);
      }
    } catch (error) {
      setloading(false);
    }
  };
  const eventBookArray = Object.values(showDetials?.event_book?.[0] || []);
  const subtotal = useCalculateSubtotal(ticketCount);
  const base64ToDataURL = (base64String) => {
    return `data:image/png;base64,${base64String}`;
  };
  let totalTax = 0;


  return (
    <>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center " >
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className='m-auto pr-24'>
              <h1 className="f-24 f-w600 mb-0">{'Ticket'}</h1>
            </div>

          </div>
        </nav>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {(Array.isArray(eventBookArray) &&
            eventBookArray.filter(
              (item) =>
                item?.event_ticket_details !== null &&
                item?.event_ticket_details !== "" &&
                item !== null &&
                item.event_ticket_details
            ).length) !== 0 ? (
            <>
              {eventBookArray &&
                Array.isArray(eventBookArray) &&
                eventBookArray
                  .filter(
                    (item) =>
                      item?.event_ticket_details !== null &&
                      item?.event_ticket_details !== "" &&
                      item !== null &&
                      item.event_ticket_details
                  )
                  .map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <div
                            className="scroll-without-category1"
                            style={{ position: "relative", overflow: "hidden" }}
                          >
                            <div className="ticket-border-circle-1"></div>

                            <div className="ticket-border-circle-2"></div>
                            <div className="ticket-border">
                              <>
                                {showDetials && (
                                  <>
                                    <div style={{}}>
                                      <div className="text-center  mt-3">
                                        <h4
                                          className="f-w600 ticket-download-heading text-dark "
                                          style={{ height: "22px" }}
                                        >
                                          {showDetials?.heading}
                                        </h4>
                                      </div>
                                      <div className="text-center">
                                        {/* <img
                                          alt=""
                                          src={
                                            "https://raw.githubusercontent.com/nating/react-native-custom-qr-codes/HEAD/assets/qr-code-3.png"
                                          }
                                          className="buyImage-ticket"
                                        /> */}
                                        <div className="text-center">
                                          <img
                                            src={base64ToDataURL(item.qr_code)}
                                            alt="QR Code"
                                            className="buyImage-ticket"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="text-center dotted-border "
                                        style={{ height: "50px" }}
                                      >
                                        <h4 className="f-w600 ticket-qr-number text-dark pb-10">
                                          {item?.transaction_id}
                                        </h4>
                                      </div>
                                    </div>

                                    <div className="d-grid mt-2 ticket-info-padding  ">
                                      <div className="d-flex flex-column align-items-center p-0">
                                        <h5 className="text-disabled f-12 f-w500 m-0">
                                          Booking Partner
                                        </h5>
                                        <Icons name="logo" className="svg-white mt-1" />
                                      </div>

                                      <div className="date-time d-flex just justify-content-between mt-2">

                                        <div className="d-grid text-left p-0">
                                          <h5 className="text-disabled f-12 f-w500 m-0">
                                            Name
                                          </h5>
                                          <h3 className="text-dark f-w400  ">
                                            {tempLogin?.user?.data?.display_name || "-"}
                                          </h3>
                                        </div>
                                        <div className="d-grid p-0 text-left col-5">
                                          <h5 className="text-disabled f-12 mb-0 f-w500">
                                            Ticket
                                          </h5>

                                          <h3 className="text-dark  f-w400  ">
                                            {sum(
                                              item?.event_ticket_details &&
                                                item.event_ticket_details
                                                  .length > 0
                                                ? Array.isArray(
                                                  JSON.parse(
                                                    item?.event_ticket_details
                                                  )
                                                )
                                                  ? JSON.parse(
                                                    item?.event_ticket_details
                                                  )
                                                  : 0
                                                : 0,
                                              "ticket"
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="date-time d-flex just justify-content-between mt-2">
                                        <div className="d-grid p-0 col-7">
                                          <h5 className="text-disabled f-12 f-w500 m-0 text-left">
                                            Date
                                          </h5>

                                          <h3 className="text-dark f-w400 font-size-date text-left">
                                            {dateFormatting(showDetials?.start_time)}{" "}
                                            -{" "}
                                            {dateFormatting(showDetials?.expiration_time)}
                                          </h3>
                                        </div>
                                        <div className="d-grid p-0 col-5">
                                          <h5 className="text-disabled f-12 mb-0 f-w500 text-left">
                                            Time
                                          </h5>

                                          <h3 className="text-dark f-w400  font-size-date text-left">
                                            {timeFormatting(showDetials?.start_time)}{" "}
                                            to{" "}
                                            {timeFormatting(showDetials?.expiration_time)}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="d-grid mt-2 ">
                                        <h5 className="text-disabled f-12 f-w500 mb-0 text-left">
                                          Location
                                        </h5>
                                        <h3 className="text-dark f-16 f-w400  mb-0 text-left">
                                          {showDetials?.addressevent}
                                        </h3>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                              {/* Ticket Calculate */}
                              <div className="ticket-info-padding tick-calc-bg mt-4">
                                <div className="d-grid">
                                  {item?.event_ticket_details &&
                                    item.event_ticket_details.length > 0 &&
                                    Array.isArray(
                                      JSON.parse(item?.event_ticket_details)
                                    ) &&
                                    JSON.parse(item?.event_ticket_details).map(
                                      (ticketItem, ticketIndex) => {
                                        return (
                                          <div
                                            key={ticketIndex}
                                            className="mb-2"
                                          >
                                            <h4 className="text-disabled mb-0 text-left f-w400 ">
                                              {ticketItem?.ticketName}
                                              <h4 className="text-dark float-right mb-0 f-w400 ">
                                                ₹ {ticketItem?.totalAmount}
                                              </h4>
                                            </h4>
                                            <h5 className="text-dark f-w400  mb-0 text-left">
                                              {ticketItem?.buySeats < 9
                                                ? "0" + ticketItem?.buySeats
                                                : ticketItem?.buySeats}
                                              /
                                              {ticketItem?.buySeats <= 1
                                                ? "Person"
                                                : "Persons"}
                                            </h5>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>

                                <hr />

                                {/* tax and fees part */}

                                <div className="d-grid just">
                                  {/* Taxes & Fees dropdown */}
                                  <Button
                                    aria-controls="more-info"
                                    aria-expanded={true} // Set to true to keep the dropdown always open
                                    className="d-flex w-100 justify-content-between btn-tpt align-items-center p-0 border-0"
                                    style={{ fontWeight: 600 }}
                                  //  onClick={() => setOpen(!open)}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h4 className="text-dark mt-2 mr-2">
                                        Taxes & Fees
                                      </h4>
                                      {open ? (
                                        <Icons
                                          name="arrow-up"
                                          className="svg-size-14 svg-black mt-1 mr-2"
                                        />
                                      ) : (
                                        <Icons
                                          name="arrow-down"
                                          className="svg-size-14 svg-black mt-1 mr-2"
                                        />
                                      )}
                                    </div>
                                    {/* <h4 className="text-dark mb-0">₹{totalTaxAmount.toFixed(2)}</h4> */}
                                    {showDetials?.tax && (
                                      <>
                                        {(() => {
                                          let totalTaxAmount = 0; // Declare totalTaxAmount locally
                                          Object.keys(JSON.parse(showDetials.tax)).map((taxKey, i) => {
                                            const tax = JSON.parse(showDetials.tax)[taxKey];
                                            let taxValue = tax.value;
                                            let displayValue = taxValue;

                                            if (tax.valuetype === "Fixed") {
                                              totalTaxAmount += parseFloat(displayValue);
                                              totalTax = totalTaxAmount// Accumulate total tax amount
                                            }

                                            let totalAm = sum(
                                              item?.event_ticket_details &&
                                                item.event_ticket_details.length > 0
                                                ? Array.isArray(JSON.parse(item.event_ticket_details))
                                                  ? JSON.parse(item.event_ticket_details)
                                                  : 0
                                                : 0,
                                              "amount"
                                            );

                                            if (tax.valuetype === "percent") {
                                              // Calculate the percentage value based on the pre-calculated subtotal
                                              taxValue = (tax.value / 100) * totalAm;
                                              displayValue = taxValue.toFixed(2); // Convert to string with 2 decimal places
                                              totalTaxAmount += parseFloat(displayValue); // Accumulate total tax amount
                                              totalTax = totalTaxAmount;
                                            }
                                          });
                                          return (
                                            <>
                                              <h4 className="text-dark mb-0">

                                                ₹{totalTaxAmount.toFixed(2)}
                                              </h4>
                                            </>
                                          );
                                        })()}
                                      </>

                                    )}
                                  </Button>

                                  {/* Taxes & Fees details */}
                                  <Collapse in={true}>
                                    <div id="more-info" className="ml-2">
                                      {showDetials?.tax && (
                                        <>
                                          {Object.keys(
                                            JSON.parse(showDetials.tax)
                                          ).map((taxKey, i) => {
                                            const tax = JSON.parse(
                                              showDetials.tax
                                            )[taxKey];
                                            let taxValue = tax.value;
                                            let displayValue = taxValue;
                                            let totalAm = sum(
                                              item?.event_ticket_details &&
                                                item.event_ticket_details
                                                  .length > 0
                                                ? Array.isArray(
                                                  JSON.parse(
                                                    item?.event_ticket_details
                                                  )
                                                )
                                                  ? JSON.parse(
                                                    item?.event_ticket_details
                                                  )
                                                  : 0
                                                : 0,
                                              "amount"
                                            );
                                            if (tax.valuetype === "percent") {
                                              // Calculate the percentage value based on the pre-calculated subtotal
                                              taxValue =
                                                (tax.value / 100) * totalAm;
                                              displayValue =
                                                taxValue.toFixed(2); // Convert to string with 2 decimal places
                                            }

                                            return (
                                              <div
                                                key={taxKey}
                                                className="d-flex justify-content-between"
                                              >
                                                <div className="d-flex">
                                                  {tax.valuetype ===
                                                    "percent" ? (
                                                    <h5 className="text-disabled">
                                                      {tax.taxtype}@{tax.value}%
                                                    </h5>
                                                  ) : (
                                                    <h5 className="text-disabled">
                                                      {tax.taxtype}
                                                    </h5>
                                                  )}
                                                </div>
                                                <h5 className="text-disabled">
                                                  ₹{displayValue}
                                                </h5>{" "}
                                                {/* Display the tax value */}
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}
                                    </div>
                                  </Collapse>
                                </div>

                                {/* Total amount */}
                                <hr />
                                <div className="d-flex mt-3 mb-2 justify-content-between">
                                  <h4 className="text-dark">Total Amount</h4>
                                  <h4 className="text-dark">
                                    ₹
                                    {(
                                      sum(
                                        item?.event_ticket_details &&
                                          item.event_ticket_details.length > 0
                                          ? Array.isArray(JSON.parse(item?.event_ticket_details))
                                            ? JSON.parse(item?.event_ticket_details)
                                            : 0
                                          : 0,
                                        "amount"
                                      ) + totalTax
                                    ).toFixed(2) /* Adding the total tax amount */}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="ticket-download-btn pt-3">
                              <div className="ticket-share-cont">
                                <Icons
                                  name="share"
                                  className="svg-size-30 svg-white"
                                />
                              </div>
                              <Button
                                style={{
                                  backgroundColor: "#E31E24",
                                  borderRadius: "12px",
                                }}
                                className="btn btn-secondary btn-block"
                                label="Download Ticket"
                              />
                            </div> */}
                        </div>
                      </div>
                    );
                  })}
            </>
          ) : (
            <h4 className="text-black pt-5 text-center">No Ticket Found</h4>
          )}
        </>
      )}
    </>
  );
};
export default Ticket;