import React from 'react'

export const Filter = ({
    className = "",
    width = "25px",
    height = "25px",
    viewBox = "0 0 25 25",
    fill = "",
    onClick = () => {},
}) => {

    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>

<rect  transform="translate(0.5 0.5)" fill=""/>
<path d="M13.3804 7.87807C10.2144 7.87807 7.04841 7.87807 3.88416 7.87632C3.69874 7.87632 3.50808 7.87632 3.33142 7.83259C2.8294 7.7119 2.54254 7.25711 2.58802 6.68863C2.63 6.14988 2.96934 5.78256 3.48535 5.72658C3.61129 5.71259 3.73723 5.70909 3.86492 5.70909C10.2127 5.70909 16.5604 5.70734 22.9099 5.70909C23.0779 5.70909 23.251 5.70909 23.4137 5.74232C23.9157 5.84378 24.2271 6.29506 24.1973 6.8583C24.1676 7.41454 23.8282 7.7976 23.3105 7.85708C23.1688 7.87282 23.0236 7.87632 22.8802 7.87632C19.7107 7.87807 16.5447 7.87807 13.3804 7.87807Z" fill="white"/>
<path d="M13.3892 12.1915C15.4917 12.1915 17.5924 12.1898 19.6949 12.1933C19.8716 12.1933 20.0535 12.1985 20.2214 12.2423C20.7252 12.3752 20.9963 12.8212 20.9474 13.3967C20.9019 13.932 20.559 14.2888 20.0343 14.3448C19.9083 14.357 19.7824 14.3605 19.6547 14.3623C15.4759 14.3623 11.2989 14.364 7.12013 14.3605C6.93472 14.3605 6.74406 14.3588 6.56739 14.315C6.06538 14.1908 5.78376 13.7361 5.82924 13.1676C5.87297 12.6288 6.21231 12.265 6.73182 12.209C6.8665 12.195 7.00119 12.1915 7.13587 12.1915C9.21914 12.1915 11.3042 12.1915 13.3892 12.1915Z" fill="white"/>
<path d="M13.3752 20.8465C12.3204 20.8465 11.2656 20.8517 10.2109 20.8447C9.58819 20.8412 9.18413 20.5404 9.08792 20.0331C8.94099 19.253 9.38004 18.6932 10.1724 18.681C11.0418 18.667 11.9111 18.6775 12.7804 18.6775C14.0381 18.6775 15.2957 18.674 16.5534 18.6792C17.1271 18.681 17.4875 18.8979 17.6396 19.3352C17.8618 19.9771 17.6082 20.8482 16.5919 20.8482C15.5196 20.8465 14.4474 20.8465 13.3752 20.8465Z" fill="white"/>

        </svg>


    )
}






