import React from "react";
import { Placeholder } from "react-bootstrap";

export const CommentSkeleton = ({ number = 1 }) => {
    const skelElements = [];
    for (let index = 0; index < number; index++) {
        skelElements.push(
            <div className="mt-4">
                <div className='d-flex ' key={index}>
                    <div className="image-comm">
                        <Placeholder
                            as="div"
                            style={{ width: "40px", height: "40px", backgroundColor: "#333", borderRadius: "50%" }}
                            animation="glow"
                        />
                    </div>
                    <div className='f-16 f-w500 header pl-3'>
                        <Placeholder
                            as="div"
                            style={{ width: "150px", height: "20px", backgroundColor: "#333" }}
                            animation="glow"
                        />

                    </div>
                </div>

                <div className='f-16 f-w500 header pl-5'>
                    <Placeholder
                        as="div"
                        style={{ width: "220px", height: "15px", backgroundColor: "#333" }}
                        animation="glow"
                    />
                </div>
            </div>
        );
    }
    return skelElements;
};
