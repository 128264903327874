import React from 'react'

export const Back = ({
  className = "",
  width = "24px",
  height = "24px",
  viewBox = "0 0 24 24",
  fill = "",
}) => {

  return (
    <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5607 4.43934C17.1464 5.02513 17.1464 5.97487 16.5607 6.56066L10.6213 12.5L16.5607 18.4393C17.1464 19.0251 17.1464 19.9749 16.5607 20.5607C15.9749 21.1464 15.0251 21.1464 14.4393 20.5607L7.43934 13.5607C6.85355 12.9749 6.85355 12.0251 7.43934 11.4393L14.4393 4.43934C15.0251 3.85355 15.9749 3.85355 16.5607 4.43934Z" fill=""/>
    </svg>

  )
}
