/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer/Footer";
import "./Home.css";
import Spinner from "react-bootstrap/Spinner";
import { NewsCard } from "../../components/cards/NewsCard";
import { exportApi } from "../../../services/indoreTalk.api";
import { useDispatch, useSelector } from "react-redux";
import { setPreviousSubCategoryExplore } from "../../../redux/slices/subCat";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icons } from "../../components/Icons/Icon";
import NavBar from "../../components/navbar/navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import {
  likePayloads,
  unnecatchLogout,
} from "../../../utils/exportGlobleFunction";
import { Skeleton } from "../../components/cards/Skeleton";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import { useSwipeable } from "react-swipeable";
import { apiCallingLimit } from "../../../utils/constGlobleData";
import { Toast } from "react-bootstrap";
import { setSignOut } from "../../../redux/slices/authSlice";
import store from "store";

export const HomeLayout = () => {
  const subCategories = useSelector((state) => state.subCat);
  const subCatNav = subCategories ? subCategories.subExploreCategory : [];
  const { tempLogin, redirectionPath } = useSelector((state) => state.auth);
  const [like, setLike] = useState();
  const userId = tempLogin?.user?.data?.social_provider;
  const navigate = useNavigate();
  const [limitNews, setLimitNews] = useState(0);
  const location = useLocation();
  const [catId, setCatId] = useState(
    subCategories.previousSubCategoryExplore > 0
      ? subCategories.previousSubCategoryExplore
      : redirectionPath.pathBoolean
      ? redirectionPath.activePathCat
      : subCatNav[0]?.id ||
        parseInt(localStorage.getItem("exploreFirstCategory"))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [liveDataHide, setLiveDataHide] = useState(true);
  const [news, setNews] = useState([]);
  const [liveNewsData, setLiveNewsData] = useState([]);
  const [infinite, setInfinite] = useState(false);
  const [activeSubCat, setActiveSubCat] = useState(
    subCategories.previousSubCategoryExplore > 0
      ? subCategories.previousSubCategoryExplore
      : catId
  );
  const dispatch = useDispatch();
  const [errorToast, setErrorToast] = useState(false);
  const [liketnDisabled, setLiketnDisabled] = useState(false);
  const [likeStates, setLikeStates] = useState({});
  const [tempLike, setTempLike] = useState({});
  const [sessionExpire, setSessionExpire] = useState(false);
  const [newsBlock, setNewsBlock] = useState(false);

  useEffect(() => {
    fetchNews(
      subCategories.previousSubCategoryExplore > 0
        ? subCategories.previousSubCategoryExplore
        : like
        ? activeSubCat
        : catId,
      userId?.user_id || 0
    );
    liveNews(
      subCategories.previousSubCategoryExplore > 0
        ? subCategories.previousSubCategoryExplore
        : like
        ? activeSubCat
        : catId,
      userId?.user_id || 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchNews = async (id, user_id, page) => {
    try {
      setIsLoading(false);
      setActiveSubCat(id);
      setLimitNews(page || 0);
      const res = await exportApi.exploreNewsGet({
        page: page || 0,
        limit: apiCallingLimit,
        category_id: id,
        user_id: user_id || 0,
      });
      setInfinite(res.data.length < apiCallingLimit);

      if (res.status === "success") {
        const filteredBusiness = news.filter((item) => item.category_id === id);
        setNews([...filteredBusiness, ...res.data]);
        setLiketnDisabled(false);
        setTempLike({});
        setLikeStates({});
      }
      setLikeStates({});
      setTempLike({});
      setIsLoading(true);
      setLiketnDisabled(false);
    } catch (err) {
      setLikeStates({});
      setTempLike({});
      console.error("Error fetching news:", err);
      setLiketnDisabled(false);
    }
  };

  const liveNews = async (id, user_id) => {
    try {
      const res = await exportApi.exploreLiveNewsGet({
        page: 0,
        category_id: id,
        user_id: user_id || 0,
      });
      if (res.status === "success") {
        setLiveNewsData(res.data);
        setLiveDataHide(false);
      } else {
        console.warn("Error fatching live news:");
      }
    } catch (error) {
      console.log("Error fetching news", error);
    }
  };

  const subCatNavChange = (subId) => {
    dispatch(setPreviousSubCategoryExplore(0));
    setLiveDataHide(true)
    setNewsBlock(true);
    setIsLoading(false);
    setNews([]);
    fetchNews(subId, userId?.user_id, 0);
    liveNews(subId, userId?.user_id);
    setActiveSubCat(subId);
    setCatId(subId);
    document.getElementById("scrollableDiv").scrollTop = 0;
  };

  const [showLoginModal, setLoginModalShow] = useState(false);
  const handleLikeClick = async (e, data) => {
    e.stopPropagation();
    if (!userId) {
      setLoginModalShow(true);
      setLiketnDisabled(false);
      setLikeStates({});
      return;
    }

    try {
      let indexToUpdate;
      let itemsToUpdate;

      if (data.explore_type === "news") {
        indexToUpdate = news.findIndex((item) => item.id === data.id);
        itemsToUpdate = news;
      } else if (data.explore_type === "live") {
        indexToUpdate = liveNewsData.findIndex((item) => item.id === data.id);
        itemsToUpdate = liveNewsData;
      } else {
        console.error("Unknown explore type:", data.explore_type);
        setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
        return;
      }
      if (indexToUpdate === -1) {
        console.error("Item not found:", data.id);
        setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
        return;
      }

      const likeResponse = await exportApiAuth.likePost(
        likePayloads(data, userId)
      );
      if (likeResponse.status === "success") {
        const likedItemData = Array.isArray(likeResponse.data)
          ? likeResponse.data[0]
          : likeResponse.data;
        itemsToUpdate[indexToUpdate] = likedItemData;

        // if (data.explore_type === "news") {
        //   setNews([...news]);
        // } else if (data.explore_type === "live") {
        //   setLiveNewsData([...liveNewsData]);
        // }
        setLiketnDisabled(false);
        setLikeStates({});
      } else {
        // if (likeResponse.code === 401) {
        setLoginModalShow(true);
        setSessionExpire(true);
        unnecatchLogout(store, dispatch, setSignOut);
        // }
        // setErrorToast(true);
        setLiketnDisabled(false);
        setLikeStates({});
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorToast(true);
      setSessionExpire(false);
      setLiketnDisabled(false);
      setLikeStates({});
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setLiveDataHide(true);
      const i =
        Array.isArray(subCategories.subExploreCategory) &&
        subCategories.subExploreCategory.findIndex(
          (item) => item.id === activeSubCat
        );
      if (subCategories.subExploreCategory.length > i + 1) {
        const swipeSubId = subCategories.subExploreCategory[i + 1]?.id;
        subCatNavChange(swipeSubId);
      } else {
        console.log("Cannot swipe left, no more items in subExploreCategory");
      }
    },
    onSwipedRight: () => {
      setLiveDataHide(true);
      const i =
        Array.isArray(subCategories.subExploreCategory) &&
        subCategories.subExploreCategory.findIndex(
          (item) => item.id === activeSubCat
        );
      if (0 < i) {
        const swipeSubId = subCategories.subExploreCategory[i - 1]?.id;
        subCatNavChange(swipeSubId);
      } else {
        console.log("Cannot swipe right, no more items in subExploreCategory");
      }
    },
  });
  return (
    <div>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center ">
          <div className="container-fluid mx-auto text-center pr-3">
            <div className="m-auto pl-28 ">
              <h1 className="f-24 f-w600 mb-0">Explore</h1>
            </div>
            <div>
              <span
                onClick={() => navigate("/home/search")}
                className="cursor-pointer"
              >
                <Icons name="search" className="svg-size-24 svg-white" />
              </span>
            </div>
          </div>
        </nav>

        {/* ..............................................Header icons................................................*/}

        <div className="top-header">
          <NavBar
            subCatNav={subCatNav}
            activeSubCat={activeSubCat}
            subCatNavChange={subCatNavChange}
          />
        </div>
      </div>

      <div id="scrollableDiv" className="auto-load-table" {...handlers}>
        <InfiniteScroll
          dataLength={news?.length || apiCallingLimit}
          next={() => {
            console.log("Next function called");
            fetchNews(
              subCategories.previousSubCategoryExplore > 0
                ? subCategories.previousSubCategoryExplore
                : like
                ? activeSubCat
                : activeSubCat
                ? activeSubCat
                : catId,
              userId?.user_id || 0,
              limitNews + 1
            );
          }}
          hasMore={!infinite}
          loader={
            !infinite && (
              <div className="d-flex justify-center mt-2">
                <Spinner animation="border" className=" " />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {liveDataHide &&
            (Array.isArray(liveNewsData) &&
            liveNewsData.filter((event) => event.is_active !== 0).length > 0 &&
            isLoading ? (
              <NewsCard
                news={liveNewsData}
                handleLikeClick={handleLikeClick}
                setLiketnDisabled={setLiketnDisabled}
                liketnDisabled={liketnDisabled}
                setTempLike={setTempLike}
                tempLike={tempLike}
                likeStates={likeStates}
                setLikeStates={setLikeStates}
              />
            ) : (
              !isLoading && <Skeleton number={1} />
            ))}
          {news?.length !== 0 &&
          Array.isArray(news) &&
          news.filter((event) => event?.is_active !== 0).length > 0 ? (
            <NewsCard
              news={news}
              handleLikeClick={handleLikeClick}
              setLiketnDisabled={setLiketnDisabled}
              liketnDisabled={liketnDisabled}
              setTempLike={setTempLike}
              tempLike={tempLike}
              likeStates={likeStates}
              setLikeStates={setLikeStates}
            />
          ) : !isLoading ? (
            <Skeleton number={5} />
          ) : (
            <p
              className="text-black text-center f-16"
              style={{ marginTop: "36vh" }}
            >
              No news found!
            </p>
          )}
        </InfiniteScroll>
      </div>
      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
          loginResion={sessionExpire}
        />
      )}
      {errorToast && (
        <Toast
          onClose={() => setErrorToast(false)}
          show={errorToast}
          delay={3000}
          className=""
          autohide={true}
          style={{ marginTop: "-60px", width: "100%", zIndex: "999" }}
        >
          <Toast.Body style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}>
            {"Somthing went wrong"}
          </Toast.Body>
        </Toast>
      )}
      <Footer
        fetchdata={fetchNews}
        clickBlock={newsBlock}
        setClickBlock={setNewsBlock}
      />
    </div>
  );
};
