import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
    eventData:{},
    listingData:{}
}

const dataSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        setExploreData: (state, action) => {
            state.data = action?.payload
        },
        setEventData: (state, action) => {
            state.eventData = action?.payload
        },
        setListingData: (state, action) => {
            state.listingData = action?.payload
        }
    },

})

export const { setExploreData, setEventData, setListingData } = dataSlice.actions

export default dataSlice.reducer
