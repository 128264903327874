import React from 'react'

export const AboutUs = ({
    className = "",
    width = "42px",
    height = "42px",
    viewBox = "0 0 42 42",
    fill = "",
  }) => {

    return (        
            <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
        <circle cx="21.4434" cy="21.0103" r="21" fill="#E31E24"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.4436 11.8302C16.3732 11.8302 12.2636 15.9398 12.2636 21.0102C12.2636 26.0796 16.3732 30.1902 21.4436 30.1902C26.5128 30.1902 30.6236 26.0795 30.6236 21.0102C30.6236 15.9399 26.5129 11.8302 21.4436 11.8302ZM10.6436 21.0102C10.6436 15.0451 15.4785 10.2102 21.4436 10.2102C27.4075 10.2102 32.2436 15.0451 32.2436 21.0102C32.2436 26.9742 27.4075 31.8102 21.4436 31.8102C15.4784 31.8102 10.6436 26.9741 10.6436 21.0102ZM21.4486 26.1898C21.0013 26.1898 20.6386 25.8271 20.6386 25.3798V20.6072C20.6386 20.1599 21.0013 19.7972 21.4486 19.7972C21.896 19.7972 22.2586 20.1599 22.2586 20.6072L22.2586 25.3798C22.2586 25.8271 21.896 26.1898 21.4486 26.1898ZM21.4488 18.2606C22.0453 18.2606 22.5288 17.7771 22.5288 17.1806C22.5288 16.5842 22.0453 16.1006 21.4488 16.1006H21.438C20.8416 16.1006 20.358 16.5842 20.358 17.1806C20.358 17.7771 20.8416 18.2606 21.438 18.2606H21.4488Z" fill="white"/>
</svg>


    )
}