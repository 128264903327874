import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  singleData: {},
  singleBusinessData: {},
  ticketCount: [],
  apiTicketData: [],
  subSubCat: 0,
};

const singledataSlice = createSlice({
  name: "singleData",
  initialState: initialState,
  reducers: {
    setsingleData: (state, action) => {
      state.singleData = action?.payload;
    },
    setSubSubCat: (state, action) => {
      state.subSubCat = action?.payload;
    },
    setTicketCount: (state, action) => {
      if (action.payload.length === 0) {
        // Resetting the apiTicketData array
        state.ticketCount = [];
      } else {
        const { ticketName, value } = action?.payload;
        // Check if the ticketName already exists in the array
        const existingTicketIndex = state.ticketCount.findIndex(
          (ticket) => ticket.ticketName === ticketName
        );
        const totalAmount = action?.payload?.ticketDetails?.per_amount * value;
        if (value === 0) {
          // Remove the ticket entry if the value is 0 and the ticket exists
          state.ticketCount.splice(existingTicketIndex, 1);
        } else if (existingTicketIndex !== -1) {
          // If ticketName exists, update its ticketNumber and totalAmount
          state.ticketCount[existingTicketIndex].ticketNumber = isNaN(value)
            ? 0
            : value;
          state.ticketCount[existingTicketIndex].totalAmount = isNaN(
            totalAmount
          )
            ? 0
            : totalAmount;
        } else if (value !== 0) {
          // If ticketName doesn't exist and value is not 0, add a new entry to the array
          state.ticketCount.push({
            ticketName,
            ticketNumber: isNaN(value) ? 0 : value,
            totalAmount: isNaN(totalAmount) ? 0 : totalAmount,
          });
        }
      }
    },

    setApiTicketData: (state, action) => {
      if (action.payload.length === 0) {
        // Resetting the apiTicketData array
        state.apiTicketData = [];
      } else {
        const { ticketName, value } = action?.payload;
        // Check if the ticketName already exists in the array
        const existingTicketIndex = state.apiTicketData.findIndex(
          (ticket) => ticket.ticketName === ticketName
        );
        const totalAmount = action?.payload?.ticketDetails?.per_amount * value;
        if (value === 0) {
          // Remove the ticket entry if the value is 0 and the ticket exists
          state.apiTicketData.splice(existingTicketIndex, 1);
        } else if (existingTicketIndex !== -1) {
          // If ticketName exists, update its ticketNumber
          state.apiTicketData[existingTicketIndex].buySeats =
            value === "NaN" ? 0 : value;
          state.apiTicketData[existingTicketIndex].totalAmount =
            totalAmount === "NaN" ? 0 : totalAmount;
          state.apiTicketData[existingTicketIndex].totalTicket =
            parseInt(action?.payload?.ticketDetails?.ticketnumber) -
            parseInt(isNaN(value) ? 0 : value);
        } else {
          // If ticketName doesn't exist, add a new entry to the array
          state.apiTicketData.push({
            ticketName,
            ticket_id: action?.payload?.ticketDetails?.id,
            buySeats: isNaN(value) ? 0 : value,
            perSeat_amount: action?.payload?.ticketDetails?.per_amount,
            totalAmount: isNaN(totalAmount) ? 0 : totalAmount,
            totalTicket:
              parseInt(action?.payload?.ticketDetails?.ticketnumber) -
              parseInt(isNaN(value) ? 0 : value),
          });
        }
      }
    },
    setsingleBusinessData: (state, action) => {
      state.singleBusinessData = action?.payload;
    },
    clearTicketCount: (state) => {
      state.ticketCount = [];
    },
  },
});

export const {
  setsingleData,
  setSubSubCat,
  setTicketCount,
  setApiTicketData,
  setsingleBusinessData,
  clearTicketCount,
} = singledataSlice.actions;

export default singledataSlice.reducer;
