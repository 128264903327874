import React from 'react'
import { Card, Placeholder } from 'react-bootstrap'

export const SingleSkeleton = ({number=1}) => {
    const skelElement = [];
    for (let index = 0; index < number; index++) {
        skelElement[index] = (
            <div className="d-flex justify-content-around mt-3" key={index}>
                <Card style={{ width: '100%' }}>
                    <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} style={{ height: '30vh' }} />
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} style={{ height: '2vh' }} />
                            <Placeholder xs={2} style={{ height: '1vh' }} />
                            <Placeholder xs={{ span: 4, offset: 6 }} style={{ height: '1vh' }} />
                            <Placeholder xs={12} style={{ height: '5vh' }} />
                        </Placeholder>
                    </Card.Body>
                </Card>
            </div>
        )
    }
    return (skelElement);
}