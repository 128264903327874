import React from 'react';

export const Star = ({
    className = "",
    width = "20px",
    height = "20px",
    viewBox = "0 0 20 20",
    fill = "",

}) => {


    return (

        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
 <path d="M10.8139 2.59466L12.6693 6.32322C12.806 6.59336 13.0669 6.78095 13.3678 6.82264L17.5354 7.42962C17.7787 7.4638 17.9996 7.5922 18.1488 7.78813C18.2964 7.98156 18.3597 8.22669 18.3239 8.46764C18.2947 8.66775 18.2005 8.85284 18.0563 8.99458L15.0365 11.9219C14.8156 12.1262 14.7156 12.4288 14.7689 12.7248L15.5124 16.8402C15.5916 17.3372 15.2624 17.8057 14.7689 17.8999C14.5655 17.9325 14.3572 17.8983 14.1738 17.8049L10.4563 15.8681C10.1804 15.7288 9.85454 15.7288 9.57865 15.8681L5.86118 17.8049C5.40442 18.0475 4.83846 17.8824 4.58424 17.4322C4.49005 17.2529 4.45671 17.0487 4.48755 16.8494L5.23105 12.7331C5.28439 12.438 5.18354 12.1337 4.96349 11.9294L1.94367 9.00375C1.58443 8.65691 1.57359 8.08578 1.9195 7.72643C1.927 7.71893 1.93534 7.71059 1.94367 7.70226C2.08704 7.55635 2.27541 7.4638 2.47879 7.43962L6.64635 6.83181C6.94641 6.78929 7.2073 6.60336 7.34483 6.33156L9.13355 2.59466C9.29275 2.2745 9.62282 2.07523 9.98123 2.08356H10.0929C10.4038 2.12108 10.6747 2.31368 10.8139 2.59466" fill="#FFB800"/>
</svg>





    );
};
