import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Icons } from "../../components/Icons/Icon";
import { useNavigate } from 'react-router-dom';
import './onboarding.css'
 
export const Onboarding = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate()
 
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };
 
  const ticketRenderer = () => {
    return (
      <>
        <div className="ticketbanner-center d-flex justify-content-center">
          <Icons name="ticketbanner" />
        </div>
        <Carousel.Caption>
          <h2 className="f-22 text-brand">Events</h2>
          <p className="text-dark-white">
            From cultural festivals to local gigs, find out what's happening
            around you.
          </p>
        </Carousel.Caption>
      </>
    );
  };
 
  const annoucementRenderer = () => {
    return (
      <>
        <div className="announcementbanner-center d-flex justify-content-center" >
          <Icons name="announcementbanner" />
        </div>
        <Carousel.Caption>
          <h2 className="f-22 text-brand">Explore Latest News</h2>
          <p className="text-dark-white">
            Stay informed with real-time updates and in-depth reports on
            Indore's happenings.
          </p>
        </Carousel.Caption>
      </>
    );
  };
 
  const cartRenderer = () => {
    return (
      <>
        <div className="cartbanner-center d-flex justify-content-center">
          <Icons name="cartbanner" />
        </div>
        <Carousel.Caption>
          <h2 className="f-22 text-brand">New and Nearby Businesses</h2>
          <p className="text-dark-white">
            Explore Indore's vibrant marketplace with our guide to the newest
            and nearest businesses.
          </p>
        </Carousel.Caption>
      </>
    );
  };
 
  const totalSlides = 3;
 
  const handleSkip = () => {
    navigate('/home')
  };
 
  const handleNext = () => {
    const nextIndex = activeIndex + 1;
 
    if (nextIndex < totalSlides) {
      setActiveIndex(nextIndex);
    } else {
      navigate('/home')
    }
  };
 
  useEffect(() => {
    // Add the class to the body element when the component mounts
    document.body.classList.add('body-color-black');
 
    // Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('body-color-black');
    };
  }, []);
 
  return (
    <>
      <div >
        <div className="home-slider">
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            interval={null}
            wrap={false}
          >
           
            <Carousel.Item>{annoucementRenderer()}</Carousel.Item>
            <Carousel.Item>{cartRenderer()}</Carousel.Item>
            <Carousel.Item>{ticketRenderer()}</Carousel.Item>
          </Carousel>
        </div>
        <div className="onbord-btn d-flex justify-content-between">
          <button
            type="button"
            className={`btn btn-light btn-tpt text-dark-white f-18 f-w4500 ${activeIndex === totalSlides - 1 ? 'custom-disabled' : ''}`}
            onClick={handleSkip}
            disabled={activeIndex === totalSlides - 1}
            style={{backgroundColor:'black'}}
         
          >
            Skip
          </button>
          <button
            type="button"
            className="btn btn-light btn-tpt mr-2 f-18 f-w4500"
            onClick={handleNext}
            style={{color:'#D2D2D7'}}
          >
            Next
          </button>
        </div>
 
      </div>
    </>
  );
};