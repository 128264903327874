import React from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This will go back to the previous page in history
  };

  return (
    <>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center " >
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className='m-auto pr-24'>
              <h1 className="f-24 f-w600 mb-0">{'Privacy Policy'}</h1>
            </div>

          </div>
        </nav>
      </div>

      <div>
        <div className="scroll-without-category">
          <strong className=" f-14 f-w600 ">Your privacy is important to us.</strong>
          <p className=" f-14 f-w400 mt-2">It is Indore Talk’s policy to respect your privacy regarding any information we may collect while operating our website and mobile application. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose, and otherwise make use of personal information. Below we have outlined our privacy policy. </p>

          <strong className=" f-14 f-w600 ">Information Collection and Use: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.
            </li>
            <li className="mb-2">
              Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.
            </li>
          </ul>

          <strong className=" f-14 f-w600 ">User-Generated Content: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              User-generated content, such as comments on posts, is moderated and can be reviewed, edited, or deleted by administrators to ensure compliance with our community standards.
            </li>
            <li className="mb-2">
              We collect data associated with user-generated content, including usernames and contact information, solely for the purpose of maintaining the integrity of our commenting system and communicating with users about their comments when necessary.
            </li>
          </ul>

          <strong className=" f-14 f-w600 ">Data Integrity and Purpose Limitation: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we obtain the consent of the individual concerned or as required by law.
            </li>
            <li className="mb-2">
              Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.
            </li>
          </ul>

          <strong className=" f-14 f-w600 ">Data Security: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              We will protect personal information by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification.
            </li>
            <li className="mb-2">
              Specific security measures are in place to protect the data associated with user-generated content, ensuring both the security and confidentiality of this information.
            </li>
          </ul>

          <strong className=" f-14 f-w600 ">Transparency and Access: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              We will make readily available to customers information about our policies and practices relating to the management of personal information.             </li>
            <li className="mb-2">
              Users have the right to access, correct, or request deletion of their personal information, including any user-generated content.             </li>
          </ul>

          <strong className=" f-14 f-w600 ">Retention of Personal Information: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              We will only retain personal information for as long as necessary for the fulfillment of those purposes. Information associated with user-generated content is retained as long as the user account is active or as needed to provide services and comply with our legal obligations. </li>
          </ul>

          <strong className=" f-14 f-w600 ">Account Deletion: </strong>
          <ul className="mt-2">
            <li className="mb-2">
            We're sorry to see you go! We wanted to take a moment to thank you for being a part of Indore Talk community. If there's anything we can do to improve your experience in the future or if you have any feedback for us, feel free to reach out us feedback@indoretalk.com               </li>
            <li className="mb-2">
            Deleting your account will also remove all related data associated with your account on Indore Talk App.              </li>
            
          </ul>

          <strong className=" f-14 f-w600 ">Changes to This Policy: </strong>
          <ul className="mt-2">
            <li className="mb-2">
              Indore Talk may change this privacy policy from time to time at Indore Talk’s sole discretion. We commit to reviewing our privacy policy regularly and adjusting it as necessary to meet changes in regulation and technology.                   </li>
            <li className="mb-2">
              Significant changes to our privacy policy will be communicated to users through our website and application, and, where applicable, through direct notification.                       </li>
          </ul>

<p className=" f-14 f-w400 mt-2">We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. </p>          

        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
