import React, { useState } from "react";
import { Card, Carousel } from "react-bootstrap";
import "./EventCard.css";
import { Button } from "../form-type/button";
import { Icons } from "../Icons/Icon";
import {
  dateFormat,
  dateFormate,
  dateFormatting,
  formatDate,
  formatLikeCount,
  shareBlockFunction,
  sliceCharacters,
} from "../../../utils/exportGlobleFunction";
import { useDispatch } from "react-redux";
import { setPreviousSubCategoryEvent } from "../../../redux/slices/subCat";
import { useNavigate } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";
import {
  cardHeadingLimit,
  cardLocationLimit,
  eventLikeCount,
  eventViewCount,
  getPlatform,
} from "../../../utils/constGlobleData";
import {
  setApiTicketData,
  setTicketCount,
} from "../../../redux/slices/singleDataSlice";

export const EventCard = (props) => {
  const dispatch = useDispatch();
  const booking = props?.events;
  const events = props?.events;
  const navigate = useNavigate();
  const date = new Date();
  const exploreEventById = async (event) => {
    try {
      dispatch(setPreviousSubCategoryEvent(event.category_id));
      dispatch(setApiTicketData([]));
      dispatch(setTicketCount([]));
      navigate(`event/${event.id}`);
    } catch (error) {
      console.error("error", error);
    }
  };

  const exploreBookingById = async (event) => {
    try {
      navigate(`/my-booking/${event.id}`, {
        state: { myBookingShow: props?.myBookingShow },
      });
    } catch (error) {
      console.error("error", error);
    }
  };
  const myBooking = (event) => {
    try {
      navigate(`/my-booking/ticket/${event.event_id}`);
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleClick = (e, item) => {
    dispatch(setPreviousSubCategoryEvent(0));

    if (!props.liketnDisabled) {
      props.setTempLike(item);
      props?.setLiketnDisabled(true);
      props.setLikeStates((prevStates) => ({
        ...prevStates,
        [item.id]: !prevStates[item.id], // Toggle the like state
      }));
      props.handleLikeClick(e, item);
    }
  };
  const [shareBlock, setShareBlock] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const handleShareLink = async (e, item) => {
    e.stopPropagation();
    shareBlockFunction(setShareBlock);
    if (!shareBlock) {
      const bannerImageUrl = item.bannerimage ? item.bannerimage[0] : "";
      const itemId = item.id;
      const urlWithEvent = window.location.href + "/event/" + itemId;
      const shareParams = {
        imageUrl: bannerImageUrl,
        title: item.heading,
        description: item.aboutevent.replace(/<[^>]+>/g, ""),
        url: urlWithEvent,
      };
      const urlAsString = String(urlWithEvent);
      if (getPlatform() === "Android") {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          // // eslint-disable-next-line no-undef
          const { title, description, imageUrl } = shareParams;
          // Call Android.callShareIntent with individual strings
          // eslint-disable-next-line no-undef
          Android.callShareIntent(title, urlAsString, description, imageUrl);
        }
      }else if(getPlatform() === "Mac OS" || getPlatform() === "iOS"){
        const { title, url, description, imageUrl } = shareParams;
        window.webkit.messageHandlers.share.postMessage(shareParams);
      } 
       else {
        if (navigator.share) {
          await navigator.share(shareParams);
        } else {
          shareOnMobile(shareParams);
        }
      }
    }
  };
  // const filteredEvents = Array.isArray(events)
  //   ? events.filter((event) =>
  //       props.iosPaidEventFilter === "iOS" ||
  //       props.iosPaidEventFilter === "Mac OS"
  //         ? event.event_ticket_type !== "paid" && event.is_active !== 0
  //         : event.is_active !== 0
  //     )
  //   : [];

  const filteredEvents = Array.isArray(events)
    ? events.filter((event) => event.is_active !== 0) : [];
  return (
    <div className="eventCard">
      {props?.myBookingShow ? (
        booking
          ?.filter((item) => item.eventData.length !== 0)
          .map((event, index) => {
            const expireDate = new Date(event?.eventData[0]?.expiration_time);
            return (
              <Card
                className={`my-3 ${!(expireDate >= date) ? "" : ""}`}
                key={index}
                onClick={() => myBooking(event && event)}
              >
                {event?.eventData[0]?.bannerimage &&
                  Array.isArray(JSON.parse(event?.eventData[0]?.bannerimage)) &&
                  JSON.parse(event?.eventData[0]?.bannerimage).length > 0 ? (
                  <Carousel
                    autoPlay={false}
                    interval={null}
                    controls={
                      event?.eventData[0]?.bannerimage &&
                        JSON.parse(event?.eventData[0]?.bannerimage).length > 1
                        ? true
                        : false
                    }
                    indicators={true}
                    activeIndex={0}
                  >
                    {event?.eventData[0]?.bannerimage &&
                      JSON.parse(event?.eventData[0]?.bannerimage).map(
                        (image, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <div className="dark-image">
                                <Card.Img
                                  variant="top"
                                  style={{
                                    maxnHeight: "70vh",
                                    minHeight: "30vh",
                                  }}
                                  src={image}
                                />
                              </div>
                            </Carousel.Item>
                          );
                        }
                      )}
                    <div className="card-text-overlay">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 d-flex alignCenter">
                            <div className="eventDate">
                            {dateFormat(event?.eventData[0].start_time)}{" "}
                            </div>
                            <div className="ms-1 mt-1">
                              <h2 className="f-14 f-w600 mb-0">
                                {sliceCharacters(
                                  event?.eventData[0].heading,
                                  cardHeadingLimit
                                )}
                              </h2>
                              <div className="d-flex alignItemsStart">
                                <Icons
                                  name="location"
                                  className="svg-white svg-size-14"
                                />
                                <h4 className="text-dark-white f-fw500 event-address-text f-10 mb-6 pt-3">
                                  {sliceCharacters(
                                    event?.eventData[0].addressevent,
                                    cardLocationLimit
                                  )}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                ) : (
                  <>
                    <div className="dark-image">
                      <Card.Img
                        variant="top"
                        style={{ maxnHeight: "70vh", minHeight: "30vh" }}
                        src={
                          event?.eventData[0]?.bannerimage &&
                          JSON.parse(event?.eventData[0]?.bannerimage)[0]
                        }
                      />
                      <div className="card-text-overlay-2">
                        <div className="container">
                          <div className="row">
                            <div className="col-12 d-flex alignCenter">
                              <div className="eventDate">
                              {dateFormat(event?.eventData[0].start_time)}{" "}
                              </div>
                              <div className="ms-1 mt-1">
                                <h2 className="f-14 f-w600 mb-0">
                                  {sliceCharacters(
                                    event?.eventData[0].heading,
                                    cardHeadingLimit
                                  )}
                                </h2>
                                <div className="d-flex alignItemsStart">
                                  <Icons
                                    name="location"
                                    className="svg-white svg-size-14"
                                  />
                                  <h4 className="text-dark-white f-fw500 event-address-text f-10 mb-6 pt-3">
                                    {sliceCharacters(
                                      event?.eventData[0].addressevent,
                                      cardLocationLimit
                                    )}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <Card.Footer className="">
                  <div className="d-flex justify-content-between">
                    <form action="">
                      <div className="d-flex align-items-center">
                        {!props?.myBookingShow && (
                          <div
                            className="like-svg-and-count d-flex align-items-center"
                            onClick={(e) => handleClick(e, event)}
                          >
                            <Icons
                              name="like"
                              className={` img-fluid cursor-pointer ${event.like === 1 ||
                                  props?.tempLike?.id === event.id
                                  ? "svg-red"
                                  : "svg-white"
                                }`}
                            />
                            <h4 className="f-12 f-w500 mb-0 text-dark m-t-2 lh-18">
                              {props?.liketnDisabled
                                ? event.like === 1
                                  ? event.like_count - 1 + eventLikeCount
                                  : event.like_count + 1 + eventLikeCount
                                : event.like_count + eventLikeCount}
                            </h4>
                          </div>
                        )}
                        <span
                          onClick={(e) => handleShareLink(e, event)}
                          className="cursor-pointer"
                        >
                          <Icons
                            name="share"
                            className="img-fluid svg-white ms-2"
                          />
                        </span>
                        <div className="like-svg-and-count d-flex align-items-center">
                          <Icons
                            name="show"
                           
                            className="img-fluid cursor-pointer svg-white"
                          />
                          <h4 className="f-14 f-w500 mb-0 pl-2 text-like m-t-2 lh-18">
                            {formatLikeCount(event.view_count + eventViewCount)}
                          </h4>
                        </div>
                      </div>
                    </form>

                    {props?.myBookingShow ? (
                      <Button
                        className="link event-button"
                        label={
                          <>
                            View Details{" "}
                            <Icons
                              name="arrow-right"
                              className="svg-white"
                              viewBox="0 1 16 16"
                            />
                          </>
                        }
                        onClick={(e) => exploreEventById(event)}
                      />
                    ) : (
                      <Button
                        className="link event-button"
                        label={
                          <>
                            View Info{" "}
                            <Icons
                              name="arrow-right"
                              className="svg-white"
                              viewBox="0 1 16 16"
                            />
                          </>
                        }
                        onClick={(e) => exploreBookingById(event)}
                      />
                    )}
                  </div>
                </Card.Footer>
              </Card>
            );
          })
      ) : filteredEvents.length === 0 ? (
        <p
          className="text-black text-center f-16"
          style={{ marginTop: "36vh" }}
        >
          No event found!
        </p>
      ) : (
        filteredEvents.map((event, index) => {
          return (
            <Card
              className={`my-3 ${event.is_active === 0 ? "disabled" : ""}`}
              key={index}
              onClick={
                event.is_active === 0 ? null : () => exploreEventById(event)
              }
            >
              {event.bannerimage.length >= 0 ? (
                <Carousel
                  autoPlay={false}
                  interval={null}
                  controls={false}
                  indicators={true}
                  activeIndex={0}
                >
                  {event?.bannerimage?.length > 0 &&
                    event.bannerimage.map((image, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <div className="dark-image">
                            <Card.Img
                              variant="top"
                              style={{
                                maxnHeight: "70vh",
                                minHeight: "30vh",
                              }}
                              src={image}
                            />
                          </div>
                        </Carousel.Item>
                      );
                    })}

                  <div className="card-text-overlay">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 d-flex alignCenter">
                          <div className="eventDate">
                          {dateFormate(event?.start_time)}{" "}
                          </div>

                          <div className="ms-1 mt-1">
                            <h2 className="f-14 f-w600 mb-0">
                              {sliceCharacters(event.heading, cardHeadingLimit)}
                            </h2>
                            <div className="d-flex alignItemsStart">
                              <Icons
                                name="location"
                                className="svg-white svg-size-14"
                              />
                              <h4 className="text-dark-white f-fw500 event-address-text f-10 mb-6 pt-3">
                                {sliceCharacters(
                                  event.addressevent,
                                  cardLocationLimit
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel>
              ) : (
                <div className="dark-image">
                  <Card.Img
                    variant="top"
                    style={{ maxHeight: "200px", minHeight: "30vh" }}
                    src={event.bannerimage ? event.bannerimage[0] : ""}
                  />
                </div>
              )}

              <Card.Footer className="">
                <div className="d-flex justify-content-between">
                  <form action="">
                    <div className="d-flex align-items-center">
                      {props?.liketnDisabled && props.likeStates[event.id] ? (
                        <div className="like-svg-and-count d-flex align-items-center">
                          <Icons
                            name="like"
                            onClick={(e) => handleClick(e, event)}
                            className={` img-fluid cursor-pointer 
                              ${props.tempLike.id === event.id
                                ? props.tempLike.like === 0
                                  ? "svg-red"
                                  : "svg-white"
                                : props.tempLike.like === 1
                                  ? "svg-white"
                                  : "svg-red"
                              }
                              `}
                          />
                          <h4 className="f-14 f-w500 mb-0 pl-2 text-dark m-t-2 lh-18">
                            {props.tempLike.id === event.id &&
                              event.like_count < 1000
                              ? props.tempLike.like !== 0
                                ? event.like_count - 1 + eventLikeCount
                                : event.like_count + 1 + eventLikeCount
                              : formatLikeCount(event.like_count + eventLikeCount)}
                          </h4>
                        </div>
                      ) : (
                        <div className="like-svg-and-count d-flex align-items-center">
                          <Icons
                            name="like"
                            onClick={(e) => handleClick(e, event)}
                            className={` img-fluid cursor-pointer ${event.like === 1 ? "svg-red" : "svg-white"
                              }`}
                          />
                          <h4 className="f-14 f-w500 mb-0 pl-2 text-dark m-t-2 lh-18">
                            {formatLikeCount(event.like_count + eventLikeCount)}
                          </h4>
                        </div>
                      )}
                      <span
                        onClick={(e) => handleShareLink(e, event)}
                        className="cursor-pointer like-svg-and-count"
                      >
                        <Icons
                          name="share"
                          className="img-fluid svg-white ms-2 "
                        />
                      </span>
                      <div className="like-svg-and-count d-flex align-items-center">
                          <Icons
                            name="show"
                           
                            className="img-fluid cursor-pointer svg-white"
                          />
                          <h4 className="f-14 f-w500 mb-0 pl-2 text-like m-t-2 lh-18">
                            {formatLikeCount(event.view_count + eventViewCount)}
                          </h4>
                        </div>
                    </div>
                  </form>
                  <Button
                    className="link event-button"
                    label={
                      <>
                        View Info{" "}
                        <Icons
                          name="arrow-right"
                          className="svg-white svg-size-11"
                        />
                      </>
                    }
                  />
                </div>
              </Card.Footer>
            </Card>
          );
        })
      )}
    </div>
  );
};
