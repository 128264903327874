import React from 'react'

export const WhatsappLink = ({
    className = "",
    width = "20px",
    height = "20px",
    viewBox = "0 0 20 20",
    fill = "",
    onClick
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} onClick={onClick} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
         <g clip-path="url(#clip0_3652_9486)">
<path d="M16.3468 4.65956C15.5214 3.82586 14.5383 3.16484 13.4548 2.71506C12.3713 2.26527 11.2091 2.03573 10.036 2.03982C5.12058 2.03982 1.11444 6.04595 1.11444 10.9613C1.11444 12.5368 1.52856 14.0672 2.30278 15.4176L1.04242 20.0449L5.76876 18.8026C7.07413 19.5138 8.54155 19.8919 10.036 19.8919C14.9514 19.8919 18.9575 15.8857 18.9575 10.9703C18.9575 8.58467 18.0302 6.34304 16.3468 4.65956ZM10.036 18.3794C8.70359 18.3794 7.39822 18.0193 6.2549 17.3442L5.98482 17.1821L3.17602 17.9203L3.92324 15.1835L3.74319 14.9045C3.00295 13.7224 2.60989 12.3561 2.60886 10.9613C2.60886 6.87419 5.93981 3.54324 10.027 3.54324C12.0075 3.54324 13.8711 4.31746 15.2665 5.72186C15.9574 6.40962 16.5049 7.22768 16.8774 8.12863C17.2498 9.02957 17.4397 9.99547 17.4361 10.9703C17.4541 15.0575 14.1231 18.3794 10.036 18.3794ZM14.1051 12.8339C13.8801 12.7258 12.7817 12.1857 12.5837 12.1047C12.3766 12.0327 12.2326 11.9966 12.0795 12.2127C11.9265 12.4378 11.5034 12.9419 11.3773 13.0859C11.2513 13.239 11.1163 13.257 10.8912 13.14C10.6661 13.0319 9.94594 12.7889 9.0997 12.0327C8.43352 11.4385 7.99239 10.7093 7.85735 10.4842C7.73132 10.2591 7.83935 10.1421 7.95638 10.0251C8.05541 9.92605 8.18144 9.76401 8.28947 9.63797C8.39751 9.51194 8.44252 9.41291 8.51454 9.26887C8.58656 9.11582 8.55055 8.98979 8.49653 8.88176C8.44252 8.77373 7.99239 7.67542 7.81234 7.22529C7.63229 6.79317 7.44323 6.84718 7.3082 6.83818H6.87607C6.72303 6.83818 6.48896 6.89219 6.2819 7.11726C6.08385 7.34232 5.50769 7.88247 5.50769 8.98079C5.50769 10.0791 6.30891 11.1414 6.41694 11.2854C6.52497 11.4385 7.99239 13.6891 10.225 14.6524C10.7562 14.8865 11.1703 15.0215 11.4944 15.1205C12.0255 15.2916 12.5117 15.2646 12.8988 15.2106C13.3309 15.1475 14.2222 14.6704 14.4022 14.1482C14.5913 13.6261 14.5913 13.185 14.5282 13.0859C14.4652 12.9869 14.3302 12.9419 14.1051 12.8339Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_3652_9486">
<rect width="20" height="20" fill="white" transform="translate(0 0.919189)"/>
</clipPath>
</defs>
</svg>
    )
}