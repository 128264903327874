
import React from 'react';

export const ListYourBusiness1= ({
    className = "",
    width = "160px",
    height = "204px",
    viewBox = "0 0 204px 160px",
    fill = "",

}) => {
   

    return (
        
            <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
               <path fillRule="evenodd" clipRule="evenodd" d="M134.151 8.18338C134.151 3.66382 137.815 0 142.334 0C146.854 0 150.518 3.66382 150.518 8.18338C150.518 12.7029 146.854 16.3668 142.334 16.3668C137.815 16.3668 134.151 12.7029 134.151 8.18338ZM7.30835 109.307C7.30835 69.922 39.236 37.9944 78.6206 37.9944C118.005 37.9944 149.933 69.922 149.933 109.307C149.933 148.691 118.005 180.619 78.6206 180.619C39.236 180.619 7.30835 148.691 7.30835 109.307ZM13.4459 191.725C10.0562 191.725 7.30835 194.473 7.30835 197.862C7.30835 201.252 10.0562 204 13.4459 204C16.8355 204 19.5834 201.252 19.5834 197.862C19.5834 194.473 16.8355 191.725 13.4459 191.725Z" fill="#EEEDED"/>
<path fillRule="evenodd" clipRule="evenodd" d="M31.1287 24.0513C25.6894 19.74 17.7849 20.6543 13.4735 26.0936C9.16213 31.5329 10.0765 39.4373 15.5158 43.7487C20.955 48.0601 28.8595 47.1458 33.1709 41.7065C37.4823 36.2672 36.568 28.3627 31.1287 24.0513ZM129.733 190.116C135.041 194.588 142.969 193.91 147.441 188.603C151.913 183.295 151.236 175.366 145.928 170.894C140.62 166.422 132.692 167.099 128.219 172.407C123.747 177.715 124.425 185.644 129.733 190.116Z" fill="#2B2A29"/>
<path opacity="0.4" d="M42.2136 106.637C42.394 115.071 42.899 129.496 42.9712 131.087C43.2273 134.489 44.5295 137.927 46.5568 140.351C49.3778 143.752 52.8516 145.271 57.6962 145.271C64.3914 145.307 71.772 145.307 78.9397 145.307C86.1363 145.307 93.1201 145.307 99.018 145.271C103.794 145.271 107.376 143.713 110.161 140.351C112.188 137.927 113.491 134.453 113.675 131.087C113.747 129.749 114.107 114.089 114.324 106.637H42.2136Z" fill="#E31E24"/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.8907 53.3686C5.99327 51.8646 3.23589 52.1836 1.73192 54.081C0.227947 55.9784 0.546901 58.7358 2.44433 60.2398C4.34175 61.7437 7.09913 61.4248 8.6031 59.5274C10.1071 57.6299 9.78812 54.8726 7.8907 53.3686ZM153.837 161.507C155.689 163.067 158.455 162.831 160.015 160.979C161.575 159.128 161.338 156.362 159.487 154.802C157.635 153.242 154.87 153.478 153.31 155.33C151.749 157.181 151.986 159.947 153.837 161.507ZM75.564 122.169V126.837C75.564 128.33 76.7761 129.542 78.2695 129.542C79.763 129.542 80.975 128.33 80.975 126.837V122.169C80.975 120.675 79.763 119.463 78.2695 119.463C76.7761 119.463 75.564 120.675 75.564 122.169ZM68.8504 121.149C70.214 121.329 71.473 120.492 71.8337 119.183C72.6345 116.253 75.2137 114.288 78.2511 114.288C81.2488 114.288 83.8569 116.297 84.5855 119.168C84.8922 120.38 85.9852 121.206 87.2081 121.206C87.3307 121.206 87.4534 121.199 87.5796 121.181C96.8432 119.9 105.674 116.621 113.112 111.697C113.866 111.196 114.324 110.348 114.324 109.439V96.9363C114.324 89.361 108.163 83.1997 100.591 83.1997H93.4595C92.7814 77.9618 88.3408 73.8892 82.9262 73.8892H73.6121C68.1939 73.8892 63.7569 77.9618 63.0787 83.1997H55.9831C48.3898 83.1997 42.214 89.361 42.214 96.9363V109.439C42.214 110.348 42.6686 111.2 43.4297 111.697C50.8535 116.6 59.6482 119.864 68.8504 121.149ZM73.6121 79.3001H82.9262C85.3503 79.3001 87.3777 80.9595 87.9837 83.1996H68.5511C69.1571 80.9595 71.1844 79.3001 73.6121 79.3001Z" fill="#E31E24"/>
</svg>

      
    );
};