import React from 'react';

export const Edit = ({
    className = "",
    width = "24px",
    height = "auto",
    viewBox = "0 0 24 24",
    fill = "",

}) => {
   

    return (
        
            <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9974 20.4429C12.9974 20.0287 13.3332 19.6929 13.7474 19.6929H21C21.4142 19.6929 21.75 20.0287 21.75 20.4429C21.75 20.8571 21.4142 21.1929 21 21.1929H13.7474C13.3332 21.1929 12.9974 20.8571 12.9974 20.4429Z" fill="white"/>
<path d="M19.1845 4.81582C18.9259 4.55717 18.6189 4.352 18.2809 4.21203C17.943 4.07205 17.5808 4 17.2151 4C16.8493 4 16.4871 4.07205 16.1492 4.21203C15.8113 4.352 15.5042 4.55717 15.2456 4.81582L4.77261 15.2893C4.46096 15.601 4.2343 15.9877 4.1153 16.4127L3.01955 20.326C2.99424 20.4167 2.99349 20.5125 3.0174 20.6036C3.0413 20.6947 3.089 20.7778 3.15559 20.8444C3.22219 20.911 3.3053 20.9587 3.3964 20.9826C3.48749 21.0065 3.58331 21.0058 3.67403 20.9804L7.58671 19.8847C8.01165 19.7658 8.39879 19.5394 8.71079 19.2274L19.1845 8.75392C19.7067 8.23165 20 7.52338 20 6.78487C20 6.04636 19.7067 5.33809 19.1845 4.81582ZM15.9971 5.56661C16.3202 5.2436 16.7584 5.06217 17.2153 5.06224C17.6722 5.0623 18.1103 5.24386 18.4334 5.56696C18.7564 5.89007 18.9378 6.32825 18.9377 6.78512C18.9377 7.24199 18.7561 7.68012 18.433 8.00313L17.3422 9.09319L14.9063 6.65667L15.9971 5.56661ZM14.1555 7.40817L16.5921 9.84469L7.95928 18.4766C7.77643 18.6595 7.54957 18.7922 7.30056 18.8619L4.29663 19.7027L5.13739 16.6995C5.20725 16.4506 5.33994 16.2237 5.52271 16.0408L14.1555 7.40817Z" fill="white"/>
</svg>

    );
};
