import React from 'react'

export const Apple = ({
    className = "",
    width = "28px",
    height = "28px",
    viewBox = "0 0 28 28",
    fill = "",
}) => {

    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M23.5599 20.7033C23.1828 21.5418 22.7366 22.3136 22.2195 23.0232C21.5147 23.9906 20.9376 24.6602 20.4928 25.032C19.8034 25.6424 19.0647 25.955 18.2737 25.9728C17.7058 25.9728 17.021 25.8172 16.2239 25.5017C15.4241 25.1876 14.6891 25.032 14.0171 25.032C13.3123 25.032 12.5563 25.1876 11.7478 25.5017C10.938 25.8172 10.2857 25.9816 9.78691 25.9979C9.02838 26.0291 8.27231 25.7076 7.51763 25.032C7.03595 24.6276 6.43347 23.9343 5.71172 22.9521C4.93734 21.9032 4.30069 20.687 3.80193 19.3004C3.26777 17.8026 3 16.3523 3 14.9482C3 13.3398 3.36103 11.9526 4.08416 10.7901C4.65248 9.85636 5.40855 9.11979 6.35483 8.57906C7.30111 8.03834 8.32356 7.76279 9.42465 7.74516C10.0271 7.74516 10.8172 7.92456 11.799 8.27715C12.7781 8.63091 13.4067 8.81031 13.6824 8.81031C13.8884 8.81031 14.5868 8.60054 15.7707 8.18233C16.8902 7.79449 17.8351 7.63391 18.6092 7.69716C20.7067 7.86012 22.2826 8.6561 23.3306 10.0901C21.4546 11.1843 20.5267 12.7169 20.5451 14.6829C20.5621 16.2142 21.1392 17.4886 22.2733 18.5004C22.7873 18.97 23.3614 19.333 24 19.5907C23.8615 19.9774 23.7153 20.3477 23.5599 20.7033ZM18.7492 2.48014C18.7492 3.68041 18.2937 4.8011 17.3858 5.8384C16.29 7.07155 14.9647 7.78412 13.5275 7.67168C13.5092 7.52769 13.4986 7.37614 13.4986 7.21688C13.4986 6.06462 14.0197 4.83147 14.945 3.82321C15.407 3.3127 15.9946 2.88823 16.7071 2.54962C17.4181 2.21605 18.0906 2.03159 18.7231 2C18.7415 2.16046 18.7492 2.32094 18.7492 2.48014Z" fill="" />
        </svg>


    )
}
