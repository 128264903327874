import React from "react";

export const Logout1 = ({
  className = "",
  width = "143px",
  height = "auto",
  viewBox = "0 0 143 290",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.331 7.26075C118.331 3.25075 121.582 0 125.592 0C129.602 0 132.852 3.25075 132.852 7.26075C132.852 11.2707 129.602 14.5215 125.592 14.5215C121.582 14.5215 118.331 11.2707 118.331 7.26075ZM5.78918 96.9834C5.78918 62.0391 34.1171 33.7112 69.0614 33.7112C104.006 33.7112 132.334 62.0391 132.334 96.9834C132.334 131.928 104.006 160.256 69.0614 160.256C34.1171 160.256 5.78918 131.928 5.78918 96.9834ZM11.2347 170.109C8.22725 170.109 5.78918 172.547 5.78918 175.555C5.78918 178.562 8.22725 181 11.2347 181C14.2422 181 16.6803 178.562 16.6803 175.555C16.6803 172.547 14.2422 170.109 11.2347 170.109Z"
        fill="#EEEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9239 21.3399C22.0979 17.5146 15.0846 18.3259 11.2592 23.1519C7.43394 27.9779 8.24519 34.9912 13.0712 38.8166C17.8973 42.6419 24.9106 41.8306 28.7359 37.0046C32.5612 32.1785 31.7499 25.1652 26.9239 21.3399ZM114.411 168.681C119.12 172.649 126.155 172.048 130.123 167.339C134.091 162.629 133.49 155.595 128.78 151.627C124.071 147.659 117.036 148.26 113.068 152.969C109.1 157.679 109.701 164.713 114.411 168.681Z"
        fill="#2B2A29"
      />
      <path
        opacity="0.4"
        d="M37.1754 84.7586C37.1754 76.4865 44.0275 69.75 52.4457 69.75H69.1895C77.5905 69.75 84.4254 76.4662 84.4254 84.7249V122.241C84.4254 130.517 77.5734 137.25 69.1517 137.25H52.4148C44.0103 137.25 37.1754 130.534 37.1754 122.275V119.103V84.7586Z"
        fill="#E31E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.841593 47.9836C2.176 46.3001 4.6225 46.0171 6.306 47.3515C7.9895 48.6859 8.2725 51.1324 6.93809 52.8159C5.60368 54.4994 3.15718 54.7824 1.47368 53.448C-0.209823 52.1136 -0.492817 49.6671 0.841593 47.9836ZM141.279 142.83C139.895 144.472 137.441 144.682 135.798 143.298C134.155 141.914 133.945 139.46 135.33 137.817C136.714 136.174 139.168 135.965 140.811 137.349C142.453 138.733 142.663 141.187 141.279 142.83ZM94.3247 91.842L103.929 101.66C104.406 102.149 104.675 102.811 104.675 103.499C104.675 104.191 104.406 104.853 103.929 105.342L94.3247 115.157C93.8317 115.659 93.1842 115.916 92.5399 115.916C91.8891 115.916 91.2416 115.659 90.7452 115.15C89.7591 114.131 89.7624 112.487 90.7518 111.474L96.0143 106.098H62.6515C61.2545 106.098 60.1205 104.937 60.1205 103.499C60.1205 102.065 61.2545 100.901 62.6515 100.901H90.9687H96.0143L90.7518 95.5242C89.7624 94.5117 89.7591 92.868 90.7452 91.8488C91.7346 90.8295 93.3321 90.8295 94.3247 91.842Z"
        fill="#E31E24"
      />
    </svg>
  );
};
