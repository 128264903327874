import React from 'react';

export const TwitterLink = ({
    className = "",
    width = "20px",
    height = "20px",
    viewBox = "0 0 20 20",
    fill = "",
    onClick
}) => {


    return (

        <svg viewBox={viewBox} width={width} onClick={onClick} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
 <path d="M11.675 9.5416L18.2293 1.92267H16.676L10.9851 8.53793L6.43963 1.92267H1.1969L8.07053 11.9262L1.1969 19.9157H2.75021L8.76017 12.9298L13.5604 19.9157H18.8031L11.6746 9.5416H11.675ZM9.54765 12.0142L8.85112 11.0182L3.30982 3.09194H5.69559L10.1673 9.48875L10.8637 10.4848L16.6767 18.7996H14.2912L9.54765 12.0147V12.0142Z" fill="white"/>
</svg>



    );
};
