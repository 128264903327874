/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from "react";
import { Icons } from "../../components/Icons/Icon";
import "./LoginHome.css";
import MyImage from "./alpha.jpg";
import { useNavigate } from 'react-router-dom'
import { Button, Container, Row, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setUserUpdateData } from "../../../redux/slices/authSlice";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";


export const LoginHome = () => {
    const tempLogin = useSelector((state) => state.auth)
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: tempLogin.tempLogin?.user?.data?.social_provider?.name ?? '',
        
        mobile:tempLogin.tempLogin?.user?.data?.mobile_number || '',
        email:tempLogin.tempLogin?.user?.data?.email || '',
    });
    const handleBackClick = () => {
        navigate(-1); 
    };
    const updateProfile = async () => {
        try {
            const login = await exportApiAuth.updateProfile({
                user_id: tempLogin.tempLogin.user.data.social_provider.user_id,
                mobile_number: formData.mobile,
                login_type: tempLogin.tempLogin.user.data.social_provider.login_type,
                email: formData.email,
                name: formData.name,
            });
            if (login.status === 'success') {
                // return
                dispatch(setUserUpdateData(login.data))
                navigate('/onboard')
            }
        } catch (error) {
            console.error('Error updating details:', error);
        }
    };


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    };

    return (

        <Container ><Row >
            <nav className="navbar top-nav navbar-light bg-light d-flex">
                <span onClick={() => handleBackClick()}>
                    <Icons name="back" className="svg-size-24 svg-red" />
                </span>
                <h1 className="f-18 f-w600 mb-0 pr-24 "></h1>

            </nav>
        </Row>
            <div className="text-center mt-2">
                <img src={MyImage} className="image rounded-circle text-center" alt=""></img></div>

            <div className="p-name container-fluid f-w600 f-24 text-center text-white">{formData?.name}</div>
            <Row>
                <div className="input-area mt-3">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            placeholder="Mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            readOnly={true}
                        />
                    </div>
                </div>
            </Row>

            <Row><div className="bottom-fix ">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>

                    <Toast.Body className="bg-green text-white text-centre">Profile Updated Successfully!</Toast.Body>
                </Toast>
                <Button className="container-fluid bg-red  mb-3" variant="danger" onClick={() => updateProfile()} >Save Details</Button>
            </div></Row>
        </Container>


    )
}  