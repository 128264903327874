import IndoreTalkAuthService from "./indoreTalkAuth.service";

class IndoreTalkAuthApi extends IndoreTalkAuthService {
  async payment(params) {
    return await this.post('pay', params);
  }
  
  async regenerateToken(params) {
    return await this.post('regenerate-token', params);
  }

  async listingPayment(params) {
    return await this.post('sub-scription-order', params);
  }

  async listingPaymentSucces(params) {
    return await this.post('sub-scription-status', params);
  }

  async subScriptionCheck(params) {
    return await this.post('sub-scription-check', params);
  }

  async pollSend(params) {
    return await this.post('store', params);
  }

  async verifyCoupon(params) {
    return await this.post('verify-coupon-code', params);
  }

  async applyCoupon(params) {
    return await this.post('apply-coupon', params);
  }

  async paymentSuccess(params) {
    return await this.post('payment-success', params);
  }

  async ratingReview(params) {
    return await this.post('listing/rating-review', params);
  }

  async addComment(params) {
    return await this.post('add-comment', params);
  }

  async fetchPayment(params) {
    return await this.post('fetch-payment', params);
  }

  async updateProfile(params) {
    const param = params
    const file = params.profile_pic
    const formData = new FormData();
    formData.append('user_id', param.user_id);
    formData.append('login_type', param.login_type);
    formData.append('display_name', param.display_name);
    formData.append('email', param.email);
    formData.append('mobile_number', param.mobile_number);
    formData.append('profile_pic', file);
    if (formData) {
      const formType = true;
      return await this.post('profile', formData, formType);
    }
  }
  async likePost(params) {
    return await this.post('like', params);
  }
  async myBooking(params) {
    return await this.post('my-booking', params);
  }
  async logOut(params) {
    return await this.post('logout', params);
  }
  async connect(params) {
    const param = params.formData
    const file = param.connect_doc
    const formData = new FormData();
    formData.append('user_id', param.user_id);
    formData.append('login_type', param.login_type);
    formData.append('name', param.name);
    formData.append('email', param.email);
    formData.append('contact_number', param.contact_number);
    formData.append('connect_doc', file);
    formData.append('city', param.city);
    formData.append('description', param.description);
    formData.append('enquiry_type', param.enquiry_type);
    // Make the API request using the form data and custom headers
    if (formData) {
      const formType = true; // Set your formType value or retrieve it dynamically
      return await this.post('connect', formData, formType);
    }
  }
  //List your business
  async ListYourBusiness(params) {
    return await this.post('listing/enquiry', params);
  }

  async addYourBusiness(params) {
    const formData = new FormData();
    for (const key in params) {
      const value = params[key];

      if (Array.isArray(value)) {

        if (key === 'gallery_image') {
          // const formData1 = new FormData()
          // let imageArray = [];

          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          }


          // console.log('formData1', formData1);
          // formData.append(key, formData1);
        }
        if (key === 'menus_image') {
          // const formData2 = new FormData()
          // let imageArray = [];

          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          }
        }
      } else {
        if (key === 'videos') {
          const vidArray = value.split();
          formData.append(`${key}[]`, vidArray);
        } else {
          formData.append(key, value);

          if (key === 'operating_hours' || key === 'branch') {
            // let obj = Object.assign({}, value);
            formData.append(key, value);
          }
        }
      }

      // console.log('key', key, value);
    }
    if (formData) {
      const formType = true; // Set your formType value or retrieve it dynamically
      return await this.post('listing/add-business-listing', formData, formType);
    }
  }


  //Career
  async career(params) {
    const param = params.formData
    // const file = param.cv_file
    const formData = new FormData();
    formData.append('user_id', param.user_id);
    formData.append('login_type', param.login_type);
    formData.append('name', param.name);
    formData.append('email', param.email);
    formData.append('contact_number', param.contact_number);
    formData.append('job_title', param.job_title);
    formData.append('address', param.address);
    // formData.append('cv_file', file);

    // Make the API request using the form data and custom headers
    if (formData) {
      const formType = true; // Set your formType value or retrieve it dynamically
      return await this.post('career', formData, formType);
    }
  }
  async singleBooking(params) {
    return await this.post('event-view-ticket', params);
  }
  
  // async deleteAccount(userid) {
  //   return await this.delete(`delete/user/${userid}`);
  // }
  async deleteAccount(userData) {
    return await this.delete(`delete/user/${userData.user_id}`, { data: userData });
  }

  async reportPost(params) {
    return await this.post('report', params);
  }
}

export const exportApiAuth = new IndoreTalkAuthApi();
