import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  listingFormInputData: {},
  cardInput: [],
  galleryimage:[],
  menuImage:[],
  coverImage:{},
  scriptionPlainList:{},
  verifyCoupon:{},
  opratingDay:[]
}

const listingFormData = createSlice({
  name: 'listingFormData',
  initialState: initialState,
  reducers: {
    setListingScriptionPlain: (state, action) => {
      console.log('action.payload',action.payload)
      state.scriptionPlainList = action.payload
    },
    setListingFormData: (state, action) => {
      state.listingFormInputData = { ...state.onBoarding, ...action.payload }
    },
    setListingFormDatacardInput: (state, action) => {
      state.cardInput = action.payload
    },
    setListingFormCoverImage: (state, action) => {
      state.coverImage = action.payload
    },
    setListingFormgelleryImage: (state, action) => {
      state.galleryimage = action.payload
    },
    setListingFormMenuImage: (state, action) => {
      state.menuImage = action.payload
    },
    setVerifyCoupon: (state, action) => {
      state.verifyCoupon = action.payload
    },
    setOpratingDay: (state, action) => {
      state.opratingDay = action.payload
    },
  },

})

export const {setListingScriptionPlain, setListingFormData, setOpratingDay, setVerifyCoupon,  setListingFormDatacardInput, setListingFormgelleryImage, setListingFormMenuImage, setListingFormCoverImage } = listingFormData.actions

export default listingFormData.reducer
