import { React } from "react";
import "./GoToLogin.css";
import { Icons } from "../Icons/Icon";
import { Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { setAgainRedirectionPath } from "../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { Button } from "../../components/form-type/button";

export const GoToLogin = ({ show, onHide, location, loginResion = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectionToLoginPage = () => {
    try {
      dispatch(
        setAgainRedirectionPath({ path: location?.pathname, pathBoolean: true })
      );
      navigate("/login");
    } catch (error) {}
  };
  return (
    <div>
      <Modal className="modal-bg-blur" show={show} onHide={onHide} centered>
        <Modal.Body>
          <Container>
            <Row className="justify-center mt-4 mb-4">
              <Icons name="splash-new" className="indorelogo svg-white" />
            </Row>
            <Row className="justify-center mt-2 mb-3 text-center">
              {" "}
             {loginResion ? 'Your session has expired. Please log in again.' : 'Please login for better experience.' }
            </Row>
            <div className="form-submit-btn">
              <Button
                onClick={() => redirectionToLoginPage()}
                className="btn btn-secondary w-100 mt-3 mb-3 p-2 z-index-999 btn-bg-red"
                label="Login"
              />
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};
