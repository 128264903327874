import React from 'react'
 
export const PlusGallery = ({
    className = "",
    width = "20px",
    height = "auto",
    viewBox = "0 0 20 21",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M17.7143 12.2049H11.2857V18.6335C11.2857 18.9745 11.1503 19.3015 10.9091 19.5426C10.668 19.7837 10.341 19.9192 10 19.9192C9.65901 19.9192 9.33198 19.7837 9.09086 19.5426C8.84975 19.3015 8.71429 18.9745 8.71429 18.6335V12.2049H2.28571C1.94472 12.2049 1.6177 12.0694 1.37658 11.8283C1.13546 11.5872 1 11.2602 1 10.9192C1 10.5782 1.13546 10.2512 1.37658 10.0101C1.6177 9.76893 1.94472 9.63348 2.28571 9.63348H8.71429V3.2049C8.71429 2.86391 8.84975 2.53688 9.09086 2.29577C9.33198 2.05465 9.65901 1.91919 10 1.91919C10.341 1.91919 10.668 2.05465 10.9091 2.29577C11.1503 2.53688 11.2857 2.86391 11.2857 3.2049V9.63348H17.7143C18.0553 9.63348 18.3823 9.76893 18.6234 10.0101C18.8645 10.2512 19 10.5782 19 10.9192C19 11.2602 18.8645 11.5872 18.6234 11.8283C18.3823 12.0694 18.0553 12.2049 17.7143 12.2049Z" fill="white"/>
</svg>
    )
}