import React from 'react'

export const Logout = ({
  className = "",
  width = "42px",
  height = "42px",
  viewBox = "0 0 42 42",
  fill = "",
}) => {

  return (
    <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="21.4434" cy="21.0103" r="21" fill="#E31E24"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.4436 11.8302C16.3732 11.8302 12.2636 15.9398 12.2636 21.0102C12.2636 26.0795 16.3732 30.1902 21.4436 30.1902C26.5128 30.1902 30.6236 26.0795 30.6236 21.0102C30.6236 15.9399 26.5129 11.8302 21.4436 11.8302ZM10.6436 21.0102C10.6436 15.0451 15.4785 10.2102 21.4436 10.2102C27.4075 10.2102 32.2436 15.0451 32.2436 21.0102C32.2436 26.9742 27.4075 31.8102 21.4436 31.8102C15.4784 31.8102 10.6436 26.9741 10.6436 21.0102Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.6528 16.4556C22.4488 16.3563 22.011 16.2579 21.4518 16.2647C20.8591 16.2728 20.2736 16.404 19.8039 16.7912M22.6528 16.4556C23.1799 16.7138 23.948 17.2215 23.948 18.2422C23.948 19.3646 23.2982 19.8684 22.1787 20.6382C21.5715 21.0558 21.1446 21.5008 20.8754 22.0011C20.6039 22.5059 20.5204 23.0161 20.5204 23.5154C20.5204 23.8794 20.8155 24.1745 21.1795 24.1745C21.5436 24.1745 21.8387 23.8794 21.8387 23.5154C21.8387 23.1697 21.8949 22.8888 22.0364 22.6257C22.1803 22.3581 22.4397 22.0587 22.9257 21.7245L22.951 21.707C24.0127 20.9771 25.2663 20.1152 25.2663 18.2422C25.2663 16.4025 23.8328 15.5656 23.232 15.2713L23.2315 15.2711L23.231 15.2708C22.775 15.0485 22.1098 14.9382 21.4351 14.9465L21.4347 14.9465L21.4342 14.9465C20.6751 14.9569 19.752 15.1256 18.9654 15.7739L18.9654 15.7739C18.3325 16.2956 17.9958 16.8881 17.8187 17.3561C17.7306 17.5892 17.6817 17.7918 17.6547 17.9409C17.6411 18.0156 17.6329 18.0774 17.628 18.1236C17.6255 18.1467 17.6238 18.166 17.6227 18.1811C17.6222 18.1886 17.6217 18.1951 17.6214 18.2005C17.6213 18.2032 17.6211 18.2056 17.621 18.2078L17.6209 18.2108L17.6208 18.2122L17.6208 18.2128C17.6208 18.2131 17.6208 18.2134 18.2793 18.2422L17.6208 18.2134C17.6049 18.5771 17.8869 18.8848 18.2505 18.9007C18.6119 18.9165 18.918 18.6382 18.9375 18.2779C18.9376 18.276 18.938 18.2713 18.9388 18.2639C18.9406 18.2472 18.9444 18.2172 18.9518 18.1763C18.9667 18.0943 18.9958 17.9704 19.0518 17.8226C19.1629 17.5288 19.3789 17.1415 19.8039 16.7912" fill="white"/>
<path d="M21.1796 27.0744C21.7621 27.0744 22.2343 26.6022 22.2343 26.0197C22.2343 25.4373 21.7621 24.9651 21.1796 24.9651C20.5972 24.9651 20.125 25.4373 20.125 26.0197C20.125 26.6022 20.5972 27.0744 21.1796 27.0744Z" fill="white"/>
    </svg>
  )
}