import React, { useState, useEffect } from 'react';
import { validationPatternForOtp } from '../../../utils/constGlobleData';

const OtpInputField = (props) => {
  const {
    size = 6,
    value,
    onChange,
    className,
    ...restProps
  } = props;
  const [inputElements, setInputElements] = useState([]);

  useEffect(() => {
    setInputElements(Array.from({ length: size }, (_, index) => document.getElementById(`otpInput-${index}`)));
  }, [size]);

  const focusNextInput = (e, currentIndex) => {
    e.target.select();
    if (e.key === 'ArrowLeft' && currentIndex > 0) {
      inputElements[currentIndex - 1]?.focus();
    } else if (currentIndex < size - 1 && inputElements[currentIndex + 1]) {
      inputElements[currentIndex + 1]?.focus();
    }
  };

  const focusPrevInput = (e, currentIndex) => {
    e.target.select();
    if ((e.key === 'Backspace' || e.key === 'Delete') && currentIndex > 0) {
      inputElements[currentIndex - 1]?.focus();
    }
  };

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;
    const newValueArray = [...value];
  
    // Validate if the new value is a single digit (0-9)
    if (validationPatternForOtp.test(newValue)) {
      newValueArray[index] = newValue;
  
      // Update the state only if the new value is valid
      onChange(newValueArray.join(''));
    } else {
      newValueArray[index] = ' ';
      onChange(newValueArray.join(''));
    }
  };
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {Array.from({ length: size }, (_, index) => (
        <input
          key={index}
          {...restProps}
          className={`no-selection-highlight ${className}`}
          id={`otpInput-${index}`}
          onChange={(e) => handleInputChange(e, index)}
          onKeyUp={(e) => {
            if (e.key === 'Backspace' || e.key === 'Delete') {
              focusPrevInput(e, index);
            }else if(e.key === ' '){
              
            } else {
              focusNextInput(e, index);
            }
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          style={{
            border: '1px solid white',
            textAlign: 'center',
            fontSize: 25,
            margin: 2,
            color:'white',
            backgroundColor: 'black'
          }}
          type={props.type}
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern={validationPatternForOtp}
          maxLength={1}
          value={value[index]}
        />
      ))}
    </div>
  );
};

export default OtpInputField;
