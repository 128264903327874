import React from 'react'

export const Polls = ({
    className = "",
    width = "18px",
    height = "18px",
    viewBox = "0 0 18 18",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9 2C8.44772 2 8 2.44772 8 3V14C8 14.5523 8.44772 15 9 15C9.55228 15 10 14.5523 10 14V3C10 2.44772 9.55228 2 9 2ZM7 3C7 1.89543 7.89543 1 9 1C10.1046 1 11 1.89543 11 3V14C11 15.1046 10.1046 16 9 16C7.89543 16 7 15.1046 7 14V3ZM4 6C3.44772 6 3 6.44772 3 7V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V7C5 6.44772 4.55228 6 4 6ZM2 7C2 5.89543 2.89543 5 4 5C5.10457 5 6 5.89543 6 7V14C6 15.1046 5.10457 16 4 16C2.89543 16 2 15.1046 2 14V7Z" fill="" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 11C12 9.89543 12.8954 9 14 9C15.1046 9 16 9.89543 16 11V14C16 15.1046 15.1046 16 14 16C12.8954 16 12 15.1046 12 14V11ZM14 10C13.4477 10 13 10.4477 13 11V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V11C15 10.4477 14.5523 10 14 10Z" fill="" />
        </svg>
    )
}


