import React, { useState } from "react";
import { Button } from "../../components/form-type/button";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components/Icons/Icon";
import InputField from "../../components/form-type/InputField";
import { Row, Toast } from "react-bootstrap";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import store from 'store'
import { setSignOut } from "../../../redux/slices/authSlice";
import { unnecatchLogout } from "../../../utils/exportGlobleFunction";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";

const Career = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const tempLogin = useSelector((state) => state.auth);
  const [errorToast, setErrorToast] = useState(null);
  const [successToast, setSuccessToast] = useState(null);
  const [showLoginModal, setLoginModalShow] = useState(false);
  const userData = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [formData, setFormData] = useState({
    user_id: userData?.user_id,
    login_type: userData?.login_type,
    name: "",
    email: "",
    contact_number: "",
    address: "",
    job_title: "",
    errors: {},
  });

  const handleBackClick = () => {
    navigate(-1); // This will go back to the previous page in history
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitClick = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!formData.name) {
      errors.name = "Name can't be empty";
    } else if (formData.name.length < 3) {
      errors.name = "Name must be at least 3 characters long";
    }
    if (!formData.job_title) {
      errors.job_title = "Job Title can't be empty";
    } else if (formData.job_title.length < 3) {
      errors.job_title = "Job Title must be 3 characters long";
    }
    if (!formData.email.trim()) {
      errors.email = "Email address can't be empty";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email)
    ) {
      errors.email = "Email address is invalid";
    }
    if (!formData.contact_number.trim()) {
      errors.contact_number = "Contact number can't be empty";
    } else if (!/^\+91[6-9]\d{9}$/.test(formData.contact_number)) {
      errors.contact_number = "Contact number is invalid";
    }
    if (!formData.address.trim()) {
      errors.address = "Address can't be empty";
    } else if (!/^[\S\s]{5,200}$/.test(formData.address)) {
      errors.address = "Address must be atleast 5 char";
    }

    if (Object.keys(errors).length > 0) {
      // If there are errors, set them and return
      setFormData((prevData) => ({
        ...prevData,
        errors: errors,
      }));
      setTimeout(() => {
        setFormData((prevData) => ({
          ...prevData,
          errors: {
            ...prevData.errors,
            name: null,
            job_title: null,
            email: null,
            contact_number: null,
            address: null,
          },
        }));
      }, 3000);
      return;
    }

    // If no errors, proceed with form submission
    try {
      const updatedContactNumber = formData?.contact_number?.startsWith("+91")
        ? formData?.contact_number.slice(3)
        : formData?.contact_number;
      formData.contact_number = updatedContactNumber;
      const res = await exportApiAuth.career({ formData });
      if (res.status === "success") {
        setSuccessToast({
          message: "Information saved successfully",
        });
        setFormData({
          user_id: userData?.user_id,
          login_type: userData?.login_type,
          name: "",
          job_title: "",
          email: "",
          contact_number: "",
          address: "",
          errors: {},
        });
      } else {
        setLoginModalShow(true)
        unnecatchLogout(store, dispatch, setSignOut)
      }
    } catch (error) {
      console.log("error", error);
      // Handle error, e.g., show error toast
      setErrorToast({
        message: "An error occurred while saving information",
      });
    }
  };

  return (
    <>
      <div className="top-header-fix">
        <nav className="navbar top-nav navbar-light bg-light text-center ">
          <div className="container-fluid mx-auto text-center">
            <span onClick={() => handleBackClick()} className="cursor-pointer">
              <Icons name="back" className="svg-size-24 svg-white" />
            </span>
            <div className="m-auto pr-24">
              <h1 className="f-24 f-w600 mb-0">Career</h1>
            </div>
          </div>
        </nav>
      </div>
      <div>
        <div className="mt-5">
          <div className="inputfield-height-with-validation-error">
            <InputField
              name="name"
              label={
                <span className="text-disabled">
                  Name <span className="text-red">*</span>
                </span>
              }
              type="text"
              value={formData.name}
              placeholder="Name"
              onChange={handleInputChange}
            />
            {formData.errors.name && (
              <p className="text-red mb-0">{formData.errors.name}</p>
            )}
          </div>
          <div className="inputfield-height-with-validation-error">
            <InputField
              name="job_title"
              label={
                <span className="text-disabled">
                  Job Title <span className="text-red">*</span>
                </span>
              }
              type="text"
              value={formData.job_title}
              placeholder="Job Title"
              onChange={handleInputChange}
            />
            {formData.errors.job_title && (
              <p className="text-red mb-0">{formData.errors.job_title}</p>
            )}
          </div>
          <div className="inputfield-height-with-validation-error">
            <InputField
              name="email"
              label={
                <span className="text-disabled">
                  Email-ID <span className="text-red">*</span>
                </span>
              }
              type="text"
              placeholder="Email-ID"
              value={formData.email}
              onChange={handleInputChange}
            />
            {formData.errors.email && (
              <p className="text-red mb-0">{formData.errors.email}</p>
            )}
          </div>
          <div className="inputfield-height-with-validation-error">
            <InputField
              name="contact_number"
              label={
                <span className="text-disabled">
                  Contact No. <span className="text-red">*</span>
                </span>
              }
              type="text"
              placeholder="Contact No."
              value={
                formData.contact_number.startsWith("+91")
                  ? formData.contact_number
                  : "+91"
              }
              onChange={(e) => {
                if (e.target.value.startsWith("+91")) {
                  handleInputChange(e);
                }
              }}
            />
            {formData.errors.contact_number && (
              <p className="text-red mb-0">{formData.errors.contact_number}</p>
            )}
          </div>
          <div className="inputfield-height-with-validation-error">
            <InputField
              name="address"
              label={
                <span className="text-disabled">
                  Address <span className="text-red">*</span>
                </span>
              }
              type="text"
              value={formData.address}
              placeholder="Address"
              onChange={handleInputChange}
            />
            {formData.errors.address && (
              <p className="text-red mb-0">{formData.errors.address}</p>
            )}
          </div>
          <Row>
            <div className="">
              {/* Error Toast */}
              {errorToast && (
                <Toast
                  onClose={() => setErrorToast(null)}
                  show={errorToast}
                  delay={333000}
                  className=""
                  autohide
                >
                  <Toast.Body
                    style={{ backgroundColor: "#FFC6C8", color: "#E31E24" }}
                  >
                    {errorToast.message}
                  </Toast.Body>
                </Toast>
              )}

              {/* Success Toast */}
              {successToast && (
                <Toast
                  onClose={() => setSuccessToast(null)}
                  show={successToast}
                  delay={3000}
                  className=""
                  autohide
                >
                  <Toast.Body
                    style={{ backgroundColor: "#C5FEB5", color: "#196004" }}
                  >
                    {successToast.message}
                  </Toast.Body>
                </Toast>
              )}
            </div>
          </Row>
          {showLoginModal &&
            <GoToLogin show={showLoginModal} onHide={() => setLoginModalShow(false)} location={location} />}
          <div className="form-submit-btn">
            <Button
              onClick={(event) => handleSubmitClick(event)}
              className="btn btn-secondary w-100 mt-3 mb-3 p-2 z-index-999 btn-bg-red"
              label="Submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
