import React from 'react'

export const PaymentUnsuccess = ({
    className = "",
    width = "143px",
    height = "181px",
    viewBox = "0 0 143 181",
    fill = "",
}) => {
    return (
        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M118.331 7.26075C118.331 3.25075 121.582 0 125.592 0C129.602 0 132.853 3.25075 132.853 7.26075C132.853 11.2707 129.602 14.5215 125.592 14.5215C121.582 14.5215 118.331 11.2707 118.331 7.26075ZM5.42578 97.2722C5.42578 62.3279 33.7537 34 68.698 34C103.642 34 131.97 62.3279 131.97 97.2722C131.97 132.217 103.642 160.544 68.698 160.544C33.7537 160.544 5.42578 132.217 5.42578 97.2722ZM11.2346 170.109C8.22712 170.109 5.78906 172.547 5.78906 175.555C5.78906 178.562 8.22712 181 11.2346 181C14.2421 181 16.6802 178.562 16.6802 175.555C16.6802 172.547 14.2421 170.109 11.2346 170.109Z" fill="#EEEDED"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.9239 21.34C22.0979 17.5147 15.0846 18.3259 11.2592 23.152C7.43394 27.978 8.24519 34.9913 13.0712 38.8166C17.8973 42.6419 24.9106 41.8307 28.7359 37.0046C32.5612 32.1786 31.7499 25.1653 26.9239 21.34ZM114.411 168.681C119.121 172.649 126.155 172.048 130.123 167.339C134.091 162.629 133.49 155.595 128.781 151.627C124.071 147.659 117.037 148.26 113.069 152.969C109.101 157.679 109.702 164.713 114.411 168.681Z" fill="#2B2A29"/>
<circle cx="68.9258" cy="97.5" r="31.5" fill="#F0A1A3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.841593 47.9836C2.176 46.3001 4.6225 46.0171 6.306 47.3515C7.9895 48.6859 8.2725 51.1324 6.93809 52.8159C5.60368 54.4994 3.15718 54.7824 1.47368 53.448C-0.209823 52.1136 -0.492817 49.6671 0.841593 47.9836ZM141.279 142.83C139.895 144.472 137.441 144.682 135.798 143.298C134.155 141.914 133.945 139.46 135.329 137.817C136.714 136.174 139.167 135.965 140.81 137.349C142.453 138.733 142.663 141.187 141.279 142.83ZM61.1067 86.6316C60.2646 85.7895 58.8993 85.7895 58.0573 86.6316C57.2152 87.4736 57.2152 88.8389 58.0573 89.681L65.8763 97.5L58.0573 105.319C57.2152 106.161 57.2152 107.526 58.0573 108.368C58.8993 109.211 60.2646 109.211 61.1067 108.368L68.9257 100.549L76.7448 108.368C77.5868 109.211 78.9521 109.211 79.7942 108.368C80.6362 107.526 80.6362 106.161 79.7942 105.319L71.9751 97.5L79.7942 89.681C80.6362 88.8389 80.6362 87.4736 79.7942 86.6316C78.9521 85.7895 77.5868 85.7895 76.7448 86.6316L68.9257 94.4506L61.1067 86.6316Z" fill="#E31E24"/>
        </svg>

    )
}