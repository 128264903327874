import React from 'react'
import './button.css';

export const Button = (props) => {
  return (
    <>
      <button
        className={`btn btn-${props.className} btn-style ${props.loading ? 'btn-loading' : ''}`}
        disabled={props.loading && props.disabled}
        onClick={props.onClick}
        {...props}>
        <span className="btn-text">
          {props.label ? props.label : props.children}
        </span>
      </button>
      {props.error && <span className='text-danger text-center mt-1'>{props.error}</span>}
    </>
  );
};



