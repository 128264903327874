import React from 'react'

export const TermsConditions = ({
  className = "",
  width = "42px",
  height = "42px",
  viewBox = "0 0 42 42",
  fill = "",
}) => {

  return (
    <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="21" cy="21" r="21" fill="#E31E24" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.6082 11.0117C23.565 11.004 23.5206 11 23.4752 11C23.4298 11 23.3854 11.004 23.3423 11.0117H17.0863C14.5988 11.003 12.5598 12.986 12.5011 15.4731L12.5009 15.4907V26.1959C12.4506 28.719 14.452 30.8084 16.9763 30.8646L16.9929 30.8647L17.0106 30.8649L17.0328 30.8651C17.0485 30.8652 17.0702 30.8653 17.095 30.8647H25.0739C25.0841 30.8647 25.0942 30.8645 25.1043 30.8641C27.6014 30.7628 29.5693 28.7005 29.5529 26.2012V17.0377C29.5529 16.8441 29.4781 16.658 29.344 16.5183L24.279 11.2423C24.1375 11.095 23.9421 11.0117 23.7379 11.0117H23.6082ZM22.7252 12.5117H17.082C15.413 12.5053 14.0447 13.8336 14.0009 15.5001V26.2037L14.0008 26.2201C13.9638 27.9175 15.308 29.3243 17.0045 29.3648L17.0237 29.3649L17.0451 29.3651L17.0572 29.3652C17.0599 29.3652 17.0609 29.3651 17.0607 29.3651C17.0688 29.3649 17.0769 29.3647 17.0849 29.3647H25.0579C26.7411 29.2888 28.065 27.8957 28.0529 26.2091L28.0529 26.2037L28.0529 17.984H26.0432L26.0411 17.984C24.2078 17.9788 22.7252 16.4925 22.7252 14.659V12.5117ZM27.2317 16.484H26.0444C25.0381 16.4807 24.2252 15.6652 24.2252 14.659V13.3523L27.2317 16.484ZM17.1382 24.3584C17.1382 23.9442 17.474 23.6084 17.8882 23.6084H23.2882C23.7024 23.6084 24.0382 23.9442 24.0382 24.3584C24.0382 24.7726 23.7024 25.1084 23.2882 25.1084H17.8882C17.474 25.1084 17.1382 24.7726 17.1382 24.3584ZM17.8873 19.856C17.4731 19.856 17.1373 20.1917 17.1373 20.606C17.1373 21.0202 17.4731 21.356 17.8873 21.356H21.2433C21.6575 21.356 21.9933 21.0202 21.9933 20.606C21.9933 20.1917 21.6575 19.856 21.2433 19.856H17.8873Z" fill="white" />
    </svg>
  )
}