
import { Notification } from "../views/components/Notification/Notification";
import { PaymentStatus } from "../views/components/Payment/PaymentStatus";
import { Search } from "../views/components/Search/Search";
import Subscription from "../views/components/Subscription/Subscription";
import Ticket from "../views/components/Ticket/Ticket";
import Connect from "../views/layouts/Connect/Connect";
import { BuyTicket } from "../views/layouts/Event/BuyTicket";
import { EventDetail } from "../views/layouts/Event/EventDetail";
import { EventHome } from "../views/layouts/Event/EventHome";
import { MyBooking } from "../views/layouts/Event/MyBooking";
import { HomeLayout } from "../views/layouts/HomePage/HomeLayout";
import ListYourBusiness from "../views/layouts/ListYourBusiness/ListYourBusiness";
import { ListingDetails } from "../views/layouts/Listing/ListingDetails";
import { ListingHome } from "../views/layouts/Listing/ListingHome";
import { LoginHome } from "../views/layouts/LoginHome/LoginHome";
import { MyDetails } from "../views/layouts/More/MyDetails";
import { MyProfile } from "../views/layouts/More/MyProfile";
import { NewsDetails } from "../views/layouts/NewsDetails/NewsDetails";
import { Onboarding } from "../views/layouts/Onboarding/Onboarding";
import { Polls } from "../views/layouts/Polls/Polls";

const PrivateRoutes = {
  private: [
    {
      path: '/',
      exact: true,
      name: 'Home',
      component: HomeLayout,
    },
    {
      path: '/news-details',
      exact: true,
      name: 'News details',
      component: NewsDetails,
    },
    
    {
      path: '/home/more/connect',
      exact: true,
      name: 'connect',
      component: Connect,
    },
    {
      path: '/event-home',
      exact: true,
      name: 'Event home',
      component: EventHome,
    },
    {
      path: '/listing-home',
      exact: true,
      name: 'listing-home page',
      component: ListingHome,
    },
    {
      path: '/listing-details',
      exact: true,
      name: 'listing-details page',
      component: ListingDetails,
    },
    {
      path: '/more',
      exact: true,
      name: 'more',
      component: MyDetails,
    },
    {
      path: '/my-profile',
      exact: true,
      name: 'my-profile',
      component: MyProfile,
    },
    {
      path: '/event-home/event/:id/event-ticket',
      exact: true,
      name: 'Event ticket',
      component: BuyTicket,
    },
    {
      path: '/event-home/event/:id/event-ticket/buy',
      exact: true,
      name: 'Buy ticket',
      component: PaymentStatus,
    },
    {
      path: '/my-booking/:id',
      exact: true,
      name: 'My booking',
      component: EventDetail,
    },
    {
      path: '/login-home',
      exact: true,
      name: 'login-home',
      component: LoginHome,
    },
    {
      path: '/home/notification',
      exact: true,
      name: 'login-home',
      component: Notification,
    },
    {
      path: '/home/search',
      exact: true,
      name: 'search',
      component: Search,
    },
    {
      path: '/my-booking',
      exact: true,
      name: 'my-booking',
      component: MyBooking,
    },
    {
      path: '/my-booking/ticket/:id',
      exact: true,
      name: 'my-ticket',
      component: Ticket,
    },
    {
      path: '/onboard',
      exact: true,
      name: 'onboarding',
      component: Onboarding,
    },
    {
      path: '/polls',
      exact: true,
      name: 'polls',
      component: Polls,
    },
    {
      path: '/more/list-your-business/form',
      exact: true,
      name: 'more',
      component:ListYourBusiness ,
    },
    {
      path: '/more/list-your-business',
      exact: true,
      name: 'more',
      component:Subscription ,
    },




  ]
}
export default PrivateRoutes