import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  subExploreCategory: {},
  subEventCategory: {},
  subListingCategory:{},
  subListingSubCategory:{},
  previousSubCategoryExplore: {},
  previousSubCategoryEvent: {},
  previousSubCategoryBusiness: 0,
  previousSubCategoryBusinessData:0,
}

const subCatSlice = createSlice({
  name: 'subCategory',
  initialState: initialState,
  reducers: {
    setSubExploreCategory: (state, action) => {
      state.subExploreCategory = action?.payload
    },
    setSubEventCategory: (state, action) => {
      state.subEventCategory = action?.payload
    },
    setSubListingCategory: (state, action) => {
      state.subListingCategory = action?.payload
    },
    setSubListingSubCategory: (state, action) => {
      state.subListingSubCategory = action?.payload
    },
    setPreviousSubCategoryExplore: (state, action) => {
      state.previousSubCategoryExplore = action?.payload
    },
    setPreviousSubCategoryEvent: (state, action) => {
      state.previousSubCategoryEvent = action?.payload
    },
    setPreviousSubCategoryBusiness: (state, action) => {
      state.previousSubCategoryBusiness = action?.payload
    },
    setPreviousSubCategoryBusinessdata: (state, action) => {
      state.previousSubCategoryBusinessData = action?.payload
    },
  },

})

export const { setSubExploreCategory,setSubListingCategory, setSubEventCategory, setSubListingSubCategory, setPreviousSubCategoryExplore, setPreviousSubCategoryEvent, setPreviousSubCategoryBusiness, setPreviousSubCategoryBusinessdata,  } = subCatSlice.actions

export default subCatSlice.reducer
