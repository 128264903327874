


import React from 'react'

export const Google = ({
  className = "",
  width = "28px",
  height = "auto",
  viewBox = "0 0 28 28",
  fill = ""
}) => {

  return (
    <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M14.1443 6.6395C15.8844 6.61306 17.5672 7.2535 18.8396 8.42643L22.2668 5.1156C20.068 3.08012 17.1573 1.96398 14.1443 2.00089C11.8931 2.00037 9.68609 2.61856 7.77014 3.78633C5.85418 4.95409 4.30482 6.62539 3.29532 8.61331L7.22226 11.6254C7.70243 10.183 8.62878 8.92586 9.8707 8.03133C11.1126 7.1368 12.6074 6.64998 14.1443 6.6395V6.6395Z" fill="#E43E2B" />
      <path d="M25.8023 14.2653C25.8166 13.4406 25.7304 12.6171 25.5454 11.8127H14.1441V16.2656H20.8373C20.7103 17.0463 20.4249 17.7936 19.998 18.4625C19.5712 19.1314 19.0119 19.7081 18.3536 20.1579L22.186 23.0903C23.3803 21.9511 24.3196 20.5771 24.9431 19.057C25.5666 17.5369 25.8605 15.9046 25.8058 14.2653H25.8023Z" fill="#3B7DED" />
      <path d="M7.23632 16.3704C6.97081 15.6066 6.83393 14.805 6.83113 13.9974C6.83598 13.1912 6.96795 12.3906 7.22231 11.6245L3.29537 8.61243C2.44365 10.283 2 12.1275 2 13.998C2 15.8685 2.44365 17.713 3.29537 19.3836L7.23632 16.3704Z" fill="#F0B501" />
      <path d="M14.144 25.9954C17.1021 26.0781 19.9799 25.0382 22.1859 23.0895L18.3535 20.1571C17.1109 20.9801 15.6394 21.3995 14.144 21.3568C12.6084 21.3478 11.1147 20.8613 9.87472 19.9664C8.63475 19.0714 7.71151 17.8135 7.23596 16.371L3.30902 19.3842C4.3158 21.3712 5.86263 23.0421 7.77627 24.2098C9.68991 25.3774 11.8948 25.9957 14.144 25.9954Z" fill="#2BA24C" />
    </svg>

  )
}


