import React from "react";

export const AnnouncementBanner = ({
  className = "",
  width = "223.11px",
  height = "284px",
  viewBox = "0 0 223.11 284",
  fill = "",
}) => {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.567 11.3926C186.567 5.10059 191.667 0 197.959 0C204.251 0 209.352 5.10059 209.352 11.3926C209.352 17.6844 204.251 22.7852 197.959 22.7852C191.667 22.7852 186.567 17.6844 186.567 11.3926ZM10.4277 152.278C10.4277 97.4482 54.876 53 109.706 53C164.535 53 208.984 97.4482 208.984 152.278C208.984 207.108 164.535 251.556 109.706 251.556C54.876 251.556 10.4277 207.108 10.4277 152.278ZM18.5266 266.911C13.8076 266.911 9.98218 270.737 9.98218 275.456C9.98218 280.174 13.8076 284 18.5266 284C23.2455 284 27.071 280.174 27.071 275.456C27.071 270.737 23.2455 266.911 18.5266 266.911Z"
        fill="#EEEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7929 74.2973C8.15141 72.2036 4.31266 72.6475 2.21891 75.289C0.125162 77.9306 0.569254 81.7693 3.21073 83.8631C5.85221 85.9569 9.69096 85.5128 11.7847 82.8713C13.8785 80.2298 13.4345 76.3911 10.7929 74.2973ZM213.974 224.843C216.551 227.015 220.402 226.686 222.573 224.108C224.745 221.531 224.416 217.68 221.839 215.509C219.261 213.337 215.411 213.666 213.239 216.243C211.067 218.821 211.396 222.671 213.974 224.843Z"
        fill="#E31E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.144 33.4832C35.5716 27.4811 24.5674 28.7539 18.5653 36.3263C12.5631 43.8987 13.836 54.903 21.4083 60.9051C28.9807 66.9073 39.985 65.6343 45.9872 58.062C51.9892 50.4897 50.7164 39.4854 43.144 33.4832ZM180.416 264.671C187.806 270.897 198.843 269.954 205.069 262.565C211.295 255.175 210.352 244.138 202.962 237.912C195.573 231.686 184.536 232.629 178.31 240.018C172.084 247.408 173.027 258.445 180.416 264.671Z"
        fill="#2B2A29"
      />
      <path
        d="M103.767 170.452L110.462 187.777C112.168 192.193 109.971 197.153 105.555 198.86C101.14 200.566 96.1769 198.369 94.4706 193.954L87.7768 176.631L75.7838 181.265C72.9471 181.851 66.8617 181.956 65.214 177.692L61.0947 167.031L56.9754 156.371C55.3277 152.107 59.9027 148.093 62.3962 146.619L74.3891 141.984L97.0424 133.231L110.43 167.877L103.767 170.452Z"
        fill="#E31E24"
      />
      <path
        opacity="0.4"
        d="M148.697 168.807L123.756 162.728L119.092 164.53L105.704 129.884L110.368 128.082L124.741 106.811C128.133 101.792 135.768 102.656 137.952 108.307L145.136 126.899C145.136 126.899 151.799 124.325 154.373 130.987C156.948 137.65 150.285 140.225 150.285 140.225L157.469 158.817C159.653 164.468 154.583 170.241 148.697 168.807Z"
        fill="#E31E24"
      />
    </svg>
  );
};
