import React, { useEffect, useState } from "react";
import "./buyTicket.css";
import { Card, Carousel, Collapse, Toast } from "react-bootstrap";
import { Icons } from "../../components/Icons/Icon";
import { useSelector } from "react-redux";
import {
  dateFormatting,
  displayRazorpay,
  formatDate,
  loadScript,
  useCalculateSubtotal,
} from "../../../utils/exportGlobleFunction";
import { useNavigate } from "react-router-dom";
import { getPlatform } from "../../../utils/constGlobleData";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { Button } from "../../components/form-type/button";

export const BuyTicket = ({ title, header }) => {
  const { singleData, ticketCount, apiTicketData } = useSelector(
    (state) => state.singleData
  );
  const tempLogin = useSelector((state) => state.auth);
  const userData = tempLogin?.tempLogin?.user?.data?.social_provider;
  const userDetials = tempLogin?.tempLogin?.user?.data;
  // const dispatch = useDispatch();
  const showDetials = singleData;
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [status, setStatus] = useState({});
  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  let totalTaxAmount = 0;
  const access_tokens =
    tempLogin?.tempLogin?.user?.data?.social_provider?.access_tokens;
  const localStore = localStorage.getItem("localData")
    ? JSON.parse(localStorage.getItem("localData"))
    : null;
  // eslint-disable-next-line
  const [payData, setPayData] = useState({
    user_id: userData?.user_id,
    event_id: showDetials?.id,
    login_type: tempLogin?.tempLogin?.user?.data?.social_provider?.login_type,
    name: userData?.name,
    mobile: tempLogin?.tempLogin?.user?.data?.mobile_number?.startsWith("+91")
      ? tempLogin?.tempLogin?.user?.data?.mobile_number.slice(3)
      : tempLogin?.tempLogin?.user?.data?.mobile_number,
    email: tempLogin?.tempLogin?.user?.data?.email,
    status: "pending",
    amount: parseFloat(useCalculateSubtotal(ticketCount)), // Parse floats before addition
    event_ticket_details: JSON.stringify(apiTicketData),
    currency: "INR",
    payment_type: "razorpay",
  });
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {

    if (localStore !== null) {  
      fetchPaymentCall(localStore);
    } 
  }, []);
  const fetchPaymentCall = async (localData) => {
    try {
      const res = await exportApiAuth.fetchPayment(localData);
     
      localStorage.removeItem("localData");
      const newUrl = "buy";
      navigate(newUrl, { state: { paymentResponse: res, modal: true } });
    
    } catch (error) {
      console.error(error);
      localStorage.removeItem("localData");
    }
  };
  
  // eslint-disable-next-line
  const handleShareLink = async (options) => {
    const data = {
      id: "16",
      name: "Test name",
      key: "rzp_test_ZmbCKND78rC5r3",
      amount: "1000",
      currency: "INR",
      orderId: "order_NifC4G5jgC9vPd",
      email: "test@gmail.com",
      phone: "9876543210",
    };
    if (getPlatform() === "Android") {
   
      // eslint-disable-next-line no-undef
      await Android.payIntent(JSON.stringify(data));
      //   }
    } else {
      if (navigator.share) {
        // await navigator.share(shareParams);
      } else {
        // shareOnMobile(shareParams);
      }
    }
  };
  // eslint-disable-next-line
  useEffect(() => {
    setPayData((prevData) => ({
      ...prevData,
      amount: (payData.amount + parseFloat(totalTaxAmount)).toFixed(2),
    }));
  }, [totalTaxAmount]);
  
  const [successToast, setSuccessToast] = useState(false);
  const displayRazorpayTicket = async () => {
    if (tempLogin?.tempLogin?.user?.data?.mobile_number === null || tempLogin?.tempLogin?.user?.data?.mobile_number === '') {
      setSuccessToast(true)
      return
    }
    const test = false;
    const result = await exportApiAuth.payment(payData);
    if (getPlatform() === "Android") {
      try {
        const result = await exportApiAuth.payment(payData);

        if (result) {
          const localVar = {
            order_id: result?.order?.id,
            booking_id: result?.id,
            login_type: payData?.login_type,
            user_id: userData?.user_id,
          };
          // Retrieve the string from localStorage and parse it back to an object
          localStorage.setItem("localData", JSON.stringify(localVar));
          await displayRazorpay(
            "bookTicket",
            payData,
            setLoader,
            navigate,
            setShow,
            setStatus,
            userData?.user_id,
            userData,
            userDetials,
            result,
            access_tokens,
            tempLogin?.tempLogin?.user?.data?.display_name
          );
        } else {
          alert("no result available");
        }
      } catch (error) {
        alert(JSON.stringify(error));
      }
    } 
    else if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
      let { amount, final_amount, id: order_id, currency, name, email, mobile, subscription_id } = result;
      const localVar = {
        order_id: result?.order?.id,
        booking_id: result?.id,
        login_type: payData?.login_type,
        user_id: userData?.user_id,
      };
      // Retrieve the string from localStorage and parse it back to an object
      localStorage.setItem("localData", JSON.stringify(localVar));
      const paymentData = {
        apiKey: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: (amount * 100).toString(),
        currency,
        name: (tempLogin?.tempLogin?.user?.data?.display_name?.length > 0) ? tempLogin.tempLogin.user.data.display_name : "user",
        email: email,
        phone: mobile,
        id: userData?.user_id,
        description: "Ticket Transaction",
        orderId: result?.order?.id,
        login_type: payData?.login_type,
        booking_id: result?.id,
        access_tokens: access_tokens,
      };

      // Post the message to the WebView
      window.webkit.messageHandlers.razorpay.postMessage(paymentData);
    }else {
      setLoader(true);
      try {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
          throw new Error("Razorpay SDK failed to load. Are you online?");
        }

        const result = await exportApiAuth.payment(payData);
        if (!result) {
          throw new Error("Server error. Are you online?");
        }

        // Getting the order details back
        const { amount, id: order_id, currency, name, email, mobile } = result;
        // Check for window.Razorpay before using it
        if (window.Razorpay) {
          const options = {
            key: process.env.RAZORPAY_API_KEY,
            amount: amount.toString(),
            // amount: amount,
            currency: currency,
            name: name,
            description: "Test Transaction",
            order_id: result?.order?.id,
            handler: async function (response) {
              try {
                if (response.razorpay_signature) {
                  const data = {
                    booking_id: result?.id,
                    status: "success",
                    login_type: payData?.login_type,
                    user_id: userData?.user_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                  };
                  const res = await exportApiAuth.paymentSuccess(data);
                  if (res.status === "success") {
                    setLoader(false);
                    setShow(true);
                    setStatus(res);
                    // navigate(`event-home/event/${showDetials?.id}/event-ticket/buy`);
                    // Assuming showDetails is defined
                    const newUrl = "buy";
                    // navigate(`event-home/event/${showDetials?.id}/event-ticket/buy`, { state: { paymentResponse: res } });
                    navigate(newUrl, {
                      state: { paymentResponse: res, modal: true },
                    });
                  } else {
                    setLoader(false);
                    console.error("Somthing went wrong");
                  }
                } else {
                  setLoader(false);
                  console.error("Payment failed");
                }
              } catch (error) {
                setLoader(false);
                console.error(
                  "Error during payment processing:",
                  error.message
                );
                // Handle error during payment processing
              }
            },
            prefill: {
              name: name,
              email: email,
              contact: mobile,
            },
            notes: {
              address: "123 Main Street, Cityville",
              order_number: result?.order?.id,
            },

            theme: {
              color: "#61dafb",
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else {
          setLoader(false);
          console.error("Razorpay script not loaded successfully.");
          alert(
            "Razorpay script not loaded successfully. Please try again later."
          );
        }
      } catch (error) {
        setLoader(false);

        // Additional error handling for Razorpay API failures
        if (error.message.includes("Razorpay API call failed")) {
          // Handle specific error related to Razorpay API failure
          console.error("Razorpay API call failed:", error.message);
          alert(
            "Razorpay API call failed. Please check your internet connection and try again."
          );
        } else {
          // Handle other types of errors
          console.error(error.message);
          alert("An unexpected error occurred. Please try again later.");
        }
      }
      // catch (error) {
      //     setLoader(false)
      //     console.error(error.message);
      //     alert(error.message);
      // }
    }
  };
  const subtotal = useCalculateSubtotal(ticketCount);
  let totalTax = 0;

  return (
    <>
      {/* nav bar */}
      <div className="eventDetail scroll-without-category">
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center pr-3">
              <span onClick={() => handleBackClick()}>
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto  ">
                <h1 className="f-24 f-w600 mb-0">{"Buy Ticket"}</h1>
              </div>
              <div>
                <span
                  onClick={() => navigate("/home/notification")}
                  className="pl-2"
                >
                  <Icons
                    name="notification"
                    className="svg-size-24 svg-white"
                  />
                </span>
              </div>
            </div>
          </nav>
        </div>
        <>
          {showDetials && (
            <>
              {showDetials?.bannerimage && (
                <Card>
                  {Array.isArray(showDetials?.bannerimage) &&
                    showDetials?.bannerimage?.length > 1 ? (
                    <Carousel
                      // interval={null}
                      interval={2500}
                      controls={
                        showDetials?.bannerimage.length > 1 ? true : false
                      }
                    >
                      {showDetials?.bannerimage.map((image, index) => (
                        <Carousel.Item key={index}>
                          <div className="dark-image">
                            <Card.Img
                              variant="top"
                              style={{ height: "25vh", width: "100%" }}
                              src={image}
                            />
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <div className="dark-image">
                      <Card.Img
                        variant="top"
                        style={{ height: "25vh" }}
                        src={
                          showDetials?.bannerimage
                            ? showDetials?.bannerimage
                            : ""
                        }
                      />
                    </div>
                  )}
                </Card>
              )}
              {/* <img src={showDetials.user.singer.imageUrl} alt="" className='buyImage' /> */}

              <div className="d-grid title-card mt-4 p-2 mb-2 ">
                <div>
                  <h2 className="text-dark">{showDetials.heading}</h2>
                </div>
                <div className="date-time d-flex just justify-content-between mt-2">
                  <div className="d-grid p-0">
                    <h5 className="text-disabled f-14 f-w500 m-0">Date</h5>

                    <h3 className="text-dark f-12 f-w500 ">
                      {dateFormatting(showDetials?.start_time) ===
                        dateFormatting(showDetials?.expiration_time)
                        ? dateFormatting(showDetials?.start_time)
                        : `${dateFormatting(
                          showDetials?.start_time
                        )} - ${dateFormatting(showDetials.expiration_time)}`}
                    </h3>
                  </div>
                  <div className="d-grid p-0">
                    <h5 className="text-disabled f-14 f-w500 m-0">Time</h5>

                    <h3 className="text-dark f-12 f-w500 ">
                      {formatDate(
                        showDetials?.start_time,
                        "onlytimeWithoutSecound"
                      )}{" "}
                      to{" "}
                      {formatDate(
                        showDetials?.expiration_time,
                        "onlytimeWithoutSecound"
                      )}
                    </h3>
                  </div>
                </div>
                <div className="d-grid ">
                  <h5 className="text-disabled f-14 f-w500 mb-0">Location</h5>
                  {/* <h3 className="text-grey-dark f-16 f-w500 mb-0 ">{showDetials?.addressevent}</h3> */}
                  <h5 className="text-dark f-12 mb-2">
                    {showDetials?.addressevent}
                  </h5>
                </div>
              </div>
            </>
          )}
        </>

        {/* Ticket Calculate */}
        <div>
          {ticketCount.map((item, index) => {
            return (
              <div className="d-grid just mt-3" key={index}>
                <div className="d-flex justify-content-between">
                  <h4 className="text-dark mb-0"> {item.ticketName}</h4>
                  {/* {showDetials?.ticket &&  */}
                  <h4 className="text-dark mb-0">₹ {item.totalAmount}</h4>

                  {/* } */}
                </div>
                {/* <h5 className='text-disabled'>{item.ticketNumber}</h5> */}
                <h5 className="text-disabled">
                  {item.ticketNumber < 10
                    ? `0${item.ticketNumber}`
                    : item.ticketNumber}{" "}
                  {item.ticketNumber < 2 ? "Ticket" : "Tickets"}{" "}
                </h5>
              </div>
            );
          })}
          <hr />
          {/* tax and fees part */}

          <div className="d-grid just">
            {/* Taxes & Fees dropdown */}
            <Button
              aria-controls="more-info"
              aria-expanded={open}
              className="d-flex w-100 justify-content-between btn-tpt align-items-center p-0 border-0"
              style={{ fontWeight: 600 }}
              onClick={() => setOpen(!open)} // Toggle the dropdown state
            >
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="text-dark mt-2 mr-2">Taxes & Fees</h4>
                {open ? (
                  <Icons
                    name="arrow-up"
                    className="svg-size-14 svg-black mr-2"
                  />
                ) : (
                  <Icons
                    name="arrow-down"
                    className="svg-size-14 svg-black mr-2"
                  />
                )}
              </div>
              {/* <h4 className="text-dark mb-0">₹{totalTaxAmount.toFixed(2)}</h4> */}
              {showDetials?.tax && (
                <>
                  {Object.keys(JSON.parse(showDetials.tax)).map((taxKey) => {
                    const tax = JSON.parse(showDetials.tax)[taxKey];
                    let taxValue = tax.value;
                    let displayValue = taxValue;
                    if (tax.valuetype === "Fixed") {
                      totalTaxAmount += parseFloat(displayValue);
                    }

                    if (tax.valuetype === "percent") {
                      // Calculate the percentage value based on the pre-calculated subtotal
                      taxValue = (tax.value / 100) * subtotal;
                      displayValue = taxValue.toFixed(2);
                      totalTaxAmount += parseFloat(displayValue);
                    }
                  })}

                  <h4 className="text-dark mb-0">
                    ₹{totalTaxAmount.toFixed(2)}
                  </h4>
                </>
              )}
            </Button>

            {/* Taxes & Fees details */}
            <Collapse in={open}>
              <div id="more-info" className="ml-2">
                {showDetials?.tax && (
                  <>
                    {Object.keys(JSON.parse(showDetials.tax)).map((taxKey) => {
                      const tax = JSON.parse(showDetials.tax)[taxKey];
                      let taxValue = tax.value;
                      let displayValue = taxValue;

                      if (tax.valuetype === "percent") {
                        // Calculate the percentage value based on the pre-calculated subtotal
                        taxValue = (tax.value / 100) * subtotal;
                        displayValue = taxValue.toFixed(2);
                        totalTax += parseFloat(displayValue);
                      }
                      return (
                        <div
                          key={taxKey}
                          className="d-flex justify-content-between"
                        >
                          <div className="d-flex">
                            {tax.valuetype === "percent" ? (
                              <h5 className="text-disabled">
                                {tax.taxtype}@{tax.value}
                                {"%"}
                              </h5>
                            ) : (
                              <h5 className=" text-disabled">{tax.taxtype}</h5>
                            )}
                          </div>
                          <h5 className="text-disabled">₹{displayValue}</h5>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </Collapse>
          </div>
          <hr />

          {/* Total amount */}
          <div className="d-flex justify-content-between">
            <h4 className="text-dark"> Total Amount</h4>
            <h4 className="text-dark">
              {/* Add totalTaxAmount to the subtotal */}₹{" "}
              {(useCalculateSubtotal(ticketCount) + totalTaxAmount).toFixed(2)}
            </h4>
          </div>

          {/* <PaymentStatus status={status} setShow={setShow} show={show} /> */}
        </div>
        {successToast && (
          <Toast
            onClose={() => setSuccessToast(null)}
            show={true}
            delay={3000}
            className="report-toaster"
            autohide
          >
            <Toast.Body
              style={{ backgroundColor: "#C5FEB5", color: "#196004" }}
            >
              {'Please update your contact details in your profile to continue.'}
            </Toast.Body>
          </Toast>
        )}
        <div className="payButton">
          <Button
            // onClick={() => payFunction()}
            onClick={displayRazorpayTicket}
            loading={loader}
            disabled={useCalculateSubtotal(ticketCount) <= 0}
            style={{
              backgroundColor: "#E31E24",
              borderRadius: "12px",
              width: "100%",
              marginTop: "50px",
              marginBottom: "30px",
              color: "white",
            }}
            label="Proceed To Pay"
          />
        </div>
      </div>
    </>
  );
};
