import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mainCategory : {},
  activeMainCat : '/home'
}

const mainCatSlice = createSlice({
  name: 'mainCategory',
  initialState: initialState,
  reducers: {
    setmainCategory: (state, action) => {
      state.mainCategory = action?.payload
    },
    setActivemainCategory: (state, action) => {
      state.activeMainCat = action?.payload
    },
  },

})

export const { setmainCategory, setActivemainCategory } = mainCatSlice.actions

export default mainCatSlice.reducer
