import React from 'react'

export const ArrowLeft = ({
    className = "",
    width = "20px",
    height = "auto",
    viewBox = "0 0 20 20",
    fill = "",
}) => {
    return (

        <svg viewBox={viewBox} width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2171 1.30261C15.6179 1.70291 15.6179 2.35194 15.2171 2.75224L7.96014 10L15.2171 17.2478C15.6179 17.6481 15.6179 18.2971 15.2171 18.6974C14.8163 19.0977 14.1664 19.0977 13.7656 18.6974L5.78293 10.7248C5.59045 10.5326 5.48232 10.2719 5.48232 10C5.48232 9.72814 5.59045 9.46742 5.78293 9.27519L13.7656 1.30261C14.1664 0.902304 14.8163 0.902304 15.2171 1.30261Z" fill="" />
        </svg>



    )
}
